import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as CONSTANTS from 'constants';
import * as API from 'api';
import * as ACTIONS_WIZARD from 'actions/HelpWizard';
import * as ACTIONS_APP from 'actions/App';

function* requestGetWizardList({ payload }) {
    try {
        yield put(ACTIONS_WIZARD.setWizardList([]));
        const response = yield call(API.POST, payload.url, payload.data);
        if (!response && !response.data) {
            return;
        } else {
            yield put(ACTIONS_WIZARD.setWizardList(response.data.wizards));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestGetWizardSteps({ payload }) {
    try {
        yield put(ACTIONS_WIZARD.setWizardSteps([]));
        const response = yield call(API.POST, payload.url, payload.data);
        if (!response && !response.data) {
            return;
        } else {
            yield put(ACTIONS_WIZARD.setWizardSteps(response.data.steps));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestWizardContactSupport({ payload }) {
    try {
        const response = yield call(API.POST, payload.url, payload.data);
        if (!response && !response.data) {
            yield put(ACTIONS_APP.showMessage(`db.error`, 'warning'));
        } else {
            return;
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestGetUserWizardResponses({ payload }) {
    try {
        yield put(ACTIONS_WIZARD.setUserWizardResponses({}));
        const response = yield call(API.POST, payload.url, payload.data);
        if (!response && !response.data) {
            return;
        } else {
            yield put(ACTIONS_WIZARD.setUserWizardResponses(response.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectGetWizardList() {
    yield takeEvery(CONSTANTS.GET_WIZARD_LIST, requestGetWizardList);
}

export function* selectGetWizardSteps() {
    yield takeEvery(CONSTANTS.GET_WIZARD_STEPS, requestGetWizardSteps);
}

export function* selectWizardContactSupport() {
    yield takeEvery(CONSTANTS.WIZARD_CONTACT_SUPPORT, requestWizardContactSupport);
}

export function* selectGetUserWizardResponses() {
    yield takeEvery(CONSTANTS.GET_USER_WIZARD_RESPONSES, requestGetUserWizardResponses);
}

export default function* rootSaga() {
    yield all([
        fork(selectGetWizardList),
        fork(selectGetWizardSteps),
        fork(selectWizardContactSupport),
        fork(selectGetUserWizardResponses)
    ]);
}
