import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FormGroup, Input, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import { UserIcon } from 'utilities/icons';
import * as UTILS from 'utilities';

import { removeOnsiteUser } from 'actions/Users';

import CellIcon from 'components/Table/cell/components/icon';
import CellText from 'components/Table/cell/components/text';
import CellButton from 'components/Table/cell/components/button';

import Modal from 'components/Modal';
import Button from 'components/Button';
class DashboardOnsiteUsersCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            reason: 'tailgated'
        };
    }
    handleAction = (data) => {
        this.props.history.push(`/app/users/display/${data.id}`);
    };
    removeOnsiteUser = () => {
        const { removeOnsiteUser, data } = this.props;
        removeOnsiteUser({ userId: data.id, userUUID: data.uuid, reason: this.state.reason });
        this.setState({ showModal: false, reason: 'Tailgated' });
    };
    render() {
        const { intl, data, width, theme, authUser, site, userTimezone } = this.props;
        const { onSiteUpdated, name, role } = data;
        const { showModal, reason } = this.state;
        return (
            <>
                <Modal
                    show={showModal}
                    toggle={() => {
                        this.setState({ showModal: !showModal });
                    }}>
                    <h1>{intl.formatMessage({ id: `pages.dashboard.removeReason` })}</h1>
                    <FormGroup style={{ marginLeft: 20 }}>
                        <Label style={{ color: this.props.theme.color.fontColor }}>
                            <Input
                                type="radio"
                                name={'tailgated'}
                                checked={reason === 'tailgated'}
                                onChange={() => this.setState({ reason: 'tailgated' })}
                            />{' '}
                            {intl.formatMessage({ id: 'pages.dashboard.tailgated' })}
                        </Label>
                        <Label style={{ color: this.props.theme.color.fontColor }}>
                            <Input
                                type="radio"
                                name={'heldOpen'}
                                checked={reason === 'gateHeldOpen'}
                                onChange={() => this.setState({ reason: 'gateHeldOpen' })}
                            />{' '}
                            {intl.formatMessage({ id: 'pages.dashboard.gateHeldOpen' })}
                        </Label>
                        <Label style={{ color: this.props.theme.color.fontColor }}>
                            <Input
                                type="radio"
                                name={'other'}
                                checked={reason === 'other'}
                                onChange={() => this.setState({ reason: 'other' })}
                            />{' '}
                            {intl.formatMessage({ id: 'pages.dashboard.other' })}
                        </Label>
                    </FormGroup>
                    <div>
                        <Button
                            width={100}
                            load={false}
                            action={this.removeOnsiteUser}
                            text={'comp.button.submit'}
                            margin={'0'}
                            padding={'10px'}
                        />
                    </div>
                </Modal>
                {width > 800 && (
                    <CellIcon
                        checker={true}
                        update={() => this.handleAction(data)}
                        Icon={UserIcon}
                        Cellwidth={'45px'}
                        fill={theme.color.themeColor}
                        color={theme.color.buttonFontColor}
                        backgroundColor={theme.color.themeColor}
                    />
                )}
                <CellText
                    update={() => this.handleAction(data)}
                    textAlign={'left'}
                    TitleSize={11}
                    Title={name ? name : intl.formatMessage({ id: `form.na` })}
                    Description={role && role.name ? role.name : intl.formatMessage({ id: `form.na` })}
                />
                <CellText
                    update={() => this.handleAction(data)}
                    textAlign={'right'}
                    TitleSize={11}
                    Title={onSiteUpdated && onSiteUpdated !== "" ? UTILS.dateTimeFormatter(onSiteUpdated, authUser, site, userTimezone) : intl.formatMessage({ id: `form.na` })}
                />
                {/* Show 'remove' button if user has manage users perm (or, if target user is a tenant and user has both manage users and manage tenants) */}
                {((role &&
                    role.tier === 0 &&
                    authUser.permissions.includes('manage_users') &&
                    authUser.permissions.includes('manage_tenants')) ||
                    authUser.permissions.includes('manage_users')) && (
                    <CellButton
                        Cellwidth={'80px'}
                        action={() => this.setState({ showModal: true })}
                        Description={intl.formatMessage({ id: 'comp.button.remove' })}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, theme, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, theme, userTimezone, authUser, site };
};

export default withRouter(injectIntl(connect(mapStateToProps, { removeOnsiteUser })(DashboardOnsiteUsersCell)));
