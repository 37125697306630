import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { GatewayRebootIcon, SharedUserIcon } from 'utilities/icons';

import HeaderFilter from 'components/Header/table/filter';
import Search from 'components/Search';
import Filters from 'components/Filters/notifications/index';
import ToolTips from 'components/ToolTips';
class HeaderTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: true
        };
    }
    toggleShowFilters = () => {
        const { setOffsetFilterHeight } = this.props;
        const { showFilters } = this.state;

        this.setState({ showFilters: !showFilters });
        setOffsetFilterHeight && setOffsetFilterHeight(showFilters);
    };
    render() {
        const {
            intl,
            theme,
            title,
            filter,
            children,
            Icon,
            total,
            subTitle,
            search,
            data,
            update,
            action,
            width,
            headers,
            manage,
            icon,
            titleOverride,
            noReboot,
            site,
            authUser,
            border,
            iconPadding,
            marginBottom,
            headerColor
        } = this.props;
        const { showFilters } = this.state;
        this.styles = styles(theme, border, iconPadding, marginBottom, headerColor);
        let active = filter && filter.find((filt) => filt.active);
        return (
            <>
                <div className={css(this.styles.wrapper)}>
                    {width > 800 && Icon && <div className={css(this.styles.iconWrapper)}>{Icon}</div>}
                    {titleOverride ? (
                        <h2 className={css(this.styles.title)}>{titleOverride}</h2>
                    ) : (
                        <h2 className={css(this.styles.title)}>
                            {intl.formatMessage({ id: title })} {children}
                        </h2>
                    )}
                    {filter && <HeaderFilter data={filter} action={action} />}
                    {subTitle && (
                        <div className={css(this.styles.subInfo)}>
                            <p className={css(this.styles.total)}>
                                {subTitle && intl.formatMessage({ id: subTitle })}: {total}
                            </p>
                        </div>
                    )}
                    <div className={css(this.styles.topRight)}>
                        {search && data && (
                            <div className={css(this.styles.floatRight)}>
                                <Search
                                    update={update}
                                    filter={headers}
                                    searchTitle={active.title}
                                    searchType={headers}
                                    data={data}
                                />
                            </div>
                        )}
                        {noReboot && site && site.pms && site.pms === 'webhooks' && (
                            <div className={css(this.styles.floatRight)}>
                                <i
                                    id="uploadCsv"
                                    className={`material-icons ${css(this.styles.filter)}`}
                                    onClick={() => action()}>
                                    {'publish'}
                                </i>
                                <ToolTips target="uploadCsv" message="form.upload" />
                            </div>
                        )}
                        {manage &&
                            !noReboot &&
                            width > 500 &&
                            manage.map((item) => (
                                <div className={css(this.styles.linkWrapper)} key={'MANAGE' + item.link}>
                                    {item.action ? (
                                        <div
                                            onClick={() => item.action()}
                                            className={
                                                authUser &&
                                                authUser.permissions.includes('open_manager_shares') &&
                                                site &&
                                                site.allowManagerShares === 'true'
                                                    ? css(this.styles.link2)
                                                    : css(this.styles.link)
                                            }>
                                            <div id="rebootUnits" className={css(this.styles.filter)}>
                                                <GatewayRebootIcon color={theme.color.themeColor} />
                                            </div>
                                            <ToolTips target="rebootUnits" message="tooltip.rebootAllUnits" />
                                        </div>
                                    ) : (
                                        <Link
                                            to={item.link}
                                            className={
                                                authUser &&
                                                authUser.permissions.includes('open_manager_shares') &&
                                                site &&
                                                site.allowManagerShares === 'true'
                                                    ? css(this.styles.link3)
                                                    : css(this.styles.link2)
                                            }>
                                            <i
                                                id="upload"
                                                className={`material-icons ${css(this.styles.filter)}`}
                                                style={{ fontSize: 24 }}>
                                                {item.icon ? item.icon : icon}
                                            </i>
                                            <ToolTips target="upload" message="comp.form.edit" />
                                        </Link>
                                    )}
                                </div>
                            ))}
                        {!noReboot &&
                            width > 500 &&
                            authUser &&
                            authUser.permissions.includes('open_manager_shares') &&
                            site &&
                            site.allowManagerShares === 'true' && (
                                <Link to={'/app/units/shared'} className={css(this.styles.floatRight)}>
                                    <div id="sharedUnits" className={css(this.styles.filter)}>
                                        <SharedUserIcon color={theme.color.themeColor} />
                                    </div>
                                    <ToolTips target="sharedUnits" message="form.sharedManager" />
                                </Link>
                            )}
                        {width > 500 && !noReboot && (
                            <div className={css(this.styles.floatRight)}>
                                <i
                                    id="showFilters"
                                    className={`material-icons ${css(this.styles.filter)}`}
                                    onClick={this.toggleShowFilters}>
                                    {showFilters ? 'filter_list' : 'menu'}
                                </i>
                                <ToolTips target="showFilters" message="filters.showHide" />
                            </div>
                        )}
                    </div>
                </div>
                {showFilters && !noReboot && width > 500 && <Filters update={update} />}
            </>
        );
    }
}

HeaderTable.defaultProps = {
    title: 'blank',
    border: true,
    Icon: null,
    total: 0,
    subTitle: null,
    search: false,
    filter: false,
    manage: false,
    icon: 'add_circle_outline',
    headers: [],
    iconPadding: 0,
    marginBottom: 0,
    titleOverride: false
};

const mapStateToProps = ({ app, auth }) => {
    const { theme, width } = app;
    const { site, authUser } = auth;
    return { theme, width, site, authUser };
};

export default injectIntl(connect(mapStateToProps)(HeaderTable));

const styles = (theme, border, iconPadding, marginBottom, headerColor) =>
    StyleSheet.create({
        wrapper: {
            paddingBottom: border ? 5 : 0,
            marginBottom,
            borderBottom: border ? `solid 1px ${theme.color.borderColor}` : 'none',
            minHeight: 40,
            textAlign: 'left',
            position: 'relative'
        },
        topRight: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        title: {
            fontSize: 24,
            display: 'block',
            float: 'left',
            color: headerColor || theme.color.themeColor,
            margin: '0 0 10px 0'
        },
        subInfo: {
            display: 'block',
            float: 'right',
            textAlign: 'right'
        },
        total: {
            margin: 0
        },
        iconWrapper: {
            display: 'block',
            float: 'left',
            fontSize: 22,
            position: 'relative',
            marginRight: 10,
            paddingTop: iconPadding
        },
        floatRight: {
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        filter: {
            color: theme.color.themeColor,
            marginLeft: 8,
            ':hover': {
                cursor: 'pointer'
            }
        },
        linkWrapper: {
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        link: {
            color: theme.color.themeColor,
            fontSize: 14,
            fontWeight: 400,
            ':hover': {
                textDecoration: 'none'
            }
        },
        link2: {
            color: theme.color.themeColor,
            fontSize: 14,
            fontWeight: 400,
            ':hover': {
                textDecoration: 'none'
            }
        },
        link3: {
            color: theme.color.themeColor,
            fontSize: 14,
            fontWeight: 400,
            float: 'right',
            ':hover': {
                textDecoration: 'none'
            }
        }
    });
