import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { 
    fetchPmsSync, 
    fetchSharedUnits, 
    setSyncUnitsHeaders, 
    setSharedUnitsHeaders 
} from 'actions/Units';

import { ProgressIcon, UnitIcon } from 'utilities/icons';

import Table from 'components/Table';
import SyncUnitsCell from 'components/Table/cell/containers/dashboard/syncPms';
import SharedUnitsCell from 'components/Table/cell/containers/dashboard/sharedUnits';
import Header from 'components/Header/dashboard';
class DashboardSharedUnits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'pmsSync',
            filterList: null,
            sharedFilterList: null
        };
    }
    componentDidMount() {
        const { fetchSharedUnits, fetchPmsSync, editWidgets, visible } = this.props;
        (editWidgets || visible) && fetchSharedUnits();
        (editWidgets || visible) && fetchPmsSync();
    }
    updateView = (view) => {
        this.setState({ view });
    };
    recentUpdatesSearch = (filterList) => {
        this.setState({ filterList });
    };
    sharedUnitsSearch = (sharedFilterList) => {
        this.setState({ sharedFilterList });
    };
    render() {
        const { theme, sharedUnits, syncPmsChanges, editWidgets, syncUnitsHeaders, sharedUnitsHeaders, setSyncUnitsHeaders, setSharedUnitsHeaders  } = this.props;
        const { view, filterList, sharedFilterList } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <Header
                    sharedView={view}
                    updateSharedView={this.updateView}
                    Icon={view === 'pmsSync' ? ProgressIcon : UnitIcon}
                    iconSize={view === 'pmsSync' ? 34 : 26}
                    title={view === 'pmsSync' ? 'comp.form.recentUpdates' : 'pages.units.shared'}
                    subtitle={view == 'sharedUnits' && sharedUnits ? sharedUnits.length : null}
                    editWidgets={editWidgets}
                    syncPmsChanges={syncPmsChanges}
                    syncUnitsHeaders={syncUnitsHeaders}
                    sharedUnits={sharedUnits}
                    sharedUnitsHeaders={sharedUnitsHeaders}
                    recentUpdatesSearch={this.recentUpdatesSearch}
                    sharedUnitsSearch={this.sharedUnitsSearch}
                />
                {view === 'sharedUnits' && (
                    <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                        <Col xs={12} style={{ padding: 0 }}>
                            <Table
                                tableHeight={185}
                                data={sharedFilterList ? sharedFilterList : sharedUnits}
                                header={sharedUnitsHeaders}
                                headerAction={setSharedUnitsHeaders}
                                Cell={SharedUnitsCell}
                                moreItemsLoading={false}
                                itemSize={editWidgets ? 50 : 24}
                                hasNextPage={false}
                                force={true}
                            />
                        </Col>
                    </Row>
                )}
                {view === 'pmsSync' && (
                    <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                        <Col xs={12} style={{ padding: 0 }}>
                            <Table
                                tableHeight={183}
                                data={filterList ? filterList : syncPmsChanges}
                                header={syncUnitsHeaders}
                                headerAction={setSyncUnitsHeaders}
                                Cell={SyncUnitsCell}
                                moreItemsLoading={false}
                                itemSize={editWidgets ? 50 : 38}
                                hasNextPage={false}
                                force={true}
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app, units }) => {
    const { theme, width } = app;
    const { sharedUnits, syncPmsChanges, syncUnitsHeaders, sharedUnitsHeaders } = units;
    return { theme, width, sharedUnits, syncPmsChanges, syncUnitsHeaders, sharedUnitsHeaders };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, { 
        fetchPmsSync, 
        fetchSharedUnits,
        setSyncUnitsHeaders, 
        setSharedUnitsHeaders 
    })(DashboardSharedUnits))
);

const styles = (theme) =>
    StyleSheet.create({
        footerRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 5,
            fontSize: 12,
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        footerRowShort: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 5,
            fontSize: 12,
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        footerRowEdit: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: 10,
            '@media(max-width:1384px)': {
                fontSize: 9
            }
        },
        footerItemActive: {
            color: 'white',
            padding: '5px 7px',
            margin: '0 6px',
            borderRadius: 4,
            background: theme.color.themeColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8',
                boxShadow: theme.color.boxShadowCornerHover
            }
        },
        footerItem: {
            color: theme.color.fontColor,
            padding: '5px 7px',
            margin: '0 6px',
            borderRadius: 4,
            background: theme.color.pageColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8',
                boxShadow: theme.color.boxShadowCornerHover
            }
        },
        search: {
            float: 'right'
        }
    });
