import * as CONSTANTS from 'constants';
import moment from 'moment-timezone';

export const phoneConverter = (num) => {
    if (!num) return null;
    let phone = num;
    phone = phone.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3');
    return phone;
};
export const hexToRGBA = (hex, a = 1) => {
    if (!hex) return 'rgba(0,0,0,0.5)';
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${a})`;
};
export const validNumber = (e) => {
    if (!e) return false;
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
        return true;
    } else {
        return false;
    }
};
export const validBirthDate = (data) => {
    if (!data) return '';
    let result = `${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(4)}`;
    return result;
};
export const getDateFormat = (authUser, site) => {
    if (authUser && authUser.preferredDateFormat == 'default') {
        if (site && site.sitePreferredDateFormat == 'DD-MM-YYYY') {
            return 'DD-MM-YYYY';
        } else {
            return 'MM/DD/YYYY';
        }
    } else if (authUser && authUser.preferredDateFormat == 'DD-MM-YYYY') {
            return 'DD-MM-YYYY';
    } else {
            return 'MM/DD/YYYY';
    }
};
export const dateFormatter = (date, authUser, site) => {
    if (!date) return '';
    if(authUser && authUser.preferredDateFormat == 'default'){
        if(site && site.sitePreferredDateFormat == 'DD-MM-YYYY'){
            return moment(date).format('DD-MM-YYYY');
        } else {
            return moment(date).format('MM/DD/YYYY');
        }
    } else if (authUser && authUser.preferredDateFormat == 'DD-MM-YYYY'){
            return moment(date).format('DD-MM-YYYY');
    } else {
            return moment(date).format('MM/DD/YYYY');
    }
};
export const dateTimeFormatter = (dateTime, authUser, site, userTimezone) => {
    if (!dateTime || (!site || !site.timeZone) || !userTimezone) return '';
    if(authUser && authUser.preferredDateFormat == 'default'){
        if(site && site.sitePreferredDateFormat == 'DD-MM-YYYY'){
            if(authUser && authUser.useSiteTime && site){
                return moment(dateTime).tz(site.timeZone).format('DD-MM-YYYY LT');
            } else {
                return moment(dateTime).tz(userTimezone).format('DD-MM-YYYY LT');
            }
        } else {
            if(authUser && authUser.useSiteTime && site){
                return moment(dateTime).tz(site.timeZone).format('MM/DD/YYYY LT');
            } else {
                return moment(dateTime).tz(userTimezone).format('MM/DD/YYYY LT');
            }
        }
    } else if (authUser && authUser.preferredDateFormat == 'DD-MM-YYYY'){
        if(authUser && authUser.useSiteTime && site){
            return moment(dateTime).tz(site.timeZone).format('DD-MM-YYYY LT');
        } else {
            return moment(dateTime).tz(userTimezone).format('DD-MM-YYYY LT');
        }
    } else {
        if(authUser && authUser.useSiteTime && site){
            return moment(dateTime).tz(site.timeZone).format('MM/DD/YYYY LT');
        } else {
            return moment(dateTime).tz(userTimezone).format('MM/DD/YYYY LT');
        }
    }
};
export const updateCheckbox = (newData, array, e, parse) => {
    let name = parse ? JSON.parse(e.target.name) : e.target.name;
    if (e.target.checked) {
        newData[array].push(name);
        return newData;
    } else {
        newData[array] = newData[array].filter((item) => item != name);
        return newData;
    }
};
export const updateCheckbox2 = (newData, e, parse) => {
    let name = parse ? JSON.parse(e.target.name) : e.target.name;
    if (e.target.checked) {
        newData.push(name);
        return newData;
    } else {
        newData = newData.filter((item) => item != name);
        return newData;
    }
};
export const updateCheckbox3 = (newData = [], name, checked) => {
    if (checked) {
        newData.push(name);
        return newData;
    } else {
        newData = newData.filter((item) => item != name);
        return newData;
    }
};
export const findIndex = (list, key, property) => {
    if (list == null || !key || !property) return;
    let active = list.findIndex((item) => item[key] === property);
    return active;
};
export const checkForScrollbars = (element, dir) => {
    if (!element) return false;
    dir = dir === 'vertical' ? 'scrollTop' : 'scrollLeft';
    let res = !!element[dir];
    if (!res) {
        element[dir] = 1;
        res = !!element[dir];
        element[dir] = 0;
    }
    return res;
};
export const cleanUpArray = (arr = [], comp = 'id') => {
    const unique = arr
        .map((e) => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e])
        .map((e) => arr[e]);
    return unique;
};
export const removeDuplicates = (arr = [], filter = []) => {
    const listOfTags = arr,
        keys = filter,
        output = listOfTags.filter(
            (
                (s) => (o) =>
                    ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join('|'))
            )(new Set())
        );
    return output;
};
export const findArrayEquals = (arr = [], key = '', valueToCheck) => {
    let found = false;
    arr.some((value) => {
        if (value[key] === valueToCheck) {
            found = true;
            return true;
        }
    });
    return found;
};
export const filterList = (list = [], type, searchValue = '') => {
    if (list == null || type == null || !searchValue) return null;
    let newList = list.filter((obj) =>
        Object.keys(obj).some((key) => {
            if (type.includes(key)) {
                return JSON.stringify(obj[key]).toLowerCase().includes(searchValue.toLowerCase());
            }
        })
    );
    return newList;
};
export const filterListStart = (list = [], type, searchValue = '') => {
    let newList = list.filter((obj) => obj.name.toLowerCase().startsWith(searchValue.toLowerCase()));
    return newList;
};
export const filterArray = (list = [], type = '', array = []) => {
    let newList = list.filter((obj) => array.includes(obj[type]));
    newList = newList.length > 0 ? newList : [];
    return newList;
};
export const sortList = (order = true, list = [], property = '') => {
    // Intl object not supported by all browsers. This could be useless because we use Intl in many npm modules
    if (typeof Intl == 'undefined') return list;
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    if (!collator) return list;
    list.sort((a, b) => collator.compare(order ? b[property] : a[property], order ? a[property] : b[property]));
    return list;
};
export const checkPMS = (site) => {
    if (!site) return false;
    return !CONSTANTS.PMS_BYPASS.includes(site.pms);
};
export const moveListToFront = (compareList = [], list = [], property = '') => {
    let temp = list.filter((item) => compareList.includes(item[property]));
    list = list.filter((item) => !compareList.includes(item[property]));
    let newList = temp.concat(list);
    return newList;
};
export const moveListToFront2 = (list = [], property = '', checker) => {
    let temp = list.filter((item) => item[property] === checker);
    list = list.filter((item) => item[property] != checker);
    let newList = temp.concat(list);
    return newList;
};
export const lightenDarkenColor = (color, percent) => {
    if (!color || !percent) return null;
    var num = parseInt(color.replace('#', ''), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00ff) + amt,
        G = (num & 0x0000ff) + amt;

    return (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
        .toString(16)
        .slice(1);
};
export const setWithExpiry = (key, value, ttl) => {
    if (!key) return null;
    const item = {
        value: value,
        expiry: new Date().getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
};
export const getWithExpiry = (key) => {
    if (!key) return null;
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
};
export const waitForElement = (selector) => {
    if (!selector) return null;
    return new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
};
export const removeAllToolTips = () => {
    const tooltips = document.querySelectorAll('.my-tooltip');
    tooltips.forEach((node) => node.remove());
};
export const generateTextID = (length = 15) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};
export const checkInView = (container, element, partial = true) => {
    if (!container || !element) return false;
    //Get container properties
    let cTop = container.scrollTop;
    let cBottom = cTop + container.clientHeight;

    //Get element properties
    let eTop = element.offsetTop;
    let eBottom = eTop + element.clientHeight;

    //Check if in view
    let isTotal = eTop >= cTop && eBottom <= cBottom;
    let isPartial = partial && ((eTop < cTop && eBottom > cTop) || (eBottom > cBottom && eTop < cBottom));

    //Return outcome
    return isTotal || isPartial;
};
export const isOverflown = (element) => {
    if (!element) return false;
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};
export const validateEmail = (email) => {
    if (!email) return '';
    var sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
    var sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
    var sAtom = '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
    var sQuotedPair = '\\x5c[\\x00-\\x7f]';
    var sDomainLiteral = '\\x5b(' + sDtext + '|' + sQuotedPair + ')*\\x5d';
    var sQuotedString = '\\x22(' + sQtext + '|' + sQuotedPair + ')*\\x22';
    var sDomain_ref = sAtom;
    var sSubDomain = '(' + sDomain_ref + '|' + sDomainLiteral + ')';
    var sWord = '(' + sAtom + '|' + sQuotedString + ')';
    var sDomain = sSubDomain + '(\\x2e' + sSubDomain + ')*';
    var sLocalPart = sWord + '(\\x2e' + sWord + ')*';
    var sAddrSpec = sLocalPart + '\\x40' + sDomain; // complete RFC822 email address spec
    var sValidEmail = '^' + sAddrSpec + '$'; // as whole string
    var reValidEmail = new RegExp(sValidEmail);
    return reValidEmail.test(email);
};
export const limitStringLength = (str, len) => {
    if (!str || !len) return '';
    return len < str.length ? `${str.substr(0, len)}...` : str;
};
export const getCursorPos = (input) => {
    if (!input) return -1;
    if ("selectionStart" in input && document.activeElement == input) {
        return {
            start: input.selectionStart,
            end: input.selectionEnd
        };
    }
    else if (input.createTextRange) {
        var sel = document.selection.createRange();
        if (sel.parentElement() === input) {
            var rng = input.createTextRange();
            rng.moveToBookmark(sel.getBookmark());
            for (var len = 0;
                     rng.compareEndPoints("EndToStart", rng) > 0;
                     rng.moveEnd("character", -1)) {
                len++;
            }
            rng.setEndPoint("StartToStart", input.createTextRange());
            for (var pos = { start: 0, end: len };
                     rng.compareEndPoints("EndToStart", rng) > 0;
                     rng.moveEnd("character", -1)) {
                pos.start++;
                pos.end++;
            }
            return pos;
        }
    }
    return -1;
}

export const setCursorPos = (ctrl, pos) => {
    if (!ctrl || !pos) return;
    if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(pos, pos);

    // IE8 and below
    } else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
}

export const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

export const getCountryCode = (flag) => {
    if (flag == '🇦🇩') return 'AD';
    if (flag == '🇦🇪') return 'AE';
    if (flag == '🇦🇫') return 'AF';
    if (flag == '🇦🇬') return 'AG';
    if (flag == '🇦🇮') return 'AI';
    if (flag == '🇦🇱') return 'AL';
    if (flag == '🇦🇲') return 'AM';
    if (flag == '🇦🇴') return 'AO';
    if (flag == '🇦🇶') return 'AQ';
    if (flag == '🇦🇷') return 'AR';
    if (flag == '🇦🇸') return 'AS';
    if (flag == '🇦🇹') return 'AT';
    if (flag == '🇦🇺') return 'AU';
    if (flag == '🇦🇼') return 'AW';
    if (flag == '🇦🇽') return 'AX';
    if (flag == '🇦🇿') return 'AZ';
    if (flag == '🇧🇦') return 'BA';
    if (flag == '🇧🇧') return 'BB';
    if (flag == '🇧🇩') return 'BD';
    if (flag == '🇧🇪') return 'BE';
    if (flag == '🇧🇫') return 'BF';
    if (flag == '🇧🇬') return 'BG';
    if (flag == '🇧🇭') return 'BH';
    if (flag == '🇧🇮') return 'BI';
    if (flag == '🇧🇯') return 'BJ';
    if (flag == '🇧🇱') return 'BL';
    if (flag == '🇧🇲') return 'BM';
    if (flag == '🇧🇳') return 'BN';
    if (flag == '🇧🇴') return 'BO';
    if (flag == '🇧🇶') return 'BQ';
    if (flag == '🇧🇷') return 'BR';
    if (flag == '🇧🇸') return 'BS';
    if (flag == '🇧🇹') return 'BT';
    if (flag == '🇧🇻') return 'BV';
    if (flag == '🇧🇼') return 'BW';
    if (flag == '🇧🇾') return 'BY';
    if (flag == '🇧🇿') return 'BZ';
    if (flag == '🇨🇦') return 'CA';
    if (flag == '🇨🇨') return 'CC';
    if (flag == '🇨🇩') return 'CD';
    if (flag == '🇨🇫') return 'CF';
    if (flag == '🇨🇬') return 'CG';
    if (flag == '🇨🇭') return 'CH';
    if (flag == '🇨🇮') return 'CI';
    if (flag == '🇨🇰') return 'CK';
    if (flag == '🇨🇱') return 'CL';
    if (flag == '🇨🇲') return 'CM';
    if (flag == '🇨🇳') return 'CN';
    if (flag == '🇨🇴') return 'CO';
    if (flag == '🇨🇷') return 'CR';
    if (flag == '🇨🇺') return 'CU';
    if (flag == '🇨🇻') return 'CV';
    if (flag == '🇨🇼') return 'CW';
    if (flag == '🇨🇽') return 'CX';
    if (flag == '🇨🇾') return 'CY';
    if (flag == '🇨🇿') return 'CZ';
    if (flag == '🇩🇪') return 'DE';
    if (flag == '🇩🇯') return 'DJ';
    if (flag == '🇩🇰') return 'DK';
    if (flag == '🇩🇲') return 'DM';
    if (flag == '🇩🇴') return 'DO';
    if (flag == '🇩🇿') return 'DZ';
    if (flag == '🇪🇨') return 'EC';
    if (flag == '🇪🇪') return 'EE';
    if (flag == '🇪🇬') return 'EG';
    if (flag == '🇪🇭') return 'EH';
    if (flag == '🇪🇷') return 'ER';
    if (flag == '🇪🇸') return 'ES';
    if (flag == '🇪🇹') return 'ET';
    if (flag == '🇫🇮') return 'FI';
    if (flag == '🇫🇯') return 'FJ';
    if (flag == '🇫🇰') return 'FK';
    if (flag == '🇫🇲') return 'FM';
    if (flag == '🇫🇴') return 'FO';
    if (flag == '🇫🇷') return 'FR';
    if (flag == '🇬🇦') return 'GA';
    if (flag == '🇬🇧') return 'GB';
    if (flag == '🇬🇩') return 'GD';
    if (flag == '🇬🇪') return 'GE';
    if (flag == '🇬🇫') return 'GF';
    if (flag == '🇬🇬') return 'GG';
    if (flag == '🇬🇭') return 'GH';
    if (flag == '🇬🇮') return 'GI';
    if (flag == '🇬🇱') return 'GL';
    if (flag == '🇬🇲') return 'GM';
    if (flag == '🇬🇳') return 'GN';
    if (flag == '🇬🇵') return 'GP';
    if (flag == '🇬🇶') return 'GQ';
    if (flag == '🇬🇷') return 'GR';
    if (flag == '🇬🇸') return 'GS';
    if (flag == '🇬🇹') return 'GT';
    if (flag == '🇬🇺') return 'GU';
    if (flag == '🇬🇼') return 'GW';
    if (flag == '🇬🇾') return 'GY';
    if (flag == '🇭🇰') return 'HK';
    if (flag == '🇭🇲') return 'HM';
    if (flag == '🇭🇳') return 'HN';
    if (flag == '🇭🇷') return 'HR';
    if (flag == '🇭🇹') return 'HT';
    if (flag == '🇭🇺') return 'HU';
    if (flag == '🇮🇩') return 'ID';
    if (flag == '🇮🇪') return 'IE';
    if (flag == '🇮🇱') return 'IL';
    if (flag == '🇮🇲') return 'IM';
    if (flag == '🇮🇳') return 'IN';
    if (flag == '🇮🇴') return 'IO';
    if (flag == '🇮🇶') return 'IQ';
    if (flag == '🇮🇷') return 'IR';
    if (flag == '🇮🇸') return 'IS';
    if (flag == '🇮🇹') return 'IT';
    if (flag == '🇯🇪') return 'JE';
    if (flag == '🇯🇲') return 'JM';
    if (flag == '🇯🇴') return 'JO';
    if (flag == '🇯🇵') return 'JP';
    if (flag == '🇰🇪') return 'KE';
    if (flag == '🇰🇬') return 'KG';
    if (flag == '🇰🇭') return 'KH';
    if (flag == '🇰🇮') return 'KI';
    if (flag == '🇰🇲') return 'KM';
    if (flag == '🇰🇳') return 'KN';
    if (flag == '🇰🇵') return 'KP';
    if (flag == '🇰🇷') return 'KR';
    if (flag == '🇰🇼') return 'KW';
    if (flag == '🇰🇾') return 'KY';
    if (flag == '🇰🇿') return 'KZ';
    if (flag == '🇱🇦') return 'LA';
    if (flag == '🇱🇧') return 'LB';
    if (flag == '🇱🇨') return 'LC';
    if (flag == '🇱🇮') return 'LI';
    if (flag == '🇱🇰') return 'LK';
    if (flag == '🇱🇷') return 'LR';
    if (flag == '🇱🇸') return 'LS';
    if (flag == '🇱🇹') return 'LT';
    if (flag == '🇱🇺') return 'LU';
    if (flag == '🇱🇻') return 'LV';
    if (flag == '🇱🇾') return 'LY';
    if (flag == '🇲🇦') return 'MA';
    if (flag == '🇲🇨') return 'MC';
    if (flag == '🇲🇩') return 'MD';
    if (flag == '🇲🇪') return 'ME';
    if (flag == '🇲🇫') return 'MF';
    if (flag == '🇲🇬') return 'MG';
    if (flag == '🇲🇭') return 'MH';
    if (flag == '🇲🇰') return 'MK';
    if (flag == '🇲🇱') return 'ML';
    if (flag == '🇲🇲') return 'MM';
    if (flag == '🇲🇳') return 'MN';
    if (flag == '🇲🇴') return 'MO';
    if (flag == '🇲🇵') return 'MP';
    if (flag == '🇲🇶') return 'MQ';
    if (flag == '🇲🇷') return 'MR';
    if (flag == '🇲🇸') return 'MS';
    if (flag == '🇲🇹') return 'MT';
    if (flag == '🇲🇺') return 'MU';
    if (flag == '🇲🇻') return 'MV';
    if (flag == '🇲🇼') return 'MW';
    if (flag == '🇲🇽') return 'MX';
    if (flag == '🇲🇾') return 'MY';
    if (flag == '🇲🇿') return 'MZ';
    if (flag == '🇳🇦') return 'NA';
    if (flag == '🇳🇨') return 'NC';
    if (flag == '🇳🇪') return 'NE';
    if (flag == '🇳🇫') return 'NF';
    if (flag == '🇳🇬') return 'NG';
    if (flag == '🇳🇮') return 'NI';
    if (flag == '🇳🇱') return 'NL';
    if (flag == '🇳🇴') return 'NO';
    if (flag == '🇳🇵') return 'NP';
    if (flag == '🇳🇷') return 'NR';
    if (flag == '🇳🇺') return 'NU';
    if (flag == '🇳🇿') return 'NZ';
    if (flag == '🇴🇲') return 'OM';
    if (flag == '🇵🇦') return 'PA';
    if (flag == '🇵🇪') return 'PE';
    if (flag == '🇵🇫') return 'PF';
    if (flag == '🇵🇬') return 'PG';
    if (flag == '🇵🇭') return 'PH';
    if (flag == '🇵🇰') return 'PK';
    if (flag == '🇵🇱') return 'PL';
    if (flag == '🇵🇲') return 'PM';
    if (flag == '🇵🇳') return 'PN';
    if (flag == '🇵🇷') return 'PR';
    if (flag == '🇵🇸') return 'PS';
    if (flag == '🇵🇹') return 'PT';
    if (flag == '🇵🇼') return 'PW';
    if (flag == '🇵🇾') return 'PY';
    if (flag == '🇶🇦') return 'QA';
    if (flag == '🇷🇪') return 'RE';
    if (flag == '🇷🇴') return 'RO';
    if (flag == '🇷🇸') return 'RS';
    if (flag == '🇷🇺') return 'RU';
    if (flag == '🇷🇼') return 'RW';
    if (flag == '🇸🇦') return 'SA';
    if (flag == '🇸🇧') return 'SB';
    if (flag == '🇸🇨') return 'SC';
    if (flag == '🇸🇩') return 'SD';
    if (flag == '🇸🇪') return 'SE';
    if (flag == '🇸🇬') return 'SG';
    if (flag == '🇸🇭') return 'SH';
    if (flag == '🇸🇮') return 'SI';
    if (flag == '🇸🇯') return 'SJ';
    if (flag == '🇸🇰') return 'SK';
    if (flag == '🇸🇱') return 'SL';
    if (flag == '🇸🇲') return 'SM';
    if (flag == '🇸🇳') return 'SN';
    if (flag == '🇸🇴') return 'SO';
    if (flag == '🇸🇷') return 'SR';
    if (flag == '🇸🇸') return 'SS';
    if (flag == '🇸🇹') return 'ST';
    if (flag == '🇸🇻') return 'SV';
    if (flag == '🇸🇽') return 'SX';
    if (flag == '🇸🇾') return 'SY';
    if (flag == '🇸🇿') return 'SZ';
    if (flag == '🇹🇨') return 'TC';
    if (flag == '🇹🇩') return 'TD';
    if (flag == '🇹🇫') return 'TF';
    if (flag == '🇹🇬') return 'TG';
    if (flag == '🇹🇭') return 'TH';
    if (flag == '🇹🇯') return 'TJ';
    if (flag == '🇹🇰') return 'TK';
    if (flag == '🇹🇱') return 'TL';
    if (flag == '🇹🇲') return 'TM';
    if (flag == '🇹🇳') return 'TN';
    if (flag == '🇹🇴') return 'TO';
    if (flag == '🇹🇷') return 'TR';
    if (flag == '🇹🇹') return 'TT';
    if (flag == '🇹🇻') return 'TV';
    if (flag == '🇹🇼') return 'TW';
    if (flag == '🇹🇿') return 'TZ';
    if (flag == '🇺🇦') return 'UA';
    if (flag == '🇺🇬') return 'UG';
    if (flag == '🇺🇲') return 'UM';
    if (flag == '🇺🇸') return 'US';
    if (flag == '🇺🇾') return 'UY';
    if (flag == '🇺🇿') return 'UZ';
    if (flag == '🇻🇦') return 'VA';
    if (flag == '🇻🇨') return 'VC';
    if (flag == '🇻🇪') return 'VE';
    if (flag == '🇻🇬') return 'VG';
    if (flag == '🇻🇮') return 'VI';
    if (flag == '🇻🇳') return 'VN';
    if (flag == '🇻🇺') return 'VU';
    if (flag == '🇼🇫') return 'WF';
    if (flag == '🇼🇸') return 'WS';
    if (flag == '🇽🇰') return 'XK';
    if (flag == '🇾🇪') return 'YE';
    if (flag == '🇾🇹') return 'YT';
    if (flag == '🇿🇦') return 'ZA';
    if (flag == '🇿🇲') return 'ZM';
    return 'US';
};

// Check for European countries as well as Saudi Arabia

export const checkEurope = (site) => {
    let countries = ['UK',
    'GB',
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'IS',
    'LI',
    'NO',
    'CH',
    'AL',
    'ME',
    'MK',
    'RS',
    'TR',
    'AD',
    'BY',
    'BA',
    'MD',
    'MC',
    'RU',
    'SM',
    'UA',
    'VA',
    'SA',
    'EU']
    if(site && site.country && countries.includes(site.country)){
        return true
    } else { return false }
}
