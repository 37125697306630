import * as CONSTANTS from 'constants';

const INIT_STATE = {
    wizardList: [],
    wizardSteps: [],
    userWizardResponses: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_WIZARD_LIST: {
            return {
                ...state,
                wizardList: action.payload
            };
        }
        case CONSTANTS.SET_WIZARD_STEPS: {
            return {
                ...state,
                wizardSteps: action.payload
            };
        }
        case CONSTANTS.SET_USER_WIZARD_RESPONSES: {
            return {
                ...state,
                userWizardResponses: action.payload
            };
        }
        default:
            return state;
    }
};
