import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as FIREBASE from 'api/firebase';
import * as UTILS from 'utilities';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_NOTIFICATIONS from 'actions/Notifications';

const getNotificationHeaders = (state) => state.notifications.notificationsHeader;
const getAuthUser = (state) => state.auth.authUser;
const getUserNotifications = (state) => state.notifications.userNotifications;

function* requestUserNotifications({ payload }) {
    let authUser = yield select(getAuthUser);
    try {
        if (authUser && authUser.role && authUser.role.tier && authUser.role.tier !== 1) {
            let notification = yield call(API.POST, payload.url, payload.data);
            if (!notification || !notification.data.notifications) {
                if (payload.page === 1) {
                    yield put(ACTIONS_NOTIFICATIONS.setUserNotifications([]));
                } else {
                    return;
                }
            } else {
                let notificationHeaders = yield select(getNotificationHeaders);
                let activeHeader = notificationHeaders.find((item) => item.active);
                if (payload.page > 1) {
                    if (notification.data.notifications) {
                        let userNotifications = yield select(getUserNotifications);
                        let addedList = userNotifications.concat(notification.data.notifications);
                        notification = UTILS.sortList(!activeHeader.order, addedList, activeHeader.sortTitle);
                        yield put(ACTIONS_NOTIFICATIONS.setUserNotifications(notification));
                    }
                } else {
                    notification = UTILS.sortList(
                        !activeHeader.order,
                        notification.data.notifications,
                        activeHeader.sortTitle
                    );
                    yield put(ACTIONS_NOTIFICATIONS.setUserNotifications(notification));
                }
            }
        } else {
            yield put(ACTIONS_NOTIFICATIONS.setUserNotifications([]));
        }
    } catch (error) {
        yield put(ACTIONS_NOTIFICATIONS.setUserNotifications([]));
        console.warn(error);
    }
}

function* requestUserNotificationsCompany({ payload }) {
    try {
        yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsCompany(null));
        let notification = yield call(API.POST, payload.url, payload.data);
        if (!notification || !notification.data.notifications) {
            if (payload.page === 1) {
                yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsCompany([]));
            } else {
                return;
            }
        } else {
            let notificationHeaders = yield select(getNotificationHeaders);
            let activeHeader = notificationHeaders.find((item) => item.active);
            if (payload.page > 1) {
                if (notification.data.notifications) {
                    let userNotifications = yield select(getUserNotifications);
                    let addedList = userNotifications.concat(notification.data.notifications);
                    notification = UTILS.sortList(!activeHeader.order, addedList, activeHeader.sortTitle);
                    yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsCompany(notification));
                }
            } else {
                notification = UTILS.sortList(
                    !activeHeader.order,
                    notification.data.notifications,
                    activeHeader.sortTitle
                );
                yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsCompany(notification));
            }
        }
    } catch (error) {
        yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsCompany([]));
        console.warn(error);
    }
}

function* requestNotificationsToken() {
    try {
        let nokeNotify = JSON.parse(localStorage.getItem('nse_notify'))
            ? JSON.parse(localStorage.getItem('nse_notify'))
            : { token: null, from: "I'm not superstitious but I am a little stitious - Michael Scott (Steve Carell)" };
        let token = nokeNotify.token;
        if (token) {
            return;
        } else {
            let Newtoken = yield call(
                FIREBASE.FIREBASE_ALLOW_NOTIFICATIONS,
                CONSTANTS.FIREBASE_NOTIFICATIONS_MESSAGING
            );
            nokeNotify.token = Newtoken;
            localStorage.setItem('nse_notify', JSON.stringify(nokeNotify));
            if (Newtoken) {
                yield put(ACTIONS_NOTIFICATIONS.registerNotificationsDevice(Newtoken));
                yield put(ACTIONS_NOTIFICATIONS.fetchUserNotifications());
            } else {
                throw 'Token not created...';
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserNotificationsUpdate({ payload }) {
    try {
        const update = yield call(
            FIREBASE.FIREBASE_UPDATE,
            CONSTANTS.FIREBASE_NOTIFICATIONS,
            'id',
            payload.data.id,
            payload.data
        );
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_NOTIFICATIONS.fetchUserNotifications());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserNotificationsRemove({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_NOTIFICATIONS.fetchUserNotifications());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestConfirmation({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            if (payload.refresh) {
                let authUser = JSON.parse(sessionStorage.getItem('user_data'));
                let siteUUID = JSON.parse(localStorage.getItem('nse_login_data')).siteUUID || authUser.defaultSiteUUID;
                let site = authUser.sites.find((site) => site.uuid === siteUUID);
                yield put(ACTIONS_APP.fetchStartup(authUser, site));
            }
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateInternalNotification({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchUserNotificationsSuite({ payload }) {
    try {
        yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsSuite(null));
        let authUser = JSON.parse(sessionStorage.getItem('user_data'));
        let notification = yield call(API.POST, payload.url, payload.data);
        if (!notification) {
            yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsSuite([]));
        } else {
            notification.data.userNotificationsSettings =
                notification.data &&
                notification.data.userNotificationsSettings &&
                authUser &&
                authUser.role &&
                authUser.role.tier == 1 &&
                notification.data.userNotificationsSettings.length === 6
                    ? [
                          {
                              notificationsType: 'entryNotifications',
                              notificationsEmails: notification.data.userNotificationsSettings[0].notificationsEmails,
                              notificationsSMS: notification.data.userNotificationsSettings[0].notificationsSMS,
                              notificationsPush: notification.data.userNotificationsSettings[0].notificationsPush
                          },
                          {
                              notificationsType: 'gatewayOffline',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'lowBattery',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'manualNotifications',
                              notificationsEmails: notification.data.userNotificationsSettings[1].notificationsEmails,
                              notificationsSMS: notification.data.userNotificationsSettings[1].notificationsSMS,
                              notificationsPush: notification.data.userNotificationsSettings[1].notificationsPush
                          },
                          {
                              notificationsType: 'motionNotifications',
                              notificationsEmails: notification.data.userNotificationsSettings[2].notificationsEmails,
                              notificationsSMS: notification.data.userNotificationsSettings[2].notificationsSMS,
                              notificationsPush: notification.data.userNotificationsSettings[2].notificationsPush
                          },
                          {
                              notificationsType: 'otherNotifications',
                              notificationsEmails: notification.data.userNotificationsSettings[3].notificationsEmails,
                              notificationsSMS: notification.data.userNotificationsSettings[3].notificationsSMS,
                              notificationsPush: notification.data.userNotificationsSettings[3].notificationsPush
                          },
                          {
                              notificationsType: 'repoNotifications',
                              notificationsEmails: notification.data.userNotificationsSettings[4].notificationsEmails,
                              notificationsSMS: notification.data.userNotificationsSettings[4].notificationsSMS,
                              notificationsPush: notification.data.userNotificationsSettings[4].notificationsPush
                          },
                          {
                              notificationsType: 'unitNotifications',
                              notificationsEmails: notification.data.userNotificationsSettings[5].notificationsEmails,
                              notificationsSMS: notification.data.userNotificationsSettings[5].notificationsSMS,
                              notificationsPush: notification.data.userNotificationsSettings[5].notificationsPush
                          },
                          {
                              notificationsType: 'unitOffline',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          }
                      ]
                    : notification.data &&
                      notification.data.userNotificationsSettings &&
                      authUser &&
                      authUser.role &&
                      authUser.role.tier == 1 &&
                      notification.data.userNotificationsSettings.length === 9
                    ? notification.data.userNotificationsSettings
                    : notification.data && notification.data.userNotificationsSettings
                    ? notification.data.userNotificationsSettings
                    : authUser && authUser.role && authUser.role.tier == 1
                    ? [
                          {
                              notificationsType: 'entryNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'gatewayOffline',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'lowBattery',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'manualNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'motionNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'otherNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'repoNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'unitNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'unitOffline',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          }
                      ]
                    : [
                          {
                              notificationsType: 'entryNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'manualNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'motionNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'otherNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'repoNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'unitNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          }
                      ];
            notification.data &&
                notification.data.userNotificationsSettings.filter(
                    (setting) => setting.notificationsType === 'temperature'
                ).length === 0 &&
                notification.data.userNotificationsSettings.push({
                    notificationsType: 'temperature',
                    notificationsEmails: false,
                    notificationsSMS: false,
                    notificationsPush: false
                });
            notification.data &&
                notification.data.userNotificationsSettings.filter(
                    (setting) => setting.notificationsType === 'targettedNotifications'
                ).length === 0 &&
                notification.data.userNotificationsSettings.push({
                    notificationsType: 'targettedNotifications',
                    notificationsEmails: false,
                    notificationsSMS: false,
                    notificationsPush: false
                });
            notification.data.userNotificationsSettings = UTILS.sortList(
                false,
                notification.data.userNotificationsSettings,
                'notificationsType'
            );
            yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsSuite(notification.data));
        }
    } catch (error) {
        yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsSuite([]));
        console.warn(error);
    }
}

function* requestSupportFetchUserNotificationsSuite({ payload }) {
    try {
        yield put(ACTIONS_NOTIFICATIONS.supportSetUserNotificationsSuite(null));
        let notification = yield call(API.POST, payload.url, payload.data);
        if (!notification) {
            yield put(ACTIONS_NOTIFICATIONS.supportSetUserNotificationsSuite([]));
        } else {
            notification.data.userNotificationsSettings =
                notification.data && notification.data.userNotificationsSettings
                    ? notification.data.userNotificationsSettings
                    : [
                          {
                              notificationsType: 'entryNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'manualNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'motionNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'otherNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'repoNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          },
                          {
                              notificationsType: 'unitNotifications',
                              notificationsEmails: false,
                              notificationsSMS: false,
                              notificationsPush: false
                          }
                      ];
            notification.data &&
                notification.data.userNotificationsSettings.filter(
                    (setting) => setting.notificationsType === 'temperature'
                ).length === 0 &&
                notification.data.userNotificationsSettings.push({
                    notificationsType: 'temperature',
                    notificationsEmails: false,
                    notificationsSMS: false,
                    notificationsPush: false
                });
            notification.data &&
                notification.data.userNotificationsSettings.filter(
                    (setting) => setting.notificationsType === 'targettedNotifications'
                ).length === 0 &&
                notification.data.userNotificationsSettings.push({
                    notificationsType: 'targettedNotifications',
                    notificationsEmails: false,
                    notificationsSMS: false,
                    notificationsPush: false
                });
            notification.data.userNotificationsSettings = UTILS.sortList(
                false,
                notification.data.userNotificationsSettings,
                'notificationsType'
            );
            yield put(ACTIONS_NOTIFICATIONS.supportSetUserNotificationsSuite(notification.data));
        }
    } catch (error) {
        yield put(ACTIONS_NOTIFICATIONS.supportSetUserNotificationsSuite([]));
        console.warn(error);
    }
}

function* requestUpdateUserNotificationsSuite({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

// same as requestUpdateUserNotificationsSuite, just only shows error notif if there is an error
function* requestUpdateUserNotificationsSuiteSilently({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.setSaveLoader(false));
            yield put(ACTIONS_APP.showMessage('db.error', 'error'));
        } else {
            yield put(ACTIONS_APP.setSaveLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSupportUpdateUserNotificationsSuite({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSupportUpdateUserNotificationsSuiteSilently({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.setSaveLoader(false));
            yield put(ACTIONS_APP.showMessage('db.error', 'error'));
        } else {
            yield put(ACTIONS_APP.setSaveLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchUserNotificationsPush({ payload }) {
    try {
        let notification = yield call(API.POST, payload.url, payload.data);
        if (!notification || !notification.data.notifications) {
            yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsPush([]));
        } else {
            notification.data.notifications = UTILS.sortList(true, notification.data.notifications, 'createdDate');
            yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsPush(notification.data.notifications));
        }
    } catch (error) {
        yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsPush([]));
        console.warn(error);
    }
}

function* requestFetchUserNotificationsEmail({ payload }) {
    try {
        let notification = yield call(API.POST, payload.url, payload.data);
        if (!notification || !notification.data.notifications) {
            yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsEmail([]));
        } else {
            notification.data.notifications = UTILS.sortList(true, notification.data.notifications, 'createdDate');
            yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsEmail(notification.data.notifications));
        }
    } catch (error) {
        yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsEmail([]));
        console.warn(error);
    }
}

function* requestFetchUserNotificationsSms({ payload }) {
    try {
        let notification = yield call(API.POST, payload.url, payload.data);
        if (!notification || !notification.data.notifications) {
            yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsSms([]));
        } else {
            notification.data.notifications = UTILS.sortList(true, notification.data.notifications, 'createdDate');
            yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsSms(notification.data.notifications));
        }
    } catch (error) {
        yield put(ACTIONS_NOTIFICATIONS.setUserNotificationsSms([]));
        console.warn(error);
    }
}

function* requestFetchNotificationTypes({ payload }) {
    try {
        let types = yield call(API.POST, payload.url, payload.data);
        if (!types || !types.data || !types.data.types) {
            yield put(ACTIONS_NOTIFICATIONS.setNotificationsTypes([]));
        } else {
            yield put(ACTIONS_NOTIFICATIONS.setNotificationsTypes(types.data.types));
        }
    } catch (error) {
        yield put(ACTIONS_NOTIFICATIONS.setNotificationsTypes([]));
        console.warn(error);
    }
}

function* requestCreateOnsiteNotification({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.request', 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectUserNotifications() {
    yield takeEvery(CONSTANTS.FETCH_USER_NOTIFICATIONS, requestUserNotifications);
    yield takeEvery(CONSTANTS.FETCH_USER_NOTIFICATIONS, requestNotificationsToken);
}
export function* selectUserNotificationsCompany() {
    yield takeEvery(CONSTANTS.FETCH_USER_NOTIFICATIONS_COMPANY, requestUserNotificationsCompany);
}
export function* selectRegisterNotificationsDevice() {
    yield takeEvery(CONSTANTS.REGISTER_NOTIFICATIONS_DEVICE, requestConfirmation);
}
export function* selectTestNotificationsDevice() {
    yield takeEvery(CONSTANTS.TEST_NOTIFICATIONS_DEVICE, requestConfirmation);
}
export function* selectUpdateUserNotifications() {
    yield takeEvery(CONSTANTS.UPDATE_USER_NOTIFICATIONS, requestUserNotificationsUpdate);
}
export function* selectRemoveUserNotifications() {
    yield takeEvery(CONSTANTS.REMOVE_USER_NOTIFICATIONS, requestUserNotificationsRemove);
}
export function* selectCreateInternalNotification() {
    yield takeEvery(CONSTANTS.CREATE_INTERNAL_NOTIFICATION, requestCreateInternalNotification);
}
export function* selectFetchUserNotificationsSuite() {
    yield takeEvery(CONSTANTS.FETCH_USER_NOTIFICATIONS_SUITE, requestFetchUserNotificationsSuite);
}
export function* selectSupportFetchUserNotificationsSuite() {
    yield takeEvery(CONSTANTS.SUPPORT_FETCH_USER_NOTIFICATIONS_SUITE, requestSupportFetchUserNotificationsSuite);
}
export function* selectUpdateUserNotificationsSuite() {
    yield takeEvery(CONSTANTS.UPDATE_USER_NOTIFICATIONS_SUITE, requestUpdateUserNotificationsSuite);
}
export function* selectUpdateUserNotificationsSuiteSilently() {
    yield takeEvery(CONSTANTS.UPDATE_USER_NOTIFICATIONS_SUITE_SILENTLY, requestUpdateUserNotificationsSuiteSilently);
}
export function* selectSupportUpdateUserNotificationsSuite() {
    yield takeEvery(CONSTANTS.SUPPORT_UPDATE_USER_NOTIFICATIONS_SUITE, requestSupportUpdateUserNotificationsSuite);
}
export function* selectSupportUpdateUserNotificationsSuiteSilently() {
    yield takeEvery(CONSTANTS.SUPPORT_UPDATE_USER_NOTIFICATIONS_SUITE_SILENTLY, requestSupportUpdateUserNotificationsSuiteSilently);
}
export function* selectFetchUserNotificationsPush() {
    yield takeEvery(CONSTANTS.FETCH_USER_NOTIFICATIONS_PUSH, requestFetchUserNotificationsPush);
}
export function* selectFetchUserNotificationsEmail() {
    yield takeEvery(CONSTANTS.FETCH_USER_NOTIFICATIONS_EMAIL, requestFetchUserNotificationsEmail);
}
export function* selectFetchUserNotificationsSms() {
    yield takeEvery(CONSTANTS.FETCH_USER_NOTIFICATIONS_SMS, requestFetchUserNotificationsSms);
}
export function* selectFetchNotificationTypes() {
    yield takeEvery(CONSTANTS.FETCH_NOTIFICATIONS_TYPES, requestFetchNotificationTypes);
}
export function* selectCreateOnsiteNotification() {
    yield takeEvery(CONSTANTS.CREATE_ONSITE_NOTIFICATION, requestCreateOnsiteNotification);
}

export default function* rootSaga() {
    yield all([
        fork(selectUserNotifications),
        fork(selectUserNotificationsCompany),
        fork(selectRegisterNotificationsDevice),
        fork(selectTestNotificationsDevice),
        fork(selectUpdateUserNotifications),
        fork(selectRemoveUserNotifications),
        fork(selectCreateInternalNotification),
        fork(selectFetchUserNotificationsSuite),
        fork(selectSupportFetchUserNotificationsSuite),
        fork(selectUpdateUserNotificationsSuite),
        fork(selectUpdateUserNotificationsSuiteSilently),
        fork(selectSupportUpdateUserNotificationsSuite),
        fork(selectSupportUpdateUserNotificationsSuiteSilently),
        fork(selectFetchUserNotificationsPush),
        fork(selectFetchUserNotificationsEmail),
        fork(selectFetchUserNotificationsSms),
        fork(selectFetchNotificationTypes),
        fork(selectCreateOnsiteNotification)
    ]);
}
