import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import CheckboxInput from 'components/Inputs/checkbox';

import { fetchUserNotificationsCompany, setManagerNotificationFilters } from 'actions/Notifications';

import Button from 'components/Button';
class FiltersNotificationSites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            sitesList: []
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.managerNotificationFilters !== this.props.managerNotificationFilters) {
            let { managerNotificationFilters } = this.props;
            this.setState({ sitesList: managerNotificationFilters.siteUUIDs });
        }
    }
    handleChange = (uuid) => {
        let { sitesList } = this.state;
        if (sitesList.includes(uuid)) {
            sitesList.splice(sitesList.indexOf(uuid), 1);
            this.setState({ sitesList: sitesList });
        } else {
            sitesList.push(uuid);
            this.setState({ sitesList: sitesList });
        }
    };
    handleSave = () => {
        let { managerNotificationFilters, fetchUserNotificationsCompany, setManagerNotificationFilters } = this.props;
        let { sitesList } = this.state;
        let notificationFilters = JSON.parse(JSON.stringify(managerNotificationFilters));
        notificationFilters.siteUUIDs = sitesList;
        setManagerNotificationFilters(notificationFilters);
        fetchUserNotificationsCompany(notificationFilters);
        this.setState({ show: false });
    };
    render() {
        const { intl, theme, onsiteUsersManager } = this.props;
        const { show, sitesList } = this.state;
        this.styles = styles(theme);
        return (
            <Dropdown isOpen={show} toggle={() => this.setState({ show: !show })}>
                <DropdownToggle
                    className={`d-inline-block ${css(this.styles.hover)}`}
                    tag="span"
                    data-toggle="dropdown">
                    <span className={css(this.styles.headerTitle)}>
                        {sitesList.length > 1
                            ? `(${sitesList.length}) ${intl.formatMessage({
                                  id: 'pages.sites.Types'
                              })}`
                            : sitesList.length > 0
                            ? `(${sitesList.length}) ${intl.formatMessage({
                                  id: 'pages.sites.Type'
                              })}`
                            : `${intl.formatMessage({
                                  id: 'comp.button.all'
                              })} ${intl.formatMessage({ id: 'pages.sites.Types' })}`}
                    </span>{' '}
                    <i className={`material-icons`}>arrow_drop_down</i>
                </DropdownToggle>
                <DropdownMenu className={css(this.styles.wrapper)}>
                    <h1 className={css(this.styles.header)}>{intl.formatMessage({ id: 'pages.sites.Types' })}</h1>
                    <hr className={css(this.styles.hr)} />
                    <div className={css(this.styles.table)}>
                        <div className={css(this.styles.checkWrapper)}>
                            <CheckboxInput
                                checked={sitesList.length === 0}
                                onClick={() => this.setState({ sitesList: [] })}
                                overrideLabel={true}
                                label={
                                    <>
                                        {intl.formatMessage({ id: 'comp.button.all' })}{' '}
                                        {intl.formatMessage({ id: 'pages.sites.Types' })}
                                    </>
                                }
                            />
                        </div>
                        {onsiteUsersManager &&
                            onsiteUsersManager.map((site) => (
                                <div key={'TYPE' + site.Name + site.uuid} className={css(this.styles.checkWrapper)}>
                                    <CheckboxInput
                                        checked={sitesList.includes(site.uuid)}
                                        onClick={() => this.handleChange(site.uuid)}
                                        overrideLabel={true}
                                        label={site.name}
                                    />
                                </div>
                            ))}
                    </div>
                    <hr className={css(this.styles.hr)} />
                    <ul className={css(this.styles.list)}>
                        <li className={css(this.styles.listItem)}>
                            <Button
                                text={'comp.button.close'}
                                outline={true}
                                action={() => this.setState({ show: !show })}
                                size={'sm'}
                            />
                        </li>
                        <li className={css(this.styles.listItem)}>
                            <Button text={'comp.button.update'} action={() => this.handleSave()} size={'sm'} />
                        </li>
                    </ul>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

const mapStateToProps = ({ app, users, notifications }) => {
    const { theme } = app;
    const { onsiteUsersManager } = users;
    const { managerNotificationFilters } = notifications;
    return { theme, onsiteUsersManager, managerNotificationFilters };
};

export default injectIntl(
    connect(mapStateToProps, {
        fetchUserNotificationsCompany,
        setManagerNotificationFilters
    })(FiltersNotificationSites)
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            padding: '10px 20px',
            backgroundColor: theme.color.compColor,
            border: `solid 1px ${theme.color.borderColor}`,
            boxShadow: theme.color.boxShadow
        },
        header: {
            fontSize: 16,
            margin: 0,
            color: theme.color.fontColor
        },
        headerTitle: {
            color: theme.color.fontColor
        },
        hr: {
            margin: '10px 0',
            borderColor: theme.color.borderColor
        },
        list: {
            padding: 0,
            margin: 0,
            display: 'table',
            width: '100%',
            color: theme.color.themeColor
        },
        listItem: {
            display: 'table-cell'
        },
        table: {
            height: 200,
            overflowY: 'auto'
        },
        checkWrapper: {
            padding: 5,
            textAlign: 'left',
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor
        },
        checkBoxField: {
            fontWeight: 400
        },
        hover: {
            ':hover': {
                cursor: 'pointer'
            }
        }
    });
