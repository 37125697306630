import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const createEntry = (firstName = '', lastName = '', email = '', phone = '', countryCode = '', type = '') => {
    return {
        type: CONSTANTS.CREATE_ENTRY,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_CREATE,
            required: {
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                countryCode: countryCode,
                type: type
            },
            data: {
                firstName,
                lastName,
                email,
                phone,
                countryCode,
                type
            }
        }
    };
};

export const deleteEntry = (unitUUID = '0') => {
    return {
        type: CONSTANTS.DELETE_ENTRY,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_DELETE,
            data: { unitUUID }
        }
    };
};

export const editEntry = (UserUUID = '0', firstName = '', lastName = '') => {
    return {
        type: CONSTANTS.EDIT_ENTRY,
        payload: {
            url: ENDPOINTS.ENDPOINT_USER_EDIT,
            data: {
                UserUUID,
                firstName,
                lastName
            }
        }
    };
};

export const fetchEntryList = (
    filter = ['gate', 'door', 'elevator', 'sample', 'repeater', 'slidingdoor', 'exitgate'],
    fetchStats
) => {
    return {
        type: CONSTANTS.FETCH_ENTRY_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_ENTRY_LIST,
            filter,
            data: {},
            fetchStats
        }
    };
};

export const fetchEntryDetails = (unitUUID = '') => {
    return {
        type: CONSTANTS.FETCH_ENTRY_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_ENTRY_LIST,
            data: { unitUUID }
        }
    };
};

export const setEntryList = (data = []) => {
    return {
        type: CONSTANTS.SET_ENTRY_LIST,
        payload: data
    };
};

export const setEntryListStats = (data = []) => {
    return {
        type: CONSTANTS.SET_ENTRY_LIST_STATS,
        payload: data
    };
};

export const setEntryListLockStats = (data = []) => {
    return {
        type: CONSTANTS.SET_ENTRY_LIST_LOCK_STATS,
        payload: data
    };
};

export const setEntryLockTypeFilter = (data) => {
    return {
        type: CONSTANTS.SET_ENTRY_LOCK_TYPE_FILTER,
        payload: data
    };
};

export const setEntryDetails = (data = null) => {
    return {
        type: CONSTANTS.SET_ENTRY_DETAILS,
        payload: data
    };
};

export const setEntryHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ENTRY_HEADERS,
        payload: data
    };
};

export const setEntryViewHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ENTRY_VIEW_HEADERS,
        payload: data
    };
};

export const setEntryFilters = (data) => {
    return {
        type: CONSTANTS.SET_ENTRY_FILTERS,
        payload: data
    };
};

export const setActiveEntry = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVE_ENTRY,
        payload: data
    };
};

export const resendEntryCode = (lockUUID = '') => {
    return {
        type: CONSTANTS.RESEND_ENTRY_CODE,
        payload: {
            url: '/keypad/setup/',
            data: {
                lockUUIDs: [lockUUID]
            }
        }
    };
};
