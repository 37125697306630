import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchReportList = () => {
    return {
        type: CONSTANTS.FETCH_REPORT_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_REPORT_LIST,
            data: {}
        }
    };
};

export const fetchReportDetails = ({ SiteUUID = '' }) => {
    return {
        type: CONSTANTS.FETCH_REPORT_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_REPORT_DETAILS,
            data: {
                SiteUUID
            }
        }
    };
};

export const fetchFacilities = () => {
    return {
        type: CONSTANTS.FETCH_FACILITIES,
        payload: {
            url: ENDPOINTS.ENDPOINT_FACILITIES_LIST,
            data: {}
        }
    };
};

export const fetchReportGatewayData = ({ SiteId = '', GatewayId = '' }) => {
    return {
        type: CONSTANTS.FETCH_REPORT_GATEWAY_DATA,
        payload: {
            SiteId: Number(SiteId),
            GatewayId: Number(GatewayId),
            SiteUUID: SiteId,
            GatewayUUID: GatewayId
        }
    };
};

export const fetchReportLockData = ({ Mac = '' }) => {
    return {
        type: CONSTANTS.FETCH_REPORT_LOCK_DATA,
        payload: {
            url: `${ENDPOINTS.ENDPOINT_REPORT_HISTORY}/${Mac}`
        }
    };
};

export const setReportList = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_LIST,
        payload: data
    };
};

export const setReportDetails = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_DETAILS,
        payload: data
    };
};

export const setReportGatewayData = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_GATEWAY_DATA,
        payload: data
    };
};

export const setReportLockData = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_LOCK_DATA,
        payload: data
    };
};

export const setReportSiteHeaders = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_SITE_HEADERS,
        payload: data
    };
};

export const setReportSiteAdvancedHeaders = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_SITE_ADVANCED_HEADERS,
        payload: data
    }
}

export const setReportLockHeaders = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_LOCK_HEADERS,
        payload: data
    };
};

export const setReportLockDetailHeaders = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_LOCK_DETAIL_HEADERS,
        payload: data
    };
};

export const setReportGatewayHeaders = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_GATEWAY_HEADERS,
        payload: data
    };
};

export const setReportVersionHeaders = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_VERSION_HEADERS,
        payload: data
    };
};

export const setReportFilters = (data) => {
    return {
        type: CONSTANTS.SET_REPORT_FILTERS,
        payload: data
    };
};

export const setSiteLockTotals = (data) => {
    return {
        type: CONSTANTS.SET_SITE_LOCK_TOTALS,
        payload: data
    };
};

export const setSiteGatewayTotals = (data) => {
    return {
        type: CONSTANTS.SET_SITE_GATEWAY_TOTALS,
        payload: data
    };
};

export const setSiteUnitFilter = (data) => {
    return {
        type: CONSTANTS.SET_SITE_UNIT_FILTER,
        payload: data
    };
};

export const setSiteGatewayFilter = (data) => {
    return {
        type: CONSTANTS.SET_SITE_GATEWAY_FILTER,
        payload: data
    };
};

export const fetchCrossSiteSeachResults = (keyword) => {
    return {
        type: CONSTANTS.FETCH_CROSS_SITE_SEARCH_RESULTS,
        payload: {
            url: '/search/all/',
            data: {
                keyword
            }
        }
    };
};

export const setCrossSiteSearchResults = (data) => {
    return {
        type: CONSTANTS.SET_CROSS_SITE_SEARCH_RESULTS,
        payload: data
    };
};

export const emailSitesList = () => {
    return {
        type: CONSTANTS.EMAIL_SITES_LIST,
        payload: {
            url: '/support/sites/export/',
            data: {}
        }
    };
};

export const fetchSuspiciousReport = (period) => {
    return {
        type: CONSTANTS.FETCH_SUSPICIOUS_REPORT,
        payload: {
            url: '/suspicious/report/read/',
            data: {
                period
            }
        }
    };
};

export const setSuspiciousReport = (data) => {
    return {
        type: CONSTANTS.SET_SUSPICIOUS_REPORT,
        payload: data
    };
};

export const fetchSuspiciousReportTop = (period) => {
    return {
        type: CONSTANTS.FETCH_SUSPICIOUS_REPORT_TOP,
        payload: {
            url: '/suspicious/report/top/',
            data: {
                period
            }
        }
    };
};

export const setSuspiciousReportTop = (data) => {
    return {
        type: CONSTANTS.SET_SUSPICIOUS_REPORT_TOP,
        payload: data
    };
};

export const fetchSuspiciousReportUser = ({ period = '', userUUID = '' }) => {
    return {
        type: CONSTANTS.FETCH_SUSPICIOUS_REPORT_USER,
        payload: {
            url: '/suspicious/report/user/',
            data: {
                period,
                userUUID
            }
        }
    };
};

export const setSuspiciousReportUser = (data) => {
    return {
        type: CONSTANTS.SET_SUSPICIOUS_REPORT_USER,
        payload: data
    };
};

export const exportSuspiciousReport = (period) => {
    return {
        type: CONSTANTS.EXPORT_SUSPICIOUS_REPORT,
        payload: {
            url: '/suspicious/report/export/',
            data: {
                period,
                toEmail: JSON.parse(sessionStorage.getItem('user_data')).email
            }
        }
    };
};

export const fetchSuspiciousManagerReport = (period) => {
    return {
        type: CONSTANTS.FETCH_SUSPICIOUS_MANAGER_REPORT,
        payload: {
            url: '/suspicious/manager/report/read/',
            data: {
                period
            }
        }
    };
};

export const setSuspiciousManagerReport = (data) => {
    return {
        type: CONSTANTS.SET_SUSPICIOUS_MANAGER_REPORT,
        payload: data
    };
};

export const fetchSuspiciousManagerReportTop = (period) => {
    return {
        type: CONSTANTS.FETCH_SUSPICIOUS_MANAGER_REPORT_TOP,
        payload: {
            url: '/suspicious/manager/report/top/',
            data: {
                period
            }
        }
    };
};

export const setSuspiciousManagerReportTop = (data) => {
    return {
        type: CONSTANTS.SET_SUSPICIOUS_MANAGER_REPORT_TOP,
        payload: data
    };
};

export const fetchSuspiciousManagerReportUser = ({ period = '', userUUID = '' }) => {
    return {
        type: CONSTANTS.FETCH_SUSPICIOUS_MANAGER_REPORT_USER,
        payload: {
            url: '/suspicious/manager/report/user/',
            data: {
                period,
                userUUID
            }
        }
    };
};

export const setSuspiciousManagerReportUser = (data) => {
    return {
        type: CONSTANTS.SET_SUSPICIOUS_MANAGER_REPORT_USER,
        payload: data
    };
};

export const exportSuspiciousManagerReport = (period) => {
    return {
        type: CONSTANTS.EXPORT_SUSPICIOUS_MANAGER_REPORT,
        payload: {
            url: '/suspicious/manager/report/export/',
            data: {
                period,
                toEmail: JSON.parse(sessionStorage.getItem('user_data')).email
            }
        }
    };
};

export const exportSuspiciousReportTop = (period) => {
    return {
        type: CONSTANTS.EXPORT_SUSPICIOUS_REPORT_TOP,
        payload: {
            url: '/suspicious/report/top/export/',
            data: {
                period,
                toEmail: JSON.parse(sessionStorage.getItem('user_data')).email
            }
        }
    };
};

export const exportSuspiciousManagerReportTop = (period) => {
    return {
        type: CONSTANTS.EXPORT_SUSPICIOUS_MANAGER_REPORT_TOP,
        payload: {
            url: '/suspicious/manager/report/top/export/',
            data: {
                period,
                toEmail: JSON.parse(sessionStorage.getItem('user_data')).email
            }
        }
    };
};
