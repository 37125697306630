import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import CheckboxInput from 'components/Inputs/checkbox';

import { fetchUserNotificationsCompany, setManagerNotificationFilters } from 'actions/Notifications';

import Button from 'components/Button';
class FiltersNotificationsTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            typesList: []
        };
    }
    handleChange = (type) => {
        let { typesList } = this.state;
        if (typesList.includes(type)) {
            typesList.splice(typesList.indexOf(type), 1);
            this.setState({ typesList: typesList });
        } else {
            typesList.push(type);
            this.setState({ typesList: typesList });
        }
    };
    handleSave = () => {
        let { managerNotificationFilters, fetchUserNotificationsCompany, setManagerNotificationFilters } = this.props;
        let { typesList } = this.state;
        let notificationFilters = JSON.parse(JSON.stringify(managerNotificationFilters));
        notificationFilters.notificationTypes = typesList;
        setManagerNotificationFilters(notificationFilters);
        fetchUserNotificationsCompany(notificationFilters);
        this.setState({ show: false });
    };
    render() {
        const { intl, theme, notificationTypes } = this.props;
        const { show, typesList } = this.state;
        this.styles = styles(theme);
        return (
            <Dropdown isOpen={show} toggle={() => this.setState({ show: !show })}>
                <DropdownToggle
                    className={`d-inline-block ${css(this.styles.hover)}`}
                    tag="span"
                    data-toggle="dropdown">
                    <span className={css(this.styles.headerTitle)}>
                        {typesList.length > 1
                            ? `(${typesList.length}) ${intl.formatMessage({
                                  id: 'comp.activity.types.some'
                              })}`
                            : typesList.length > 0
                            ? `(${typesList.length}) ${intl.formatMessage({
                                  id: 'comp.table.header.type'
                              })}`
                            : `${intl.formatMessage({
                                  id: 'pages.notifications.Type'
                              })} ${intl.formatMessage({ id: 'comp.activity.types.some' })}`}
                    </span>{' '}
                    <i className={`material-icons`}>arrow_drop_down</i>
                </DropdownToggle>
                <DropdownMenu className={css(this.styles.wrapper)}>
                    <h1 className={css(this.styles.header)}>
                        {intl.formatMessage({ id: 'pages.notifications.Type' })}{' '}
                        {intl.formatMessage({ id: 'comp.activity.types.some' })}
                    </h1>
                    <hr className={css(this.styles.hr)} />
                    <div className={css(this.styles.table)}>
                        <div className={css(this.styles.checkWrapper)}>
                            <CheckboxInput
                                checked={typesList.length < 1}
                                onClick={() => this.setState({ typesList: [] })}
                                label={'comp.activity.types.all'}
                            />
                        </div>
                        {notificationTypes &&
                            notificationTypes.map((type) => (
                                <div key={'TYPE' + type} className={css(this.styles.checkWrapper)}>
                                    <CheckboxInput
                                        checked={typesList.includes(type)}
                                        onClick={() => this.handleChange(type)}
                                        overrideLabel={true}
                                        style={{ textTransform: 'capitalize' }}
                                        label={type.split('_').join(' ')}
                                    />
                                </div>
                            ))}
                    </div>
                    <hr className={css(this.styles.hr)} />
                    <ul className={css(this.styles.list)}>
                        <li className={css(this.styles.listItem)}>
                            <Button
                                text={'comp.button.close'}
                                outline={true}
                                action={() => this.setState({ show: !show })}
                                size={'sm'}
                            />
                        </li>
                        <li className={css(this.styles.listItem)}>
                            <Button text={'comp.button.update'} action={() => this.handleSave()} size={'sm'} />
                        </li>
                    </ul>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

const mapStateToProps = ({ app, notifications }) => {
    const { theme } = app;
    const { notificationTypes, managerNotificationFilters } = notifications;
    return { theme, notificationTypes, managerNotificationFilters };
};

export default injectIntl(
    connect(mapStateToProps, {
        fetchUserNotificationsCompany,
        setManagerNotificationFilters
    })(FiltersNotificationsTypes)
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            padding: '10px 20px',
            backgroundColor: theme.color.compColor,
            border: `solid 1px ${theme.color.borderColor}`,
            boxShadow: theme.color.boxShadow
        },
        header: {
            fontSize: 16,
            margin: 0,
            color: theme.color.fontColor
        },
        headerTitle: {
            color: theme.color.fontColor
        },
        hr: {
            margin: '10px 0',
            borderColor: theme.color.borderColor
        },
        list: {
            padding: 0,
            margin: 0,
            display: 'table',
            width: '100%',
            color: theme.color.themeColor
        },
        listItem: {
            display: 'table-cell'
        },
        table: {
            height: 210,
            overflowY: 'auto'
        },
        checkWrapper: {
            padding: 5,
            textAlign: 'left',
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor
        },
        checkBoxField: {
            fontWeight: 400,
            textTransform: 'capitalize'
        },
        hover: {
            ':hover': {
                cursor: 'pointer'
            }
        }
    });
