import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';

import { fetchUserNotificationsCompany, fetchNotificationsTypes } from 'actions/Notifications';

import { NotifyIcon } from 'utilities/icons';

import Spinner from 'components/Spinner';

import Table from 'components/Table';
import Header from 'components/Header/table/managerNotifications';
import HeaderTableInterface from 'components/Header/table/interface';
import NotificationCell from 'components/Table/cell/containers/users/notificationsManagerDash';

class DashboardCompanyNotifications extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { fetchUserNotificationsCompany, fetchNotificationsTypes, managerNotificationFilters } = this.props;
        fetchUserNotificationsCompany(managerNotificationFilters);
        fetchNotificationsTypes();
    }
    render() {
        const { intl, theme, userNotificationsCompany } = this.props;
        return (
            <>
                <Row>
                    <Col xs={12}>
                        <HeaderTableInterface filterHeight={38} hideWidth={500}>
                            <Header
                                title="pages.home.notifications"
                                update={(filterList) => this.setState({ filterList })}
                                Icon={<NotifyIcon color={theme.color.themeColor} />}
                                headerColor={theme.color.fontColor}
                            />
                            {userNotificationsCompany ? (
                                userNotificationsCompany.length > 0 ? (
                                    <Table
                                        tableHeight={240}
                                        maxHeight={240}
                                        border={false}
                                        data={userNotificationsCompany}
                                        Cell={NotificationCell}
                                        moreItemsLoading={false}
                                        itemSize={40}
                                        force={true}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            minHeight: 110,
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            animationName: fadeIn,
                                            animationDuration: '1.2s'
                                        }}>
                                        <p>
                                            {intl.formatMessage({
                                                id: 'comp.table.cell.emptyNotifications'
                                            })}
                                        </p>
                                    </div>
                                )
                            ) : (
                                <Spinner />
                            )}
                        </HeaderTableInterface>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, notifications }) => {
    const { theme } = app;
    const { userNotificationsCompany, managerNotificationFilters } = notifications;
    return { theme, userNotificationsCompany, managerNotificationFilters };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, { fetchUserNotificationsCompany, fetchNotificationsTypes })(
            DashboardCompanyNotifications
        )
    )
);
