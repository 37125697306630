import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchGatewayKeeperList = (filter = [true, false]) => {
    return {
        type: CONSTANTS.FETCH_GATEWAY_KEEPER_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_GATEWAY_KEEPER_LIST,
            filter,
            data: {}
        }
    };
};

export const fetchGatewayKeeperDeviceList = ({
    versionBle = '',
    versionHWBle = '',
    versionWp = '',
    versionWpOtap = '',
    versionWpStack = '',
    gatewayMac = '',
    online = '',
    missing = '',
    kinds = ['unit', 'gate', 'repeater', 'door', 'slidingdoor', 'sample', 'elevator', 'padlock', 'alarm', 'exitgate']
}) => {
    return {
        type: CONSTANTS.FETCH_GATEWAY_KEEPER_DEVICE_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_GATEWAY_KEEPER_DEVICE_LIST,
            data: {
                kinds,
                versionHWBle,
                versionBle,
                versionWp,
                versionWpOtap,
                versionWpStack,
                gatewayMac,
                online,
                missing
            }
        }
    };
};

export const fetchGatewayKeeperDetails = (gatewayUUID = '', filter = [true, false]) => {
    return {
        type: CONSTANTS.FETCH_GATEWAY_KEEPER_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_GATEWAY_KEEPER_LIST,
            filter,
            gatewayUUID,
            data: {}
        }
    };
};

export const fetchGatewayKeeperDeviceDetails = (
    mac = '',
    filter = {
        versionBle: '',
        versionWp: '',
        versionWpOtap: '',
        versionWpStack: '',
        gatewayMac: '',
        online: '',
        missing: ''
    }
) => {
    return {
        type: CONSTANTS.FETCH_GATEWAY_KEEPER_DEVICE_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_GATEWAY_KEEPER_DEVICE_LIST,
            mac,
            data: {
                versionBle: filter.versionBle,
                versionWp: filter.versionWp,
                versionWpOtap: filter.versionWpOtap,
                versionWpStack: filter.versionWpStack,
                gatewayMac: filter.gatewayMac,
                online: filter.online,
                missing: filter.missing
            }
        }
    };
};

export const fetchGatewayKeeperUnitHistory = (mac = '') => {
    return {
        type: CONSTANTS.FETCH_GATEWAY_KEEPER_DEVICE_HISTORY,
        payload: {
            url: ENDPOINTS.ENDPOINT_GATEWAY_KEEPER_DEVICE_HISTORY,
            data: {
                mac
            }
        }
    };
};

export const fetchGatewayKeeperGatewayHistory = (mac = '', serial = '') => {
    return {
        type: CONSTANTS.FETCH_GATEWAY_KEEPER_GATEWAY_HISTORY,
        payload: {
            url: '/gateway/history/',
            data: {
                mac,
                serial
            }
        }
    };
};

export const rebootSiteGateways = (uuids = []) => {
    return {
        type: CONSTANTS.REBOOT_SITE_GATEWAYS,
        payload: {
            url: '/gateway/reboot/',
            data: {
                uuids
            }
        }
    };
};

export const rebootGateway = (data) => {
    return {
        type: CONSTANTS.REBOOT_GATEWAY,
        payload: {
            url: '/gateway/setgateway/',
            data
        }
    };
};

export const enterDiagnosticMode = (UUIDs = []) => {
    return {
        type: CONSTANTS.ENTER_DIAGNOSTIC_MODE,
        payload: {
            url: '/gateway/diagmode/',
            data: {
                UUIDs,
                checkinInterval: 300,
                runTimeMinutes: 60
            }
        }
    };
};

export const setGatewayKeeperList = (data = []) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_LIST,
        payload: data
    };
};

export const setGatewayKeeperListStats = (data) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_LIST_STATS,
        payload: data
    };
};

export const setGatewayKeeperDeviceList = (data = null) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_LIST,
        payload: data
    };
};

export const setGatewayKeeperDetails = (data = null) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_DETAILS,
        payload: data
    };
};

export const setGatewayKeeperDeviceDetails = (data = null) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_DETAILS,
        payload: data
    };
};

export const setGatewayKeeperUnitHistory = (data) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_HISTORY,
        payload: data
    };
};

export const setGatewayKeeperLockDetailHeaders = (data) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_LOCK_DETAIL_HEADERS,
        payload: data
    };
};

export const setGatewayKeeperFilters = (data) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_FILTERS,
        payload: data
    };
};

export const setGatewayKeeperDeviceFilters = (data) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_DEVICE_FILTERS,
        payload: data
    };
};

export const setGatewayKeeperStatsToggle = (data) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_STATS_TOGGLE,
        payload: data
    };
};

export const setGatewayKeeperGatewayHistory = (data) => {
    return {
        type: CONSTANTS.SET_GATEWAY_KEEPER_GATEWAY_HISTORY,
        payload: data
    };
};

export const deleteSiteGateway = (uuid = '') => {
    return {
        type: CONSTANTS.DELETE_SITE_GATEWAY,
        payload: {
            url: '/gateway/remove/',
            data: {
                uuid
            }
        }
    };
};

export const fetchHardwareTypes = () => {
    return {
        type: CONSTANTS.FETCH_HARDWARE_TYPES,
        payload: {
            url: '/hardware/types/get/',
            data: {}
        }
    };
};

export const setHardwareTypes = (data) => {
    return {
        type: CONSTANTS.SET_HARDWARE_TYPES,
        payload: data
    };
};

export const fetchSiteFirmwareSettings = (uuid) => {
    return {
        type: CONSTANTS.FETCH_SITE_FIRMWARE_SETTINGS,
        payload: {
            url: '/site/update/settings/get/',
            data: {
                siteUUIDs: [uuid]
            }
        }
    };
};

export const setSiteFirmwareSettings = (data) => {
    return {
        type: CONSTANTS.SET_SITE_FIRMWARE_SETTINGS,
        payload: data
    };
};

export const updateSiteFirmwareSettings = (data) => {
    return {
        type: CONSTANTS.UPDATE_SITE_FIRMWARE_SETTINGS,
        payload: {
            url: '/site/update/settings/set/',
            data
        }
    };
};

export const triggerSiteFirmwareUpdate = (uuid) => {
    return {
        type: CONSTANTS.TRIGGER_SITE_FIRMWARE_UPDATE,
        payload: {
            url: '/trigger/site/updates/bysites/',
            data: {
                siteUUIDs: [uuid]
            }
        }
    };
};

export const triggerIndividualFirmwareUpdate = (siteUUID, hardwareVersion) => {
    return {
        type: CONSTANTS.TRIGGER_INDIVIDUAL_FIRMWARE_UPDATE,
        payload: {
            url: '/trigger/site/update/hardwareversion/',
            data: {
                siteUUID,
                hardwareVersion
            }
        }
    };
};

export const fetchFirmwareSiteStatuses = (uuid) => {
    return {
        type: CONSTANTS.FETCH_FIRMWARE_SITE_STATUSES,
        payload: {
            url: '/site/update/statuses/get/',
            data: {
                siteUUIDs: [uuid]
            }
        }
    };
};

export const setFirmwareSiteStatuses = (data) => {
    return {
        type: CONSTANTS.SET_FIRMWARE_SITE_STATUSES,
        payload: data
    };
};
