import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';

import {
    SunIcon,
    MoonIcon,
    SunCloudsIcon,
    MoonCloudsIcon,
    CloudIcon,
    CloudsIcon,
    RainIcon,
    RainSunIcon,
    RainMoonIcon,
    ThunderstormIcon,
    SnowIcon,
    MistIcon
} from 'utilities/icons';
class WeatherWeeklyForecastWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weather: sessionStorage.getItem('weather_week')
                ? JSON.parse(sessionStorage.getItem('weather_week'))
                : props.weatherWeekInfo
                ? props.weatherWeekInfo
                : null
        };
    }
    componentDidUpdate(prevProps) {
        if (sessionStorage.getItem('weather_week') && !this.state.weather) {
            this.setState({ weather: JSON.parse(sessionStorage.getItem('weather_week')) });
        } else if (prevProps.weatherWeekInfo !== this.props.weatherWeekInfo) {
            this.setState({ weather: this.props.weatherWeekInfo });
        }
    }
    renderIcon = (param) => {
        switch (param) {
            case '01n':
                return <MoonIcon />;
            case '02d':
                return <SunCloudsIcon />;
            case '02n':
                return <MoonCloudsIcon />;
            case '03d':
                return <CloudIcon />;
            case '03n':
                return <CloudIcon />;
            case '04d':
                return <CloudsIcon />;
            case '04n':
                return <CloudsIcon />;
            case '09d':
                return <RainIcon />;
            case '09n':
                return <RainIcon />;
            case '10d':
                return <RainSunIcon />;
            case '10n':
                return <RainMoonIcon />;
            case '11d':
                return <ThunderstormIcon />;
            case '11n':
                return <ThunderstormIcon />;
            case '13d':
                return <SnowIcon />;
            case '13n':
                return <SnowIcon />;
            case '50d':
                return <MistIcon />;
            case '50n':
                return <MistIcon />;
            default:
                return <SunIcon />;
        }
    };
    render() {
        const { theme, authUser, editWidgets, dash, width } = this.props;
        const { weather } = this.state;
        this.styles = styles(theme, width);
        return (
            weather && (
                <div className={css(this.styles.boxWrapper)}>
                    <div className={css(this.styles.wrapper)}>
                        {authUser && authUser.role && authUser.role.tier === 1 ? (
                            <div className={dash ? css(this.styles.tempSupportSmall) : css(this.styles.tempSupport)}>
                                {weather &&
                                    weather.list &&
                                    weather.list[0] &&
                                    weather.list[0].main &&
                                    weather.list[0].main.temp &&
                                    `${Math.round(weather.list[0].main.temp * (9 / 5) - 459.67)}F°/${Math.round(
                                        weather.list[0].main.temp - 273.15
                                    )}C°`}
                            </div>
                        ) : (
                            <div className={css(this.styles.temp)}>
                                {weather &&
                                weather.list &&
                                weather.list[0] &&
                                weather.list[0].main &&
                                weather.list[0].main.temp &&
                                authUser &&
                                authUser.temperatureScale &&
                                authUser.temperatureScale === 'fahrenheit'
                                    ? `${Math.round(weather.list[0].main.temp * (9 / 5) - 459.67)}°`
                                    : `${Math.round(weather.list[0].main.temp - 273.15)}°`}
                            </div>
                        )}
                        <div className={css(this.styles.conditions)}>
                            {weather &&
                                weather.list &&
                                weather.list[0] &&
                                weather.list[0].weather &&
                                weather.list[0].weather[0] &&
                                weather.list[0].weather[0].main}
                        </div>
                        {weather &&
                            weather.list &&
                            weather.list[0] &&
                            weather.list[0].weather &&
                            weather.list[0].weather[0] &&
                            this.renderIcon(weather.list[0].weather[0].icon)}
                        <div style={{ fontSize: width > 1500 ? 13 : width > 500 ? 11 : 9 }}>
                            {moment().format('dddd')}
                        </div>
                    </div>
                    <div className={css(this.styles.wrapper)}>
                        {authUser && authUser.role && authUser.role.tier === 1 ? (
                            <div className={dash ? css(this.styles.tempSupportSmall) : css(this.styles.tempSupport)}>
                                {weather &&
                                    weather.list &&
                                    weather.list[8] &&
                                    weather.list[8].main &&
                                    weather.list[8].main.temp &&
                                    `${Math.round(weather.list[8].main.temp * (9 / 5) - 459.67)}F°/${Math.round(
                                        weather.list[8].main.temp - 273.15
                                    )}C°`}
                            </div>
                        ) : (
                            <div className={css(this.styles.temp)}>
                                {weather &&
                                weather.list &&
                                weather.list[8] &&
                                weather.list[8].main &&
                                weather.list[8].main.temp &&
                                authUser &&
                                authUser.temperatureScale &&
                                authUser.temperatureScale === 'fahrenheit'
                                    ? `${Math.round(weather.list[8].main.temp * (9 / 5) - 459.67)}°`
                                    : `${Math.round(weather.list[8].main.temp - 273.15)}°`}
                            </div>
                        )}
                        <div className={css(this.styles.conditions)}>
                            {weather &&
                                weather.list &&
                                weather.list[8] &&
                                weather.list[8].weather &&
                                weather.list[8].weather[0] &&
                                weather.list[8].weather[0].main}
                        </div>
                        {weather &&
                            weather.list &&
                            weather.list[8] &&
                            weather.list[8].weather &&
                            weather.list[8].weather[0] &&
                            this.renderIcon(weather.list[8].weather[0].icon)}
                        <div style={{ fontSize: width > 1500 ? 13 : width > 500 ? 11 : 9 }}>
                            {moment().add(1, 'days').format('dddd')}
                        </div>
                    </div>
                    {width > 350 && (
                        <div className={css(this.styles.wrapper)}>
                            {authUser && authUser.role && authUser.role.tier === 1 ? (
                                <div
                                    className={dash ? css(this.styles.tempSupportSmall) : css(this.styles.tempSupport)}>
                                    {weather &&
                                        weather.list &&
                                        weather.list[16] &&
                                        weather.list[16].main &&
                                        weather.list[16].main.temp &&
                                        `${Math.round(weather.list[16].main.temp * (9 / 5) - 459.67)}F°/${Math.round(
                                            weather.list[16].main.temp - 273.15
                                        )}C°`}
                                </div>
                            ) : (
                                <div className={css(this.styles.temp)}>
                                    {weather &&
                                    weather.list &&
                                    weather.list[16] &&
                                    weather.list[16].main &&
                                    weather.list[16].main.temp &&
                                    authUser &&
                                    authUser.temperatureScale &&
                                    authUser.temperatureScale === 'fahrenheit'
                                        ? `${Math.round(weather.list[16].main.temp * (9 / 5) - 459.67)}°`
                                        : `${Math.round(weather.list[16].main.temp - 273.15)}°`}
                                </div>
                            )}
                            <div className={css(this.styles.conditions)}>
                                {weather &&
                                    weather.list &&
                                    weather.list[16] &&
                                    weather.list[16].weather &&
                                    weather.list[16].weather[0] &&
                                    weather.list[16].weather[0].main}
                            </div>
                            {weather &&
                                weather.list &&
                                weather.list[16] &&
                                weather.list[16].weather &&
                                weather.list[16].weather[0] &&
                                this.renderIcon(weather.list[16].weather[0].icon)}
                            <div style={{ fontSize: width > 1500 ? 13 : width > 500 ? 11 : 9 }}>
                                {moment().add(2, 'days').format('dddd')}
                            </div>
                        </div>
                    )}
                    {!editWidgets && width > 1300 && (
                        <div className={css(this.styles.wrapper)}>
                            {authUser && authUser.role && authUser.role.tier === 1 ? (
                                <div
                                    className={dash ? css(this.styles.tempSupportSmall) : css(this.styles.tempSupport)}>
                                    {weather &&
                                        weather.list &&
                                        weather.list[24] &&
                                        weather.list[24].main &&
                                        weather.list[24].main.temp &&
                                        `${Math.round(weather.list[24].main.temp * (9 / 5) - 459.67)}F°/${Math.round(
                                            weather.list[24].main.temp - 273.15
                                        )}C°`}
                                </div>
                            ) : (
                                <div className={css(this.styles.temp)}>
                                    {weather &&
                                    weather.list &&
                                    weather.list[24] &&
                                    weather.list[24].main &&
                                    weather.list[24].main.temp &&
                                    authUser &&
                                    authUser.temperatureScale &&
                                    authUser.temperatureScale === 'fahrenheit'
                                        ? `${Math.round(weather.list[24].main.temp * (9 / 5) - 459.67)}°`
                                        : `${Math.round(weather.list[24].main.temp - 273.15)}°`}
                                </div>
                            )}
                            <div className={css(this.styles.conditions)}>
                                {weather &&
                                    weather.list &&
                                    weather.list[24] &&
                                    weather.list[24].weather &&
                                    weather.list[24].weather[0] &&
                                    weather.list[24].weather[0].main}
                            </div>
                            {weather &&
                                weather.list &&
                                weather.list[24] &&
                                weather.list[24].weather &&
                                weather.list[24].weather[0] &&
                                this.renderIcon(weather.list[24].weather[0].icon)}
                            <div style={{ fontSize: width > 1500 ? 13 : width > 500 ? 11 : 9 }}>
                                {moment().add(3, 'days').format('dddd')}
                            </div>
                        </div>
                    )}
                    {!editWidgets && width > 1300 && (
                        <div className={css(this.styles.wrapper)}>
                            {authUser && authUser.role && authUser.role.tier === 1 ? (
                                <div
                                    className={dash ? css(this.styles.tempSupportSmall) : css(this.styles.tempSupport)}>
                                    {weather &&
                                        weather.list &&
                                        weather.list[32] &&
                                        weather.list[32].main &&
                                        weather.list[32].main.temp &&
                                        `${Math.round(weather.list[32].main.temp * (9 / 5) - 459.67)}F°/${Math.round(
                                            weather.list[32].main.temp - 273.15
                                        )}C°`}
                                </div>
                            ) : (
                                <div className={css(this.styles.temp)}>
                                    {weather &&
                                    weather.list &&
                                    weather.list[32] &&
                                    weather.list[32].main &&
                                    weather.list[32].main.temp &&
                                    authUser &&
                                    authUser.temperatureScale &&
                                    authUser.temperatureScale === 'fahrenheit'
                                        ? `${Math.round(weather.list[32].main.temp * (9 / 5) - 459.67)}°`
                                        : `${Math.round(weather.list[32].main.temp - 273.15)}°`}
                                </div>
                            )}
                            <div className={css(this.styles.conditions)}>
                                {weather &&
                                    weather.list &&
                                    weather.list[32] &&
                                    weather.list[32].weather &&
                                    weather.list[32].weather[0] &&
                                    weather.list[32].weather[0].main}
                            </div>
                            {weather &&
                                weather.list &&
                                weather.list[32] &&
                                weather.list[32].weather &&
                                weather.list[32].weather[0] &&
                                this.renderIcon(weather.list[32].weather[0].icon)}
                            <div style={{ fontSize: width > 1500 ? 13 : width > 500 ? 11 : 9 }}>
                                {moment().add(4, 'days').format('dddd')}
                            </div>
                        </div>
                    )}
                </div>
            )
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { theme, width, weatherWeekInfo } = app;
    const { authUser } = auth;
    return { theme, width, weatherWeekInfo, authUser };
};

export default connect(mapStateToProps)(WeatherWeeklyForecastWidget);

const styles = (theme, width) =>
    StyleSheet.create({
        boxWrapper: {
            background: theme.color.compColor,
            width: '100%',
            margin: '10px 0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        wrapper: {
            background: theme.color.compColor,
            margin: '0 5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: theme.color.fontColor,
            minHeight: 135,
            minWidth: width > 1300 ? '18%' : width > 350 ? '30%' : '45%',
            maxWidth: width > 1300 ? '18%' : width > 350 ? '30%' : '45%',
            padding: '15px 12px',
            borderRadius: 5,
            fontSize: 13,
            boxShadow: theme.color.boxShadowCorner
        },
        temp: {
            fontSize: 22,
            fontFamily: 'Poppins'
        },
        tempSupport: {
            fontSize: 13,
            fontFamily: 'Poppins'
        },
        tempSupportSmall: {
            fontSize: 10,
            fontFamily: 'Poppins'
        },
        conditions: {
            fontSize: 10,
            color: theme.color.subFontColor
        }
    });
