export const ACTIVITY_SUBTYPES_ALL = [
    'lock.failed_unlock.invalid_accesscode',
    'lock.accesscode.unlock',
    'admin.password.reset',
    'lock.alarm',
    'lock.blacklist.add',
    'lock.blacklist.remove',
    'user.fob.remove',
    'user.fob.sync',
    'lock.locked',
    'lock.edit',
    'lock.lock_time_invalid',
    'lock.rekey',
    'lock.accesscode.remove',
    'lock.schedule.remove',
    'lock.sensor',
    'lock.accesscode.set',
    'lock.schedule.set',
    'lock.failed_unlock',
    'lock.limited',
    'share.create',
    'share.delete',
    'lock.unassigned',
    'site.manager.delete',
    'site.sync',
    'support.companies.get',
    'support.gateways.details',
    'support.logs.get.all',
    'support.login.attempts',
    'support.logs.get',
    'support.lock.rekey',
    'support.site.sync',
    'support.site.sync.status',
    'support.site.sync.units',
    'support.unit.history',
    'support.unit.externalid.set',
    'support.user.fob.remove',
    'support.user.fob',
    'support.user.permissions.edit',
    'support.user.permissions.get',
    'support.user.phone.details',
    'support.user.phone',
    'support.user.pin.refresh',
    'support.user.unfreeze',
    'support.user.pin.view',
    'unit.assign',
    'unit.edit',
    'unit.unassign',
    'lock.unlock',
    'user.activate.account',
    'user.client.checkout',
    'user.confirm',
    'user.create',
    'user.delete',
    'user.edit',
    'user.login',
    'user.logout',
    'user.password.confirm',
    'user.password.update',
    'user.site.sync',
    'unlock.accessed_offline_fob',
    'unlock.accessed_offline_phone',
    'unlock.accessed_online_phone',
    'unlock.accessed_offline_access_code',
    'unlock.accessed_online_phone_bt',
    'unlock.accessed_online_phone_mesh',
    'unlock.unlocked_mechanical_override'
];

export const SUPPORT_ACTIVITY_SUBTYPES = [
    // keeping commented out subtypes for organization purposes

    // lock subtypes
    'lock.locked',

    // unlock subtypes
    // "unlock.accessed_offline_fob",
    // "unlock.accessed_offline_phone",
    // "unlock.accessed_online_phone",
    // "unlock.accessed_offline_access_code",
    // "unlock.accessed_online_phone_bt",
    // "unlock.accessed_online_phone_mesh",
    // "unlock.unlocked_mechanical_override",
    'lock.unlock',
    // "lock.limited",

    // invalid access code subtypes
    // "lock.failed_unlock.invalid_accesscode",

    // failed unlock subtypes
    'lock.failed_unlock',
    //"lock.failed_unlock.overlocked",
    //"lock.failed_unlock.gatelocked",
    // "lock.failed_unlock.blacklisted",
    // "lock.failed_unlock.out_of_schedule",

    // unit update subtypes
    'unit.assign',
    // "unit.edit",
    // "site.unit.setstate",
    // "lock.battery.replace",

    // temperature subtypes
    'lock.temperature_out_of_bounds',

    // alarm subtypes
    'lock.alarm',

    // share subtypes
    'share.create',
    // "share.delete",
    // "site.manager.delete",
    // "share.create.manager",
    // "share.delete.manager",

    // account subtypes
    'user.confirm',
    // "user.update",
    // "user.password.update",
    // "user.delete",

    // auction subtypes
    'auction.unassign',
    // "auction.assign",
    // "auction.expired",

    'transfer.unassign',

    // data export subtypes
    'activity.sitedata.export',

    // motion subtypes
    'lock.motionstart',
    // "lock.motionstop",
    'note.all',
    'lock.hold.all'
    //"note.create",
    //"note.resolved",
];

export const ACTIVITY_SUBTYPES = [
    // "lock.failed_unlock.invalid_accesscode",
    'unlock.unlocked_mechanical_override',
    'lock.unlock',
    'lock.locked',
    // "user.confirm",
    'lock.failed_unlock',
    // "user.password.update",
    'share.create',
    // "share.delete",
    // "user.delete",
    // "site.manager.delete",
    // "site.unit.setstate",
    // "unit.assign",
    // "unit.edit",
    // "lock.alarm",
    'note.all',
    'lock.hold.all'
    //"note.create",
    //"note.resolved",
];

export const MAP_ACTIVITY_SUBTYPES = [
    'lock.unlock',
    'lock.locked',
    'lock.motionstart',
    'lock.motionstop',
    'lock.sensor'
];

export const MOTION_ACTIVITY_SUBTYPES = [
    'lock.failed_unlock.invalid_accesscode',
    'unlock.unlocked_mechanical_override',
    'lock.unlock',
    'lock.locked',
    // "user.confirm",
    'lock.failed_unlock',
    // "user.password.update",
    'share.create',
    // "share.delete",
    // "user.delete",
    // "site.manager.delete",
    // "site.unit.setstate",
    // "unit.assign",
    // "unit.edit",
    // "lock.alarm",
    'lock.motionstart',
    'lock.motionstop'
];

export const ACTIVITY_TYPES = ['alert', 'support', 'user', 'unit', 'lock', 'fob', 'site'];

export const SUPPORT_ACTIVITY_TYPES = [
    'lock',
    'unlock',
    'invalidAccessCode',
    'failedUnlock',
    'unitUpdates',
    'temperature',
    'alarm',
    'shares',
    'account',
    'auction',
    'dataExport',
    'motion',
    'lockBattery'
];

export const ACTIVITY_LEVELS = ['1'];

export const ACTIVITY_DEVICE = ['iOS', 'android', 'keyFob', 'accessCode', 'remoteEntry'];

export const ACTIVITY_USER_TYPES = ['client', 'shared'];

export const ACTIVITY_ACCESS_TYPES = ['entryPoint', 'unlockUnit', 'lockUnit'];
