import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import Loader from 'react-loader-spinner';
import { setLoader } from 'actions/App';

class CustomButton extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.setLoader(false);
    }
    handleAction = () => {
        const { load } = this.props;
        load && this.props.setLoader(true);
        this.props.action();
    };
    buttonIsDisabled = () => {
        const { disabled, authUser, permission, showLoader } = this.props;
        if (disabled) return true;
        return permission ? !authUser.permissions.includes(permission) : showLoader;
    }
    render() {
        const {
            intl,
            authUser,
            text,
            type,
            size,
            block,
            style,
            icon,
            iconOverride,
            permission,
            theme,
            outline,
            margin,
            padding,
            color,
            width,
            float,
            showLoader
        } = this.props;
        this.styles = styles(theme, margin, padding, color ? color : theme.color.themeColor, outline, width, float, block, icon, this.buttonIsDisabled);
        
        return (
            <div 
                className={css(
                    this.styles.buttonWrapper, 
                    this.buttonIsDisabled() ? this.styles.buttonDisabled : this.styles.buttonEnabled,
                    size === 'sm' ? this.styles.smallStyling :
                    size === 'lg' ? this.styles.largeStyling : null
                )} 
                onClick={!this.buttonIsDisabled() ? this.handleAction : null}
                style={style}>

                {showLoader ? 
                    <Loader type={type} color={theme.color.buttonFontColor} height={10} width={50} /> 
                    : 
                    <div className={css(this.styles.contentContainer)}>
                        {icon && (!iconOverride ? 
                            <span className={css(this.styles.icon) + ' material-icons'}>
                                {icon}
                            </span>
                            : 
                            icon)}
                        {permission
                            ? authUser.permissions.includes(permission)
                                ? intl.formatMessage({ id: text })
                                : intl.formatMessage({ id: 'comp.button.denied' })
                            : intl.formatMessage({ id: text })}
                    </div>}
            </div>
        );
    }
}

CustomButton.defaultProps = {
    text: 'blank',
    margin: 10,
    padding: '5px 15px',
    size: 'sm',
    block: false,
    outline: false,
    color: null,
    icon: null,
    iconOverride: false,
    permission: null,
    type: 'ThreeDots',
    load: true,
    width: null,
    disabled: false,
    float: false
};

const mapStateToProps = ({ auth, app }) => {
    const { authUser } = auth;
    const { theme, showLoader } = app;
    return { authUser, theme, showLoader };
};

export default injectIntl(connect(mapStateToProps, { setLoader })(CustomButton));

const styles = (theme, margin, padding, backgroundColor, outline, width, float, block, icon, buttonIsDisabled) =>
    StyleSheet.create({
        buttonWrapper: {
            display: block ? 'block' : 'inline-block',
            lineHeight: 1.5,
            margin,
            padding,
            paddingLeft: icon && 0,
            minWidth: width,
            width: block && '100%',
            float: float && float,
            color: outline ? backgroundColor : theme.color.buttonFontColor,
            backgroundColor: outline ? theme.color.compColor : backgroundColor,
            border: outline ? `1px solid ${theme && theme.color.themeColor}` : 'none',
            borderRadius: 5,
            userSelect: 'none',
            cursor: 'pointer',
            appearance: 'button',
            fontWeight: 400,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'middle',
            transition: 'opacity .2s ease-in-out, color .2s ease-in-out, background-color .2s ease-in-out, border .2s ease-in-out',
            ':hover': {
                opacity: '0.8'
            }
        },
        buttonEnabled: {

        },
        buttonDisabled: {
            backgroundColor: outline ? 'none' : theme.color.borderColor,
            borderColor: theme.color.borderColor,
            color: theme.color.fontColor,
            opacity: 0.6,
            cursor: 'not-allowed',
            ':hover': {
                opacity: 0.6,
            }
        },
        contentContainer: {
            textAlign: 'center',
        },
        icon: {
            color: buttonIsDisabled() ? (theme && theme.color.fontColor) : (theme && theme.color.themeColor),
            width: 24,
            height: 24,
            margin: '0 7px 0 11px',
        },

        smallStyling: {
            padding: padding || '0.25rem 0.5rem',
            paddingLeft: icon && 0,
            fontSize: 13,
        },
        largeStyling: {
            padding: padding || '0.5rem 1rem',
            paddingLeft: icon && 0,
            fontSize: 17,
        },
    });