import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from '../store/history';
import moment from 'moment';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as ICONS from 'utilities/icons';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_UNITS from 'actions/Units';
import * as ACTIONS_USERS from 'actions/Users';

const getUnitHeaders = (state) => state.units.unitsHeader;
const getUnitsList = (state) => state.units.unitsList;
const getOpenUnits = (state) => state.units.openUnits;
const getLockHeaders = (state) => state.units.locksHeader;
const getUnitObject = (state) => state.units.unitObject;
const getLockObject = (state) => state.units.lockObject;
const getLockFilterUnit = (state) => state.units.lockFilterUnit;
const getActivityState = (state) => state.activity;
const getUnitsFilters = (state) => state.units.unitsFilters;
const getLockTypeFilter = (state) => state.units.lockTypeFilter;
const getLockStatusFilter = (state) => state.units.lockStatusFilter;
const getAuthUser = (state) => state.auth.authUser;
const getSite = (state) => state.auth.site;
const getCurrentLockSchedules = (state) => state.units.currentLockSchedules;
const getSyncUnitsHeaders = (state) => state.units.syncUnitsHeaders;
const getSharedUnitsHeaders = (state) => state.units.sharedUnitsHeaders;
const getNotesHeaders = (state) => state.units.notesHeaders;
const getCommentsHeaders = (state) => state.units.commentsHeaders;
const getRepeaterHeaders = (state) => state.units.repeaterHeader;

const filterOutUnitsUsingFilters = (data, unitsFilters, activeHeader) => {
    // filter out units according to table filters selected
    // TODO: maybe add more shared logic to this function down the road? 
    const activeFiltersTitles = unitsFilters && unitsFilters.filter(f => f.active);
    const filtersInclude = (text) => {
        let result = false;
        for (let i of activeFiltersTitles) {
            if (i && i.title === text) {
                result = true;
            }
        }
        return result;
    }
    let filteredUnitsReducer = [];
    let usedFilteredReducer = false;
    const addToFilteredReducer = (data) => {
        usedFilteredReducer = true;
        data && filteredUnitsReducer.push(...data);
    }
    if (filtersInclude('filters.units.occupied')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'inuse'))
    }
    if (filtersInclude('filters.units.available')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'available'))
    }
    if (filtersInclude('filters.units.repo')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'repo'))
    }
    if (filtersInclude('filters.units.overlock')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'overlock'))
    }
    if (filtersInclude('filters.units.gatelock')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'gatelock'))
    }
    if (filtersInclude('filters.units.transfer')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'transfer-smartentry'))
    }
    if (filtersInclude('filters.units.auction-smartentry')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'auction-smartentry'))
    }
    if (filtersInclude('filters.units.checkout')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'checkout'))
    }
    if (filtersInclude('filters.units.prelet')) {
        data && addToFilteredReducer(data.filter((unit) => unit.rentalState === 'prelet'))
    }
    if (filtersInclude('filters.units.other')) {
        data && addToFilteredReducer(data.filter(
            (unit) =>
                unit.rentalState == 'charity' ||
                unit.rentalState == 'corporate' ||
                unit.rentalState == 'needscleaning' ||
                unit.rentalState == 'delinquent' ||
                unit.rentalState == 'checkout' ||
                unit.rentalState == 'damaged'
        ))
    }
    // if filter is applied and no filtered results, return []
    if (usedFilteredReducer && filteredUnitsReducer.length === 0) {
        data = [];
    }
    if (usedFilteredReducer && filteredUnitsReducer && filteredUnitsReducer.length) { 
        const uniqueArray = filteredUnitsReducer.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === filteredUnitsReducer.findIndex(obj => {
                return JSON.stringify(obj) === _value;
            });
        });
        data = uniqueArray;
    }

    data = UTILS.sortList(!activeHeader.order, data, activeHeader.sortTitle);
    return data;
}

function* requestUnits({ payload }) {
    try {
        if (payload.handleRefresh || (payload.paginate && payload.data.page > 1)) {
            // don't set to null on map since it refreshes constantly OR if we are paginating and it is beyond the first page
        } else {
            yield put(ACTIONS_UNITS.setUnitList(null));
        }
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data || !units.data.units) {
            yield put(ACTIONS_UNITS.setUnitList([]));
            yield put(ACTIONS_UNITS.setOpenUnits([]));
            if (payload.fetchStats) {
                let stats = {
                    total: 0,
                    occupied: 0,
                    available: 0,
                    overlock: 0,
                    gatelock: 0,
                    repo: 0,
                    transfer: 0,
                    auction: 0,
                    dashboardOther: 0,
                    other: 0
                };
                let lockStats = {
                    total: 0,
                    nokeOne: 0,
                    nokeVolt: 0,
                    hdPadlock: 0,
                    nokePad: 0,
                    nokeScreen: 0,
                    other: 0
                };
                let lockStatusStats = {
                    total: 0,
                    online: 0,
                    offline: 0
                };
                yield put(ACTIONS_UNITS.setUnitListLockStats(lockStats));
                yield put(ACTIONS_UNITS.setUnitListLockStatusStats(lockStatusStats));
                yield put(ACTIONS_UNITS.setUnitListStats(stats));
            }
        } else {
            // if we are paginating and we hit the backend with a page number and it sends an empty array that means there are no more
            if (units.data.page > 1 && units.data.units.length === 0) {
                return;
            }
            let activityState = yield select(getActivityState);
            units.data = units.data.units.filter((unit) => {
                return unit.accessType === 'rentable';
            });


            ///////////////////////
            ///// STATS STUFF /////
            ///////////////////////

            // only do this if we need to (currently only on dashboard page and units page)
            if (payload.fetchStats) {
                let total = units.data.length;
                let occupied = units.data.filter((item) => item.rentalState === 'inuse').length;
                let available = units.data.filter((item) => item.rentalState === 'available').length;
                let overlock = units.data.filter((item) => item.rentalState === 'overlock').length;
                let gatelock = units.data.filter((item) => item.rentalState === 'gatelock').length;
                let repo = units.data.filter((item) => item.rentalState === 'repo').length;
                let transfer = units.data.filter((item) => item.rentalState === 'transfer-smartentry').length;
                let auction = units.data.filter((item) => item.rentalState === 'auction-smartentry').length;
                let stats = {
                    total,
                    occupied,
                    available,
                    overlock,
                    gatelock,
                    repo,
                    transfer,
                    auction,
                    dashboardOther: total - available - occupied - overlock,
                    other: total - available - occupied - overlock - repo - transfer - gatelock - auction
                };
                let unitLocks = units.data.filter((unit) => unit.locks && unit.locks.length > 0);
                let locksTotal = unitLocks.length;
                let online = unitLocks.filter((item) => item.locks[0].gwDelinquent === 0).length;
                let offline = unitLocks.filter((item) => item.locks[0].gwDelinquent === 1).length;
                let nokeOne = unitLocks.filter((item) => item.locks[0].bleHwVersion.includes('A')).length;
                let nokeVolt = unitLocks.filter((item) => item.locks[0].bleHwVersion.includes('E')).length;
                let hdPadlock = unitLocks.filter((item) => item.locks[0].bleHwVersion.includes('I')).length;
                let nokePad = unitLocks.filter((item) => item.locks[0].bleHwVersion == '3K').length;
                let nokeScreen = unitLocks.filter((item) => item.locks[0].bleHwVersion == '4K').length;
                let lockStats = {
                    total: locksTotal,
                    nokeOne,
                    nokeVolt,
                    hdPadlock,
                    nokePad,
                    nokeScreen,
                    other: locksTotal - nokeOne - nokeVolt - hdPadlock - nokePad - nokeScreen
                };
                let lockStatusStats = {
                    total: locksTotal,
                    online,
                    offline
                };
                yield put(ACTIONS_UNITS.setUnitListLockStats(lockStats));
                yield put(ACTIONS_UNITS.setUnitListLockStatusStats(lockStatusStats));
                yield put(ACTIONS_UNITS.setUnitListStats(stats));
            }

            ///////////////////////
            // TEMPERATURE STUFF //
            ///////////////////////

            // Hiding Temperature until we get accurate temps
            // Always setting as an empty array for now:
            yield put(ACTIONS_UNITS.setTemperatureUnits([]));
            // if (domain.includes('home')) {
            //     let tempUnits = units.data.filter(
            //         (unit) =>
            //             unit.locks &&
            //             unit.locks.length > 0 &&
            //             unit.locks.filter((lock) => lock.outOfTemperature).length > 0
            //     );
            //     yield put(ACTIONS_UNITS.setTemperatureUnits(tempUnits));
            // }

            ///////////////////////
            ///// UNITS STUFF /////
            ///////////////////////
            let authUser = yield select(getAuthUser);
            units.data.map((unit) => {
                unit.User.name = `${unit.User.firstName} ${unit.User.lastName}`;
                unit.Icon = ICONS.UnitIcon;
                unit.locks = unit.locks ? unit.locks : [];
                unit.zoneIDs = unit.zoneIDs ? unit.zoneIDs : [];
                unit.zoneUUIDs = unit.zoneUUIDs ? unit.zoneUUIDs : [];
                unit.userSchedule =
                    unit.User &&
                    unit.User.role &&
                    unit.User.role.userHoursSuiteName &&
                    unit.User.role.userHoursSuiteName !== '' &&
                    (!authUser.featureflagAssignments.includes('newScheduleEnabled') && !authUser.featureflagAssignments.includes('threeScheduleEnabled'))
                        ? unit.User.role.userHoursSuiteName
                        : unit.User &&
                          unit.User.role &&
                          unit.User.role.userSiteHoursSuiteName &&
                          unit.User.role.userSiteHoursSuiteName !== ''
                          && authUser.featureflagAssignments.includes('newScheduleEnabled')
                          && authUser.featureflagAssignments.includes('threeScheduleEnabled')
                        ? unit.User.role.userSiteHoursSuiteName
                        : '';
                unit.active = activityState.activityFilters.units.includes(unit.id);
            });
            let openUnits = [];
            units.data.map((unit) => {
                let lockStateChecker = false;
                unit.locks && unit.locks.map((lock) => {
                    if (lock.hwState == 'UNLOCKED') {
                        lockStateChecker = true;
                    }
                });
                if (lockStateChecker) {
                    openUnits.push(unit);
                    unit.lockState = 'UNLOCKED';
                } else if (unit.locks && unit.locks.length > 0) {
                    unit.lockState = 'LOCKED';
                } else {
                    unit.lockState = '';
                }
            });
            units.data = UTILS.cleanUpArray(units.data, 'id');
            let unitHeaders = yield select(getUnitHeaders);
            const activeHeader = unitHeaders.find((item) => item.active);
            const lockFilter = yield select(getLockFilterUnit);
            const site = yield select(getSite);
            const unitsFilters = yield select(getUnitsFilters);
            const lockTypeFilter = yield select(getLockTypeFilter);
            const lockStatusFilter = yield select(getLockStatusFilter);
            let filteredUnits = lockFilter ? units.data.filter((unit) => unit.locks.length > 0) : units.data;

            // filter out units according to table filters selected
            filteredUnits = filterOutUnitsUsingFilters(filteredUnits, unitsFilters, activeHeader);

            filteredUnits =
                lockTypeFilter !== 'all' || lockStatusFilter !== 'all'
                    ? filteredUnits.filter((unit) => unit.locks && unit.locks.length > 0)
                    : filteredUnits;
            filteredUnits =
                lockTypeFilter == 'nokeOne'
                    ? filteredUnits.filter((unit) => unit.locks.some((lock) => lock.bleHwVersion.includes('A')))
                    : lockTypeFilter == 'nokeVolt'
                    ? filteredUnits.filter((unit) => unit.locks.some((lock) => lock.bleHwVersion.includes('E')))
                    : lockTypeFilter == 'hdPadlock'
                    ? filteredUnits.filter((unit) => unit.locks.some((lock) => lock.bleHwVersion.includes('I')))
                    : lockTypeFilter == 'nokePad'
                    ? filteredUnits.filter((unit) => unit.locks.some((lock) => lock.bleHwVersion.includes('3K')))
                    : lockTypeFilter == 'nokeScreen'
                    ? filteredUnits.filter((unit) => unit.locks.some((lock) => lock.bleHwVersion.includes('4K')))
                    : lockTypeFilter == 'other'
                    ? filteredUnits.filter((unit) =>
                          unit.locks.some(
                              (lock) =>
                                  !lock.bleHwVersion.includes('A') &&
                                  !lock.bleHwVersion.includes('E') &&
                                  !lock.bleHwVersion.includes('I') &&
                                  !lock.bleHwVersion.includes('K')
                          )
                      )
                    : filteredUnits;
            filteredUnits =
                lockStatusFilter == 'online'
                    ? filteredUnits.filter((unit) => unit.locks.some((lock) => lock.gwDelinquent === 0))
                    : lockStatusFilter == 'offline'
                    ? filteredUnits.filter((unit) => unit.locks.some((lock) => lock.gwDelinquent === 1))
                    : filteredUnits;
            unitHeaders[3].hide =
                filteredUnits.filter((unit) => unit.detailsPrice && unit.detailsPrice !== 0).length > 0 ? false : true;
            unitHeaders[4].hide =
                filteredUnits.filter((unit) => unit.detailsDepth && unit.detailsDepth !== 0).length > 0 ? false : true;
            unitHeaders[5].hide =
                filteredUnits.filter((unit) => unit.detailsWidth && unit.detailsWidth !== 0).length > 0 ? false : true;
            filteredUnits &&
                filteredUnits.map(
                    (unit) =>
                        unit.locks &&
                        unit.locks.map(
                            (lock) =>
                                (lock.temperature =
                                    lock.bleHwVersion == '1A' && Number(lock.bleAppVersion) <= 1.23
                                        ? lock.temperature / 4
                                        : lock.bleHwVersion == '3K' && Number(lock.bleAppVersion) <= 3.11
                                        ? lock.temperature / 4
                                        : lock.temperature)
                        )
                );
            if (payload.paginate && payload.data.page > 1) {
                let existingUnits = yield select(getUnitsList);
                let existingOpenUnits = yield select(getOpenUnits);
                yield put(ACTIONS_UNITS.setUnitList(existingUnits.concat(filteredUnits)));
                yield put(ACTIONS_UNITS.setOpenUnits(existingOpenUnits.concat(openUnits)));
            } else {
                yield put(ACTIONS_UNITS.setUnitList(filteredUnits));
                yield put(ACTIONS_UNITS.setOpenUnits(openUnits));
            }
            yield put(ACTIONS_UNITS.setUnitZoneSelected(''));
        }
    } catch (error) {
        yield put(ACTIONS_UNITS.setUnitList([]));
        yield put(ACTIONS_UNITS.setOpenUnits([]));
        console.warn(error);
    }
}

function* requestUnitsStats({ payload }) {
    try {
        const unit = yield call(API.POST, payload.url, payload.data);
        if (!unit) {
            return;
        }
        const { locks, units } = unit.data;
        let stats = {
            total: units.total,
            occupied: units.inuse,
            available: units.available,
            overlock: units.overlock,
            gatelock: units.gatelock,
            repo: units.repo,
            prelet: units.prelet, 
            checkout: units.checkout,
            transfer: units.transfer,
            auction: units.auction,
            dashboardOther: units.dashboardOther,
            other: units.other
        };
        let lockStats = {
            total: locks.total,
            nokeOne: locks.nokeOne,
            nokeVolt: locks.nokeVolt,
            hdPadlock: locks.hdPadlock,
            nokePad: locks.nokePad,
            nokeScreen: locks.nokeScreen,
            nokeContact: locks.nokeContact,
            other: locks.other
        };
        let lockStatusStats = {
            total: locks.total,
            online: locks.online,
            offline: locks.offline
        };
        yield put(ACTIONS_UNITS.setUnitListLockStats(lockStats));
        yield put(ACTIONS_UNITS.setUnitListLockStatusStats(lockStatusStats));
        yield put(ACTIONS_UNITS.setUnitListStats(stats));
    } catch (error) {
        console.warn(error);
    }
}

function* requestUnitsByZone({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setUnitList(null));
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units) {
            yield put(ACTIONS_UNITS.setUnitList([]));
        } else {
            units.data = units.data.units;
            let authUser = yield select(getAuthUser);
            units.data.map((unit) => {
                unit.User.name = `${unit.User.firstName} ${unit.User.lastName}`;
                unit.Icon = ICONS.UnitIcon;
                unit.locks = unit.locks ? unit.locks : [];
                unit.zoneIDs = unit.zoneIDs ? unit.zoneIDs : [];
                unit.zoneUUIDs = unit.zoneUUIDs ? unit.zoneUUIDs : [];
                unit.userSchedule =
                    unit.User &&
                    unit.User.role &&
                    unit.User.role.userHoursSuiteName &&
                    unit.User.role.userHoursSuiteName !== '' &&
                    (!authUser.featureflagAssignments.includes('newScheduleEnabled') && !authUser.featureflagAssignments.includes('threeScheduleEnabled'))
                        ? unit.User.role.userHoursSuiteName
                        : unit.User &&
                        unit.User.role &&
                        unit.User.role.userSiteHoursSuiteName &&
                        unit.User.role.userSiteHoursSuiteName !== ''
                        && authUser.featureflagAssignments.includes('newScheduleEnabled')
                        && authUser.featureflagAssignments.includes('threeScheduleEnabled')
                        ? unit.User.role.userSiteHoursSuiteName
                        : '';
            });
            units.data = UTILS.cleanUpArray(units.data, 'id');
            let unitHeaders = yield select(getUnitHeaders);
            const activeHeader = unitHeaders.find((item) => item.active);
            const lockFilter = yield select(getLockFilterUnit);
            const lockTypeFilter = yield select(getLockTypeFilter);
            const lockStatusFilter = yield select(getLockStatusFilter);
            const unitsFilters = yield select(getUnitsFilters);

            units.data = lockFilter ? units.data.filter((unit) => unit.locks.length > 0) : units.data;

            // filter out units according to table filters selected
            units.data = filterOutUnitsUsingFilters(units.data, unitsFilters, activeHeader);

            units.data =
                lockTypeFilter !== 'all' || lockStatusFilter !== 'all'
                    ? units.data.filter((unit) => unit.locks && unit.locks.length > 0)
                    : units.data;
            units.data =
                lockTypeFilter == 'unit'
                    ? units.data.filter((unit) => unit.locks.some((lock) => lock.hwType == 'unit'))
                    : lockTypeFilter == 'gate'
                    ? units.data.filter((unit) => unit.locks.some((lock) => lock.hwType == 'gate'))
                    : lockTypeFilter == 'door'
                    ? units.data.filter((unit) => unit.locks.some((lock) => lock.hwType == 'door'))
                    : lockTypeFilter == 'slidingdoor'
                    ? units.data.filter((unit) => unit.locks.some((lock) => lock.hwType == 'slidingdoor'))
                    : lockTypeFilter == 'elevator'
                    ? units.data.filter((unit) => unit.locks.some((lock) => lock.hwType == 'elevator'))
                    : units.data;
            units.data =
                lockStatusFilter == 'online'
                    ? units.data.filter((unit) => unit.locks.some((lock) => lock.gwDelinquent === 0))
                    : lockStatusFilter == 'offline'
                    ? units.data.filter((unit) => unit.locks.some((lock) => lock.gwDelinquent === 1))
                    : units.data;
            unitHeaders[2].hide =
                units.data.filter((unit) => unit.detailsPrice && unit.detailsPrice !== 0).length > 0 ? false : true;
            unitHeaders[3].hide =
                units.data.filter((unit) => unit.detailsDepth && unit.detailsDepth !== 0).length > 0 ? false : true;
            unitHeaders[4].hide =
                units.data.filter((unit) => unit.detailsWidth && unit.detailsWidth !== 0).length > 0 ? false : true;
            yield put(ACTIONS_UNITS.setUnitList(units.data));
            yield put(ACTIONS_UNITS.setUnitZoneSelected(payload.data.zoneUUID));
        }
    } catch (error) {
        yield put(ACTIONS_UNITS.setUnitList([]));
        console.warn(error);
    }
}

function* requestUnit({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserDetails(null));
        yield put(ACTIONS_UNITS.setUnit(null));
        const unit = yield call(API.POST, payload.url, payload.data);
        if (!unit) {
            yield history.goBack();
        } else {
            if (unit.data.unit.User) {
                let { firstName, lastName } = unit.data.unit.User;
                unit.data.unit.User.name = `${firstName} ${lastName}`;
            }
            unit.data.unit.locks = unit.data.unit.locks ? unit.data.unit.locks : [];
            unit.data.unit.zoneIDs = unit.data.unit.zoneIDs ? unit.data.unit.zoneIDs : [];
            unit.data.unit.zoneUUIDs = unit.data.unit.zoneUUIDs ? unit.data.unit.zoneUUIDs : [];
            unit.data.unit.isExit = unit.data.unit.isExit ? 'true' : 'false';
            sessionStorage.setItem('unit_data', JSON.stringify(unit.data));
            const lockHeaders = yield select(getLockHeaders);
            const activeHeader = lockHeaders.find((item) => item.active);
            unit.data.unit.locks &&
                unit.data.unit.locks.map(
                    (lock) =>
                        (lock.temperature =
                            lock.bleHwVersion == '1A' && Number(lock.bleAppVersion) <= 1.23
                                ? lock.temperature / 4
                                : lock.bleHwVersion == '3K' && Number(lock.bleAppVersion) <= 3.11
                                ? lock.temperature / 4
                                : lock.temperature)
                );
            unit.data.unit.locks = UTILS.sortList(!activeHeader.order, unit.data.unit.locks, activeHeader.sortTitle);
            yield put(ACTIONS_UNITS.setUnit(unit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestLastSyncInfo({ payload }) {
    try {
        const info = yield call(API.POST, payload.url, payload.data);
        if (!info) {
            yield history.goBack();
        } else {
            yield put(ACTIONS_UNITS.setLastSyncInfo(info.data.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUnitLockDetails({ payload }) {
    let authUser = yield select(getAuthUser);
    try {
        const lock = yield select(getLockObject);
        if (lock && lock.uuid === payload.lockUUID) {
            // no need to call site units again if it already exists as that's the slowest endpoint known to mankind
        } else {
            yield put(ACTIONS_UNITS.setUnitLockDetails(null));
            const units = yield call(API.POST, payload.url, payload.data);
            if (!units) {
                yield history.goBack();
            } else {
                let frontendVersion = sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : 'default';
                units.locks = [];
                units.data = units.data.units;
                units.data.map((unit) => {
                    unit.User.name = `${unit.User.firstName} ${unit.User.lastName}`;
                    unit.Icon = ICONS.UnitIcon;
                    unit.locks = unit.locks ? unit.locks : [];
                    unit.zoneIDs = unit.zoneIDs ? unit.zoneIDs : [];
                    unit.zoneUUIDs = unit.zoneUUIDs ? unit.zoneUUIDs : [];
                    unit.locks && unit.locks.length > 0 && unit.locks.map((lock) => {
                        lock.Icon = ICONS.LockIcon;
                        units.locks.push(lock);
                        lock.unitUUID = unit && unit.uuid ? unit.uuid : null;
                        lock.imageURL = lock.imageURL !== ''
                        ? `${lock.imageURL}?t=${new Date().getTime()}&v=${frontendVersion}`
                        : lock.imageURL
                    });
                });
                units.lock = units.locks.find((grab) => grab.uuid === payload.lockUUID);
                if (
                    units.lock &&
                    units.lock.mac &&
                    units.lock.bleHwVersion &&
                    authUser &&
                    authUser.permissions.includes('support_view_all_companies')
                ) {
                    yield put(ACTIONS_UNITS.fetchLockManufacturingData(units.lock.mac, units.lock.bleHwVersion));
                }
                if (!units.lock) {
                    yield put(ACTIONS_APP.showMessage(`notifications.error.fail.notSiteLock`));
                    yield history.goBack();
                } else {
                    yield put(ACTIONS_UNITS.setUnitLockDetails(units.lock));
                }
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateUnit({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update || !update.data) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            const unit = yield select(getUnitObject);
            if (update.data.User) {
                let { firstName, lastName } = update.data.User;
                update.data.User.name = `${firstName} ${lastName}`;
            }
            const lockHeaders = yield select(getLockHeaders);
            const activeHeader = lockHeaders.find((item) => item.active);
            update.data.locks = UTILS.sortList(!activeHeader.order, update.data.locks, activeHeader.sortTitle);
            update.data.isExit = update.data.isExit ? 'true' : 'false'
            let newData = {
                unit: update.data,
                sharedUsers: unit.sharedUsers,
            };
            yield put(ACTIONS_UNITS.setUnit(newData));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateLock({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield put(ACTIONS_UNITS.setUnitLockDetails(null));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

// same as requestUpdateLock, just only gives error notification if error, no going back in history
function* requestUpdateLockSilently({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            yield put(ACTIONS_APP.setSaveLoader(false));
            yield put(ACTIONS_APP.showMessage('db.error', 'error'));
        } else {
            yield put(ACTIONS_APP.setSaveLoader(false));
            yield put(ACTIONS_UNITS.setUnitLockDetails(null));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateLockSettings({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateLockMac({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetUnitState({ payload }) {
    try {
        const state = yield call(API.POST, payload.url, payload.data);
        if (!state) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield put(ACTIONS_UNITS.setUnit(null));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCheckoutUnit({ payload }) {
    try {
        const checkout = yield call(API.POST, payload.url, payload.data);
        if (!checkout) {
            yield history.push('/app/units/all');
        } else {
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestAssignUnit({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const assign = yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.setSaveLoader(false));
        if (!assign) {
            return;
        } else if (assign.message === 'Work done, but could not send text and/or email to the user.') {
            yield put(ACTIONS_APP.showMessage(`notifications.success.assign.workDone`, 'success'));
            yield history.push(`/app/units/display/${payload.data.unitUUID}`);
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.assign`, 'success'));
            yield history.push(`/app/units/display/${payload.data.unitUUID}`);
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_APP.setSaveLoader(false));
    }
}

function* requestUnassignUnit({ payload }) {
    try {
        const unassign = yield call(API.POST, payload.url, payload.data);
        if (!unassign) {
            yield history.push('/app/units/all');
        } else {
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestAuctionUnit({ payload }) {
    try {
        const auction = yield call(API.POST, payload.url, payload.data);
        if (!auction) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.assign`, 'success'));
            yield history.push(`/app/units/display/${payload.data.unitUUID}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestReassignAuction({ payload }) {
    try {
        const auction = yield call(API.POST, payload.url, payload.data);
        if (!auction) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.assign`, 'success'));
            yield history.push(`/app/units/display/${payload.data.unitUUID}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUnauctionUnit({ payload }) {
    try {
        const unauction = yield call(API.POST, payload.url, payload.data);
        if (!unauction) {
            yield history.push('/app/units/all');
        } else {
            yield put(ACTIONS_UNITS.fetchUnitDetails(payload.data.unitUUID));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTransferUnit({ payload }) {
    try {
        const transfer = yield call(API.POST, payload.url, payload.data);
        if (!transfer) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.assign`, 'success'));
            yield history.push(`/app/units/display/${payload.data.unitUUID}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUntransferUnit({ payload }) {
    try {
        const untransfer = yield call(API.POST, payload.url, payload.data);
        if (!untransfer) {
            yield history.push('/app/units/all');
        } else {
            if (payload.unitUUID && payload.unitUUID !== '') {
                yield put(ACTIONS_UNITS.fetchUnitDetails(payload.unitUUID));
            }
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRemoteUnlock({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setUnlockStatus(null));
        const unlock = yield call(API.POST, payload.url, payload.data);
        if (!unlock) {
            yield put(ACTIONS_UNITS.setUnlockStatus('failed'));
            return;
        } else {
            let bleHwVersion = payload && payload.payload && payload.payload.bleHwVersion;

            if (bleHwVersion === '1A') {
                // show this message instead of success message if unit is a Noke One (1A)
                //yield put(ACTIONS_APP.showMessage(`notifications.info.nokeOne.unlock`, 'info'));
                // commenting it out since they're pulling this out of the release so just copied the default success unlock message for now.
                yield put(ACTIONS_APP.showMessage(`notifications.success.unlock`, 'success'));
            } else {
                yield put(ACTIONS_APP.showMessage(`notifications.success.unlock`, 'success'));
            }
            yield put(ACTIONS_UNITS.setUnlockStatus('success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUnlockRequestPin({ payload }) {
    try {
        const unlock = yield call(API.POST, payload.url, payload.data);
        if (!unlock) {
            return;
        } else {
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateRepeatingLockSchedule({ payload }) {
    try {
        const res = yield call(API.POST, payload.url, payload.data);
        if (!res) {
            yield put(ACTIONS_APP.showMessage(`db.error`, 'error'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateRepeatingLockSchedule({ payload }) {
    try {
        const res = yield call(API.POST, payload.url, payload.data);
        if (!res || !res.data) {
            yield put(ACTIONS_APP.showMessage(`db.error`, 'error'));
        } else {
            const currentLockSchedules = yield select(getCurrentLockSchedules);
            const currentLockID =
                currentLockSchedules && currentLockSchedules.length > 0 && currentLockSchedules[0].lockID;
            const newCurrentLockSchedules =
                currentLockID && res.data.lockHoldSchedules.filter((schedule) => schedule.lockID === currentLockID);
            if (currentLockID && newCurrentLockSchedules)
                yield put(ACTIONS_UNITS.setCurrentLockSchedules(newCurrentLockSchedules));
            yield put(ACTIONS_UNITS.setCurrentSiteLockSchedules(res.data.lockHoldSchedules));
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteRepeatingLockSchedule({ payload }) {
    try {
        const res = yield call(API.POST, payload.url, payload.data);
        if (!res || !res.data) {
            yield put(ACTIONS_APP.showMessage(`db.error`, 'error'));
        } else {
            const currentLockSchedules = yield select(getCurrentLockSchedules);
            const currentLockID =
                currentLockSchedules && currentLockSchedules.length > 0 && currentLockSchedules[0].lockID;
            const newCurrentLockSchedules =
                currentLockID && res.data.lockHoldSchedules.filter((schedule) => schedule.lockID === currentLockID);
            if (currentLockID && newCurrentLockSchedules)
                yield put(ACTIONS_UNITS.setCurrentLockSchedules(newCurrentLockSchedules));
            yield put(ACTIONS_UNITS.setCurrentSiteLockSchedules(res.data.lockHoldSchedules));
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestHoldOpenGateTime({ payload }) {
    try {
        const hold = yield call(API.POST, payload.url, payload.data);
        if (!hold) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.request`, 'success'));
            yield put(ACTIONS_UNITS.fetchLockHolds());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestHoldOpenGateSchedule({ payload }) {
    try {
        const hold = yield call(API.POST, payload.url, payload.data);
        if (!hold) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.request`, 'success'));
            yield put(ACTIONS_UNITS.fetchLockHolds());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRemoveLockHold({ payload }) {
    try {
        const hold = yield call(API.POST, payload.url, payload.data);
        if (!hold) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.request`, 'success'));
            yield put(ACTIONS_UNITS.fetchLockHolds());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestReadLockSchedule({ payload }) {
    try {
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule || !schedule.data || !schedule.data.lockHoldSchedules) {
            yield put(ACTIONS_UNITS.setCurrentLockSchedules([]));
            return;
        } else {
            yield put(ACTIONS_UNITS.setCurrentLockSchedules(schedule.data.lockHoldSchedules));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestReadSiteLockSchedules({ payload }) {
    try {
        const schedules = yield call(API.POST, payload.url, payload.data);
        if (!schedules || !schedules.data || !schedules.data.lockHoldSchedules) {
            yield put(ACTIONS_UNITS.setCurrentSiteLockSchedules([]));
            return;
        } else {
            yield put(ACTIONS_UNITS.setCurrentSiteLockSchedules(schedules.data.lockHoldSchedules));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestConfirmation({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchLowBatteryUnits({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setLowBatteryUnits(null));
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data) {
            yield put(ACTIONS_UNITS.setLowBatteryUnits([]));
            return;
        } else {
            let filteredUnits = [];
            for (let i = 0; i < units.data.length; i++) {
                if (units.data[i].batteryVoltage > 0 || units.data[i].wiredVoltage > 0) {
                    if (units.data[i].batteryStatus === 'bad' || units.data[i].wiredStatus === 'bad') {
                        if (units.data[i].wiredStatus === 'good') {
                        } else {
                            filteredUnits.push(units.data[i]);
                        }
                    }
                }
            }
            filteredUnits = UTILS.removeDuplicates(filteredUnits, ['lockId']);
            filteredUnits = UTILS.sortList(false, filteredUnits, 'batteryVoltage');
            yield put(ACTIONS_UNITS.setLowBatteryUnits(filteredUnits));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchLockHolds({ payload }) {
    try {
        const holds = yield call(API.POST, payload.url, payload.data);
        if (!holds || !holds.data || !holds.data.lockHolds) {
            yield put(ACTIONS_UNITS.setLockHolds([]));
            return;
        } else {
            let completedHolds = holds.data.lockHolds.filter(hold => hold.commandStatus && (hold.commandStatus == 'complete' || hold.commandStatus == 'pending_response'))
            yield put(ACTIONS_UNITS.setLockHolds(completedHolds.length > 0 ? completedHolds : holds.data.lockHolds));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateLock({ payload }) {
    try {
        const lock = yield call(API.POST, payload.url, payload.data);
        if (!lock) {
            yield put(ACTIONS_APP.showMessage('notifications.error.transfer.unitCreate', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield history.push(`/app/units/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteLock({ payload }) {
    try {
        const lock = yield call(API.POST, payload.url, payload.data);
        if (!lock) {
            yield put(ACTIONS_APP.showMessage('notifications.error.transfer.unitUnassign', 'warning'));
            return;
        } else {
            if (payload.lock) {
                yield put(ACTIONS_UNITS.createLock(payload.lock));
            } else {
                yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
                yield put(ACTIONS_UNITS.setUnit(null));
                yield history.push(`/app/units/all`);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteUnit({ payload }) {
    try {
        const unit = yield call(API.POST, payload.url, payload.data);
        if (!unit) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.noDelete', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield history.push(`/app/units/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRebootSiteUnits({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.unitReboot', 'success'));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchLockSettings({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setLockSettings(null));
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data) {
            yield put(ACTIONS_UNITS.setLockSettings([]));
            return;
        } else {
            yield put(ACTIONS_UNITS.setLockSettings(units.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSyncUnits({ payload }) {
    try {
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data) {
            return;
        } else {
            let finalUnits = [];
            units.data.pms
                ? units.data.pms.map((unit) => {
                      units.data.site.map((siteUnit) => {
                          if (siteUnit.unitName === unit.unitName && siteUnit.externalID === unit.externalID) {
                              // this means we already have them in the db
                          } else if (siteUnit.unitName === unit.unitName) {
                              unit.siteName = siteUnit.unitName;
                              unit.name = unit.unitName;
                              unit.match = true;
                              unit.externalId = unit.externalID;
                              finalUnits.push(unit);
                          } else {
                              let index = finalUnits.findIndex((e) => e.unitName === unit.unitName);
                              let siteIndex = finalUnits.findIndex((e) => e.unitName === siteUnit.unitName);
                              if (index === -1) {
                                  unit.siteName = unit.unitName;
                                  unit.name = unit.unitName;
                                  unit.externalId = unit.externalID;
                                  finalUnits.push(unit);
                              }
                              if (siteIndex === -1) {
                                  siteUnit.siteName = siteUnit.unitName;
                                  siteUnit.name = siteUnit.unitName;
                                  siteUnit.externalId = siteUnit.externalID;
                                  finalUnits.push(siteUnit);
                              }
                          }
                      });
                      let index = finalUnits.findIndex((e) => e.unitName === unit.unitName);
                      if (index === -1) {
                          unit.name = unit.unitName;
                          unit.siteName = unit.unitName;
                          finalUnits.push(unit);
                      }
                  })
                : units.data.site.map((unit) => {
                      unit.name = unit.unitName;
                      unit.externalId = unit.externalID;
                      finalUnits.push(unit);
                  });
            finalUnits = UTILS.sortList(false, finalUnits, 'name');
            yield put(ACTIONS_UNITS.setSyncUnits(finalUnits));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSyncUnitsDash({ payload }) {
    try {
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data) {
            yield put(ACTIONS_UNITS.setPmsSyncUnits([]));
            return;
        } else {
            units.data.pms ? UTILS.sortList(false, units.data.pms, 'unitName') : null;
            units.data.pms
                ? yield put(ACTIONS_UNITS.setPmsSyncUnits(units.data.pms))
                : yield put(ACTIONS_UNITS.setPmsSyncUnits([]));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchPmsSync({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setPmsSyncChanges(null))
        const sync = yield call(API.POST, payload.url, payload.data);
        if (!sync || !sync.data) {
            return;
        } else {
            if(sync.data.changes){
                let syncUnitsHeaders = yield select(getSyncUnitsHeaders);
                let activeHeader = syncUnitsHeaders.find((item) => item.active);
                sync.data.changes = UTILS.sortList(!activeHeader.order, sync.data.changes, activeHeader.sortTitle);
            } else {
                null
            }
            sync.data.changes
                ? yield put(ACTIONS_UNITS.setPmsSyncChanges(sync.data.changes))
                : yield put(ACTIONS_UNITS.setPmsSyncChanges([]));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSharedUnits({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setSharedUnits(null));
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data) {
            yield put(ACTIONS_UNITS.setSharedUnits([]));
            return;
        } else {
            let sharedUnitsHeaders = yield select(getSharedUnitsHeaders);
            let activeHeader = sharedUnitsHeaders.find((item) => item.active);
            units.data = UTILS.sortList(!activeHeader.order, units.data, activeHeader.sortTitle);
            yield put(ACTIONS_UNITS.setSharedUnits(units.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteShares({ payload }) {
    try {
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data) {
            yield put(ACTIONS_UNITS.setSiteShares([]));
            return;
        } else {
            yield put(ACTIONS_UNITS.setSiteShares(units.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteFobs({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setSiteFobs(null));
        const fobs = yield call(API.POST, payload.url, payload.data);
        if (!fobs || !fobs.data || !fobs.data.fobs) {
            yield put(ACTIONS_UNITS.setSiteFobs([]));
            return;
        } else {
            yield put(ACTIONS_UNITS.setSiteFobs(fobs.data.fobs));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestManagerShareRequest({ payload }) {
    try {
        const share = yield call(API.POST, payload.url, payload.data);
        if (!share) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.request', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchAllUnitNotes({ payload }) {
    try {
        let authUser = yield select(getAuthUser);
        yield put(ACTIONS_UNITS.setAllUnitNotes(null));
        const notes = yield call(API.POST, payload.url, payload.data);
        if (!notes || !notes.data || !notes.data.notes) {
            yield put(ACTIONS_UNITS.setAllUnitNotes([]));
            return;
        } else {
            let frontendVersion = sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : 'default';
            notes.data.notes =
                authUser && authUser.role.tier == 1
                    ? notes.data.notes
                    : notes.data.notes.filter((note) => !note.createdBySupport);
            notes.data.notes.forEach(
                (note) =>
                    (note.imageURL = note.imageURL
                        ? note.imageURL !== ''
                            ? `${note.imageURL}?t=${new Date().getTime()}&v=${frontendVersion}`
                            : imageURL
                        : '')
            );
            const notesHeaders = yield select(getNotesHeaders);
            const activeHeader = notesHeaders.find((item) => item.active);
            notes.data.notes = UTILS.sortList(!activeHeader.order, notes.data.notes, activeHeader.sortTitle);
            yield put(ACTIONS_UNITS.setAllUnitNotes(notes.data.notes));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchUnitNotes({ payload }) {
    try {
        let authUser = yield select(getAuthUser);
        yield put(ACTIONS_UNITS.setUnitNotes(null));
        const notes = yield call(API.POST, payload.url, payload.data);
        if (!notes || !notes.data || !notes.data.notes) {
            yield put(ACTIONS_UNITS.setUnitNotes([]));
            return;
        } else {
            let frontendVersion = sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : 'default';
            notes.data.notes.forEach(
                (note) =>
                    (note.imageURL = note.imageURL
                        ? note.imageURL !== ''
                            ? `${note.imageURL}?t=${new Date().getTime()}&v=${frontendVersion}`
                            : imageURL
                        : '')
            );
            notes.data.notes =
                authUser && authUser.role.tier == 1
                    ? notes.data.notes
                    : notes.data.notes.filter((note) => !note.createdBySupport);
            notes.data.notes ? UTILS.sortList(false, notes.data.notes, 'dueDate') : null;
            yield put(ACTIONS_UNITS.setUnitNotes(notes.data.notes));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchUnitNote({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setUnitNote(null));
        const notes = yield call(API.POST, payload.url, payload.data);
        if (!notes || !notes.data || !notes.data.notes) {
            yield put(ACTIONS_UNITS.setUnitNote([]));
            return;
        } else {
            let frontendVersion = sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : 'default';
            let note = notes.data.notes.filter((note) => note.noteUUID == payload.noteUUID)[0];
            note.dueDate = note.dueDate ? moment(note.dueDate) : '';
            note.imageURL = note.imageURL
                ? note.imageURL !== ''
                    ? `${note.imageURL}?t=${new Date().getTime()}&v=${frontendVersion}`
                    : imageURL
                : '';
            yield put(ACTIONS_UNITS.setUnitNote(note));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateUnitNote({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditUnitNote({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteUnitNote({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield put(ACTIONS_UNITS.fetchAllUnitNotes());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchLockManufacturingData({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setLockManufacturingData(null));
        const manufacturing = yield call(API.POST, payload.url, payload.data);
        if (!manufacturing || !manufacturing.data || !manufacturing.data.lockManufacturingRecords) {
            yield put(ACTIONS_UNITS.setLockManufacturingData([]));
            return;
        } else {
            manufacturing.data.lockManufacturingRecords = UTILS.sortList(
                false,
                manufacturing.data.lockManufacturingRecords,
                'createdDate'
            );
            yield put(ACTIONS_UNITS.setLockManufacturingData(manufacturing.data.lockManufacturingRecords));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUploadUnitTaskImage({ payload }) {
    const formData = new FormData();
    payload.data.Image && formData.append('Image', payload.data.Image);
    payload.data.UnitNoteUUID && formData.append('UnitNoteUUID', payload.data.UnitNoteUUID);
    payload.data.SiteUUID && formData.append('SiteUUID', payload.data.SiteUUID);
    try {
        const upload = yield call(API.POST, payload.url, formData);
        if (!upload) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail`, 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.image`, 'success'));
            yield put(
                payload.data && payload.data.unitUUID
                    ? ACTIONS_UNITS.fetchUnitNotes(payload.data.unitUUID)
                    : ACTIONS_UNITS.fetchAllUnitNotes()
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteUnitTaskImage({ payload }) {
    try {
        const image = yield call(API.POST, payload.url, payload.data);
        if (!image) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield put(
                payload && payload.unitUUID
                    ? ACTIONS_UNITS.fetchUnitNotes(payload.unitUUID)
                    : ACTIONS_UNITS.fetchAllUnitNotes()
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchUnitTaskComments({ payload }) {
    try {
        yield put(ACTIONS_UNITS.setUnitTaskComments(null));
        const comments = yield call(API.POST, payload.url, payload.data);
        if (!comments || !comments.data || !comments.data.comments) {
            yield put(ACTIONS_UNITS.setUnitTaskComments([]));
            return;
        } else {
            const commentsHeaders = yield select(getCommentsHeaders);
            const activeHeader = commentsHeaders.find((item) => item.active);
            comments.data.comments = UTILS.sortList(!activeHeader.order, comments.data.comments, activeHeader.sortTitle);
            yield put(ACTIONS_UNITS.setUnitTaskComments(comments.data.comments));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateUnitTaskComment({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield put(ACTIONS_UNITS.fetchUnitTaskComments(payload.data.noteUUID));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditUnitTaskComment({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield put(ACTIONS_UNITS.fetchUnitTaskComments(payload.data.noteUUID));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteUnitTaskComment({ payload }) {
    try {
        const note = yield call(API.POST, payload.url, payload.data);
        if (!note) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield put(ACTIONS_UNITS.fetchUnitTaskComments(payload.data.noteUUID));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchRepeaters({ payload }) {
    const domain = window.location.href;
    try { 
        domain && !domain.includes('app/settings/repeaters/all') ? yield put(ACTIONS_UNITS.setRepeaterList(null)) : null;
        const units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data || !units.data.units) {
            yield put(ACTIONS_UNITS.setRepeaterList([]));
            return;
        } else {
            let repeaters = units.data.units.filter(unit => unit.accessType == 'site').filter(unit => unit.locks && unit.locks[0] && unit.locks[0].hwType && unit.locks[0].hwType == "repeater")
            repeaters.map(repeater => {
                const { locks } = repeater;
                repeater.online = locks && locks[0] && locks[0].gwDelinquent && locks[0].gwDelinquent == 1;
                repeater.linkQuality = locks && locks[0] && locks[0].linkQuality;
                repeater.voltageWired = locks && locks[0] && locks[0].voltageWired;
                repeater.voltageBat = locks && locks[0] && locks[0].voltageBattery;
            })
            const repeaterHeader = yield select(getRepeaterHeaders);
            const activeHeader = repeaterHeader.find((item) => item.active);
            repeaters = UTILS.sortList(!activeHeader.order, repeaters, activeHeader.sortTitle);
            yield put(ACTIONS_UNITS.setRepeaterList(repeaters));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectUnitsList() {
    yield takeEvery(CONSTANTS.FETCH_UNITS_LIST, requestUnits);
}

export function* selectUnitsStats() {
    yield takeEvery(CONSTANTS.FETCH_UNITS_STATS, requestUnitsStats);
}

export function* selectUnitsListByZone() {
    yield takeEvery(CONSTANTS.FETCH_UNITS_LIST_BY_ZONE, requestUnitsByZone);
}

export function* selectUnitById() {
    yield takeEvery(CONSTANTS.FETCH_UNIT_DETAILS, requestUnit);
}

export function* selectLastSyncInfo() {
    yield takeEvery(CONSTANTS.FETCH_LAST_SYNC_INFO, requestLastSyncInfo);
}

export function* selectUnitLockDetails() {
    yield takeEvery(CONSTANTS.FETCH_UNIT_LOCK_DETAILS, requestUnitLockDetails);
}

export function* selectUpdateUnit() {
    yield takeEvery(CONSTANTS.EDIT_UNIT, requestUpdateUnit);
}

export function* selectUpdateLock() {
    yield takeEvery(CONSTANTS.EDIT_LOCK, requestUpdateLock);
}

export function* selectUpdateLockSilently() {
    yield takeEvery(CONSTANTS.EDIT_LOCK_SILENTLY, requestUpdateLockSilently);
}

export function* selectUpdateLockSettings() {
    yield takeEvery(CONSTANTS.EDIT_LOCK_SETTINGS, requestUpdateLockSettings);
}

export function* selectUpdateLockMac() {
    yield takeEvery(CONSTANTS.EDIT_LOCK_MAC, requestUpdateLockMac);
}

export function* selectCheckoutUnit() {
    yield takeEvery(CONSTANTS.CHECKOUT_UNIT, requestCheckoutUnit);
}

export function* selectAssignUnit() {
    yield takeEvery(CONSTANTS.ASSIGN_UNIT, requestAssignUnit);
}

export function* selectUnassignUnit() {
    yield takeEvery(CONSTANTS.UNASSIGN_UNIT, requestUnassignUnit);
}

export function* selectAuctionUnit() {
    yield takeEvery(CONSTANTS.AUCTION_UNIT, requestAuctionUnit);
}

export function* selectReassignAuction() {
    yield takeEvery(CONSTANTS.REASSIGN_AUCTION, requestReassignAuction);
}


export function* selectUnauctionUnit() {
    yield takeEvery(CONSTANTS.UNAUCTION_UNIT, requestUnauctionUnit);
}

export function* selectTransferUnit() {
    yield takeEvery(CONSTANTS.TRANSFER_UNIT, requestTransferUnit);
}

export function* selectUntransferUnit() {
    yield takeEvery(CONSTANTS.UNTRANSFER_UNIT, requestUntransferUnit);
}

export function* selectRekeyUnit() {
    yield takeEvery(CONSTANTS.REKEY_UNIT, requestConfirmation);
}

export function* selectSetUnitState() {
    yield takeEvery(CONSTANTS.SET_UNIT_STATE, requestSetUnitState);
}

export function* selectEditUnitExternalId() {
    yield takeEvery(CONSTANTS.EDIT_UNIT_EXTERNAL_ID, requestConfirmation);
}

export function* selectRemoteUnlock() {
    yield takeEvery(CONSTANTS.REMOTE_UNLOCK, requestRemoteUnlock);
}

export function* selectUnlockRequestPin() {
    yield takeEvery(CONSTANTS.UNLOCK_REQUEST_PIN, requestUnlockRequestPin);
}

export function* selectCreateRepeatingLockSchedule() {
    yield takeEvery(CONSTANTS.CREATE_REPEATING_LOCK_SCHEDULE, requestCreateRepeatingLockSchedule);
}

export function* selectUpdateRepeatingLockSchedule() {
    yield takeEvery(CONSTANTS.UPDATE_REPEATING_LOCK_SCHEDULE, requestUpdateRepeatingLockSchedule);
}

export function* selectDeleteRepeatingLockSchedule() {
    yield takeEvery(CONSTANTS.DELETE_REPEATING_LOCK_SCHEDULE, requestDeleteRepeatingLockSchedule);
}

export function* selectHoldOpenGateTime() {
    yield takeEvery(CONSTANTS.HOLD_OPEN_GATE_TIME, requestHoldOpenGateTime);
}

export function* selectHoldOpenGateSchedule() {
    yield takeEvery(CONSTANTS.HOLD_OPEN_GATE_SCHEDULE, requestHoldOpenGateSchedule);
}

export function* selectFetchLowBatteryUnits() {
    yield takeEvery(CONSTANTS.FETCH_LOW_BATTERY_UNITS, requestFetchLowBatteryUnits);
}

export function* selectFetchLockHolds() {
    yield takeEvery(CONSTANTS.FETCH_LOCK_HOLDS, requestFetchLockHolds);
}

export function* selectRemoveLockHold() {
    yield takeEvery(CONSTANTS.REMOVE_LOCK_HOLD, requestRemoveLockHold);
}

export function* selectReadLockSchedule() {
    yield takeEvery(CONSTANTS.READ_LOCK_SCHEDULE, requestReadLockSchedule);
}

export function* selectReadSiteLockSchedules() {
    yield takeEvery(CONSTANTS.READ_SITE_LOCK_SCHEDULES, requestReadSiteLockSchedules);
}

export function* selectCreateLock() {
    yield takeEvery(CONSTANTS.CREATE_LOCK, requestCreateLock);
}

export function* selectDeleteLock() {
    yield takeEvery(CONSTANTS.DELETE_LOCK, requestDeleteLock);
}

export function* selectDeleteUnit() {
    yield takeEvery(CONSTANTS.DELETE_UNIT, requestDeleteUnit);
}

export function* selectRebootSiteUnits() {
    yield takeEvery(CONSTANTS.REBOOT_SITE_UNITS, requestRebootSiteUnits);
}

export function* selectLockSettings() {
    yield takeEvery(CONSTANTS.FETCH_LOCK_SETTINGS, requestFetchLockSettings);
}

export function* selectFetchSyncUnits() {
    yield takeEvery(CONSTANTS.FETCH_SYNC_UNITS, requestFetchSyncUnits);
}

export function* selectFetchSyncUnitsDash() {
    yield takeEvery(CONSTANTS.FETCH_SYNC_UNITS_DASH, requestFetchSyncUnitsDash);
}

export function* selectFetchPmsSync() {
    yield takeEvery(CONSTANTS.FETCH_PMS_SYNC, requestFetchPmsSync);
}

export function* selectFetchSharedUnits() {
    yield takeEvery(CONSTANTS.FETCH_SHARED_UNITS, requestFetchSharedUnits);
}

export function* selectSetupUnit() {
    yield takeEvery(CONSTANTS.SETUP_UNIT, requestConfirmation);
}

export function* selectResendKeys() {
    yield takeEvery(CONSTANTS.RESEND_KEYS, requestConfirmation);
}

export function* selectFetchSiteShares() {
    yield takeEvery(CONSTANTS.FETCH_SITE_SHARES, requestFetchSiteShares);
}

export function* selectFetchSiteFobs() {
    yield takeEvery(CONSTANTS.FETCH_SITE_FOBS, requestFetchSiteFobs);
}

export function* selectManagerShareRequest() {
    yield takeEvery(CONSTANTS.MANAGER_SHARE_REQUEST, requestManagerShareRequest);
}

export function* selectFetchAllUnitNotes() {
    yield takeEvery(CONSTANTS.FETCH_ALL_UNIT_NOTES, requestFetchAllUnitNotes);
}

export function* selectFetchUnitNotes() {
    yield takeEvery(CONSTANTS.FETCH_UNIT_NOTES, requestFetchUnitNotes);
}

export function* selectFetchUnitNote() {
    yield takeEvery(CONSTANTS.FETCH_UNIT_NOTE, requestFetchUnitNote);
}

export function* selectCreateUnitNote() {
    yield takeEvery(CONSTANTS.CREATE_UNIT_NOTE, requestCreateUnitNote);
}

export function* selectEditUnitNote() {
    yield takeEvery(CONSTANTS.EDIT_UNIT_NOTE, requestEditUnitNote);
}

export function* selectDeleteUnitNote() {
    yield takeEvery(CONSTANTS.DELETE_UNIT_NOTE, requestDeleteUnitNote);
}

export function* selectFetchLockManufacturingData() {
    yield takeEvery(CONSTANTS.FETCH_LOCK_MANUFACTURING_DATA, requestFetchLockManufacturingData);
}

export function* selectUploadUnitTaskImage() {
    yield takeEvery(CONSTANTS.UPLOAD_UNIT_TASK_IMAGE, requestUploadUnitTaskImage);
}

export function* selectDeleteUnitTaskImage() {
    yield takeEvery(CONSTANTS.DELETE_UNIT_TASK_IMAGE, requestDeleteUnitTaskImage);
}

export function* selectFetchUnitTaskComments() {
    yield takeEvery(CONSTANTS.FETCH_UNIT_TASK_COMMENTS, requestFetchUnitTaskComments);
}

export function* selectCreateUnitTaskComment() {
    yield takeEvery(CONSTANTS.CREATE_UNIT_TASK_COMMENT, requestCreateUnitTaskComment);
}

export function* selectEditUnitTaskComment() {
    yield takeEvery(CONSTANTS.EDIT_UNIT_TASK_COMMENT, requestEditUnitTaskComment);
}

export function* selectDeleteUnitTaskComment() {
    yield takeEvery(CONSTANTS.DELETE_UNIT_TASK_COMMENT, requestDeleteUnitTaskComment);
}

export function* selectFetchRepeaters() {
    yield takeEvery(CONSTANTS.FETCH_REPEATER_LIST, requestFetchRepeaters);
}

export default function* rootSaga() {
    yield all([
        fork(selectUnitsList),
        fork(selectUnitsStats),
        fork(selectUnitsListByZone),
        fork(selectUnitById),
        fork(selectUnitLockDetails),
        fork(selectUpdateUnit),
        fork(selectUpdateLock),
        fork(selectUpdateLockSilently),
        fork(selectUpdateLockSettings),
        fork(selectUpdateLockMac),
        fork(selectCheckoutUnit),
        fork(selectAssignUnit),
        fork(selectUnassignUnit),
        fork(selectAuctionUnit),
        fork(selectReassignAuction),
        fork(selectUnauctionUnit),
        fork(selectTransferUnit),
        fork(selectUntransferUnit),
        fork(selectRekeyUnit),
        fork(selectEditUnitExternalId),
        fork(selectSetUnitState),
        fork(selectRemoteUnlock),
        fork(selectUnlockRequestPin),
        fork(selectCreateRepeatingLockSchedule),
        fork(selectUpdateRepeatingLockSchedule),
        fork(selectDeleteRepeatingLockSchedule),
        fork(selectHoldOpenGateTime),
        fork(selectHoldOpenGateSchedule),
        fork(selectFetchLowBatteryUnits),
        fork(selectCreateLock),
        fork(selectDeleteLock),
        fork(selectDeleteUnit),
        fork(selectRebootSiteUnits),
        fork(selectLockSettings),
        fork(selectLastSyncInfo),
        fork(selectFetchSyncUnits),
        fork(selectFetchSyncUnitsDash),
        fork(selectFetchPmsSync),
        fork(selectFetchSharedUnits),
        fork(selectSetupUnit),
        fork(selectResendKeys),
        fork(selectFetchLockHolds),
        fork(selectRemoveLockHold),
        fork(selectReadLockSchedule),
        fork(selectReadSiteLockSchedules),
        fork(selectFetchSiteShares),
        fork(selectFetchSiteFobs),
        fork(selectManagerShareRequest),
        fork(selectFetchAllUnitNotes),
        fork(selectFetchUnitNotes),
        fork(selectFetchUnitNote),
        fork(selectCreateUnitNote),
        fork(selectEditUnitNote),
        fork(selectDeleteUnitNote),
        fork(selectFetchLockManufacturingData),
        fork(selectUploadUnitTaskImage),
        fork(selectDeleteUnitTaskImage),
        fork(selectFetchUnitTaskComments),
        fork(selectCreateUnitTaskComment),
        fork(selectEditUnitTaskComment),
        fork(selectDeleteUnitTaskComment),
        fork(selectFetchRepeaters),
    ]);
}
