import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'aphrodite';
import { injectIntl } from 'react-intl';

class HeaderTableInterface extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offsetFilterHeight: false
        };
    }
    componentDidMount() {
        this.setState({ offsetFilterHeight: !this.props.initialFilter });
    }
    setOffsetFilterHeight = (val) => {
        this.setState({ offsetFilterHeight: val });
    };
    compNameIncludes = (child, name) => {
        if (!child || !name) return false;
        return child.type && child.type.displayName && child.type.displayName.includes(name);
    }
    
    render() {
        const { offsetFilterHeight } = this.state;
        const { theme, width, filterHeight, hideWidth } = this.props;
        const firstChild = this.props.children[0];
        const secondChild = this.props.children[1];
        this.styles = styles(theme);

        if (!this.props.children || this.props.children.length !== 2) {
            console.error('warning: props length not 2');
            return this.props.children;
        }

        return (
            <>
                {firstChild && (this.compNameIncludes(firstChild, 'Header') ? 
                    React.cloneElement(firstChild, {
                        setOffsetFilterHeight: this.setOffsetFilterHeight
                    })
                    : 
                    firstChild
                )}
                {secondChild && (this.compNameIncludes(secondChild, 'Table') ? 
                    React.cloneElement(secondChild, {
                        tableHeight:
                            (secondChild.props.tableHeight || 0) +
                            (offsetFilterHeight && filterHeight && (!hideWidth || width > hideWidth) ? filterHeight : 0),
                        overrideTableHeight:
                            (secondChild.props.tableHeight || 0) +
                            (offsetFilterHeight && filterHeight && (!hideWidth || width > hideWidth) ? filterHeight : 0)
                    })
                    : 
                    secondChild
                )}
            </>
        );
    }
}

HeaderTableInterface.defaultProps = {
    filterHeight: 38,
    initialFilter: true
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default injectIntl(connect(mapStateToProps, {})(HeaderTableInterface));

const styles = () => StyleSheet.create({});
