import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { switchSite } from 'actions/Auth';
import { setSwitcherLoader } from 'actions/App';

import CellText from 'components/Table/cell/components/text';
class UserNotificationsCellManagerDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        };
    }
    render() {
        const { intl, data, history, toggle, switchSite, setSwitcherLoader, site, width } = this.props;
        const { createdDate, eventsId, title, type, siteID } = data;
        const { show } = this.state;
        return (
            show && (
                <>
                    <CellText
                        Title={type && type == 'feature' ? `${type}: ${title}` : title}
                        Description={moment(createdDate).local().calendar()}
                        textAlign={'left'}
                        TitleColor={true}
                        maxCharLength={width < 500 && width / 12}
                        update={
                            eventsId && eventsId !== ''
                                ? () => {
                                      toggle && toggle();
                                      sessionStorage.removeItem('manager_dash');
                                      setSwitcherLoader({loading: true, text: intl.formatMessage({ id: 'comp.button.switchingSite' })});
                                      siteID && siteID !== site.id && switchSite(siteID.toString(), false, false, false, false, true);
                                      history.push(`/app/activity/notification/${eventsId}`);
                                  }
                                : null
                        }
                    />
                </>
            )
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, height } = app;
    const { site } = auth;
    return { width, height, site };
};

export default withRouter(injectIntl(connect(mapStateToProps, {switchSite, setSwitcherLoader})(UserNotificationsCellManagerDashboard)));
