import React from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { StyleSheet, css } from 'aphrodite';
class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            render: false
        };
    }
    componentDidMount() {
        this._isMounted = true;
        const { fallback } = this.props;
        fallback
            ? setTimeout(
                  function () {
                      this._isMounted && this.setState({ render: true });
                  }.bind(this),
                  3000
              )
            : this._isMounted && this.setState({ render: true });
    }
    componentWillUnmount() {
        this.setState({ render: false });
        this._isMounted = false;
    }
    render() {
        const { theme, type, width, color, bodyWidth, height } = this.props;
        const { render } = this.state;
        this.styles = styles(bodyWidth, height);
        return (
            <div className={css(this.styles.wrapper)}>
                {render && <Loader type={type} color={color ? color : theme.color.themeColor} width={width} />}
            </div>
        );
    }
}

Spinner.defaultProps = {
    type: 'ThreeDots',
    bodyWidth: '100%',
    width: 80
};

const mapStateToProps = ({ app }) => {
    const { theme, height } = app;
    return { theme, height };
};

export default connect(mapStateToProps)(Spinner);

const styles = (width, height) =>
    StyleSheet.create({
        wrapper: {
            textAlign: 'center',
            width,
            margin: `${height / 10} auto 0 auto`
        }
    });
