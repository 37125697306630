import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { Button } from 'reactstrap';
import { injectIntl } from 'react-intl';
import Loader from 'react-loader-spinner';
import moment from 'moment-timezone';
import { checkForScrollbars } from 'utilities';
import { SyncIcon } from 'utilities/icons';
import * as UTILS from 'utilities';
import { setLoader, showMessage } from 'actions/App';
import { syncSite } from 'actions/Auth';
import ToolTips from 'components/ToolTips';
class PMSButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            syncing: false
        };
    }
    componentDidMount() {
        const { setLoader } = this.props;
        setLoader(false);
        this.updateSyncing();
        localStorage.getItem('site_sync_pms') ? null : localStorage.setItem('site_sync_pms', JSON.stringify([]));
        this.checkSyncing = setInterval(() => this.updateSyncing(), 10000);
    }
    componentWillUnmount() {
        clearInterval(this.checkSyncing);
    }
    updateSyncing = () => {
        const { site } = this.props;
        let siteObject =
            localStorage.getItem('site_sync_pms') &&
            JSON.parse(localStorage.getItem('site_sync_pms')).filter((object) => object.siteUUID == site.uuid);
        let minutes = siteObject && siteObject[0] && moment().diff(siteObject[0].lastCodeSentTime, 'minutes', true);
        let syncing = minutes && minutes < 5 ? true : false;
        this.setState({ syncing: syncing });
    };
    handleAction = () => {
        const { site, showMessage, setLoader, syncSite } = this.props;
        let siteObject =
            localStorage.getItem('site_sync_pms') &&
            JSON.parse(localStorage.getItem('site_sync_pms')).filter((object) => object.siteUUID == site.uuid);
        if (siteObject && siteObject[0]) {
            let minutes = moment().diff(siteObject[0].lastCodeSentTime, 'minutes', true);
            if (minutes && minutes < 5) {
                setLoader(false);
                showMessage(`notifications.error.fail.site.sync`, 'warning');
            } else {
                let newArray = JSON.parse(localStorage.getItem('site_sync_pms'));
                let index = newArray.findIndex((object) => object.siteUUID == site.uuid);
                newArray[index].lastCodeSentTime = moment().utc().format();
                localStorage.setItem('site_sync_pms', JSON.stringify(newArray));
                setLoader(true);
                this.setState({ syncing: true });
                syncSite({ site: site, force: false, notify: true });
            }
        } else {
            let newArray = localStorage.getItem('site_sync_pms') ? JSON.parse(localStorage.getItem('site_sync_pms')) : [];
            newArray.push({ siteUUID: site.uuid, lastCodeSentTime: moment().utc().format() });
            localStorage.setItem('site_sync_pms', JSON.stringify(newArray));
            setLoader(true);
            this.setState({ syncing: true });
            syncSite({ site: site, force: false, notify: true });
        }
    };
    render() {
        const { intl, authUser, text, type, permission, theme, site, userTimezone, showLoader } = this.props;
        const { syncing } = this.state;
        this.styles = styles(theme);

        const isWebhooks = site && site.pms === 'webhooks';

        return (
            <>
                <Button
                    id={'syncCustomerButton'}
                    style={{ boxShadow: theme.color.boxShadowIntense }}
                    disabled={
                        showLoader ? showLoader : permission ? !authUser.permissions.includes(permission) : showLoader
                    }
                    className={syncing && !showLoader ? css(this.styles.syncingButton) : css(this.styles.button)}
                    onClick={!isWebhooks ? this.handleAction : () => this.props.history.push('/app/settings/pms')}
                    block>
                    {!isWebhooks ? <>
                        {showLoader ? (
                            <div style={{ textAlign: 'center' }}>
                                <Loader type={type} color={theme.color.buttonFontColor} height={14} width={40} />
                            </div>
                        ) : (
                            <div className={css(this.styles.buttonElementContainer)}>
                                {!checkForScrollbars(document.querySelector('div[data-id="sidebar"]'), 'vertical') && (
                                    <span
                                        style={{ height: 17, width: 17 }}
                                        className={
                                            syncing && !showLoader
                                                ? css(this.styles.animateRotate)
                                                : css(this.styles.noAnimate)
                                        }>
                                        <SyncIcon
                                            color={syncing ? theme.color.fontColor : 'white'}
                                            height={17}
                                            width={17}
                                        />
                                    </span>
                                )}
                                <p
                                    className={css(
                                        syncing && !showLoader ? this.styles.titleSyncing : this.styles.title,
                                        this.styles[localStorage.getItem('nse_local') + 'Title'],
                                        checkForScrollbars(document.querySelector('div[data-id="sidebar"]'), 'vertical')
                                            ? this.styles.smallTitleStyle
                                            : null
                                    )}>
                                    {syncing
                                        ? `${intl.formatMessage({ id: 'pages.sync.Types' })}...`
                                        : intl.formatMessage({ id: 'comp.pmsUpdate' })}
                                </p>
                            </div>
                        )}
                    </> : <>
                        {/* show if PMS is webhooks */}
                        <div className={css(this.styles.buttonElementContainer)} style={{justifyContent: 'center'}}>
                            {!checkForScrollbars(document.querySelector('div[data-id="sidebar"]'), 'vertical') && (
                                    <span
                                        style={{ height: 17, width: 17 }}
                                        className={this.styles.noAnimate}>
                                        <span className="material-icons" style={{fontSize: 17, color: theme.color.buttonFontColor}}>
                                            webhook
                                        </span>
                                    </span>
                                )}
                            <p
                                className={css(
                                    syncing && !showLoader ? this.styles.titleSyncing : this.styles.title,
                                    this.styles[localStorage.getItem('nse_local') + 'WebhooksTitle'],
                                    checkForScrollbars(document.querySelector('div[data-id="sidebar"]'), 'vertical')
                                        ? this.styles.smallTitleStyle
                                        : null
                                )}>
                                {intl.formatMessage({ id: 'comp.viewWebhooks' })}
                            </p>
                        </div>
                    </>}
                    
                </Button>
                {/* TODO: make tooltip for webhooks variant */}
                {!isWebhooks ? <ToolTips
                    placement="bottom-end"
                    target="syncCustomerButton"
                    message="comp.pmsUpdate.tooltip"
                /> : <ToolTips 
                    placement="bottom-end"
                    target="syncCustomerButton"
                    message="comp.viewWebhooks.tooltip"
                />}
                {!isWebhooks && <p className={css(this.styles.info, this.styles[localStorage.getItem('nse_local') + 'Info'])}>
                    {intl.formatMessage({ id: text })}:{' '}
                    {site && site.lastSyncDate && site.lastSyncDate.startsWith('1970-01') ?
                    intl.formatMessage({ id: 'form.na' })
                    : UTILS.dateTimeFormatter(site.lastSyncDate, authUser, site, userTimezone)}
                </p>}
            </>
        );
    }
}

PMSButton.defaultProps = {
    text: 'comp.lastUpdated',
    permission: null,
    type: 'ThreeDots'
};

const mapStateToProps = ({ auth, app }) => {
    const { authUser, site } = auth;
    const { theme, showLoader, userTimezone } = app;
    return { authUser, theme, showLoader, site, userTimezone };
};

export default injectIntl(
    withRouter(
        connect(mapStateToProps, {
            setLoader,
            syncSite,
            showMessage
        })(PMSButton)
    )
);

const translateKeyframes = {
    from: {
        transform: 'rotate3d(0, 0, 1, 0deg)'
    },
    to: {
        transform: 'rotate3d(0, 0, 1, 360deg)'
    }
};

const styles = (theme) =>
    StyleSheet.create({
        button: {
            margin: 0,
            padding: '6px 0px 6px 5px',
            textAlign: 'left',
            color: theme.color.fontColor,
            backgroundColor: theme.color.themeColor,
            boxShadow: theme.color.boxShadowIntense,
            borderColor: theme.color.themeColor,
            borderRadius: '4px',
            ':hover': {
                opacity: '0.9',
                boxShadow: theme.color.boxShadowIntense
            },
            '@media(max-height:800px)': {
                padding: '2px 0px 2px 5px',
            }
        },
        syncingButton: {
            margin: 0,
            padding: '6px 0px 6px 5px',
            textAlign: 'left',
            color: theme.color.fontColor,
            backgroundColor:
                theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                    ? '#626262'
                    : theme && theme.color && theme.color.name && theme.color.name.includes('light2')
                    ? '#bbbcc8'
                    : '#DCDCDC',
            opacity: 0.8,
            boxShadow: theme.color.boxShadowIntense,
            borderColor: theme.color.subFontColor,
            borderRadius: '4px',
            ':hover': {
                cursor: 'not-allowed',
                opacity: '0.9',
                boxShadow: theme.color.boxShadowIntense
            },
            '@media(max-height:800px)': {
                padding: '2px 0px 2px 5px',
            }
        },
        animateRotate: {
            animationName: [translateKeyframes],
            animationDuration: '2s, 1200ms',
            animationIterationCount: 'infinite'
        },
        noAnimate: {},
        buttonElementContainer: {
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center',
        },
        title: {
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: 500,
            textTransform: 'uppercase',
            margin: '0px 0px 0px 4px',
            fontSize: 11,
            '@media(max-height:800px)': {
                fontSize: 10,
            }
        },
        titleSyncing: {
            color: theme.color.fontColor,
            fontFamily: 'Poppins',
            fontWeight: 500,
            textTransform: 'uppercase',
            margin: '0px 0px 0px 4px',
            fontSize: 12,
            '@media(max-height:800px)': {
                fontSize: 10,
            }
        },
        smallTitleStyle: {
            marginLeft: 6,
            padding: '2px 0'
        },
        info: {
            margin: 0,
            fontSize: 8,
            paddingTop: 5,
            fontWeight: 600,
            color: theme.color.themeColor,
            fontFamily: 'Helvetica',
            textAlign: 'end'
        },
        // language adjustment classes for info class
        deInfo: { fontSize: 7 },
        plInfo: { fontSize: 6.8 },
        etInfo: { fontSize: 7 },
        // language adjustment classes for title class
        enTitle: { fontSize: 11 },
        deTitle: { fontSize: 9.3 },
        esTitle: { fontSize: 8.7 },
        ptTitle: { fontSize: 8.7 },
        frTitle: { fontSize: 9.5 },
        plTitle: { fontSize: 10 },
        etTitle: { fontSize: 8.2 },
        // langauge adjustment classes for title class on webhooks text
        plWebhooksTitle: { fontSize: 8.5 },
    });
