//||||||||||||||||||||||||||||||||||||||||
//|| USER NOTIFICATIONS                 ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_USER_NOTIFICATIONS = '/user/notifications/internal/get/';
export const PURPOSE_USER_NOTIFICATIONS = 'Fetch a list of notifications for a user.';
export const PERMISSIONS_USER_NOTIFICATIONS = [];
export const REQUIREMENTS_USER_NOTIFICATIONS = ['Token Required'];
export const PARAMETER_USER_NOTIFICATIONS = {
    userId: 0
};
export const RETURN_USER_NOTIFICATIONS = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| NOTIFICATIONS REGISTER DEVICE      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_NOTIFICATIONS_REGISTER_DEVICE = '/notifications/device/register/';
export const PURPOSE_NOTIFICATIONS_REGISTER_DEVICE = 'Register a desktop device to allow notifications.';
export const PERMISSIONS_NOTIFICATIONS_REGISTER_DEVICE = [];
export const REQUIREMENTS_NOTIFICATIONS_REGISTER_DEVICE = ['Token Required'];
export const PARAMETER_NOTIFICATIONS_REGISTER_DEVICE = {
    uuid: '',
    token: ''
};
export const RETURN_NOTIFICATIONS_REGISTER_DEVICE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| NOTIFICATIONS TEST DEVICE          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_NOTIFICATIONS_TEST_DEVICE = '/notifications/test/send/';
export const PURPOSE_NOTIFICATIONS_TEST_DEVICE = 'Test notifications by sending a test result.';
export const PERMISSIONS_NOTIFICATIONS_TEST_DEVICE = [];
export const REQUIREMENTS_NOTIFICATIONS_TEST_DEVICE = ['Token Required'];
export const PARAMETER_NOTIFICATIONS_TEST_DEVICE = {};
export const RETURN_NOTIFICATIONS_TEST_DEVICE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UPDATE USER NOTIFICATIONS          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UPDATE_USER_NOTIFICATIONS = '/site/users/notifications/edit';
export const PURPOSE_UPDATE_USER_NOTIFICATIONS = 'Update notifications for a user.';
export const PERMISSIONS_UPDATE_USER_NOTIFICATIONS = [];
export const REQUIREMENTS_UPDATE_USER_NOTIFICATIONS = ['Token Required'];
export const PARAMETER_UPDATE_USER_NOTIFICATIONS = {
    userId: 0
};
export const RETURN_UPDATE_USER_NOTIFICATIONS = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| REMOVE USER NOTIFICATIONS          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REMOVE_USER_NOTIFICATIONS = '/user/notifications/internal/dismiss/';
export const PURPOSE_REMOVE_USER_NOTIFICATIONS = 'Remove notifications for a user.';
export const PERMISSIONS_REMOVE_USER_NOTIFICATIONS = [];
export const REQUIREMENTS_REMOVE_USER_NOTIFICATIONS = ['Token Required'];
export const PARAMETER_REMOVE_USER_NOTIFICATIONS = {
    userId: 0
};
export const RETURN_REMOVE_USER_NOTIFICATIONS = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| CLEAR ALL USER NOTIFICATIONS       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_CLEAR_ALL_USER_NOTIFICATIONS = '/site/users/notifications/clear/';
export const PURPOSE_CLEAR_ALL_USER_NOTIFICATIONS = 'Clear all notifications for a user.';
export const PERMISSIONS_CLEAR_ALL_USER_NOTIFICATIONS = [];
export const REQUIREMENTS_CLEAR_ALL_USER_NOTIFICATIONS = ['Token Required'];
export const PARAMETER_CLEAR_ALL_USER_NOTIFICATIONS = {
    userId: 0
};
export const RETURN_CLEAR_ALL_USER_NOTIFICATIONS = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| ADD USER NOTIFICATIONS             ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ADD_USER_NOTIFICATIONS = '/site/users/notifications/add';
export const PURPOSE_ADD_USER_NOTIFICATIONS = 'Add notifications for a user.';
export const PERMISSIONS_ADD_USER_NOTIFICATIONS = [];
export const REQUIREMENTS_ADD_USER_NOTIFICATIONS = ['Token Required'];
export const PARAMETER_ADD_USER_NOTIFICATIONS = {
    userId: 0
};
export const RETURN_ADD_USER_NOTIFICATIONS = {
    notifications: [{}]
};

//||||||||||||||||||||||||||||||||||||||||
//|| NOTIFICATIONS SETTINGS EDIT        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_NOTIFICATIONS_SETTINGS_EDIT = '/user/notifications/';
export const PURPOSE_NOTIFICATIONS_SETTINGS_EDIT = 'Edit a site notifications.';
export const PERMISSIONS_NOTIFICATIONS_SETTINGS_EDIT = [];
export const REQUIREMENTS_NOTIFICATIONS_SETTINGS_EDIT = ['Token Required'];
export const PARAMETER_NOTIFICATIONS_SETTINGS_EDIT = {
    companyId: 0,
    data: {
        notificationsOn: false,
        notificationsEmails: false,
        notificationsPush: false
    }
};
export const RETURN_NOTIFICATIONS_SETTINGS_EDIT = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| CREATE INTERNAL NOTIFICATION       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_CREATE_INTERNAL_NOTIFICATION = '/user/notifications/internal/new/';
export const PURPOSE_CREATE_INTERNAL_NOTIFICATION = 'Create internal notifications for employees for a site';
export const PERMISSIONS_CREATE_INTERNAL_NOTIFICATION = ['manage_site_notifications'];
export const REQUIREMENTS_CREATE_INTERNAL_NOTIFICATION = ['Token Required'];
export const PARAMETER_CREATE_INTERNAL_NOTIFICATION = {
    siteIds: [],
    title: '',
    body: '',
    type: 'alert'
};
export const RETURN_CREATE_INTERNAL_NOTIFICATION = {};
