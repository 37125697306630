import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { PieChart, Pie, Label, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { setLockStatusFilter, setLockTypeFilter, setUnitFilter, setUnitFilters } from 'actions/Units';

import { UnitIcon } from 'utilities/icons';
import * as UTILS from 'utilities';

import Spinner from 'components/Spinner';
import Header from 'components/Header/dashboard';

function CustomLabel({ viewBox, value1, value2 }) {
    const { cx, cy } = viewBox;
    return (
        <text
            x={cx}
            y={cy}
            fill="#3d405c"
            className="recharts-text recharts-label"
            textAnchor="middle"
            dominantBaseline="central">
            <tspan alignmentBaseline="middle" fontSize="24" fontFamily="Poppins" fill="#01acf7">
                {value1}
            </tspan>
            <tspan alignmentBaseline="middle" fontSize="12" x={cx} dy="20" fill="#808080">
                {value2}
            </tspan>
        </text>
    );
}
class DashboardRentalStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMore: 'unitStatus'
        };
    }
    linkLockStatus = (data) => {
        const { setLockStatusFilter, history, setLockTypeFilter, unitsFilters, setUnitFilter, setUnitFilters } =
            this.props;
        setLockTypeFilter('all');
        setUnitFilter('filters.all');
        let newUnitsFilters = JSON.parse(JSON.stringify(unitsFilters));
        newUnitsFilters.map((filter) =>
            filter.title == 'filters.all' ? (filter.active = true) : (filter.active = false)
        );
        setUnitFilters(newUnitsFilters);
        data.name == 'Online Locks' ? setLockStatusFilter('online') : setLockStatusFilter('offline');
        history.push(`/app/units/all/`);
    };
    linkLockType = (data) => {
        const { setLockTypeFilter, history, setLockStatusFilter, unitsFilters, setUnitFilter, setUnitFilters } =
            this.props;
        setLockStatusFilter('all');
        setUnitFilter('filters.all');
        let newUnitsFilters = JSON.parse(JSON.stringify(unitsFilters));
        newUnitsFilters.map((filter) =>
            filter.title == 'filters.all' ? (filter.active = true) : (filter.active = false)
        );
        setUnitFilters(newUnitsFilters);
        data.name == 'Unit'
            ? setLockTypeFilter('unit')
            : data.name == 'Gate'
            ? setLockTypeFilter('gate')
            : data.name == 'Door'
            ? setLockTypeFilter('door')
            : data.name == 'Sliding Door'
            ? setLockTypeFilter('slidingdoor')
            : data.name == 'Elevator'
            ? setLockTypeFilter('elevator')
            : data.name == 'Other'
            ? setLockTypeFilter('other')
            : setLockTypeFilter('all');
        history.push(`/app/units/all/`);
    };
    linkUnitStatus = (data) => {
        const { history, unitsFilters, setUnitFilter, setUnitFilters } = this.props;
        data =
            data.link == 'Occupied'
                ? {
                      title: 'filters.units.occupied',
                      value: ['inuse'],
                      active: false
                  }
                : data.link == 'Available'
                ? {
                      title: 'filters.units.available',
                      value: ['available'],
                      active: false
                  }
                : data.link == 'Overlock'
                ? {
                      title: 'filters.units.overlock',
                      value: ['available', 'inuse', 'pending'],
                      active: false
                  }
                : data.link == 'Repossessed'
                ? {
                      title: 'filters.units.repo',
                      value: ['available', 'inuse', 'pending'],
                      active: false
                  }
                : data.link == 'Pending'
                ? {
                      title: 'filters.units.pending',
                      value: ['pending'],
                      active: false
                  }
                : data.link == 'Gatelock'
                ? {
                      title: 'filters.units.gatelock',
                      value: ['available', 'inuse', 'pending'],
                      active: false
                  }
                : data.link == 'Auction'
                ? {
                      title: 'filters.units.auction-smartentry',
                      value: ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry'],
                      active: false
                  }
                : data.link == 'Transfer'
                ? {
                      title: 'filters.units.transfer',
                      value: ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry'],
                      active: false
                  }
                  : data.link == 'Prelet'
                ? {
                      title: 'filters.units.prelet',
                      value: ['prelet'],
                      active: false
                  }
                  :  data.link == 'Checkout'
                ? {
                      title: 'filters.units.checkout',
                      value: ['checkout'],
                      active: false
                  }
                : data.link == 'Other'
                ? {
                      title: 'filters.units.other',
                      value: ['available', 'inuse', 'pending'],
                      active: false
                  }
                : {
                      title: 'filters.all',
                      value: ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry'],
                      active: true
                  };
        let newUnitsFilters = JSON.parse(JSON.stringify(unitsFilters));
        newUnitsFilters.map((filter) =>
            filter.title == data.title ? (filter.active = true) : (filter.active = false)
        );
        setUnitFilter(data.title);
        setUnitFilters(newUnitsFilters);
        history.push(`/app/units/all/`);
    };
    updateView = (viewMore) => {
        this.setState({ viewMore });
    };
    render() {
        const {
            intl,
            theme,
            width,
            unitsListStats,
            unitsListLockStats,
            unitsListLockStatusStats,
            editWidgets
        } = this.props;
        const { viewMore } = this.state;
        this.styles = styles(theme);
        const CustomTooltip = ({ active, payload }) => {
            if (active) {
                return (
                    <div className={css(this.styles.tooltipWrapper)}>
                        <p className={css(this.styles.tooltipLabel)}>{`${payload[0].name}`}</p>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '-6px 0 0 0',
                                padding: '0 0 8px 0'
                            }}>
                            <div
                                style={{
                                    backgroundColor: `${payload[0].payload.color}`,
                                    marginRight: 2,
                                    border: '2px solid' + theme.color.borderColor,
                                    height: 13,
                                    width: 13,
                                    borderRadius: '50%'
                                }}></div>
                            <p className={css(this.styles.tooltipText)}>{`${payload[0].value}`}</p>
                        </div>
                    </div>
                );
            }
            return null;
        };
        const data = [
            {
                name: `${intl.formatMessage({ id: 'filters.units.available' })}: ${
                    unitsListStats ? Number(((unitsListStats.available / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.available : 0,
                color: '#0EC55E',
                link: 'Available'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.occupied' })}: ${
                    unitsListStats ? Number(((unitsListStats.occupied / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.occupied : 0,
                color: '#F2CF52',
                link: 'Occupied'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.overlock' })}: ${
                    unitsListStats ? Number(((unitsListStats.overlock / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.overlock : 0,
                color: '#EF7319',
                link: 'Overlock'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.gatelock' })}: ${
                    unitsListStats ? Number(((unitsListStats.gatelock / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.gatelock : 0,
                color: '#E02550',
                link: 'Gatelock'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.repo' })}: ${
                    unitsListStats ? Number(((unitsListStats.repo / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.repo : 0,
                color: '#EDC9F5',
                link: 'Repossessed'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.auction-smartentry' })}: ${
                    unitsListStats ? Number(((unitsListStats.auction / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.auction : 0,
                color: '#821C9E',
                link: 'Auction'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.transfer' })}: ${
                    unitsListStats ? Number(((unitsListStats.transfer / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.transfer : 0,
                color: '#136BB0',
                link: 'Transfer'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.checkout' })}: ${
                    unitsListStats ? Number(((unitsListStats.checkout / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.checkout : 0,
                color: '#136BB0',
                link: 'Checkout'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.prelet' })}: ${
                    unitsListStats ? Number(((unitsListStats.prelet / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.prelet : 0,
                color: '#136BB0',
                link: 'Prelet'
            },
            {
                name: `${intl.formatMessage({ id: 'db.other' })}: ${
                    unitsListStats ? Number(((unitsListStats.other / unitsListStats.total) * 100).toFixed(1)) : 0
                }%`,
                value: unitsListStats ? unitsListStats.other : 0,
                color: '#2793E8',
                link: 'Other'
            }
        ];
        const lockData = [
            {
                name: `${intl.formatMessage({ id: 'filters.units.nokeOne' })}: ${
                    unitsListLockStats
                        ? Number(((unitsListLockStats.nokeOne / unitsListLockStats.total) * 100).toFixed(1))
                        : 0
                }%`,
                value: unitsListLockStats ? unitsListLockStats.nokeOne : 0,
                color: '#E02550'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.nokeVolt' })}: ${
                    unitsListLockStats
                        ? Number(((unitsListLockStats.nokeVolt / unitsListLockStats.total) * 100).toFixed(1))
                        : 0
                }%`,
                value: unitsListLockStats ? unitsListLockStats.nokeVolt : 0,
                color: '#EF7319'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.hdPadlock' })}: ${
                    unitsListLockStats
                        ? Number(((unitsListLockStats.hdPadlock / unitsListLockStats.total) * 100).toFixed(1))
                        : 0
                }%`,
                value: unitsListLockStats ? unitsListLockStats.hdPadlock : 0,
                color: '#F2CF52'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.nokePad' })}: ${
                    unitsListLockStats
                        ? Number(((unitsListLockStats.nokePad / unitsListLockStats.total) * 100).toFixed(1))
                        : 0
                }%`,
                value: unitsListLockStats ? unitsListLockStats.nokePad : 0,
                color: '#0EC55E'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.nokeScreen' })}: ${
                    unitsListLockStats
                        ? Number(((unitsListLockStats.nokeScreen / unitsListLockStats.total) * 100).toFixed(1))
                        : 0
                }%`,
                value: unitsListLockStats ? unitsListLockStats.nokeScreen : 0,
                color: '#2793e8'
            },
            {
                name: `${intl.formatMessage({ id: 'filters.units.other' })}: ${
                    unitsListLockStats
                        ? Number(((unitsListLockStats.other / unitsListLockStats.total) * 100).toFixed(1))
                        : 0
                }%`,
                value: unitsListLockStats ? unitsListLockStats.other : 0,
                color: '#821C9E'
            }
        ];
        const lockStatusData = [
            {
                name: `${intl.formatMessage({ id: 'comp.table.header.locks.online' })}: ${
                    unitsListLockStatusStats
                        ? Number(((unitsListLockStatusStats.online / unitsListLockStatusStats.total) * 100).toFixed(1))
                        : 0
                }%`,
                value: unitsListLockStatusStats ? unitsListLockStatusStats.online : 0,
                color: '#00cc66'
            },
            {
                name: `${intl.formatMessage({ id: 'comp.table.header.locks.offline' })}: ${
                    unitsListLockStatusStats
                        ? Number(((unitsListLockStatusStats.offline / unitsListLockStatusStats.total) * 100).toFixed(1))
                        : 0
                }%`,
                value: unitsListLockStatusStats ? unitsListLockStatusStats.offline : 0,
                color: '#ffcc66'
            }
        ];
        let unitStatusData = UTILS.sortList(
            true,
            data.filter((stat) => stat.value > 0),
            'value'
        );
        let lockDataStatus = UTILS.sortList(
            true,
            lockStatusData.filter((stat) => stat.value > 0),
            'value'
        );
        let lockTypeData = UTILS.sortList(
            true,
            lockData.filter((stat) => stat.value > 0),
            'value'
        );
        return (
            <>
                <Header
                    unitStatus={viewMore}
                    updateUnitStatus={this.updateView}
                    Icon={UnitIcon}
                    editWidgets={editWidgets}
                    iconSize={26}
                    title={
                        viewMore === 'lockStatus'
                            ? 'comp.table.header.lockStatus'
                            : viewMore === 'lockType'
                            ? 'comp.table.header.lockType'
                            : 'comp.table.header.unitStatus'
                    }
                />
                <Row style={{ margin: 0, padding: '10px 20px 20px 20px' }}>
                    <Col xs={12} lg={12} style={{ padding: 0 }}>
                        {unitsListStats ? (
                            viewMore === 'lockStatus' ? (
                                <ResponsiveContainer
                                    width={'100%'}
                                    minHeight={editWidgets ? 225 : width > 1400 ? 250 : width > 300 ? 275 : 280}>
                                    <PieChart>
                                        <Pie
                                            data={lockDataStatus}
                                            nameKey="name"
                                            dataKey="value"
                                            innerRadius={editWidgets ? 35 : width > 300 ? 45 : 30}
                                            outerRadius={editWidgets ? 45 : width > 300 ? 65 : 40}
                                            startAngle={60}
                                            endAngle={-300}
                                            cx="50%"
                                            cy="50%"
                                            fill="#82ca9d"
                                            stroke="none"
                                            minAngle={3}
                                            paddingAngle={3}
                                            isAnimationActive={true}
                                            labelLine={true}
                                            label>
                                            {lockDataStatus.map((entry) => (
                                                <Cell
                                                    onClick={() => this.linkLockStatus(entry)}
                                                    className={css(this.styles.cell)}
                                                    key={'Cell' + entry.name}
                                                    fill={entry.color}
                                                />
                                            ))}
                                            <Label
                                                width={30}
                                                position="center"
                                                content={
                                                    <CustomLabel
                                                        value1={unitsListLockStatusStats.total}
                                                        value2={intl.formatMessage({
                                                            id: 'comp.table.header.total'
                                                        })}
                                                    />
                                                }></Label>
                                        </Pie>
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend
                                            wrapperStyle={
                                                editWidgets
                                                    ? {
                                                          fontSize: 10,
                                                          marginTop: 25,
                                                          fontFamily: 'Roboto'
                                                      }
                                                    : width > 300
                                                    ? {
                                                          fontSize: 12,
                                                          fontFamily: 'Roboto',
                                                          lineHeight: 2
                                                      }
                                                    : { fontSize: 10, fontFamily: 'Roboto' }
                                            }
                                            iconType={'circle'}
                                            align={'center'}
                                            iconSize={12}
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                            ) : viewMore === 'lockType' ? (
                                <ResponsiveContainer
                                    width={'100%'}
                                    minHeight={editWidgets ? 225 : width > 1400 ? 250 : width > 300 ? 275 : 280}>
                                    <PieChart>
                                        <Pie
                                            data={lockTypeData}
                                            nameKey="name"
                                            dataKey="value"
                                            innerRadius={editWidgets ? 35 : width > 300 ? 45 : 30}
                                            outerRadius={editWidgets ? 45 : width > 300 ? 65 : 40}
                                            startAngle={60}
                                            endAngle={-300}
                                            cx="50%"
                                            cy="50%"
                                            fill="#82ca9d"
                                            stroke="none"
                                            minAngle={3}
                                            paddingAngle={3}
                                            isAnimationActive={true}
                                            labelLine={true}
                                            label>
                                            {lockTypeData.map((entry) => (
                                                <Cell
                                                    onClick={() => this.linkLockType(entry)}
                                                    className={css(this.styles.cell)}
                                                    key={'Cell' + entry.name}
                                                    fill={entry.color}
                                                />
                                            ))}
                                            <Label
                                                width={30}
                                                position="center"
                                                content={
                                                    <CustomLabel
                                                        value1={unitsListLockStats.total}
                                                        value2={intl.formatMessage({
                                                            id: 'comp.table.header.total'
                                                        })}
                                                    />
                                                }></Label>
                                        </Pie>
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend
                                            wrapperStyle={
                                                editWidgets
                                                    ? {
                                                          fontSize: 10,
                                                          marginTop: 25,
                                                          fontFamily: 'Roboto'
                                                      }
                                                    : width > 300
                                                    ? {
                                                          fontSize: 12,
                                                          fontFamily: 'Roboto',
                                                          lineHeight: 2
                                                      }
                                                    : { fontSize: 10, fontFamily: 'Roboto' }
                                            }
                                            iconType={'circle'}
                                            align={'center'}
                                            iconSize={12}
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                            ) : (
                                <ResponsiveContainer
                                    width={'100%'}
                                    minHeight={editWidgets ? 225 : width > 1400 ? 250 : width > 300 ? 275 : 280}>
                                    <PieChart>
                                        <Pie
                                            data={unitStatusData}
                                            nameKey="name"
                                            dataKey="value"
                                            innerRadius={editWidgets ? 35 : width > 300 ? 45 : 30}
                                            outerRadius={editWidgets ? 45 : width > 300 ? 65 : 40}
                                            startAngle={60}
                                            endAngle={-300}
                                            cx="50%"
                                            cy="50%"
                                            fill="#82ca9d"
                                            stroke="none"
                                            minAngle={3}
                                            paddingAngle={3}
                                            isAnimationActive={true}
                                            labelLine={true}
                                            label>
                                            {unitStatusData.map((entry) => (
                                                <Cell
                                                    onClick={() => this.linkUnitStatus(entry)}
                                                    className={css(this.styles.cell)}
                                                    key={'Cell' + entry.name}
                                                    fill={entry.color}
                                                />
                                            ))}
                                            <Label
                                                width={30}
                                                position="center"
                                                content={
                                                    <CustomLabel
                                                        value1={unitsListStats.total}
                                                        value2={intl.formatMessage({
                                                            id: 'comp.table.header.total'
                                                        })}
                                                    />
                                                }></Label>
                                        </Pie>
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend
                                            wrapperStyle={
                                                editWidgets
                                                    ? {
                                                          fontSize: 10,
                                                          marginTop: 25,
                                                          fontFamily: 'Roboto'
                                                      }
                                                    : width > 300
                                                    ? {
                                                          fontSize: 12,
                                                          fontFamily: 'Roboto',
                                                          lineHeight: 2
                                                      }
                                                    : { fontSize: 10, fontFamily: 'Roboto' }
                                            }
                                            iconType={'circle'}
                                            align={'center'}
                                            iconSize={12}
                                        />
                                    </PieChart>
                                </ResponsiveContainer>
                            )
                        ) : (
                            <Spinner />
                        )}
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ auth, app, units }) => {
    const { authUser } = auth;
    const { theme, width } = app;
    const { unitsListStats, unitsListLockStats, unitsListLockStatusStats, unitsFilters } = units;
    return {
        authUser,
        theme,
        unitsListStats,
        unitsListLockStats,
        unitsListLockStatusStats,
        unitsFilters,
        width
    };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, {
            setLockStatusFilter,
            setLockTypeFilter,
            setUnitFilter,
            setUnitFilters
        })(DashboardRentalStatus)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        titleHeader: {
            fontSize: 13,
            margin: '5px 0 0 0',
            color: theme.color.themeColor,
            opacity: 1,
            transition: 'all .1s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            },
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        footerRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
        },
        footerItemActive: {
            color: 'white',
            padding: '5px 7px',
            margin: '0 6px',
            borderRadius: 4,
            background: theme.color.themeColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            fontSize: 12,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        footerItem: {
            color: theme.color.fontColor,
            padding: '5px 7px',
            margin: '0 6px',
            borderRadius: 4,
            background: theme.color.pageColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            fontSize: 12,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        cell: {
            opacity: 1,
            ':hover': {
                cursor: 'pointer',
                opacity: 0.7
            }
        },
        tooltipWrapper: {
            background: theme.color.compColor,
            borderRadius: 4,
            color: theme.color.compColor,
            boxShadow: theme.color.boxShadow,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 80,
            textAlign: 'center'
        },
        tooltipLabel: {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            padding: '8px 20px 8px 20px',
            color:
                theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                    ? theme.color.fontColor
                    : theme.color.compColor,
            background: theme.color.themeColor,
            width: '100%'
        },
        tooltipText: {
            margin: '0 0 0 0',
            padding: '0 0 0 0',
            color: theme.color.fontColor
        }
    });
