import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as ICONS from 'utilities/icons';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_ENTRY from 'actions/Entry';
import * as ACTIONS_UNITS from 'actions/Units';
import * as ACTIONS_ACTIVITY from 'actions/Activity';
import moment from 'moment';

const getEntryHeaders = (state) => state.entry.entryHeader;
const getAuthUser = (state) => state.auth.authUser;
const getEntryLockTypeFilter = (state) => state.entry.entryLockTypeFilter;

function* requestEntryList({ payload }) {
    try {
        yield put(ACTIONS_ENTRY.setEntryList(null));
        yield put(ACTIONS_ENTRY.setEntryListStats(null));
        let entry = yield call(API.POST, payload.url, payload.data);
        if (!entry || !entry.data || !entry.data.units) {
            yield put(ACTIONS_ENTRY.setEntryList([]));
            if (payload.fetchStats) {
                let stats = {
                    total: 0,
                    gate: 0,
                    door: 0,
                    elevator: 0,
                    sample: 0,
                    repeater: 0,
                    slidingdoor: 0,
                    exitgate: 0
                };
                let lockStats = {
                    total: 0,
                    nokeOne: 0,
                    nokeVolt: 0,
                    hdPadlock: 0,
                    nokePad: 0,
                    nokeScreen: 0,
                    other: 0
                };
                yield put(ACTIONS_ENTRY.setEntryListStats(stats));
                yield put(ACTIONS_ENTRY.setEntryListLockStats(lockStats));
            }
        } else {
            entry.lock = [];
            entry.data.units = entry.data.units ? entry.data.units : [];
            entry.data.units = entry.data.units.map((item) => {
                item.locks.map((lock) => {
                    lock.Icon = ICONS.EntryIcon;
                    lock.entryId = item.id;
                    lock.entryUUID = item.uuid;
                    lock.lockId = lock.id;
                    lock.lockUUID = lock.uuid;
                    lock.zoneIDs = item.zoneIDs ? item.zoneIDs : [];
                    lock.zoneUUIDs = item.zoneUUIDS ? item.zoneUUIDS : [];
                    entry.lock.push(lock);
                });
            });

            ///////////////////////
            ///// STATS STUFF /////
            ///////////////////////

            // only do this if we need to
            if (payload.fetchStats) {
                let stats = {
                    total: entry.lock.length,
                    gate: entry.lock.filter((item) => item.hwType === 'gate').length,
                    door: entry.lock.filter((item) => item.hwType === 'door').length,
                    elevator: entry.lock.filter((item) => item.hwType === 'elevator').length,
                    sample: entry.lock.filter((item) => item.hwType === 'sample').length,
                    repeater: entry.lock.filter((item) => item.hwType === 'repeater').length,
                    slidingdoor: entry.lock.filter((item) => item.hwType === 'slidingdoor').length,
                    exitgate: entry.lock.filter((item) => item.hwType === 'exitgate').length
                };
                let entryTotal = entry.lock.length;
                let nokeOne = entry.lock.filter((item) => item.bleHwVersion.includes('A')).length;
                let nokeVolt = entry.lock.filter((item) => item.bleHwVersion.includes('E')).length;
                let hdPadlock = entry.lock.filter((item) => item.bleHwVersion.includes('I')).length;
                let nokePad = entry.lock.filter((item) => item.bleHwVersion == '3K').length;
                let nokeScreen = entry.lock.filter((item) => item.bleHwVersion == '4K').length;
                let lockStats = {
                    total: entryTotal,
                    nokeOne,
                    nokeVolt,
                    hdPadlock,
                    nokePad,
                    nokeScreen,
                    other: entryTotal - nokeOne - nokeVolt - hdPadlock - nokePad - nokeScreen
                };
                yield put(ACTIONS_ENTRY.setEntryListLockStats(lockStats));
                yield put(ACTIONS_ENTRY.setEntryListStats(stats));
            }

            ///////////////////////
            //// Entries STUFF ////
            ///////////////////////

            entry.lock = UTILS.cleanUpArray(entry.lock, 'id');
            entry.lock = UTILS.filterArray(entry.lock, 'hwType', payload.filter);
            let entryHeaders = yield select(getEntryHeaders);
            let activeHeader = entryHeaders.find((item) => item.active);
            entry.lock = UTILS.sortList(!activeHeader.order, entry.lock, activeHeader.sortTitle);
            const entryLockTypeFilter = yield select(getEntryLockTypeFilter);
            entry.lock =
                entryLockTypeFilter == 'nokeOne'
                    ? entry.lock.filter((lock) => lock.bleHwVersion.includes('A'))
                    : entryLockTypeFilter == 'nokeVolt'
                    ? entry.lock.filter((lock) => lock.bleHwVersion.includes('E'))
                    : entryLockTypeFilter == 'hdPadlock'
                    ? entry.lock.filter((lock) => lock.bleHwVersion.includes('I'))
                    : entryLockTypeFilter == 'nokePad'
                    ? entry.lock.filter((lock) => lock.bleHwVersion.includes('3K'))
                    : entryLockTypeFilter == 'nokeScreen'
                    ? entry.lock.filter((lock) => lock.bleHwVersion.includes('4K'))
                    : entryLockTypeFilter == 'other'
                    ? entry.lock.filter(
                          (lock) =>
                              !lock.bleHwVersion.includes('A') &&
                              !lock.bleHwVersion.includes('E') &&
                              !lock.bleHwVersion.includes('I') &&
                              !lock.bleHwVersion.includes('K')
                      )
                    : entry.lock;
            yield put(ACTIONS_ENTRY.setEntryList(entry.lock));
        }
    } catch (error) {
        yield put(ACTIONS_ENTRY.setEntryList([]));
        yield put(ACTIONS_ENTRY.setEntryListStats(null));
        console.warn(error);
    }
}

function* requestEntryDetails({ payload }) {
    let authUser = yield select(getAuthUser);
    try {
        yield put(ACTIONS_ENTRY.setEntryDetails(null));
        let entry = yield call(API.POST, payload.url, {});
        entry.data.units = entry.data.units ? entry.data.units : [];
        let unitObject =
            entry.data.units.filter(
                (entry) => entry.locks && entry.locks[0] && entry.locks[0].uuid === payload.data.unitUUID
            ).length > 0
                ? entry.data.units.filter(
                      (entry) => entry.locks && entry.locks[0] && entry.locks[0].uuid === payload.data.unitUUID
                  )[0]
                : {};
        unitObject.isExit = unitObject.isExit ? 'true' : 'false';
        unitObject.unitId = unitObject.id ? unitObject.id : '';
        unitObject.unitUUID = unitObject.uuid ? unitObject.uuid : '';
        yield put(ACTIONS_UNITS.setUnit(unitObject));
        if (!entry || !entry.data) {
            yield put(ACTIONS_ENTRY.setEntryDetails(null));
        } else {
            entry.lock = [];
            entry.data.units = entry.data.units ? entry.data.units : [];
            entry.data.units = entry.data.units.map((item) => {
                item.locks.map((lock) => {
                    lock.Icon = ICONS.EntryIcon;
                    lock.entryId = item.id;
                    lock.entryUUID = item.uuid;
                    lock.lockId = lock.id;
                    lock.lockUUID = lock.uuid;
                    entry.lock.push(lock);
                });
            });
            entry.lock = entry.lock.find((grab) => grab.uuid === payload.data.unitUUID);
            entry.lock.temperature =
                entry.lock.bleHwVersion == '1A' && Number(entry.lock.bleAppVersion) <= 1.23
                    ? entry.lock.temperature / 4
                    : entry.lock.bleHwVersion == '3K' && Number(entry.lock.bleAppVersion) <= 3.11
                    ? entry.lock.temperature / 4
                    : entry.lock.temperature;
            if (
                entry &&
                entry.lock &&
                entry.lock.mac &&
                entry.lock.bleHwVersion &&
                authUser &&
                authUser.permissions.includes('support_view_all_companies')
            ) {
                yield put(ACTIONS_UNITS.fetchLockManufacturingData(entry.lock.mac, entry.lock.bleHwVersion));
            }
            yield put(ACTIONS_ENTRY.setEntryDetails(entry.lock));
            yield put(
                ACTIONS_ACTIVITY.fetchActivityList({
                    limit: 200,
                    page: 1,
                    type: 'entry',
                    filter: {
                        locks: [entry.lock.lockUUID],
                        date: {
                            start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                            end: moment().format('YYYY-MM-DD')
                        }
                    }
                })
            );
        }
    } catch (error) {
        yield put(ACTIONS_ENTRY.setEntryDetails(null));
        console.warn(error);
    }
}

function* requestEntryCreate({}) {
    try {
    } catch (error) {
        console.warn(error);
    }
}

function* requestEntryEdit({}) {
    try {
    } catch (error) {
        console.warn(error);
    }
}

function* requestEntryDeletion({}) {
    try {
    } catch (error) {
        console.warn(error);
    }
}

function* requestResendEntryCode({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sentAccessCodes', 'success'));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectCreateEntry() {
    yield takeEvery(CONSTANTS.CREATE_ENTRY, requestEntryCreate);
}
export function* selectDeleteEntry() {
    yield takeEvery(CONSTANTS.DELETE_ENTRY, requestEntryDeletion);
}
export function* selectEditEntry() {
    yield takeEvery(CONSTANTS.EDIT_ENTRY, requestEntryEdit);
}
export function* selectFetchEntryList() {
    yield takeEvery(CONSTANTS.FETCH_ENTRY_LIST, requestEntryList);
}
export function* selectFetchEntryDetails() {
    yield takeEvery(CONSTANTS.FETCH_ENTRY_DETAILS, requestEntryDetails);
}

export function* selectResendEntryCode() {
    yield takeEvery(CONSTANTS.RESEND_ENTRY_CODE, requestResendEntryCode);
}

export default function* rootSaga() {
    yield all([
        fork(selectCreateEntry),
        fork(selectDeleteEntry),
        fork(selectEditEntry),
        fork(selectFetchEntryList),
        fork(selectFetchEntryDetails),
        fork(selectResendEntryCode)
    ]);
}
