import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import { NotifyIcon, FileIcon, QuestionMarkLogo, BugIcon, NewsIcon, SiteIcon, AddUserPlus } from 'utilities/icons';

import { toggleCollapsedNav, setTestSuite, setShowPortalSearch } from 'actions/App';
import { setViewAs, setAuthUser } from 'actions/Auth';
import { fetchUserNotifications } from 'actions/Notifications';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants';
import { limitStringLength } from 'utilities';

import Notifications from 'components/Notifications/list';
import HelpWizard from 'components/HelpWizard';
import BackButton from 'components/Link/back';
import ViewAsMenu from 'components/Menus/header/viewAs';
import Dropdown from 'components/Dropdowns';
import ToolTips from 'components/ToolTips';
import { fadeIn } from 'react-animations';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fire: false,
            showHelper: false,
            helperMinimized: false,
            helpWizardInProgress: false
        };
    }
    componentDidMount() {
        const { fetchUserNotifications, userNotifications } = this.props;
        !userNotifications && fetchUserNotifications();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.showLoader !== this.props.showLoader && !this.props.showLoader) {
            setTimeout(() => {
                this.setState({ fire: false });
            }, 4000);
        }
    }
    onToggleCollapsedNav = () => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };
    checkURL = () => {
        const { location } = this.props;
        let locationFirst = location.pathname.split('/')[2];
        let locationSecond = location.pathname.split('/')[3];
        if (
            (locationFirst && locationFirst.includes('all')) ||
            (locationSecond && locationSecond.includes('all')) ||
            (locationFirst && locationFirst.includes('settings')) ||
            (locationSecond && locationSecond.includes('settings')) ||
            (locationFirst && locationFirst.includes('hours')) ||
            (locationSecond && locationSecond.includes('hours')) ||
            (locationFirst && locationFirst.includes('help')) ||
            (locationSecond && locationSecond.includes('help'))
        ) {
            if (location.pathname.includes('display/schedule') || location.pathname.includes('schedule/create')) {
                // note: display/schedule and schedule/create are for some subpages on the hours page
                return true;
            }
            if (locationFirst === 'feedback') {
                // submit ticket page can be accessed multiple places and is its own page so its a good idea to have it here too
                return true;
            }
            if (location.pathname.includes('notifications/all') || location.pathname.includes('notes/all') || location.pathname.includes('releases/all')) {
                // these are second level pages accessed from dashboard widgets or the header
                return true;
            }
            if (locationSecond === 'instructions') {
                return false;
            }
            return false;
        }
        if (location.pathname.includes('user/facilities/all')) {
            // for non support sites page
            return false;
        }
        return true;
    };
    refresh = () => {
        window.location.reload();
        this.setState({ fire: true });
    };
    linkFeedback = () => {
        this.props.history.push('/app/feedback');
    };
    setManage = () => {
        sessionStorage.setItem('manager_dash', true);
        this.props.history.push('/dashboard');
    };
    linkSites = () => {
        let { authUser, history } = this.props;
        authUser && authUser.permissions.includes('support_view_all_companies')
            ? history.push('/app/facilities/all')
            : history.push('/app/user/facilities/all');
    };
    addUser = () => {
        let { history } = this.props;
        history.push('/app/users/create');
    };
    toggleHelperMinimized = () => {
        this.setState({ helperMinimized: !this.state.helperMinimized });
    };
    toggleShowHelper = () => {
        this.setState({
            showHelper: !this.state.showHelper,
            helpWizardInProgress: false,
            helperMinimized: false
        });
    };
    toggleHelpWizardInProgress = () => {
        this.setState({ helpWizardInProgress: !this.state.helpWizardInProgress });
    };
    isOnLocalhost = () => {
        if (!location) return false;
        return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
    };
    togglePortalSearch = () => {
        const { showPortalSearch, setShowPortalSearch } = this.props;
        setShowPortalSearch(!showPortalSearch);
    }
    resetUser = () => {
        const { setViewAs, setAuthUser } = this.props;
        let newUser = JSON.parse(sessionStorage.getItem('user_data'))
        setAuthUser(newUser);
        setViewAs(null);
    }
    toggleHelpWizard = () => {
        const { showHelper, helperMinimized, helpWizardInProgress } = this.state;
        this.setState({
            helperMinimized: helpWizardInProgress
                ? !helperMinimized
                : helperMinimized,
            showHelper: helpWizardInProgress ? showHelper : !showHelper
        });
    }
    render() {
        const { drawerType, intl, width, theme, userNotifications, site, authUser, viewedRole, showTestSuite, setTestSuite, showPortalSearch } = this.props;
        const { fire, showHelper, helperMinimized } = this.state;

        this.styles = styles(theme, helperMinimized);
        const drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? 'd-flex d-xl-none'
            : drawerType.includes(COLLAPSED_DRAWER)
            ? 'd-flex'
            : 'd-none';
        const total = userNotifications ? userNotifications.length : 0;

        return (
            <div className={`app-main-header ${css(this.styles.wrapper)}`}>
                <div className={css(this.styles.fadeIn) + ` d-flex app-toolbar align-items-center`}>
                    <span className={` jr-menu-icon pointer ${drawerStyle}`} onClick={this.onToggleCollapsedNav}>
                        <span className={`menu-icon ${css(this.styles.menu)}`} />
                    </span>
                    {site && site.installerAccountsEnabled === 'true' && width > 500 ? (
                        <ul className="header-notifications list-inline ml-0">
                            <li className="list-inline-item app-tour">
                                <div id="installerModeHead" className={css(this.styles.installerBanner)}>
                                    <i
                                        className={`material-icons ${css(this.styles.iconInstaller)}`}
                                        id="add_circle_outline">
                                        report_problem
                                    </i>
                                    <span style={{ margin: '0 10px' }}>INSTALLER MODE</span>
                                    <i
                                        className={`material-icons ${css(this.styles.iconInstaller)}`}
                                        id="add_circle_outline">
                                        report_problem
                                    </i>
                                </div>
                                <ToolTips target="installerModeHead" message="comp.installerMode" />
                            </li>
                        </ul>
                    ) : (
                        site &&
                        site.installerAccountsEnabled === 'true' && (
                            <ul className="header-notifications list-inline ml-0">
                                <li className="list-inline-item app-tour">
                                    <div id="installerModeHead" className={css(this.styles.installerBannerSmall)}>
                                        <i
                                            className={`material-icons ${css(this.styles.iconInstallerSmall)}`}
                                            id="add_circle_outline">
                                            report_problem
                                        </i>
                                        <span style={{ height: 9, fontSize: 8 }}>INSTALLER</span>
                                        <span style={{ height: 9, fontSize: 8 }}>MODE</span>
                                    </div>
                                    <ToolTips target="installerModeHead" message="comp.installerMode" />
                                </li>
                            </ul>
                        )
                    )}
                    {viewedRole && width > 952 && (
                        <ul className="header-notifications list-inline ml-0">
                            <li className="list-inline-item app-tour">
                                <div className={css(this.styles.viewedRole)} id="viewedRoleHead" onClick={() => this.resetUser()}>
                                    <i className="material-icons" id="add_circle_outline">
                                        person_search
                                    </i>
                                    <span style={{ margin: '0 10px' }}>Viewing as {viewedRole}</span>
                                    <i className="material-icons" id="add_circle_outline">
                                        person_search
                                    </i>
                                </div>
                                <ToolTips target="viewedRoleHead" message="comp.table.header.viewAsTooltip" />
                            </li>
                        </ul>
                    )}
                    <ul className="header-notifications list-inline ml-0">
                        <li className="list-inline-item app-tour">{this.checkURL() ? <BackButton /> : null}</li>
                    </ul>

                    {!showPortalSearch && <div className={css(this.styles.fadeIn) + ` d-flex align-items-center`} style={{width: '100%'}}>
                        <ul className="header-notifications list-inline ml-auto">
                            <li className="list-inline-item app-tour">
                                <Dropdown
                                    menu={Notifications}
                                    style={{ zIndex: 999 }}
                                    width={width > 800 ? 600 : width > 420 ? 375 : width}
                                    height={width > 800 ? 400 : 420}>
                                    <div id="notification_icon" className={css(this.styles.hover)}>
                                        {total != 0 && (
                                            <div className={css(this.styles.notifyWrapper)}>{total > 9 ? '9+' : total}</div>
                                        )}
                                        {authUser && authUser.role && authUser.role.tier !== 1 ? (
                                            <NotifyIcon color={theme.color.themeColor} strokeWidth={'1.8px'} />
                                        ) : (
                                            <div style={{ marginTop: 4 }}>
                                                <NewsIcon color={theme.color.themeColor} strokeWidth={'1.3px'} />
                                            </div>
                                        )}
                                    </div>
                                </Dropdown>
                            </li>
                            {width < 952 &&
                                authUser &&
                                authUser.permissions &&
                                authUser.permissions.includes('manage_users') && (
                                    <li className="list-inline-item mail-tour">
                                        <div
                                            id="add_circle_outline"
                                            className={css(this.styles.hover)}
                                            onClick={() => this.addUser()}>
                                            <i className={`material-icons ${css(this.styles.icon)}`}>add_circle_outline</i>
                                        </div>
                                        <ToolTips target="add_circle_outline" message="form.add.user" />
                                    </li>
                                )}
                            <li className="list-inline-item mail-tour">
                                <i
                                    onClick={this.refresh}
                                    className={`material-icons ${css(this.styles.icon, fire && this.styles.zippyHeader)}`}
                                    id="refreshIconHeader">
                                    autorenew
                                </i>
                            </li>
                            {width > 952 && authUser && authUser.role && authUser.role.tier && authUser.role.tier === 1 ? (
                                <li className="list-inline-item mail-tour">
                                    <Dropdown menu={ViewAsMenu}>
                                        <div id="viewAsButton" className={css(this.styles.hover)}>
                                            <i className={`material-icons ${css(this.styles.iconView)}`}>person_search</i>
                                        </div>
                                        <ToolTips target="viewAsButton" message="comp.table.header.viewAs" />
                                    </Dropdown>
                                </li>
                            ) : null}
                            {width > 952 &&
                                authUser &&
                                authUser.role &&
                                authUser.role.tier &&
                                authUser.role.tier > 1 &&
                                authUser.permissions &&
                                authUser.permissions.includes('manage_employees') &&
                                authUser.permissions.includes('manage_users') &&
                                authUser.sites &&
                                authUser.sites.length > 1 &&
                                authUser.sites.length < 16 ? (
                                    <li className="list-inline-item mail-tour">
                                        <div
                                            onClick={this.setManage}
                                            id="managerDashboardHeader"
                                            className={css(this.styles.hover)}>
                                            <i className={`material-icons ${css(this.styles.iconView)}`}>dashboard</i>
                                        </div>
                                        <ToolTips target="managerDashboardHeader" message="comp.button.dashManager" />
                                    </li>
                                ) : null}
                            {site && site.companyUUID && site.companyUUID == "2" ? null : width > 400 && (
                                <li className="list-inline-item mail-tour">
                                    <div onClick={this.linkFeedback} id="feedback_icon" className={css(this.styles.hover)}>
                                        <div style={{ marginTop: 3 }}>
                                            <FileIcon
                                                height={22}
                                                width={22}
                                                strokeWidth={1.3}
                                                color={theme.color.themeColor}
                                            />
                                        </div>
                                    </div>
                                </li>
                            )}

                            {width > 600 && this.isOnLocalhost() && (
                                <li className="list-inline-item">
                                    <div
                                        id="testsuite-icon"
                                        className={css(this.styles.hover)}
                                        style={{marginTop: 3, marginLeft: 3}}
                                        onClick={() => setTestSuite(!showTestSuite)}>
                                        <BugIcon 
                                            stroke={theme.color.themeColor} 
                                            strokeWidth={28}
                                            height={25}
                                            width={25} />
                                    </div>
                                </li>
                            )}
                            
                            {width > 600 && authUser && authUser.featureflagAssignments && authUser.featureflagAssignments.includes('feature_search_list') && 
                                <li className="list-inline-item help-wizard" style={{marginRight: 5}} onClick={this.togglePortalSearch}>
                                    <span className={`material-icons ${css(this.styles.icon)}`}>search</span>
                                </li>}

                            {width > 300 && width <= 1200 && (
                                // spacing was off for this so i had to add some styling
                                <li style={{marginRight: 15, padding:0, display: 'inline-block'}}>
                                    <div
                                        className={css(
                                            this.styles.iconBg,
                                            helperMinimized ? this.styles.iconActiveBg : null
                                        )}
                                        onClick={this.toggleHelpWizard}
                                        id="helpWizardIcon">
                                        <div
                                            id="helpwizard-icon"
                                            className={css(this.styles.hover)}
                                            style={{marginTop: 1}}>
                                            <QuestionMarkLogo fill={helperMinimized ? 'white' : theme.color.themeColor} height={20} width={20} />
                                        </div>
                                    </div>
                                    <ToolTips target="helpWizardIcon" message="tooltip.helpWizard.title" />
                                </li>
                            )}

                            {(showHelper || helperMinimized) && (
                                <HelpWizard
                                    helperMinimized={helperMinimized}
                                    toggleHelperMinimized={this.toggleHelperMinimized}
                                    toggleShowHelper={this.toggleShowHelper}
                                    toggleHelpWizardInProgress={this.toggleHelpWizardInProgress}
                                    style={{ zIndex: 999 }}
                                />
                            )}

                            {width > 952 && <li className="list-inline-item">
                                <div className={css(this.styles.headerMainButton)}>
                                    {width > 1200 && <div 
                                        className={css(this.styles.mainButtonHelp)} 
                                        onClick={this.toggleHelpWizard}>
                                        <div className={css(this.styles.mainButtonIcon)} style={{marginBottom : 2}}>
                                            <QuestionMarkLogo fill={helperMinimized ? 'white' : theme.color.themeColor} height={17} width={17} />
                                        </div>
                                        <div>{intl.formatMessage({ id: 'comp.sidebar.help' })}</div>
                                    </div>}
                                    {authUser && authUser.permissions && authUser.permissions.includes('manage_users') && (
                                        <div className={css(this.styles.mainButtonAddUser)} onClick={this.addUser}>
                                            <div className={css(this.styles.mainButtonIcon)}>
                                                <AddUserPlus fill={theme.color.themeColor} height={14} width={20} />
                                            </div>
                                            <div>{intl.formatMessage({ id: 'form.add.user' })}</div>
                                        </div>
                                    )}
                                    <div id="siteNameTip" className={css(this.styles.mainButtonSite)} onClick={this.linkSites}>
                                        <div className={css(this.styles.mainButtonIcon)} style={{marginBottom: 2,}}>
                                            <SiteIcon color={theme.color.buttonFontColor} height={17} width={20} strokeWidth={1.5} />
                                        </div>
                                        <div>{site && site.name && limitStringLength(site.name, 25)}</div>
                                    </div>
                                    <UncontrolledTooltip
                                        placement={'bottom'}
                                        target={'siteNameTip'}
                                        delay={{ show: 75, hide: 250 }}
                                        style={{ 
                                            color: theme.color.themeColor, 
                                            background: theme.color.compColor,
                                            border: '1px solid' + theme.color.themeColor,
                                            padding: '5px 10px',
                                            opacity: 1 + ' !important',
                                        }}
                                        className="my-tooltip">
                                            {site && site.name}
                                    </UncontrolledTooltip>
                                </div>
                            </li>}
                        </ul>
                    </div>}
                </div>

                {!showPortalSearch && <>
                    <ToolTips target="refreshIconHeader" message="tooltip.refresh" />
                    <ToolTips
                        target="notification_icon"
                        message={
                            authUser && authUser.role && authUser.role.tier !== 1
                                ? 'comp.button.notifications'
                                : 'pages.releases.Types'
                        }
                    />
                    {site && site.companyUUID && site.companyUUID == "2" ? null : width > 400 && <ToolTips target="feedback_icon" message="comp.button.submit.tickets" />}
                </>}
            </div>
        );
    }
}

const mapStateToProps = ({ app, auth, notifications }) => {
    const { authUser, site, viewedRole } = auth;
    const { drawerType, width, theme, navCollapsed, showLoader, showTestSuite, showPortalSearch } = app;
    const { userNotifications } = notifications;
    return {
        drawerType,
        width,
        theme,
        navCollapsed,
        authUser,
        site,
        showLoader,
        userNotifications,
        viewedRole,
        showTestSuite,
        showPortalSearch
    };
};

export default injectIntl(withRouter(connect(mapStateToProps, { 
    toggleCollapsedNav, 
    fetchUserNotifications, 
    setTestSuite, 
    setViewAs,
    setAuthUser,
    setShowPortalSearch
})(Header)));

const translateKeyframes = {
    '100%': {
        transform: 'rotate(360deg)'
    }
};
const wizardBgBlink = {
    '0%': { opacity: 0.65 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.65 }
};

const styles = (theme,helperMinimized) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.headerColor
        },
        menu: {
            backgroundColor: theme.color.themeColor,
            height: 2
        },
        icon: {
            fontSize: 24,
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        iconView: {
            fontSize: 27,
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        zippyHeader: {
            animationName: [translateKeyframes],
            animationDuration: '2s, 1200ms',
            animationIterationCount: 'infinite'
        },
        notifyWrapper: {
            height: 18,
            width: 18,
            backgroundColor: 'red',
            borderRadius: '50%',
            position: 'absolute',
            top: 0,
            left: 15,
            margin: 0,
            color: '#fff',
            fontSize: 11,
            textAlign: 'center',
            paddingTop: 2
        },
        hover: {
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        installerBanner: {
            minWidth: 260,
            background: 'red',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
            fontSize: 20,
            minHeight: '4vh',
            marginRight: 15,
            '@media(max-width:1300px)': {
                minWidth: 240,
                fontSize: 14,
                marginRight: 0
            },
            '@media(max-width:1050px)': {
                minWidth: 0,
                fontSize: 10,
                marginRight: 0
            },
            '@media(max-width:800px)': {
                fontSize: 10,
                marginRight: 0
            },
            '@media(max-width:500px)': {
                minWidth: '15vw',
                fontSize: 10
            },
            '@media(max-width:315px)': {
                minWidth: '0vw',
                display: 'none'
            }
        },
        installerBannerSmall: {
            color: 'red',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
            fontSize: 20,
            minHeight: '4vh',
            marginRight: 15,
            '@media(max-width:1300px)': {
                minWidth: 240,
                fontSize: 14,
                marginRight: 0
            },
            '@media(max-width:1050px)': {
                minWidth: 0,
                fontSize: 10,
                marginRight: 0
            },
            '@media(max-width:800px)': {
                fontSize: 10,
                marginRight: 0
            },
            '@media(max-width:500px)': {
                minWidth: '15vw',
                fontSize: 10
            },
            '@media(max-width:315px)': {
                minWidth: '0vw',
                display: 'none'
            }
        },
        iconBg: {
            width: 30,
            height: 30,
            display: 'grid',
            placeItems: 'center',
            paddingTop: 2,
            borderRadius: 4
        },
        iconActiveBg: {
            backgroundColor: theme.color.themeColor,
            animationName: [wizardBgBlink],
            animationDuration: '2s',
            animationIterationCount: 'infinite'
        },
        viewedRole: {
            minWidth: '25vw',
            background: theme.color.themeColor,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 13,
            maxHeight: 31,
            color: theme.color.buttonFontColor,
            borderRadius: 4,
            padding: '6px 5px',
            fontFamily: 'Poppins',
            marginRight: 15,
            ':hover': {
                opacity: 0.8,
                cursor: 'pointer',
            }
        },
        iconInstaller: {
            fontSize: 24,
            color: 'white',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            },
            '@media(max-width:1200px)': {
                fontSize: 16
            },
            '@media(max-width:1000px)': {
                fontSize: 0
            },
            '@media(max-width:500px)': {
                fontSize: 0
            }
        },
        iconInstallerSmall: {
            margin: '0 0 -6px 0'
        },
        hoverButton: {
            boxShadow: theme.color.boxShadowIntense,
            ':hover': {
                boxShadow: theme.color.boxShadowIntense,
                cursor: 'pointer',
                opacity: '0.8'
            }
        },

        headerMainButton: {
            display: 'flex', 
            alignItems: 'center',
            fontSize: 15,
            fontFamily: 'Poppins',
            fontWeight: 600,
            height: 36,
            overflow: 'hidden', 
            cursor: 'pointer',
            marginLeft: 5,
        },
        mainButtonAddUser: {
            border: `1px solid ${theme.color.themeColor}`,
            borderRadius: 5,
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            padding: '0px 10px',
            marginRight: 10,
            height: '100%',
            color: theme.color.themeColor,
            backgroundColor: theme.color.headerColor,
            transition: 'background-color 0.2s ease-in-out, opacity .2s ease-in-out',
            ':hover': {
                opacity: 0.8,
            }
        },
        mainButtonHelp: {
            border: `1px solid ${theme.color.themeColor}`,
            borderRadius: 5,
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            padding: '0px 10px',
            paddingLeft: 7,
            marginRight: 10,
            height: '100%',
            color: helperMinimized ? theme.color.buttonFontColor : theme.color.themeColor,
            backgroundColor: helperMinimized ? theme.color.themeColor : theme.color.headerColor,
            transition: 'background-color 0.2s ease-in-out, opacity .2s ease-in-out',
            ':hover': {
                opacity: 0.8,
            },

            animationName: helperMinimized && [wizardBgBlink],
            animationDuration: '2s',
            animationIterationCount: 'infinite'
        },
        mainButtonSite: {
            display: 'flex',
            gap: 5,
            borderRadius: 5,
            alignItems: 'center',
            padding: '0px 10px',
            paddingRight: 12,
            height: '100%',
            color: theme.color.buttonFontColor,
            backgroundColor: theme.color.themeColor,
            transition: 'background-color 0.2s ease-in-out, opacity .2s ease-in-out',
            ':hover': {
                opacity: 0.8,
            }
        },
        mainButtonIcon: {
            display: 'grid',
            placeItems: 'center',
            width: 20,
            height: 20,
        },

        fadeIn: {
            animationName: fadeIn,
            animationDuration: '.2s'
        }
    });
