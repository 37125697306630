import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';

import Types from 'components/Filters/notifications/types';
import Sites from 'components/Filters/notifications/sites';
class FiltersNotifications extends React.Component {
    render() {
        const { theme, update } = this.props;
        this.styles = styles(theme);
        return (
            <div className={css(this.styles.wrapper)}>
                <ul className={css(this.styles.list)}>
                    <li className={css(this.styles.listItem)}>
                        <Types update={update} />
                    </li>
                    <li className={css(this.styles.listItem)}>
                        <Sites update={update} />
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default connect(mapStateToProps)(FiltersNotifications);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            padding: 5,
            marginBottom: 0,
            borderBottom: `solid 1px ${theme.color.borderColor}`,
            minHeight: 30,
            textAlign: 'left',
            position: 'relative'
        },
        list: {
            padding: 0,
            margin: 0,
            display: 'block',
            width: '100%',
            color: theme.color.themeColor
        },
        listItem: {
            display: 'inline-block',
            minWidth: 150,
            maxWidth: 200
        }
    });
