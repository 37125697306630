import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { fadeInDown } from 'react-animations';

import { LoginIcon, FileIcon } from 'utilities/icons';

import { fetchUserNotifications } from 'actions/Notifications';

import ToolTips from 'components/ToolTips';
import Weather from 'components/Weather/weatherHeader';
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fire: false
        };
    }
    componentDidMount() {
        const { fetchUserNotifications, userNotifications } = this.props;
        !userNotifications && fetchUserNotifications();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.showLoader !== this.props.showLoader && !this.props.showLoader) {
            setTimeout(() => {
                this.setState({ fire: false });
            }, 4000);
        }
    }
    refresh = () => {
        window.location.reload();
        this.setState({ fire: true });
    };
    linkFeedback = () => {
        this.props.history.push('/dashboard/feedback');
    };
    setManage = () => {
        sessionStorage.setItem('manager_dash', true);
        this.props.history.push('/dashboard');
    };
    render() {
        const { width, theme } = this.props;
        const { fire } = this.state;
        this.styles = styles(theme);
        return (
            <div className={`app-main-header ${css(this.styles.wrapper)}`}>
                <div className={`d-flex app-toolbar align-items-center`}>
                    <div className={css(this.styles.imageWrapper, this.styles.fadeInDown)} onClick={this.switchURL}>
                        <img
                            alt={theme.title}
                            src={
                                theme && theme.menuLogo
                                    ? theme.menuLogo
                                    : 'https://storage.googleapis.com/janus-nokebucket/assets/images/Themes/Demo/logo.png'
                            }
                            className={css(this.styles.image)}
                        />
                    </div>
                    <ul className="header-notifications list-inline ml-auto">
                        {width > 800 && (
                            <li className="list-inline-item mail-tour" style={{ marginRight: 0 }}>
                                <Weather />
                            </li>
                        )}
                        <li className="list-inline-item mail-tour">
                            <i
                                onClick={this.refresh}
                                className={`material-icons ${css(this.styles.icon, fire && this.styles.zippyHeader)}`}
                                id="autorenew">
                                autorenew
                            </i>
                        </li>
                        {width > 952 && (
                            <li className="list-inline-item mail-tour">
                                <div onClick={this.setManage} id="managerDashboard" className={css(this.styles.hover)}>
                                    <i
                                        className={`material-icons ${css(this.styles.iconView)}`}
                                        id="add_circle_outline">
                                        dashboard
                                    </i>
                                </div>
                                <ToolTips target="managerDashboard" message="comp.button.dashManager" />
                            </li>
                        )}
                        {width > 400 && (
                            <li className="list-inline-item mail-tour">
                                <div onClick={this.linkFeedback} id="feedback_icon" className={css(this.styles.hover)}>
                                    <FileIcon height={22} width={22} color={theme.color.themeColor} />
                                </div>
                            </li>
                        )}
                        <li className="list-inline-item mail-tour">
                            <div
                                onClick={() => this.props.history.push('dashboard/logout/')}
                                id="logout"
                                className={css(this.styles.hover)}>
                                <LoginIcon height={22} width={22} color={theme.color.themeColor} />
                            </div>
                            <ToolTips target="logout" message="comp.button.logout" />
                        </li>
                    </ul>
                </div>
                <ToolTips target="autorenew" message="tooltip.refresh" />
                {width > 400 && <ToolTips target="feedback_icon" message="comp.button.submit.tickets" />}
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { width, theme, showLoader } = app;
    return { width, theme, showLoader };
};

export default injectIntl(withRouter(connect(mapStateToProps, { fetchUserNotifications })(Header)));

const translateKeyframes = {
    '100%': {
        transform: 'rotate(360deg)'
    }
};

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.headerColor
        },
        icon: {
            fontSize: 24,
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        iconView: {
            fontSize: 27,
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        zippyHeader: {
            animationName: [translateKeyframes],
            animationDuration: '2s, 1200ms',
            animationIterationCount: 'infinite'
        },
        hover: {
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        imageWrapper: {
            backgroundColor: theme.color.headerColor,
            overflow: 'hidden'
        },
        image: {
            height: 52,
            padding: '0px 0px',
            borderRadius: 5,
            backgroundColor: theme.color.buttonFontColor
        },
        fadeInDown: {
            animationName: fadeInDown,
            animationDuration: '1.2s'
        }
    });
