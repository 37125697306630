import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { fadeIn } from 'react-animations';
import Skeleton from 'react-loading-skeleton';

import { fetchDailyCodes } from 'actions/AccessCodes';
import { KeypadsIcon } from 'utilities/icons';

import Header from 'components/Header/dashboard';
class DashboardDailyCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeLeft: null
        };
    }
    componentDidMount() {
        const { authUser, fetchDailyCodes, editWidgets, visible } = this.props;
        authUser &&
            authUser.permissions.includes('manage_access_codes') &&
            (editWidgets || visible) &&
            fetchDailyCodes();
        setTimeout(() => {
            this.showTimer();
        }, 1000);
    }
    showTimer = () => {
        const { intl, site } = this.props;
        let currentTime = moment().tz('America/Denver');
        let midnight = site ? moment().tz(site.timeZone).endOf('day') : moment().tz('America/Denver').endOf('day');
        let leftTime = moment(midnight).diff(currentTime, 'seconds');
        let duration = moment.duration(leftTime, 'seconds');
        let interval = 1000;
        if (duration.asSeconds() <= 0) {
            clearInterval(interval);
        }
        duration = moment.duration(duration.asSeconds() - 1, 'seconds');
        this.setState({
            timeLeft:
                duration.hours() +
                ` ${intl.formatMessage({ id: 'pages.hours.Type' })} ` +
                duration.minutes() +
                ` ${intl.formatMessage({ id: 'form.minutes' })}`
        });
        return (
            duration.days() +
            ' Days ' +
            duration.hours() +
            ' Hours ' +
            duration.minutes() +
            ' Minutes ' +
            duration.seconds() +
            ' Seconds'
        );
    };
    render() {
        const { intl, theme, width, dailyCodes } = this.props;
        const { timeLeft } = this.state;
        this.styles = styles(theme, width);
        return (
            <>
                <Header
                    Icon={KeypadsIcon}
                    iconSize={26}
                    title={
                        dailyCodes && dailyCodes.length > 1
                            ? 'pages.dashboard.dailyCodesPlural'
                            : 'pages.dashboard.dailyCodes'
                    }
                    subtitle={
                        dailyCodes && dailyCodes.length > 1 && timeLeft
                            ? `${timeLeft && timeLeft} ${intl.formatMessage({
                                  id: 'pages.dashboard.untilRefresh'
                              })}`
                            : null
                    }
                />
                <Row style={{ margin: '0 0 0 0', padding: '5px 20px 20px 20px' }}>
                    <Col className={css(this.styles.wrapper)} style={{ padding: '0 0', overflowY: 'auto' }} xs={12}>
                        {dailyCodes && dailyCodes.length == 1 ? (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        margin: '10px 0 0 0'
                                    }}>
                                    {<KeypadsIcon height={26} width={26} color={theme.color.themeColor} />}
                                    <h1
                                        style={{
                                            fontFamily: 'Poppins',
                                            fontSize: 35,
                                            margin: '0 0 0 10px'
                                        }}>
                                        {dailyCodes && dailyCodes[0] ? (
                                            dailyCodes[0].accessCode
                                        ) : (
                                            <Skeleton inline={true} width={128} />
                                        )}
                                    </h1>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        margin: '20px 0 0 0'
                                    }}>
                                    <h2 style={{ fontSize: width > 600 ? 16 : 12 }}>
                                        <span
                                            style={{
                                                marginLeft: 5,
                                                color: theme.color.themeColor,
                                                fontSize: width > 600 ? 15 : 11
                                            }}>
                                            {intl.formatMessage({
                                                id: 'pages.dashboard.refreshesIn'
                                            })}
                                            :
                                        </span>{' '}
                                        {timeLeft ? timeLeft : <Skeleton inline={true} width={80} />}
                                    </h2>
                                </div>
                            </div>
                        ) : (
                            dailyCodes && (
                                <table className={css(this.styles.table)}>
                                    {dailyCodes && dailyCodes.length > 1 ? (
                                        <tbody>
                                            {dailyCodes.map((code, i) => (
                                                <tr
                                                    key={'DAILYCODES' + code.firstName + code.accessCode + i}
                                                    className={css(this.styles.row)}>
                                                    <td>
                                                        <KeypadsIcon
                                                            height={22}
                                                            width={22}
                                                            color={theme.color.themeColor}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className={css(this.styles.cell)}>
                                                            <span
                                                                className={
                                                                    width > 400
                                                                        ? css(this.styles.bold)
                                                                        : css(this.styles.text)
                                                                }>
                                                                {code.accessCode && code.accessCode}{' '}
                                                                {code.state && `(${code.state})`}
                                                            </span>
                                                            <span className={css(this.styles.text)}>
                                                                {code.firstName && code.firstName}{' '}
                                                                {code.lastName && code.lastName}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    {width > 400 && (
                                                        <td className={css(this.styles.text)}>
                                                            {code.accessType && code.accessType}
                                                        </td>
                                                    )}
                                                    {width > 400 && (
                                                        <td
                                                            className={css(this.styles.text)}
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                alignItems: 'center'
                                                            }}>
                                                            {code.zones &&
                                                                code.zones.map((zone, i) => (
                                                                    <span
                                                                        key={zone + i}
                                                                        style={{
                                                                            padding: '0 4px',
                                                                            marginRight: 3,
                                                                            background: theme.color.pageColor,
                                                                            borderRadius: 5
                                                                        }}>
                                                                        {zone}
                                                                    </span>
                                                                ))}
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody
                                            style={{
                                                minHeight: 102,
                                                fontSize: 13,
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                animationName: fadeIn,
                                                animationDuration: '1.2s'
                                            }}>
                                            <tr>
                                                <td>
                                                    {intl.formatMessage({
                                                        id: 'comp.table.cell.empty'
                                                    })}
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            )
                        )}
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, accessCodes, auth }) => {
    const { theme, width } = app;
    const { dailyCodes } = accessCodes;
    const { authUser, site } = auth;
    return { theme, width, dailyCodes, authUser, site };
};

export default withRouter(injectIntl(connect(mapStateToProps, { fetchDailyCodes })(DashboardDailyCodes)));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            maxHeight: 110,
            height: 110
        },
        header: {
            color: theme.color.themeColor,
            margin: '0 0 10px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        },
        table: {
            width: '100%',
            fontSize: 11
        },
        row: {
            borderBottom: '1px solid' + theme.color.borderColor
        },
        bold: {
            fontWeight: 600
        },
        text: {
            textTransform: 'capitalize'
        },
        cell: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        }
    });
