//||||||||||||||||||||||||||||||||||||||||
//|| UNIT LIST                          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_LIST = '/site/units/';
export const PURPOSE_UNIT_LIST = 'Fetch list of units for the site.';
export const PERMISSIONS_UNIT_LIST = [];
export const REQUIREMENTS_UNIT_LIST = ['Token Required'];
export const PARAMETER_UNIT_LIST = {
    filter: ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry'],
    companyId: 0
};
export const RETURN_UNIT_LIST = {
    page: 0,
    pages: 0,
    units: [
        {
            User: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            },
            accessType: '',
            companyId: 0,
            details: '',
            detailsDepth: 0,
            detailsPrice: 0,
            detailsWidth: 0,
            externalId: '',
            id: 0,
            jsonType: '',
            lastUpdated: '',
            locks: [
                {
                    bleAppVersion: '',
                    bleHwVersion: '',
                    companyId: 0,
                    gatewayId: 0,
                    gwDelinquent: 0,
                    hwState: '',
                    hwType: '',
                    id: 0,
                    mac: '',
                    name: '',
                    offlineKey: '',
                    siteId: 0,
                    syncFlag: '',
                    time: '',
                    unlockCmd: '',
                    voltageBattery: '',
                    voltageWired: '',
                    wpAppVersion: '',
                    wpOtapVersion: '',
                    wpStackVersion: ''
                }
            ],
            name: '',
            rentalState: '',
            siteId: 0
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNITS STATS                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNITS_STATS = '/site/units/stats/';
export const PURPOSE_UNITS_STATS = 'Fetch the stats for all the units on a site.';
export const PERMISSIONS_UNITS_STATS = [];
export const REQUIREMENTS_UNITS_STATS = ['Token Required'];


//||||||||||||||||||||||||||||||||||||||||
//|| UNIT LIST BY ZONE                  ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_LIST_BY_ZONE = '/zone/units/';
export const PURPOSE_UNIT_LIST_BY_ZONE = 'Fetch a list of units for a zone.';
export const PERMISSIONS_UNIT_LIST_BY_ZONE = [];
export const REQUIREMENTS_UNIT_LIST_BY_ZONE = ['Token Required'];
export const PARAMETER_UNIT_LIST_BY_ZONE = {
    zoneId: 0
};
export const RETURN_UNIT_LIST_BY_ZONE = {
    units: [
        {
            Id: 0,
            Name: '',
            SiteId: 0,
            Units: []
        }
    ]
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT DETAILS                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_DETAILS = '/lock/profile/';
export const PURPOSE_UNIT_DETAILS = 'Fetch unit details.';
export const PERMISSIONS_UNIT_DETAILS = [];
export const REQUIREMENTS_UNIT_DETAILS = ['Token Required'];
export const PARAMETER_UNIT_DETAILS = {
    unitId: 0,
    companyId: 0
};
export const RETURN_UNIT_DETAILS = {
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT EDIT                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_EDIT = '/unit/edit/';
export const PURPOSE_UNIT_EDIT = 'Edit unit.';
export const PERMISSIONS_UNIT_EDIT = [];
export const REQUIREMENTS_UNIT_EDIT = ['Token Required'];
export const PARAMETER_UNIT_EDIT = {
    unitId: 0,
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};
export const RETURN_UNIT_EDIT = {
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT CHECKOUT                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_CHECKOUT = '/lock/checkout/complete/';
export const PURPOSE_UNIT_CHECKOUT = 'Complete checkout for a unit.';
export const PERMISSIONS_UNIT_CHECKOUT = [];
export const REQUIREMENTS_UNIT_CHECKOUT = ['Token Required'];
export const PARAMETER_UNIT_CHECKOUT = {
    lockId: 0,
    companyId: 0
};
export const RETURN_UNIT_CHECKOUT = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT ASSIGN                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_ASSIGN = '/site/unit/assign/';
export const PURPOSE_UNIT_ASSIGN = 'Assign unit to a user.';
export const PERMISSIONS_UNIT_ASSIGN = [];
export const REQUIREMENTS_UNIT_ASSIGN = ['Token Required'];
export const PARAMETER_UNIT_ASSIGN = {
    email: '',
    phone: '',
    unitId: 0,
    companyId: 0
};
export const RETURN_UNIT_ASSIGN = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT UNASSIGN                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_UNASSIGN = '/site/unit/unassign/';
export const PURPOSE_UNIT_UNASSIGN = 'Unassign a unit.';
export const PERMISSIONS_UNIT_UNASSIGN = [];
export const REQUIREMENTS_UNIT_UNASSIGN = ['Token Required'];
export const PARAMETER_UNIT_UNASSIGN = {
    companyId: 0
};
export const RETURN_UNIT_UNASSIGN = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT AUCTION                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_AUCTION = '/site/unit/auction/assign/';
export const PURPOSE_UNIT_AUCTION = 'Assign unit to auction mode';
export const PERMISSIONS_UNIT_AUCTION = [];
export const REQUIREMENTS_UNIT_AUCTION = ['Token Required'];
export const PARAMETER_UNIT_AUCTION = {};
export const RETURN_UNIT_AUCTION = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT REASSIGN AUCTION              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_REASSIGN_AUCTION = '/site/unit/auction/reassign/';
export const PURPOSE_UNIT_REASSIGN_AUCTION = 'Reassign unit to another auction user';
export const PERMISSIONS_UNIT_REASSIGN_AUCTION = [];
export const REQUIREMENTS_UNIT_REASSIGN_AUCTION = ['Token Required'];
export const PARAMETER_UNIT_REASSIGN_AUCTION = {};
export const RETURN_UNIT_REASSIGN_AUCTION = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT UNAUCTION                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_UNAUCTION = '/site/unit/auction/unassign/';
export const PURPOSE_UNIT_UNAUCTION = 'Unassign unit from auction mode';
export const PERMISSIONS_UNIT_UNAUCTION = [];
export const REQUIREMENTS_UNIT_UNAUCTION = ['Token Required'];
export const PARAMETER_UNIT_UNAUCTION = {};
export const RETURN_UNIT_UNAUCTION = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT REKEY                         ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_REKEY = '/support/rekey/';
export const PURPOSE_UNIT_REKEY = 'Rekey a unit.';
export const PERMISSIONS_UNIT_REKEY = ['support_rekey'];
export const REQUIREMENTS_UNIT_REKEY = ['Token Required'];
export const PARAMETER_UNIT_REKEY = {
    unitId: 0
};
export const RETURN_UNIT_REKEY = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT EDIT EXTERNAL ID              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_EDIT_EXTERNAL_ID = '/support/site/units/externalids/';
export const PURPOSE_UNIT_EDIT_EXTERNAL_ID = 'Edit an external id for a unit.';
export const PERMISSIONS_UNIT_EDIT_EXTERNAL_ID = ['support_edit_external_ids'];
export const REQUIREMENTS_UNIT_EDIT_EXTERNAL_ID = ['Token Required'];
export const PARAMETER_UNIT_EDIT_EXTERNAL_ID = {
    siteId: 0,
    units: []
};
export const RETURN_UNIT_EDIT_EXTERNAL_ID = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT LAST SYNC                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_LAST_SYNC_INFO = '/site/sync/info/';
export const PURPOSE_LAST_SYNC_INFO = 'Get information from last PMS sync.';
export const PERMISSIONS_LAST_SYNC_INFO = [];
export const REQUIREMENTS_LAST_SYNC_INFO = ['Token Required'];
export const PARAMETER_LAST_SYNC_INFO = {
    unitId: 0
};
export const RETURN_LAST_SYNC_INFO = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT SET STATE                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_SET_STATE = '/site/unit/setstate/';
export const PURPOSE_UNIT_SET_STATE = 'Set unit state to occupied, available, repossessed and overlock.';
export const PERMISSIONS_UNIT_SET_STATE = [];
export const REQUIREMENTS_UNIT_SET_STATE = ['Token Required'];
export const PARAMETER_UNIT_SET_STATE = {
    companyId: 0,
    unitId: 0,
    newState: ''
};
export const RETURN_UNIT_SET_STATE = {
    User: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    },
    accessType: '',
    companyId: 0,
    details: '',
    detailsDepth: 0,
    detailsPrice: 0,
    detailsWidth: 0,
    externalId: '',
    id: 0,
    jsonType: '',
    lastUpdated: '',
    locks: [
        {
            bleAppVersion: '',
            bleHwVersion: '',
            companyId: 0,
            gatewayId: 0,
            gwDelinquent: 0,
            hwState: '',
            hwType: '',
            id: 0,
            mac: '',
            name: '',
            offlineKey: '',
            siteId: 0,
            syncFlag: '',
            time: '',
            unlockCmd: '',
            voltageBattery: '',
            voltageWired: '',
            wpAppVersion: '',
            wpOtapVersion: '',
            wpStackVersion: ''
        }
    ],
    name: '',
    rentalState: '',
    siteId: 0
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT LOCK EDIT                     ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_LOCK_EDIT = '/lock/edit/';
export const PURPOSE_UNIT_LOCK_EDIT = 'Edit unit lock.';
export const PERMISSIONS_UNIT_LOCK_EDIT = [];
export const REQUIREMENTS_UNIT_LOCK_EDIT = ['Token Required'];
export const PARAMETER_UNIT_LOCK_EDIT = {
    lockId: 0,
    bleAppVersion: '',
    bleHwVersion: '',
    companyId: 0,
    gatewayId: 0,
    gwDelinquent: 0,
    hwState: '',
    hwType: '',
    id: 0,
    mac: '',
    name: '',
    offlineKey: '',
    siteId: 0,
    syncFlag: '',
    time: '',
    unlockCmd: '',
    voltageBattery: '',
    voltageWired: '',
    wpAppVersion: '',
    wpOtapVersion: '',
    wpStackVersion: ''
};
export const RETURN_UNIT_LOCK_EDIT = {
    bleAppVersion: '',
    bleHwVersion: '',
    companyId: 0,
    gatewayId: 0,
    gwDelinquent: 0,
    hwState: '',
    hwType: '',
    id: 0,
    mac: '',
    name: '',
    offlineKey: '',
    siteId: 0,
    syncFlag: '',
    time: '',
    unlockCmd: '',
    voltageBattery: '',
    voltageWired: '',
    wpAppVersion: '',
    wpOtapVersion: '',
    wpStackVersion: ''
};

//||||||||||||||||||||||||||||||||||||||||
//|| UNIT LOCK EDIT MAC                 ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_LOCK_EDIT_MAC = '/lock/edit/mac/';
export const PURPOSE_UNIT_LOCK_EDIT_MAC = 'Edit unit lock.';
export const PERMISSIONS_UNIT_LOCK_EDIT_MAC = [];
export const REQUIREMENTS_UNIT_LOCK_EDIT_MAC = ['Token Required'];
export const PARAMETER_UNIT_LOCK_EDIT_MAC = {
    lockId: 0,
    mac: ''
};
export const RETURN_UNIT_LOCK_EDIT_MAC = {};

//||||||||||||||||||||||||||||||||||||||||
//|| LOCK SETTINGS EDIT                 ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UNIT_LOCK_SETTINGS_EDIT = '/lock/settings/';
export const PURPOSE_UNIT_LOCK_SETTINGS_EDIT = 'Edit unit lock settings.';
export const PERMISSIONS_UNIT_LOCK_SETTINGS_EDIT = [];
export const REQUIREMENTS_UNIT_LOCK_SETTINGS_EDIT = ['Token Required'];
export const PARAMETER_UNIT_LOCK_SETTINGS_EDIT = {
    lockId: 0,
    mac: ''
};
export const RETURN_UNIT_LOCK_SETTINGS_EDIT = {};

//||||||||||||||||||||||||||||||||||||||||
//|| REMOTE UNLOCK                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REMOTE_UNLOCK = '/lock/unlock/mesh/';
export const PURPOSE_REMOTE_UNLOCK = 'Remote unlock units.';
export const PERMISSIONS_REMOTE_UNLOCK = [];
export const REQUIREMENTS_REMOTE_UNLOCK = ['Token Required'];
export const PARAMETER_REMOTE_UNLOCK = {
    mac: ''
};
export const RETURN_REMOTE_UNLOCK = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| Hold Open Gate Time                ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_HOLD_OPEN_GATE_TIME = '/lock/hold/now/';
export const PURPOSE_HOLD_OPEN_GATE_TIME = 'Hold open a gate a specified amount of time.';
export const PERMISSIONS_HOLD_OPEN_GATE_TIME = [];
export const REQUIREMENTS_HOLD_OPEN_GATE_TIME = ['Token Required'];
export const PARAMETER_HOLD_OPEN_GATE_TIME = {
    mac: ''
};
export const RETURN_HOLD_OPEN_GATE_TIME = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| Hold Open Gate Schedule            ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_HOLD_OPEN_GATE_SCHEDULE = '/lock/hold/create/';
export const PURPOSE_HOLD_OPEN_GATE_SCHEDULE = 'Hold open a gate during a schedule time.';
export const PERMISSIONS_HOLD_OPEN_GATE_SCHEDULE = [];
export const REQUIREMENTS_HOLD_OPEN_GATE_SCHEDULE = ['Token Required'];
export const PARAMETER_HOLD_OPEN_GATE_SCHEDULE = {
    mac: ''
};
export const RETURN_HOLD_OPEN_GATE_SCHEDULE = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| REPEAT LOCK SCHEDULE               ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_READ_LOCK_SCHEDULE = '/lock/hold/schedule/read/';
export const ENDPOINT_READ_SITE_LOCK_SCHEDULES = '/lock/hold/schedule/read/site/';
export const ENDPOINT_CREATE_REPEATING_LOCK_SCHEDULE = '/lock/hold/schedule/create/';
export const ENDPOINT_UPDATE_REPEATING_LOCK_SCHEDULE = '/lock/hold/schedule/update/';
export const ENDPOINT_DELETE_REPEATING_LOCK_SCHEDULE = '/lock/hold/schedule/delete/';

//||||||||||||||||||||||||||||||||||||||||
//|| FETCH LOCK HOLDS                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FETCH_LOCK_HOLDS = '/lock/hold/read/site/';
export const PURPOSE_FETCH_LOCK_HOLDS = 'Fetch current lock holds';
export const PERMISSIONS_FETCH_LOCK_HOLDS = [];
export const REQUIREMENTS_FETCH_LOCK_HOLDS = ['Token Required'];
export const PARAMETER_FETCH_LOCK_HOLDS = {
    mac: ''
};
export const RETURN_FETCH_LOCK_HOLDS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| Remove Lock Hold                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_REMOVE_LOCK_HOLD = '/site/hold/delete/';
export const PURPOSE_REMOVE_LOCK_HOLD = 'Remove lock hold(s) from entry unit';
export const PERMISSIONS_REMOVE_LOCK_HOLD = [];
export const REQUIREMENTS_REMOVE_LOCK_HOLD = ['Token Required'];
export const PARAMETER_REMOVE_LOCK_HOLD = {
    mac: ''
};
export const RETURN_REMOVE_LOCK_HOLD = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| FETCH LOW BATTERY UNITS            ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_FETCH_LOW_BATTERY_UNITS = '/lock/battery/';
export const PURPOSE_FETCH_LOW_BATTERY_UNITS = 'Fetch low battery units.';
export const PERMISSIONS_FETCH_LOW_BATTERY_UNITS = [];
export const REQUIREMENTS_FETCH_LOW_BATTERY_UNITS = ['Token Required'];
export const PARAMETER_FETCH_LOW_BATTERY_UNITS = {
    companyId: 0,
    siteId: 0
};
export const RETURN_FETCH_LOW_BATTERY_UNITS = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| CREATE LOCK                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_CREATE_LOCK = '/lock/create/';
export const PURPOSE_CREATE_LOCK = 'Create Lock.';
export const PERMISSIONS_CREATE_LOCK = [];
export const REQUIREMENTS_CREATE_LOCK = ['Token Required'];
export const PARAMETER_CREATE_LOCK = {
    locks: [
        {
            unitName: '',
            zoneId: 0,
            mac: '',
            name: '',
            type: '',
            accessType: ''
        }
    ]
};
export const RETURN_CREATE_LOCK = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| DELETE LOCK                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_DELETE_LOCK = '/lock/delete/';
export const PURPOSE_DELETE_LOCK = 'Delete Lock.';
export const PERMISSIONS_DELETE_LOCK = [];
export const REQUIREMENTS_DELETE_LOCK = ['Token Required'];
export const PARAMETER_DELETE_LOCK = {
    lockId: 0
};
export const RETURN_DELETE_LOCK = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| SETUP UNIT                         ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SETUP_UNIT = '/lock/setup/resend/';
export const PURPOSE_SETUP_UNIT = 'Rekey a unit.';
export const PERMISSIONS_SETUP_UNIT = ['manage_locks'];
export const REQUIREMENTS_SETUP_UNIT = ['Token Required'];
export const PARAMETER_SETUP_UNIT = {
    unitId: 0
};
export const RETURN_SETUP_UNIT = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| RESEND KEYS                        ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_RESEND_KEYS = '/lock/keys/resend/';
export const PURPOSE_RESEND_KEYS = 'Resend keys.';
export const PERMISSIONS_RESEND_KEYS = ['manage_locks'];
export const REQUIREMENTS_RESEND_KEYS = ['Token Required'];
export const PARAMETER_RESEND_KEYS = {
    unitId: 0
};
export const RETURN_RESEND_KEYS = {
    result: 'success'
};
