import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';

import ToolTips from 'components/ToolTips';
class CellStar extends React.Component {
    constructor(props) {
        super(props);
    }
    parentAction = (e) => {
        e.stopPropagation();
        const { action } = this.props;
        action();
    };
    render() {
        const { theme, Cellwidth, priority, tooltip, tooltipText, tooltipId } = this.props;
        this.styles = styles();
        return (
            <td width={Cellwidth}>
                <div id={tooltip && tooltipId} className={css(this.styles.wrapper, this.styles.hover)}>
                    <div onMouseDown={(e) => this.parentAction(e)} className={css(this.styles.button)}>
                        <i className="material-icons" style={{ fontSize: 18, color: theme.color.themeColor }}>
                            {priority ? 'star' : 'star_outline'}
                        </i>
                    </div>
                    {tooltip && (
                        <ToolTips
                            target={tooltipId}
                            message={tooltipText}
                            modifiers={{ 
                                preventOverflow: { boundariesElement: 'viewport' },
                                hide: { enabled: false },
                            }}
                        />
                    )}
                </div>
            </td>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default withRouter(connect(mapStateToProps)(CellStar));

const styles = () =>
    StyleSheet.create({
        wrapper: {
            padding: '2px 10px',
            margin: 0,
            maxHeight: 55,
            overflow: 'hidden'
        },
        hover: {
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        button: {
            height: 25,
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all .2s ease-in-out'
        }
    });
