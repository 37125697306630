import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { fadeInDown } from 'react-animations';
import { ErrorBoundary } from 'react-error-boundary';

import Header from 'components/Header/managerDashboard';
import Dashboard from './routes/Display';
import Spinner from 'components/Spinner';
import { ErrorFallback } from 'components/ErrorFallback';

const Logout = React.lazy(() => import('app/routes/Auth/routes/Logout'));
const Feedback = React.lazy(() => import('app/routes/Feedback'));
class ManagerDashboardIndex extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { match } = this.props;
        this.styles = styles();
        return (
            <div className="app-main-container">
                <div className={`app-header ${css(this.styles.fadeInDown)}`}>
                    <Header />
                </div>
                <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Suspense fallback={<Spinner fallback={true} />}>
                                <Switch>
                                    <Route path={`${match.url}/dashboard`} component={Dashboard} />
                                    <Route path={`${match.url}/feedback`} component={Feedback} />
                                    <Route path={`${match.url}/logout`} component={Logout} />
                                    <Route component={Dashboard} />
                                </Switch>
                            </Suspense>
                        </ErrorBoundary>
                    </div>
                </main>
            </div>
        );
    }
}

export default withRouter(connect(null)(ManagerDashboardIndex));

const styles = () =>
    StyleSheet.create({
        fadeInDown: {
            animationName: fadeInDown,
            animationDuration: '1.2s'
        }
    });
