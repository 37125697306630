import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_REPORTS from 'actions/Reports';

const getReportsState = (state) => state.reports;
const getSiteUnitFilter = (state) => state.reports.siteUnitFilter;
const getSiteGatewayFilter = (state) => state.reports.siteGatewayFilter;

function* requestReportList({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setReportList(null));
        const reports = yield call(API.POST, payload.url, payload.data);
        if (!reports) {
            yield put(ACTIONS_REPORTS.setReportList([]));
        } else {
            reports.data.sites.map((site) => {
                if (
                    (site.gatewaysStats && site.gatewaysStats.offline > 0) ||
                    (site.lockStats && site.lockStats.offline > 0)
                ) {
                    site.Status = false;
                } else {
                    site.Status = true;
                }
            });
            const reportState = yield select(getReportsState);
            let activeSiteHeader = reportState.reportSiteAdvancedHeader.find((item) => item.active);
            if (activeSiteHeader.sortTitle && typeof activeSiteHeader.sortTitle === 'object') {
                reports.data.sites = UTILS.sortList(
                    !activeSiteHeader.order,
                    reports.data.sites,
                    activeSiteHeader.sortTitle[0]
                );
                reports.data.sites = UTILS.sortList(
                    !activeSiteHeader.order,
                    reports.data.sites,
                    activeSiteHeader.sortTitle[1]
                );
            } else {
                reports.data.sites = UTILS.sortList(
                    !activeSiteHeader.order,
                    reports.data.sites,
                    activeSiteHeader.sortTitle
                );
            }
            let lockStats = reports.data.sites.map((site) =>
                site.lockStats ? site.lockStats : [{ total: 0, online: 0, offline: 0, lowBattery: 0 }]
            );
            let gatewaysStats = reports.data.sites.map((site) =>
                site.gatewaysStats ? site.gatewaysStats : [{ total: 0, online: 0, offline: 0 }]
            );
            let merged = [].concat.apply([], lockStats);
            let mergedGateways = [].concat.apply([], gatewaysStats);
            let total = merged.reduce((a, b) => a + b.total, 0);
            let online = merged.reduce((a, b) => a + b.online, 0);
            let offline = merged.reduce((a, b) => a + b.offline, 0);
            let lowBattery = merged.reduce((a, b) => a + b.lowBattery, 0);
            let lockTotals = { total, online, offline, lowBattery };
            let totalGateways = mergedGateways.reduce((a, b) => a + b.total, 0);
            let onlineGateways = mergedGateways.reduce((a, b) => a + b.online, 0);
            let offlineGateways = mergedGateways.reduce((a, b) => a + b.offline, 0);
            let gatewayTotals = { total: totalGateways, online: onlineGateways, offline: offlineGateways };
            const siteUnitFilter = yield select(getSiteUnitFilter);
            const siteGatewayFilter = yield select(getSiteGatewayFilter);
            reports.data.sites =
                siteUnitFilter == 'online'
                    ? reports.data.sites.filter((site) => site.lockStats && site.lockStats.online > 0)
                    : siteUnitFilter == 'offline'
                    ? reports.data.sites.filter((site) => site.lockStats && site.lockStats.offline > 0)
                    : siteUnitFilter == 'lowBattery'
                    ? reports.data.sites.filter((site) => site.lockStats && site.lockStats.lowBattery > 0)
                    : reports.data.sites;
            reports.data.sites =
                siteGatewayFilter == 'online'
                    ? reports.data.sites.filter((site) => site.gatewaysStats && site.gatewaysStats.online > 0)
                    : siteGatewayFilter == 'offline'
                    ? reports.data.sites.filter((site) => site.gatewaysStats && site.gatewaysStats.offline > 0)
                    : reports.data.sites;
            yield put(ACTIONS_REPORTS.setReportList(reports.data.sites));
            yield put(ACTIONS_REPORTS.setSiteLockTotals(lockTotals));
            yield put(ACTIONS_REPORTS.setSiteGatewayTotals(gatewayTotals));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setReportList([]));
    }
}

function* requestFacilitiesList({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setReportList(null));
        const facilities = yield call(API.POST, payload.url, payload.data);
        if (!facilities) {
            yield put(ACTIONS_REPORTS.setReportList([]));
        } else {
            const reportState = yield select(getReportsState);
            let activeSiteHeader = reportState.reportSiteHeader.find((item) => item.active);
            if (activeSiteHeader.sortTitle && typeof activeSiteHeader.sortTitle === 'object') {
                facilities.data = UTILS.sortList(
                    !activeSiteHeader.order,
                    facilities.data,
                    activeSiteHeader.sortTitle[0]
                );
                facilities.data = UTILS.sortList(
                    !activeSiteHeader.order,
                    facilities.data,
                    activeSiteHeader.sortTitle[1]
                );
            } else {
                facilities.data = UTILS.sortList(
                    !activeSiteHeader.order,
                    facilities.data,
                    activeSiteHeader.sortTitle
                );
            }
            yield put(ACTIONS_REPORTS.setReportList(facilities.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setReportList([]));
    }
}

function* requestReportDetails({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setReportDetails(null));
        const reports = yield call(API.POST, payload.url, payload.data);
        if (!reports) {
            yield history.goBack();
            return;
        } else {
            reports.data.gateways = reports.data.gateways ? reports.data.gateways : [];
            reports.data.locks = reports.data.locks ? reports.data.locks : [];
            reports.data.OnlineGateways = reports.data.gateways
                ? reports.data.gateways.filter((gateway) => gateway.online).length
                : '';
            reports.data.OfflineGateways = reports.data.gateways
                ? reports.data.gateways.filter((gateway) => !gateway.online).length
                : '';
            reports.data.OnlineLocks = reports.data.locks
                ? reports.data.locks.filter((lock) => lock.online).length
                : '';
            reports.data.OfflineLocks = reports.data.locks
                ? reports.data.locks.filter((lock) => !lock.online).length
                : '';
            reports.data.LowBatteryLocks = reports.data.locks
                ? reports.data.locks.filter((lock) => lock.batteryStatus == 'bad').length
                : '';
            if (reports.data.OfflineGateways > 0 || reports.data.OfflineLocks > 0) {
                reports.data.Status = false;
            } else {
                reports.data.Status = true;
            }
            if (!reports.data.bleFirmwareStats) {
                reports.data.bleFirmwareStats = reports.data.locks.reduce(function(acc, curr) {
                    curr.bleFWVersion === ''
                    ?
                    acc["N/A"] = !acc["N/A"] ? acc["N/A"] = 1 : acc["N/A"] += 1
                    :
                    acc[curr.bleFWVersion] =  !acc[curr.bleFWVersion] ? acc[curr.bleFWVersion] = 1 : acc[curr.bleFWVersion] += 1
                    return acc;
                }, {})
            }
            if (!reports.data.wpFirmwareStats) {
                reports.data.wpFirmwareStats = reports.data.locks.reduce(function(acc, curr) {
                    curr.wpFWVersion === ''
                    ?
                    acc["N/A"] = !acc["N/A"] ? acc["N/A"] = 1 : acc["N/A"] += 1
                    :
                    acc[curr.wpFWVersion] =  !acc[curr.wpFWVersion] ? acc[curr.wpFWVersion] = 1 : acc[curr.wpFWVersion] += 1
                    return acc;
                }, {})
            }
            if (!reports.data.firmwares) {
                reports.data.firmwares = reports.data.gateways.reduce(function(acc, curr) {
                    curr.firmwareVersion === ''
                    ?
                    acc["N/A"] = !acc["N/A"] ? acc["N/A"] = 1 : acc["N/A"] += 1
                    :
                    acc[curr.firmwareVersion] =  !acc[curr.firmwareVersion] ? acc[curr.firmwareVersion] = 1 : acc[curr.firmwareVersion] += 1
                    return acc;
                }, {})
            }
            yield put(ACTIONS_REPORTS.setReportDetails(reports.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestReportGatewayData({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setReportGatewayData(null));
        const reportState = yield select(getReportsState);
        const report = reportState.reportsList.find((item) => item.Id === payload.SiteId);
        if (!report) {
            yield put(ACTIONS_REPORTS.setReportGatewayData(null));
            yield history.goBack();
        } else {
            let gateway = report.Gateways.find((item) => item.Id === payload.GatewayId);
            let Stats = Object.entries(gateway.Stats);
            if (Stats.length > 0) {
                gateway.Stats.version_ble = Object.entries(gateway.Stats.version_ble).map((item) => {
                    return { Version: item[0], Total: item[1] };
                });
                gateway.Stats.version_wp = Object.entries(gateway.Stats.version_wp).map((item) => {
                    return { Version: item[0], Total: item[1] };
                });
                gateway.Stats.version_wp_otap = Object.entries(gateway.Stats.version_wp_otap).map((item) => {
                    return { Version: item[0], Total: item[1] };
                });
                gateway.Stats.version_wp_stack = Object.entries(gateway.Stats.version_wp_stack).map((item) => {
                    return { Version: item[0], Total: item[1] };
                });
                let activeVersionHeader = reportState.reportVersionsHeader.find((item) => item.active);
                gateway.Stats.version_ble = UTILS.sortList(
                    !activeVersionHeader.order,
                    gateway.Stats.version_ble,
                    activeVersionHeader.sortTitle
                );
                gateway.Stats.version_wp = UTILS.sortList(
                    !activeVersionHeader.order,
                    gateway.Stats.version_wp,
                    activeVersionHeader.sortTitle
                );
                gateway.Stats.version_wp_otap = UTILS.sortList(
                    !activeVersionHeader.order,
                    gateway.Stats.version_wp_otap,
                    activeVersionHeader.sortTitle
                );
                gateway.Stats.version_wp_stack = UTILS.sortList(
                    !activeVersionHeader.order,
                    gateway.Stats.version_wp_stack,
                    activeVersionHeader.sortTitle
                );
            } else {
                gateway.Stats = null;
            }
            yield put(ACTIONS_REPORTS.setReportGatewayData(gateway));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setReportGatewayData(null));
    }
}

function* requestReportLockData({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setReportLockData(null));
        const data = yield call(API.GET_REPORTS, payload.url);
        if (!data) {
            yield put(ACTIONS_REPORTS.setReportLockData([]));
        } else {
            yield put(ACTIONS_REPORTS.setReportLockData(data.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setReportLockData([]));
    }
}

function* requestCrossSiteSearch({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setCrossSiteSearchResults(null));
        const results = yield call(API.POST, payload.url, payload.data);
        if (!results || !results.data || !results.data.results) {
            yield put(ACTIONS_REPORTS.setCrossSiteSearchResults([]));
        } else {
            yield put(ACTIONS_REPORTS.setCrossSiteSearchResults(results.data.results));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setCrossSiteSearchResults([]));
    }
}

function* requestEmailSitesList({ payload }) {
    try {
        const activity = yield call(API.POST, payload.url, payload.data);
        if (!activity) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail', 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sitesList', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSuspiciousReport({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setSuspiciousReport(null));
        const results = yield call(API.POST, payload.url, payload.data);
        if (!results || !results.data) {
            yield put(ACTIONS_REPORTS.setSuspiciousReport([]));
        } else {
            // filter out support users
            results.data.attemptedOutOfZone = results.data.attemptedOutOfZone ? results.data.attemptedOutOfZone.filter(result => !result.isSupportUser) : []
            results.data.didNotGoToUnit = results.data.didNotGoToUnit ? results.data.didNotGoToUnit.filter(result => !result.isSupportUser) : []
            results.data.didNotLeaveAfterLockingUnit = results.data.didNotLeaveAfterLockingUnit ? results.data.didNotLeaveAfterLockingUnit.filter(result => !result.isSupportUser) : []
            results.data.lateNightAccess = results.data.lateNightAccess ? results.data.lateNightAccess.filter(result => !result.isSupportUser) : []
            results.data.multipleEntries = results.data.multipleEntries ? results.data.multipleEntries.filter(result => !result.isSupportUser) : []
            results.data.overlockAccessAttempt = results.data.overlockAccessAttempt ? results.data.overlockAccessAttempt.filter(result => !result.isSupportUser) : []
            yield put(ACTIONS_REPORTS.setSuspiciousReport(results.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setSuspiciousReport([]));
    }
}

function* requestFetchSuspiciousReportTop({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setSuspiciousReportTop(null));
        const results = yield call(API.POST, payload.url, payload.data);
        if (!results || !results.data || !results.data.users) {
            yield put(ACTIONS_REPORTS.setSuspiciousReportTop([]));
        } else {
            // filter out support users
            results.data.users = results.data.users.filter(user => !user.isSupportUser);
            results.data.users.map((user) => {
                user.attemptedOutOfZoneCount = user.attemptedOutOfZone ? user.attemptedOutOfZone.count : 0;
                user.didNotGoToUnitCount = user.didNotGoToUnit ? user.didNotGoToUnit.count : 0;
                user.didNotLeaveAfterLockingUnitCount = user.didNotLeaveAfterLockingUnit
                    ? user.didNotLeaveAfterLockingUnit.count
                    : 0;
                user.multipleEntriesCount = user.multipleEntries ? user.multipleEntries.count : 0;
                user.overlockAccessAttemptCount = user.overlockAccessAttempt ? user.overlockAccessAttempt.count : 0;
                user.lateNightAccessCount = user.lateNightAccess ? user.lateNightAccess.count : 0;
            });
            results.data.users = UTILS.sortList(false, results.data.users, 'userName');
            yield put(ACTIONS_REPORTS.setSuspiciousReportTop(results.data.users));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setSuspiciousReportTop([]));
    }
}

function* requestFetchSuspiciousReportUser({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setSuspiciousReportUser(null));
        const results = yield call(API.POST, payload.url, payload.data);
        if (!results || !results.data) {
            yield put(ACTIONS_REPORTS.setSuspiciousReportUser([]));
        } else {
            yield put(ACTIONS_REPORTS.setSuspiciousReportUser(results.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setSuspiciousReportUser([]));
    }
}

function* requestExportSuspiciousReport({ payload }) {
    try {
        const report = yield call(API.POST, payload.url, payload.data);
        if (!report) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail', 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sitesList', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSuspiciousManagerReport({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setSuspiciousManagerReport(null));
        const results = yield call(API.POST, payload.url, payload.data);
        if (!results || !results.data) {
            yield put(ACTIONS_REPORTS.setSuspiciousManagerReport([]));
        } else {
            // filter out support users
            results.data.manuallyChangingStatus = results.data.manuallyChangingStatus ? results.data.manuallyChangingStatus.filter(result => !result.isSupportUser) : []
            results.data.overridingOccupiedUnits = results.data.overridingOccupiedUnits ? results.data.overridingOccupiedUnits.filter(result => !result.isSupportUser) : []
            yield put(ACTIONS_REPORTS.setSuspiciousManagerReport(results.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setSuspiciousManagerReport([]));
    }
}

function* requestFetchSuspiciousManagerReportTop({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setSuspiciousManagerReportTop(null));
        const results = yield call(API.POST, payload.url, payload.data);
        if (!results || !results.data || !results.data.users) {
            yield put(ACTIONS_REPORTS.setSuspiciousManagerReportTop([]));
        } else {
            // filter out support users
            results.data.users = results.data.users.filter(user => !user.isSupportUser);
            results.data.users.map((user) => {
                user.manuallyChangingStatusCount = user.manuallyChangingStatus ? user.manuallyChangingStatus.count : 0;
                user.overridingOccupiedUnitsCount = user.overridingOccupiedUnits
                    ? user.overridingOccupiedUnits.count
                    : 0;
            });
            results.data.users = UTILS.sortList(false, results.data.users, 'userName');
            yield put(ACTIONS_REPORTS.setSuspiciousManagerReportTop(results.data.users));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setSuspiciousManagerReportTop([]));
    }
}

function* requestFetchSuspiciousManagerReportUser({ payload }) {
    try {
        yield put(ACTIONS_REPORTS.setSuspiciousManagerReportUser(null));
        const results = yield call(API.POST, payload.url, payload.data);
        if (!results || !results.data) {
            yield put(ACTIONS_REPORTS.setSuspiciousManagerReportUser([]));
        } else {
            yield put(ACTIONS_REPORTS.setSuspiciousManagerReportUser(results.data));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_REPORTS.setSuspiciousManagerReportUser([]));
    }
}

function* requestExportSuspiciousManagerReport({ payload }) {
    try {
        const report = yield call(API.POST, payload.url, payload.data);
        if (!report) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail', 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sitesList', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestExportSuspiciousReportTop({ payload }) {
    try {
        const report = yield call(API.POST, payload.url, payload.data);
        if (!report) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail', 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sitesList', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestExportSuspiciousManagerReportTop({ payload }) {
    try {
        const report = yield call(API.POST, payload.url, payload.data);
        if (!report) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail', 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sitesList', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectReportList() {
    yield takeEvery(CONSTANTS.FETCH_REPORT_LIST, requestReportList);
}

export function* selectReportDetails() {
    yield takeEvery(CONSTANTS.FETCH_REPORT_DETAILS, requestReportDetails);
}

export function* selectFacilitiesList() {
    yield takeEvery(CONSTANTS.FETCH_FACILITIES, requestFacilitiesList);
}

export function* selectReportGatewayData() {
    yield takeEvery(CONSTANTS.FETCH_REPORT_GATEWAY_DATA, requestReportGatewayData);
}

export function* selectReportLockData() {
    yield takeEvery(CONSTANTS.FETCH_REPORT_LOCK_DATA, requestReportLockData);
}

export function* selectCrossSiteSearch() {
    yield takeEvery(CONSTANTS.FETCH_CROSS_SITE_SEARCH_RESULTS, requestCrossSiteSearch);
}

export function* emailSitesList() {
    yield takeEvery(CONSTANTS.EMAIL_SITES_LIST, requestEmailSitesList);
}

export function* selectFetchSuspiciousReport() {
    yield takeEvery(CONSTANTS.FETCH_SUSPICIOUS_REPORT, requestFetchSuspiciousReport);
}

export function* selectFetchSuspiciousReportTop() {
    yield takeEvery(CONSTANTS.FETCH_SUSPICIOUS_REPORT_TOP, requestFetchSuspiciousReportTop);
}

export function* selectFetchSuspiciousReportUser() {
    yield takeEvery(CONSTANTS.FETCH_SUSPICIOUS_REPORT_USER, requestFetchSuspiciousReportUser);
}

export function* selectExportSuspiciousReport() {
    yield takeEvery(CONSTANTS.EXPORT_SUSPICIOUS_REPORT, requestExportSuspiciousReport);
}

export function* selectFetchSuspiciousManagerReport() {
    yield takeEvery(CONSTANTS.FETCH_SUSPICIOUS_MANAGER_REPORT, requestFetchSuspiciousManagerReport);
}

export function* selectFetchSuspiciousManagerReportTop() {
    yield takeEvery(CONSTANTS.FETCH_SUSPICIOUS_MANAGER_REPORT_TOP, requestFetchSuspiciousManagerReportTop);
}

export function* selectFetchSuspiciousManagerReportUser() {
    yield takeEvery(CONSTANTS.FETCH_SUSPICIOUS_MANAGER_REPORT_USER, requestFetchSuspiciousManagerReportUser);
}

export function* selectExportSuspiciousManagerReport() {
    yield takeEvery(CONSTANTS.EXPORT_SUSPICIOUS_MANAGER_REPORT, requestExportSuspiciousManagerReport);
}

export function* selectExportSuspiciousReportTop() {
    yield takeEvery(CONSTANTS.EXPORT_SUSPICIOUS_REPORT_TOP, requestExportSuspiciousReportTop);
}

export function* selectExportSuspiciousManagerReportTop() {
    yield takeEvery(CONSTANTS.EXPORT_SUSPICIOUS_MANAGER_REPORT_TOP, requestExportSuspiciousManagerReportTop);
}

export default function* rootSaga() {
    yield all([
        fork(selectReportList),
        fork(selectReportDetails),
        fork(selectFacilitiesList),
        fork(selectReportGatewayData),
        fork(selectReportLockData),
        fork(selectCrossSiteSearch),
        fork(emailSitesList),
        fork(selectFetchSuspiciousReport),
        fork(selectFetchSuspiciousReportTop),
        fork(selectFetchSuspiciousReportUser),
        fork(selectExportSuspiciousReport),
        fork(selectFetchSuspiciousManagerReport),
        fork(selectFetchSuspiciousManagerReportTop),
        fork(selectFetchSuspiciousManagerReportUser),
        fork(selectExportSuspiciousManagerReport),
        fork(selectExportSuspiciousReportTop),
        fork(selectExportSuspiciousManagerReportTop)
    ]);
}
