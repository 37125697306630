import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'components/Modal/new';
import { withRouter } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';

import { switchSite } from 'actions/Auth';
import { fetchOnsiteUsersManager, setSiteManagerPriority } from 'actions/Users';
import { setSwitcherLoader } from 'actions/App';

import { SiteIcon } from 'utilities/icons';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
class DashboardMyStores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            currentSite: null
        };
    }
    componentDidMount() {
        const { fetchOnsiteUsersManager } = this.props;
        fetchOnsiteUsersManager();
    }
    toggleModal = (site) => {
        site
            ? this.setState({ showModal: true, currentSite: site })
            : this.setState({ showModal: false, currentSite: null });
    };
    switchSite = (data) => {
        const { switchSite, setSwitcherLoader, intl } = this.props;
        setSwitcherLoader({loading: true, text: intl.formatMessage({ id: 'comp.button.switchingSite' })});
        switchSite(data.uuid, false, false, false, false, true);
        setTimeout(() => {
            sessionStorage.removeItem('manager_dash');
        }, 750);
        setTimeout(() => {
            this.props.history.push('/app/home');
        }, 1000);
    };
    editPriority = (e, site) => {
        e.stopPropagation();
        const { onsiteUsersManager, setSiteManagerPriority } = this.props;
        let sites = JSON.parse(JSON.stringify(onsiteUsersManager));
        sites.map((siteList) => (siteList.uuid == site.uuid ? (siteList.priority = !site.priority) : null));
        setSiteManagerPriority(sites);
    };
    render() {
        const { intl, theme, height, onsiteUsersManager } = this.props;
        const { showModal, currentSite } = this.state;
        this.styles = styles(theme);
        return (
            <>
                <Modal isOpen={showModal} toggle={() => this.toggleModal(null)}>
                    <ModalHeader
                        style={{
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            borderBottom: '1px solid' + theme.color.borderColor,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4
                        }}
                        toggle={() => this.toggleModal(null)}>
                        <SiteIcon color={theme.color.themeColor} />
                    </ModalHeader>
                    <ModalBody
                        style={{
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '80px 0',
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4
                        }}>
                        <div>
                            <h1 className={css(this.styles.header)}>
                                {intl.formatMessage({ id: 'comp.button.viewStore' })} {currentSite && currentSite.name}?
                            </h1>
                        </div>
                        <div style={{ marginTop: 50 }}>
                            {currentSite && (
                                <Button
                                    width={100}
                                    text={'comp.form.yes'}
                                    action={() => this.switchSite(currentSite)}
                                />
                            )}
                            <Button width={100} text={'comp.button.goBack'} action={() => this.toggleModal(null)} />
                        </div>
                    </ModalBody>
                </Modal>
                <Row>
                    <Col xs={12}>
                        <div className={css(this.styles.headerContainer)}>
                            <SiteIcon color={theme.color.themeColor} />
                            <h1 className={css(this.styles.headerMain)}>
                                {intl.formatMessage({ id: 'pages.dashboard.yourStores' })}
                            </h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div style={{ overflowY: 'auto' }}>
                            {onsiteUsersManager ? (
                                <div className={css(this.styles.table)}>
                                    {onsiteUsersManager && onsiteUsersManager.length > 0 ? (
                                        <div className={css(this.styles.siteList)} style={{ maxHeight: height - 200 }}>
                                            {onsiteUsersManager.slice(0, 25).map((site) => (
                                                <div
                                                    key={'SITELIST' + site.uuid}
                                                    className={css(this.styles.siteBox)}
                                                    onClick={() => this.toggleModal(site)}>
                                                    <div className={css(this.styles.siteBoxTop)}>
                                                        <SiteIcon color={'white'} />
                                                        <div
                                                            className={css(this.styles.priorityIcon)}
                                                            onClick={(e) => this.editPriority(e, site)}>
                                                            <i className="material-icons" style={{ fontSize: 18 }}>
                                                                {site.priority ? 'star' : 'star_outline'}
                                                            </i>
                                                        </div>
                                                    </div>
                                                    <div className={css(this.styles.siteBoxBottom)}>
                                                        <p
                                                            style={{
                                                                fontWeight: 600,
                                                                fontSize: 14,
                                                                margin: 0
                                                            }}>
                                                            {site.name}
                                                        </p>
                                                        <p style={{ margin: 0 }}>
                                                            {intl.formatMessage({
                                                                id: 'pages.dashboard.onsiteNow'
                                                            })}
                                                            : {site.usersOnSite}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                minHeight: 102,
                                                fontSize: 12,
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                animationName: fadeIn,
                                                animationDuration: '1.2s'
                                            }}>
                                            <div>
                                                <span>
                                                    {intl.formatMessage({
                                                        id: 'comp.table.cell.empty'
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, users }) => {
    const { theme, height } = app;
    const { onsiteUsersManager } = users;
    return { theme, height, onsiteUsersManager };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, { fetchOnsiteUsersManager, switchSite, setSiteManagerPriority, setSwitcherLoader })(DashboardMyStores)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            margin: '0 0 5px 0'
        },
        headerMain: {
            color: theme.color.fontColor,
            margin: '0 0 5px 0'
        },
        headerContainer: {
            display: 'flex',
            gap: 10,
            padding: 10
        },
        table: {
            width: '100%',
            height: '100%',
            fontSize: 11
        },
        siteList: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            flexWrap: 'wrap',
            height: '100%',
            width: '100%',
            overflowY: 'auto'
        },
        siteBox: {
            color: theme.color.fontColor,
            margin: '5px 1%',
            minHeight: 125,
            minWidth: 150,
            // maxWidth: 250,
            textAlign: 'center',
            width: '31%',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            ':hover': {
                cursor: 'pointer',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:500px)': {
                width: '100%',
                marginRight: 10
            }
        },
        siteBoxTop: {
            background: theme.color.themeColor,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px 0',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            position: 'relative'
        },
        siteBoxBottom: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '10px 10px'
        },
        priorityIcon: {
            color: 'white',
            position: 'absolute',
            top: 2,
            right: 2,
            transition: 'all .2s ease-in-out',
            opacity: 1,
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        }
    });
