import React from 'react';
import { Button, Form, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import LanguageSwitcher from 'components/LanguageSwitcher';
import SelectInput from 'components/Inputs/select';
import AuthTemplate from 'components/Auth';
import { userSignIn, userSignOut } from 'actions/Auth';
class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: null,
            companies: null,
            company: 0
        };
    }
    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/signin');
        } else {
            let { login, companies } = this.props.location.state;
            let company = localStorage.getItem('nse_login_data')
                ? JSON.parse(localStorage.getItem('nse_login_data')).companyUUID
                : companies[0].CompanyUUID;
            this.setState({ login, companies, company });
        }
        document.title = 'Company Select | Smart Entry';
    }
    login = (e) => {
        e.preventDefault();
        let { login, company } = this.state;
        login.companyUUID = company;
        this.props.userSignIn(login);
    };
    render() {
        const { intl, theme } = this.props;
        let { companies, company } = this.state;
        this.styles = styles(theme);
        return (
            companies && (
                <AuthTemplate theme={theme}>
                    <div className={css(this.styles.wrapper)}>
                        <Form onSubmit={this.login}>
                            <Label className={css(this.styles.label)}>
                                {intl.formatMessage({ id: 'auth.company.header' })}
                            </Label>
                            <div className={css(this.styles.inputWrapper)}>
                                <SelectInput
                                    options={
                                        companies &&
                                        companies.map((c) => ({
                                            text: c.CompanyName,
                                            key: c.CompanyUUID,
                                            value: c.CompanyUUID
                                        }))
                                    }
                                    value={company}
                                    width={'90%'}
                                    onChange={(e) => this.setState({ company: e.target.value })}
                                    fontSize={14}
                                    hideDropdown={true}
                                    noBorder={true}
                                    noMargin={true}
                                />
                                <Button className={css(this.styles.submit)} type="submit" color="link">
                                    <i className="material-icons" style={{ float: 'right' }}>
                                        arrow_forward
                                    </i>
                                </Button>
                            </div>
                        </Form>
                        <a title="Sign Out Link" onClick={() => this.props.userSignOut()} style={{ marginBottom: 50 }}>
                            <span className={css(this.styles.link)}>
                                {intl.formatMessage({ id: 'comp.button.cancel' })}
                            </span>
                        </a>
                        <div className={css(this.styles.lang)}>
                            <LanguageSwitcher />
                        </div>
                    </div>
                </AuthTemplate>
            )
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

export default injectIntl(
    connect(mapStateToProps, {
        userSignIn,
        userSignOut
    })(Company)
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            marginTop: '10vh',
            fontWeight: 400,
            backgroundColor: theme.color.compColor
        },
        label: {
            color: theme.color.themeColor,
            fontSize: 18
        },
        inputWrapper: {
            margin: '20px 0 5px 0',
            display: 'flex',
            borderBottom: `solid ${theme.color.borderColor} 1px`
        },
        submit: {
            borderRadius: 0,
            fontSize: 12,
            padding: 0,
            margin: '0px 0px 7px 0px',
            width: '10%',
            color: theme.color.themeColor
        },
        link: {
            color: theme.color.fontColor,
            textDecoration: 'underline',
            fontSize: 12,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        lang: {
            marginTop: 40
        }
    });
