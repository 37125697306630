import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody } from 'components/Modal/new';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';

import ReactCardCarousel from 'react-card-carousel';

import { hexToRGBA } from 'utilities';

import { setShowTutorials, updateUserTutorial } from 'actions/Tutorials';

import parse from 'html-react-parser';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
class TutorialsMainDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.CarouselMain = React.createRef();
        this.state = {
            showModalMain: false,
            currentIndex: 0
        };
    }
    handleExpand = (index) => {
        this.setState({ showModalMain: true, currentIndex: index });
    };
    trackChange = () => {
        let { tutorials, updateUserTutorial, authUser } = this.props;
        let currentIndex = this.CarouselMain.getCurrentIndex();
        let currentTutorial = tutorials && currentIndex && tutorials[currentIndex];
        currentTutorial &&
            currentTutorial.tutorialUUID &&
            updateUserTutorial({
                tutorialUUID: currentTutorial.tutorialUUID,
                userUUID: authUser.uuid
            });
    };
    skipAll = () => {
        const { setShowTutorials, tutorials, userSeenTutorials, authUser, updateUserTutorial } = this.props;
        setShowTutorials(false);
        let allTutorials = tutorials.filter((tutorial) => !userSeenTutorials.includes(tutorial));
        allTutorials.map((tutorial) =>
            updateUserTutorial({ tutorialUUID: tutorial.tutorialUUID, userUUID: authUser.uuid })
        );
    };
    render() {
        const { intl, theme, height, width, authUser, tutorials } = this.props;
        let { showModalMain, currentIndex } = this.state;
        this.styles = styles(theme);
        return (
            <Container fluid>
                {tutorials ? (
                    <ReactCardCarousel
                        ref={(Carousel) => (this.CarouselMain = Carousel)}
                        afterChange={() => this.trackChange()}
                        disable_box_shadow={true}
                        disable_fade_in={true}>
                        {tutorials.map(
                            (tutorial, i) =>
                                authUser &&
                                (tutorial.permission == '' || authUser.permissions.includes(tutorial.permission)) &&
                                (tutorial.featureflag == '' ||
                                    authUser.featureflagAssignments.includes(tutorial.featureflag)) && (
                                    <div
                                        className={css(this.styles.tutorialCard)}
                                        style={{
                                            height: height - 230,
                                            width:
                                                width > 1600
                                                    ? width / 4
                                                    : width > 1200
                                                    ? width / 3.5
                                                    : width > 700
                                                    ? width / 2.5
                                                    : width / 1.5,
                                            textTransform: 'none',
                                            overflow: 'auto'
                                        }}
                                        key={tutorial.tutorialUUID + i}>
                                        {(!tutorial.embeddedHTML ||
                                            (tutorial.embeddedHTML && tutorial.embeddedHTML === '')) && (
                                            <div
                                                style={{
                                                    margin: 0,
                                                    minWidth: '100%',
                                                    maxWidth: '100%',
                                                    minHeight: '55%',
                                                    height: '55%',
                                                    background: `url(${tutorial.gifLinkWeb}) no-repeat center`,
                                                    backgroundSize: 'cover',
                                                    objectFit: 'cover',
                                                    borderTopLeftRadius: 15,
                                                    borderTopRightRadius: 15,
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'space-between',
                                                    textTransform: 'none'
                                                }}>
                                                <div
                                                    className={css(this.styles.bottomIcon)}
                                                    onClick={() => this.handleExpand(i)}>
                                                    <i
                                                        className="material-icons"
                                                        style={{
                                                            fontSize: 24,
                                                            padding: 0,
                                                            fontWeight: '400',
                                                            color: theme.color.themeColor
                                                        }}>
                                                        zoom_out_map
                                                    </i>
                                                </div>
                                                <div
                                                    className={css(this.styles.bottomIcon)}
                                                    onClick={() => this.skipAll()}>
                                                    <i
                                                        className="material-icons"
                                                        style={{
                                                            fontSize: 24,
                                                            padding: 0,
                                                            fontWeight: '400',
                                                            color: theme.color.themeColor
                                                        }}>
                                                        close
                                                    </i>
                                                </div>
                                            </div>
                                        )}
                                        {tutorial.embeddedHTML && tutorial.embeddedHTML !== '' && (
                                            <div
                                                style={{
                                                    borderRadius: 15,
                                                    boxShadow: theme.color.boxShadow,
                                                    marginBottom: 10
                                                }}>
                                                <div
                                                    className={css(this.styles.fixedIconLeft)}
                                                    onClick={() => this.handleExpand(i)}>
                                                    <i
                                                        className="material-icons"
                                                        style={{
                                                            fontSize: 24,
                                                            padding: 0,
                                                            fontWeight: '400',
                                                            color: theme.color.themeColor
                                                        }}>
                                                        zoom_out_map
                                                    </i>
                                                </div>
                                                <div
                                                    className={css(this.styles.fixedIconRight)}
                                                    onClick={() => this.skipAll()}>
                                                    <i
                                                        className="material-icons"
                                                        style={{
                                                            fontSize: 24,
                                                            padding: 0,
                                                            fontWeight: '400',
                                                            color: theme.color.themeColor
                                                        }}>
                                                        close
                                                    </i>
                                                </div>
                                                {parse(tutorial.embeddedHTML)}
                                            </div>
                                        )}
                                        <div
                                            style={{
                                                height: '50%',
                                                width: '100%',
                                                borderTopLeftRadius: 15,
                                                borderTopRightRadius: 15,
                                                borderBottomLeftRadius: 15,
                                                borderBottomRightRadius: 15,
                                                marginTop: -20,
                                                padding: '10px 20px 10px 20px',
                                                background: theme.color.headerColor,
                                                color: theme.color.themeColor,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                borderRight: '1px solid' + theme.color.borderColor,
                                                borderLeft: '1px solid' + theme.color.borderColor
                                            }}>
                                            <div>
                                                {tutorial.featureTitle && (
                                                    <h2
                                                        style={{
                                                            fontWeight: 600,
                                                            marginTop: 10,
                                                            fontFamily: 'poppins'
                                                        }}>
                                                        {tutorial.featureTitle}
                                                    </h2>
                                                )}
                                                {tutorial.featureDescription && (
                                                    <p
                                                        style={{
                                                            textTransform: 'none',
                                                            fontFamily: 'roboto',
                                                            color: theme.color.fontColor
                                                        }}>
                                                        {tutorial.featureDescription.length > 550
                                                            ? `${tutorial.featureDescription.substring(0, 550)}..`
                                                            : tutorial.featureDescription}
                                                    </p>
                                                )}
                                                {tutorial && tutorial.externalLink && tutorial.externalLink !== "" && (
                                                    <a
                                                        title="Tutorial - Read More Link"
                                                        className={css(this.styles.tutorialExternalLink)}
                                                        target="_blank"
                                                        href={tutorial.externalLink}
                                                        rel="noreferrer">
                                                        {intl.formatMessage({
                                                            id: 'pages.releases.readMore'
                                                        })}
                                                    </a>
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    bottom: 10,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '95%',
                                                    justifyContent: 'space-between'
                                                }}>
                                                <div>
                                                    {tutorials && tutorials.length > 1 && (
                                                        <div
                                                            className={css(this.styles.releaseItemIconEdit)}
                                                            onClick={() => this.CarouselMain.prev()}>
                                                            <i
                                                                className="material-icons"
                                                                style={{
                                                                    fontSize: 38,
                                                                    fontWeight: '400'
                                                                }}>
                                                                chevron_left
                                                            </i>
                                                        </div>
                                                    )}
                                                </div>
                                                <div></div>
                                                <div>
                                                    {tutorials && tutorials.length > 1 && (
                                                        <div
                                                            className={css(this.styles.releaseItemIconEdit)}
                                                            onClick={() => this.CarouselMain.next()}>
                                                            <i
                                                                className="material-icons"
                                                                style={{
                                                                    fontSize: 38,
                                                                    fontWeight: '400'
                                                                }}>
                                                                chevron_right
                                                            </i>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                        )}
                    </ReactCardCarousel>
                ) : (
                    <Spinner />
                )}
                {tutorials && (
                    <Modal
                        isOpen={showModalMain}
                        toggle={() => this.setState({ showModalMain: !showModalMain })}
                        style={{ minWidth: '55vw' }}>
                        <ModalHeader toggle={() => this.setState({ showModalMain: !showModalMain })}>
                            <span></span>
                        </ModalHeader>
                        <ModalBody>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0 20px 40px 20px'
                                }}>
                                {tutorials &&
                                    tutorials[currentIndex] &&
                                    tutorials[currentIndex].gifLinkWeb &&
                                    tutorials[currentIndex].gifLinkWeb != '' && (
                                        <img
                                            alt="Web Tutorial Gif Image"
                                            style={{ minHeight: 250, maxWidth: '100%' }}
                                            src={tutorials[currentIndex].gifLinkWeb}></img>
                                    )}
                                <h2
                                    style={{
                                        fontWeight: 600,
                                        marginTop: 10,
                                        fontFamily: 'poppins',
                                        color: theme.color.themeColor
                                    }}>
                                    {tutorials && tutorials[currentIndex].featureTitle}
                                </h2>
                                <p style={{ textTransform: 'none', fontFamily: 'roboto' }}>
                                    {tutorials && tutorials[currentIndex].featureDescription}
                                </p>
                                {tutorials &&
                                    tutorials[currentIndex].embeddedHTML &&
                                    tutorials[currentIndex].embeddedHTML === '' && (
                                        <img
                                            style={{
                                                boxShadow: theme.color.boxShadow,
                                                maxWidth: '100%',
                                                minWidth: '40vw'
                                            }}
                                            src={tutorials && tutorials[currentIndex].gifLinkWeb}
                                            alt={tutorials && tutorials[currentIndex].featureTitle}
                                        />
                                    )}
                                {tutorials &&
                                    tutorials[currentIndex].embeddedHTML &&
                                    tutorials[currentIndex].embeddedHTML !== '' &&
                                    parse(tutorials[currentIndex].embeddedHTML)}
                            </div>
                            <Button
                                action={() => this.setState({ showModalMain: !showModalMain })}
                                text={`pages.users.dismissTraining`}
                                padding={'10px 20px'}
                                style={{ float: 'right' }}
                                load={false}
                            />
                        </ModalBody>
                    </Modal>
                )}
            </Container>
        );
    }
}

const mapStateToProps = ({ auth, app, tutorials }) => {
    const { authUser } = auth;
    const { theme, height, width } = app;
    const { userSeenTutorials } = tutorials;
    return { authUser, theme, height, width, userSeenTutorials };
};

export default injectIntl(
    withRouter(connect(mapStateToProps, { setShowTutorials, updateUserTutorial })(TutorialsMainDisplay))
);

const styles = (theme) =>
    StyleSheet.create({
        tutorialCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'center',
            color: theme.color.themeColor,
            background: 'white',
            fontSize: '12px',
            textTransform: 'none',
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            borderBottom: '1px solid' + theme.color.borderColor
        },
        bottomIcon: {
            color: theme.color.themeColor,
            margin: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        releaseItemIconEdit: {
            color: theme.color.themeColor,
            margin: 10,
            backgroundColor: hexToRGBA(theme.color.headerColor, 0.65),
            height: 40,
            width: 40,
            fontSize: 38,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        fixedIconLeft: {
            position: 'fixed',
            top: 0,
            left: 0,
            color: theme.color.themeColor,
            margin: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        fixedIconRight: {
            position: 'fixed',
            top: 0,
            right: 0,
            color: theme.color.themeColor,
            margin: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        }
    });
