import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from '../store/history';
import { saveAs } from 'file-saver';

import * as API from 'api';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_MAPS from 'actions/Maps';

function* requestSiteMap({ payload }) {
    try {
        yield put(ACTIONS_MAPS.setSiteMap(null));
        const sitemap = yield call(API.POST, payload.url, payload.data);
        if (!sitemap || !sitemap.data || !sitemap.data.siteMaps) {
            yield put(ACTIONS_MAPS.setSiteMap([]));
        } else {
            sitemap.data.siteMaps.length > 0
                ? yield put(ACTIONS_MAPS.setSiteMap(sitemap.data.siteMaps))
                : yield put(ACTIONS_MAPS.setSiteMap([]));
        }
    } catch (error) {
        yield put(ACTIONS_MAPS.setSiteMap([]));
        console.warn(error);
    }
}

function* requestUploadSiteMap({ payload }) {
    let newData =
        payload.data.siteMap && payload.data.siteMap.length > 0
            ? JSON.parse(payload.data.siteMap)
            : payload.data.siteMap;
    let newUmap = payload.data.uMap && payload.data.uMap !== '' ? JSON.parse(payload.data.uMap) : payload.data.uMap;
    let newSiteData =
        payload.data.siteData && payload.data.siteData !== ''
            ? JSON.parse(payload.data.siteData)
            : payload.data.siteData;
    let data = {
        siteMap: newData,
        name: payload.data.name,
        viewBox: payload.data.viewBox,
        baseSiteMap: payload.data.baseSiteMap,
        uMap: newUmap,
        siteData: newSiteData
    };
    try {
        const update = yield call(API.POST, payload.url, data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.push('/app/maps/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSiteMap({ payload }) {
    payload.data.unitID = Number(payload.data.unitID);
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.push('/app/maps/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteSiteMap({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield put(ACTIONS_MAPS.setMap(null));
            yield put(ACTIONS_MAPS.fetchSiteMap());
            yield history.push('/app/maps/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSiteMapData({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.push('/app/maps/all');
            yield put(ACTIONS_MAPS.fetchSiteMap());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateShape({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.push('/app/maps/all');
            yield put(ACTIONS_MAPS.fetchSiteMap());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUploadSiteMapSvg({ payload }) {
    const formData = new FormData();
    payload.data.mapSVG && formData.append('mapSVG', payload.data.mapSVG);
    payload.data.mapUUID && formData.append('mapUUID', payload.data.mapUUID);
    try {
        const upload = yield call(API.POST, payload.url, formData);
        if (!upload) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail`, 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.imageSVG`, 'success'));
            yield history.push('/app/maps/all');
            yield put(ACTIONS_MAPS.fetchSiteMap());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDownloadSiteMapSvg({ payload }) {
    try {
        const downloadData = yield call(API.POST, payload.url, payload.data);
        if (!downloadData || !downloadData.data || !downloadData.data.mapSVG) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail`, 'warning'));
            return;
        } else {
            var blob = new Blob([downloadData.data.mapSVG], { type: 'image/svg+xml' });
            saveAs(blob, 'map.svg');
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectSiteMap() {
    yield takeEvery(CONSTANTS.FETCH_SITE_MAP, requestSiteMap);
}

export function* selectUploadSiteMap() {
    yield takeEvery(CONSTANTS.UPLOAD_SITE_MAP, requestUploadSiteMap);
}

export function* selectEditSiteMap() {
    yield takeEvery(CONSTANTS.EDIT_SITE_MAP, requestEditSiteMap);
}

export function* selectDeleteSiteMap() {
    yield takeEvery(CONSTANTS.DELETE_SITE_MAP, requestDeleteSiteMap);
}

export function* selectEditSiteMapData() {
    yield takeEvery(CONSTANTS.EDIT_SITE_MAP_DATA, requestEditSiteMapData);
}

export function* selectCreateShape() {
    yield takeEvery(CONSTANTS.CREATE_SHAPE_SITE_MAP, requestCreateShape);
}

export function* selectUploadSiteMapSvg() {
    yield takeEvery(CONSTANTS.UPLOAD_SITE_MAP_SVG, requestUploadSiteMapSvg);
}

export function* selectDownloadSiteMapSvg() {
    yield takeEvery(CONSTANTS.DOWNLOAD_SITE_MAP_SVG, requestDownloadSiteMapSvg);
}

export default function* rootSaga() {
    yield all([
        fork(selectSiteMap),
        fork(selectUploadSiteMap),
        fork(selectEditSiteMap),
        fork(selectDeleteSiteMap),
        fork(selectEditSiteMapData),
        fork(selectCreateShape),
        fork(selectUploadSiteMapSvg),
        fork(selectDownloadSiteMapSvg)
    ]);
}
