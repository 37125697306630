import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { getLang } from '../lngProvider';
import { Modal, ModalHeader, ModalBody } from 'components/Modal/new';
import { StyleSheet, css } from 'aphrodite';
import { isAndroid } from 'react-device-detect';
import 'rmc-drawer/assets/index.css';
import 'styles/bootstrap.scss';
import 'styles/app.scss';
import 'styles/app-rtl.scss';
import { Helmet } from 'react-helmet';

import { userSignOutSuccess, switchSite, hideAuthLoader } from 'actions/Auth';
import {
    updateWindowWidth,
    updateWindowHeight,
    fetchIpLocation,
    fetchTheme,
    setLoader,
    setErrorDetails,
    fetchOutageStatus
} from 'actions/App';

import MainApp from 'app/routes';
import AuthApp from 'app/routes/Auth';
import Company from 'app/routes/Auth/routes/Company';
import Site from 'app/routes/Auth/routes/Site';
import Tutorials from 'app/routes/Tutorials/routes/Display/main';
import ManagerDashboard from 'app/routes/ManagerDashboard/';
import { KnowledgeBaseIcon } from 'utilities/icons';

import Notification from 'components/Notifications/new';
import TestSuite from 'components/TestSuite';
import Cookies from 'components/Cookies';
import Outage from 'components/Outage';
import PendingOutage from 'components/Outage/pending';
import Error from 'components/ErrorHandling';
import RageModal from 'components/RageModal';
import ErrorCritical from 'components/ErrorCritical';
import * as process from "process";
window.process = process;
window.Buffer = window.Buffer || require("buffer").Buffer;
class App extends React.Component {
    constructor(props) {
        super(props);
        if (!props.theme) {
            this.props.fetchTheme(1);
        }
        this.state = {
            showBanner: true,
            userInput: '',
            rageClickModal: false,
            currentAppLocale: null,
            criticalError: false,
        };
    }
    componentDidMount() {
        // remove loader from DOM
        const loaders = [
            document.querySelector('.appLoaderContainerBlue'),
            document.querySelector('.appLoaderContainerWhite'),
            document.querySelector('.appLoaderContainerDark')
        ];
        for (let i = 0; i < loaders.length; i++) {
            if (!loaders[i] || loaders[i].style.display !== 'grid') continue;
            loaders[i] && setTimeout(() => loaders[i].classList.add('appLoaderContainerFadeOut'), 2000);
            loaders[i] && setTimeout(() => loaders[i].remove(), 5000);
        }

        let isToken = this.checkToken(window.location.href);
        if (!isToken) {
            this.checkSetup();
            this.props.fetchIpLocation();
        }
        const url = window.location.href;
        if (url.includes('?') && url.includes('v=')) {
            var myNewURL = this.refineURL();
            window.history.pushState("object or string", "Title", "/" + myNewURL );
            this.props.history.push('/app/home/all');
        }
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('storage', () => this.localStorageUpdated());
        let cookiesAccepted = JSON.parse(localStorage.getItem('cookies_accepted'));
        cookiesAccepted && this.setState({ showBanner: false });
        const { fetchOutageStatus } = this.props;
        url.includes('/signin') && fetchOutageStatus();
        if (typeof window !== 'undefined') {
            this.setState({ rageClickModal: localStorage.getItem('rage_click_popup') ? true : false });
        }
    }
    componentDidUpdate() {
        const { currentAppLocale } = this.state;
        const { locale } = this.props;
        window.addEventListener('resize', this.updateDimensions);
        this.checkSetup();

        if (
            (!currentAppLocale && locale)
            // unncoment out this line if no page refresh
            || (currentAppLocale && !currentAppLocale.locale.includes(locale))
        ) { 
            getLang(locale).then(data => {
                this.setState({currentAppLocale: data.default});
            }); 
        }
    }
    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('storage', this.localStorageUpdated);
        }
    }
    componentDidCatch(error, info) {
        this.setState({criticalError: {error, info}});
    }
    localStorageUpdated = () => {
        if (!localStorage.getItem('rage_click_popup')) {
            this.updateState(false);
        } else if (!this.state.rageClickModal) {
            this.updateState(true);
        }
    };
    updateState = (value) => {
        this.setState({ rageClickModal: value });
    };
    removeRageClick = () => {
        this.setState({ rageClickModal: false });
        localStorage.removeItem('rage_click_popup');
    };
    updateDimensions = () => {
        const { navCollapsed } = this.props;
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const url = window.location.href;
        this.props.updateWindowWidth(width);
        if (isAndroid && url.includes('signin')) {
            console.warn('android');
        } else if (isAndroid && navCollapsed) {
            console.warn('android');
        } else {
            this.props.updateWindowHeight(height);
        }
    };
    checkError = (errorDetails) => {
        let { code, subcode } = errorDetails;
        if (!errorDetails) return false;
        // error code 130.1 and 130.2 are for the scheduling a hold outside of hours / overnight; a custom modal is used instead of the typical error modal
        if (code === 130 && (subcode === 1 || subcode === 2)) return false;
        return true;
    };
    checkSetup = () => {
        const { authUser } = this.props;
        const url = window.location.href;
        let userSession = JSON.parse(sessionStorage.getItem('user_data'));
        if (authUser && !userSession) {
            this.props.userSignOutSuccess(null);
        } else if (authUser && authUser.state === 'update_required' && !url.includes('/setup')) {
            this.props.history.push('/setup/password');
            sessionStorage.removeItem('manager_dash');
            this.props.setLoader(false);
        }
        this.updateDimensions();
    };
    checkToken = (url) => {
        if (url.includes('?') && url.includes('token=') && url.includes('siteID=')) {
            let token = url.split('?')[1].replace('token=', '');
            let siteUUID = JSON.parse(url.split('?')[2].replace('siteID=', ''));
            this.props.switchSite(siteUUID.toString(), token);
            return true;
        }
        if (url.includes('?') && url.includes('token=') && url.includes('siteUUID=')) {
            let token = url.split('?')[1].replace('token=', '');
            let siteUUID = url.split('?')[2].replace('siteUUID=', '');
            this.props.switchSite(siteUUID, token, true);
            return true;
        }
        // The below logic is for when we still have a token in local storage we can try to re-log them back in automatically...
        // An example might be they accidentally closed the browser tab, but then came right back...we don't want them to have to log in again since we already know them
        if (localStorage.getItem('nse_login_data') &&
        JSON.parse(localStorage.getItem('nse_login_data')).token &&
        (this.props.history.location.pathname === '/signin' || this.props.history.location.pathname === '/')) {
            this.props.switchSite(JSON.parse(localStorage.getItem('nse_login_data')).siteUUID, JSON.parse(localStorage.getItem('nse_login_data')).token);
            return true;
        }
        return false;
    };
    removeQuery = (key) => {
        const { location, history } = this.props;
        let pathname = location.pathname;
        let searchParams = new URLSearchParams(location.search);
        searchParams.delete(key);
        history.push({
          pathname: pathname,
          search: searchParams.toString()
        });
    };
    checkUser = () => {
        const { authUser } = this.props;
        let userSession = JSON.parse(sessionStorage.getItem('user_data'));
        let authedDevice = localStorage.getItem('auth_device') ? localStorage.getItem('auth_device') : null;
        if (
            (!userSession && !authUser) ||
            !userSession ||
            (authUser && authUser.use2FactorAuth && !authedDevice) ||
            (authUser && authUser.use2FactorAuth && authedDevice && !authedDevice.includes(authUser.id))
        ) {
            return false;
        } else {
            return true;
        }
    };
    checkManager = () => {
        if (sessionStorage.getItem('manager_dash')) {
            return true;
        } else {
            return false;
        }
    };
    checkUrl = () => {
        const subdomain = window.location.host.split('.');
        var site = subdomain[0];
        if (subdomain[0] === 'www') {
            site = subdomain[1];
        }
        if (site.includes('localhost') || site.includes('dev')) {
            return true;
        } else {
            return true;
        }
    };
    disableCookies = () => {
        localStorage.setItem('cookies_accepted', true);
        this.setState({ showBanner: false });
    };
    dismissError = (stay) => {
        const { setErrorDetails, history, setLoader, hideAuthLoader } = this.props;
        const url = window.location.href;
        setErrorDetails(null);
        !url.includes('/signin') && !stay && history.goBack();
        setLoader(false);
        hideAuthLoader();
    };
    refineURL = () => {
        //get full URL
        var currURL= window.location.href; //get current address
        
        //Get the URL between what's after '/' and befor '?' 
        //1- get URL after'/'
        var afterDomain= currURL.substring(currURL.lastIndexOf('/') + 1);
        //2- get the part before '?'
        var beforeQueryString= afterDomain.split("?")[0];  
    
        return beforeQueryString;    
    }
    render() {
        let { currentAppLocale, criticalError } = this.state;
        let { match, theme, errorDetails, height, width, authUser, showTutorials, userTutorials, showTestSuite, switcherLoader, site } = this.props;
        this.styles = styles(theme, height);
        let { showBanner, rageClickModal } = this.state;
        const url = window.location.href;
        // error page returns if critical error
        if (criticalError) {
            return <IntlProvider
                key={currentAppLocale.locale}
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>
                <ErrorCritical errorInfo={criticalError} />
            </IntlProvider>
        }
        let currentOutage = JSON.parse(sessionStorage.getItem('current_outage'));
        let pendingOutage = JSON.parse(sessionStorage.getItem('pending_outage'));
        let themeColor =
            localStorage.getItem('nse_theme') &&
            JSON.parse(localStorage.getItem('nse_theme')) &&
            JSON.parse(localStorage.getItem('nse_theme')).color &&
            JSON.parse(localStorage.getItem('nse_theme')).color.themeColor;
        return (
            currentAppLocale && <IntlProvider
                key={currentAppLocale.locale}
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>
                <div className={`app-main ${css(this.styles.page)}`}>
                    {currentOutage && <Outage app={this.checkUser()} />}
                    {pendingOutage && !currentOutage && <PendingOutage app={this.checkUser()} />}
                    {showTutorials && width > 800 && !url.includes('signin') && !url.includes('setup') && (
                        <div className={css(this.styles.tutorialPage)}>
                            <Tutorials tutorials={userTutorials} />
                        </div>
                    )}
                    <Modal isOpen={switcherLoader.loading}>
                        <ModalBody
                            style={{
                                minHeight: '20vh',
                                background: theme.color.compColor,
                                color: theme.color.fontColor
                            }}>
                            <div className={css(this.styles.wrapperProgress)}>
                                <div style={{ fontSize: 18, marginBottom: 20 }}>
                                    {switcherLoader.text && switcherLoader.text !== ''
                                    ?
                                    switcherLoader.text
                                    :
                                    // if we don't pass a message here when setting the loader lets just show 'Loading...'
                                    <FormattedMessage id="search.assignUnit.loading" />}
                                </div>
                                <div className={css(this.styles.wrapper)}>
                                    <span className={css(this.styles.bar)}>
                                        <span className={css(this.styles.progress)}></span>
                                    </span>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={rageClickModal}>
                        <ModalHeader
                            toggle={() => this.removeRageClick()}
                            style={{ borderBottom: 'none' }}>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 40,
                                    width: 40,
                                    border: '2px solid' + theme && theme.color ? theme.color.themeColor : '#0070CE',
                                    borderRadius: '50%'
                                }}>
                                <KnowledgeBaseIcon color={theme && theme.color ? theme.color.themeColor : '#0070CE'} />
                            </span>
                        </ModalHeader>
                        <ModalBody>
                            <RageModal dismissModal={this.removeRageClick} />
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={errorDetails && this.checkError(errorDetails)}
                        headerType={'error'}
                        toggle={() => this.dismissError(true)}>
                        <ModalHeader
                            toggle={() => this.dismissError(true)}>
                            <span>Error</span>
                        </ModalHeader>
                        <ModalBody>
                            <Error errorDetails={errorDetails} dismissError={this.dismissError} />
                        </ModalBody>
                    </Modal>
                    <Helmet>
                        {site && site.companyUUID && site.companyUUID == "2" ? null :
                        this.checkUser() &&
                            !url.includes('signin') &&
                            !url.includes('setup') &&
                            width > 800 &&
                            authUser &&
                            authUser.permissions &&
                            authUser.permissions.includes('live_chat') && (
                                <script
                                    type="text/javascript"
                                    id="hs-script-loader"
                                    async
                                    defer
                                    src="https://js-na1.hs-scripts.com/4335336.js"></script>
                            )}
                        {url.includes('run') &&
                        <meta name="loadforge-site-verification" content="ae5d1e318b9d0a234101b98a4fe397ce1422e73b57e41281f41c23616ade8cbf43c84357181eba237622f9ac0f7e5cb27ea090874be3b4d275b83ae4b5ac7f85" />}
                        <meta name="theme-color" content={themeColor || '#ffffff'} />
                        <meta
                            name="theme-color"
                            content={themeColor || '#49494B'}
                            media="(prefers-color-scheme: dark)"
                        />
                    </Helmet>
                    <Notification />
                    {showTestSuite && <TestSuite />}
                    {(this.checkUser() && this.checkManager() && !url.includes('signin')) ? (
                        <Switch>
                            <Route path={`${match.url}dashboard`} component={ManagerDashboard} />
                            <Route component={ManagerDashboard} />
                        </Switch>
                    ) : (this.checkUser() && !url.includes('signin')) ? (
                        <Switch>
                            <Route path={`${match.url}app`} component={MainApp} />
                            <Route path={`${match.url}setup`} component={AuthApp} />
                            <Route component={MainApp} />
                        </Switch>
                    ) : (
                        <Switch>
                            <Redirect exact from={`${match.url}/`} to={`${match.url}signin`} />
                            <Route path={`${match.url}signin`} component={AuthApp} />
                            <Route path={`${match.url}company`} component={Company} />
                            <Route path={`${match.url}site`} component={Site} />
                            <Route
                                exact
                                path={`${match.url}`}
                                component={
                                    this.checkUrl()
                                        ? AuthApp
                                        : () => {
                                              window.location = 'https://noke.com/smart-door-controller-overview';
                                              return null;
                                          }
                                }
                            />
                            <Route component={AuthApp} />
                        </Switch>
                    )}
                    {!this.checkUser() && showBanner && <Cookies disableCookies={this.disableCookies} />}
                </div>
            </IntlProvider>
        );
    }
}

const mapStateToProps = ({ app, auth, tutorials }) => {
    const { navCollapsed, locale, theme, height, width, errorDetails, showTestSuite, switcherLoader } = app;
    const { authUser, site } = auth;
    const { showTutorials, userTutorials } = tutorials;
    return { navCollapsed, locale, theme, height, width, authUser, site, errorDetails, showTestSuite, showTutorials, userTutorials, switcherLoader };
};

export default withRouter(
    connect(mapStateToProps, {
        userSignOutSuccess,
        updateWindowWidth,
        updateWindowHeight,
        fetchIpLocation,
        fetchTheme,
        setLoader,
        switchSite,
        setErrorDetails,
        hideAuthLoader,
        fetchOutageStatus
    })(App)
);

const styles = (theme, height) =>
    StyleSheet.create({
        page: {
            backgroundColor: theme && theme.color ? theme.color.pageColor : "#f5f7f9",
            color: theme && theme.color ? theme.color.fontColor : "#808080",
            height
        },
        tutorialPage: {
            position: 'fixed',
            zIndex: 1050,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background: 'rgba(0, 0, 0, 0.5)'
        },
        darkModeStyle: {
            backgroundColor: theme && theme.color ? theme.color.pageColor : "#f5f7f9",
            color: theme && theme.color ? theme.color.fontColor : "#808080",
            borderColor: theme && theme.color ? theme.color.borderColor : "#d8dcdf",
        },
        wrapperProgress: {
            padding: 30,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        wrapper: {
            borderRadius: 60,
            overflow: 'hidden',
            width: '80%'
        },
        bar: {
            display: 'block',
            width: '100%',
            background: 'rgba(0,0,0,0.075)'
        },
        progress: {
            display: 'block',
            background: theme.color.themeColor,
            color: '#fff',
            padding: 5,
            animationName: {
                '0%': {
                    width: '0'
                },
                '25%': {
                    width: '24%'
                },
                '56%': {
                    width: '50%'
                },
                '71%': {
                    width: '60%'
                },
                '94%': {
                    width: '86%'
                },
                '100%': {
                    width: '100%'
                }
            },
            animationDuration: '.5s',
            animationIterationCount: 'forward'
        },
    });
