import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const createTutorial = ({
    featureflagUUID = '',
    featureTitle = '',
    featureDescription = '',
    gifLinkWeb = '',
    gifLinkApp = '',
    permission = '',
    embeddedHTML = '',
    externalLink = '',
    companyUUIDs = [],
    allCompanies = true
}) => {
    return {
        type: CONSTANTS.CREATE_TUTORIAL,
        payload: {
            url: ENDPOINTS.ENDPOINT_CREATE_TUTORIAL,
            data:
                featureflagUUID != ''
                    ? {
                          featureTitle,
                          featureDescription,
                          gifLinkWeb,
                          gifLinkApp,
                          embeddedHTML,
                          externalLink,
                          companyUUIDs: allCompanies ? ["-1"] : companyUUIDs,
                          permission,
                          featureflagUUID
                      }
                    : {
                          featureTitle,
                          featureDescription,
                          gifLinkWeb,
                          gifLinkApp,
                          embeddedHTML,
                          externalLink,
                          companyUUIDs: allCompanies ? ["-1"] : companyUUIDs,
                          permission
                      }
        }
    };
};

export const fetchTutorials = () => {
    return {
        type: CONSTANTS.FETCH_TUTORIALS,
        payload: {
            url: ENDPOINTS.ENDPOINT_TUTORIAL_LIST,
            data: {}
        }
    };
};

export const setTutorials = (data) => {
    return {
        type: CONSTANTS.SET_TUTORIALS,
        payload: data
    };
};

export const updateTutorial = ({
    tutorialUUID = '',
    featureTitle = '',
    featureDescription = '',
    gifLinkWeb = '',
    gifLinkApp = '',
    permission = '',
    featureflagUUID = '',
    embeddedHTML = '',
    externalLink = '',
    companyUUIDs = [],
    allCompanies = true
}) => {
    return {
        type: CONSTANTS.UPDATE_TUTORIAL,
        payload: {
            url: ENDPOINTS.ENDPOINT_UPDATE_TUTORIAL,
            data: {
                tutorialUUID,
                featureTitle,
                featureDescription,
                gifLinkWeb,
                gifLinkApp,
                permission,
                featureflagUUID,
                embeddedHTML,
                externalLink,
                companyUUIDs: allCompanies ? ["-1"] : companyUUIDs
            }
        }
    };
};

export const deleteTutorial = (tutorialUUID = '') => {
    return {
        type: CONSTANTS.DELETE_TUTORIAL,
        payload: {
            url: ENDPOINTS.ENDPOINT_DELETE_TUTORIAL,
            data: {
                tutorialUUID
            }
        }
    };
};

export const fetchUserTutorials = (data) => {
    return {
        type: CONSTANTS.FETCH_USER_TUTORIALS,
        payload: {
            url: ENDPOINTS.ENDPOINT_TUTORIAL_LIST,
            data
        }
    };
};

export const setUserTutorials = (data) => {
    return {
        type: CONSTANTS.SET_USER_TUTORIALS,
        payload: data
    };
};

export const setShowTutorials = (data) => {
    return {
        type: CONSTANTS.SET_SHOW_TUTORIALS,
        payload: data
    };
};

export const updateUserTutorial = ({ tutorialUUID = '', userUUID = '' }) => {
    return {
        type: CONSTANTS.UPDATE_USER_TUTORIAL,
        payload: {
            url: ENDPOINTS.ENDPOINT_UPDATE_USER_TUTORIAL,
            data: {
                tutorialUUID,
                userUUID
            }
        }
    };
};

export const setSeenTutorials = (data) => {
    return {
        type: CONSTANTS.SET_SEEN_TUTORIALS,
        payload: data
    };
};

export const setTutorialTooltips = (data) => {
    return {
        type: CONSTANTS.SET_TUTORIAL_TOOLTIPS,
        payload: data
    };
};
export const addTutorialTooltip = (data) => {
    return {
        type: CONSTANTS.ADD_TUTORIAL_TOOLTIP,
        payload: data
    };
};
export const removeTutorialTooltip = (data) => {
    return {
        type: CONSTANTS.REMOVE_TUTORIAL_TOOLTIP,
        payload: data
    };
};
