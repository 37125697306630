import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_ACCESS_CODES from 'actions/AccessCodes';
import * as ACTIONS_UNITS from 'actions/Units';
import * as ACTIONS_USERS from 'actions/Users';

const getAccessCodesHeaders = (state) => state.accessCodes.accessCodeHeader;

function* requestAccessCodesList({ payload }) {
    try {
        yield put(ACTIONS_ACCESS_CODES.setAccessCodeList(null));
        yield put(ACTIONS_ACCESS_CODES.setAccessCodesStats(null));
        let accessCodes = yield call(API.POST, payload.url, payload.data);
        if (!accessCodes || !accessCodes.data.accessCodes) {
            yield put(ACTIONS_ACCESS_CODES.setAccessCodeList([]));
            let stats = {
                total: 0,
                pending: 0,
                setup: 0,
                active: 0,
                inactive: 0,
                error: 0
            };
            yield put(ACTIONS_ACCESS_CODES.setAccessCodesStats(stats));
        } else {
            accessCodes.data.accessCodes.map((code) => {
                code.userName = `${code.user.firstName ? code.user.firstName : ''} ${code.user.lastName ? code.user.lastName : ''}`;
                code.unitName = code.unit.name;
                code.state = code.state ? code.state : '';
            });
            let pending = accessCodes.data.accessCodes.filter((item) => item.state === 'pending').length;
            let setup = accessCodes.data.accessCodes.filter((item) => item.state === 'setup').length;
            let active = accessCodes.data.accessCodes.filter((item) => item.state === 'active').length;
            let inactive = accessCodes.data.accessCodes.filter((item) => item.state === 'inactive').length;
            let error = accessCodes.data.accessCodes.filter((item) => item.state === 'error').length;
            let stats = {
                total: pending + setup + active + inactive + error,
                pending,
                setup,
                active,
                inactive,
                error
            };
            yield put(ACTIONS_ACCESS_CODES.setAccessCodesStats(stats));
            accessCodes.data.accessCodes = UTILS.filterArray(accessCodes.data.accessCodes, 'state', payload.filter);
            let accessCodeHeaders = yield select(getAccessCodesHeaders);
            let activeHeader = accessCodeHeaders.find((item) => item.active);
            accessCodes.data.accessCodes = UTILS.sortList(!activeHeader.order, accessCodes.data.accessCodes, activeHeader.sortTitle);
            yield put(ACTIONS_ACCESS_CODES.setAccessCodeList(accessCodes.data.accessCodes));
        }
    } catch (error) {
        yield put(ACTIONS_ACCESS_CODES.setAccessCodeList([]));
        console.warn(error);
    }
}

function* requestAccessCodesDetails({ payload }) {
    try {
        yield put(ACTIONS_ACCESS_CODES.setAccessCodeDetails(null));
        let accessCode = yield call(API.POST, payload.url, payload.data);
        if (!accessCode) {
            yield put(ACTIONS_ACCESS_CODES.setAccessCodeDetails(null));
            history.push('/app/accessCodes');
        } else if (!accessCode.data.codeDetails) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.accessCode.none`, 'warning'));
            history.push('/app/accessCodes');
        } else {
            accessCode.data.codeDetails.map((code) => {
                code.entryName = code.unit.name;
            });
            let accessCodes = yield call(API.POST, payload.list.url, payload.list.data);
            let accessCodeDetails =
                accessCodes &&
                accessCodes.data &&
                accessCodes.data.accessCodes &&
                accessCodes.data.accessCodes.find((code) => code.accessCodeUUID == payload.data.accessCodeUUID);
            if (accessCodeDetails) {
                accessCodeDetails.units = accessCode.data.codeDetails ? accessCode.data.codeDetails : [];
                yield put(ACTIONS_ACCESS_CODES.setAccessCodeDetails(accessCodeDetails));
            } else {
                history.push('/app/accessCodes');
            }
        }
    } catch (error) {
        yield put(ACTIONS_ACCESS_CODES.setAccessCodeDetails(null));
        console.warn(error);
    }
}

function* requestEditAccessCodes({ payload }) {
    const url = window.location.href;
    try {
        if (payload.data.accessCode.length < 4) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.accessCode.short`, 'warning'));
            return;
        }
        if (payload.data.accessCode.length > 12) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.accessCode.long`, 'warning'));
            return;
        }
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield put(
                !url.includes('users')
                    ? ACTIONS_UNITS.fetchUnitDetails(payload.data.unitUUID)
                    : ACTIONS_USERS.fetchUserUnits(payload.data.userUUID)
            );
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchDailyCodes({ payload }) {
    try {
        yield put(ACTIONS_ACCESS_CODES.setDailyCodes(null));
        let dailyCodes = yield call(API.POST, payload.url, payload.data);
        if (!dailyCodes || !dailyCodes.data || !dailyCodes.data.dailyAccessCodes) {
            yield put(ACTIONS_ACCESS_CODES.setDailyCodes([]));
        } else {
            yield put(ACTIONS_ACCESS_CODES.setDailyCodes(dailyCodes.data.dailyAccessCodes));
        }
    } catch (error) {
        yield put(ACTIONS_ACCESS_CODES.setDailyCodes([]));
        console.warn(error);
    }
}

function* requestSendSiteCodes({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sendSiteCodes', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSendPendingSiteCodes({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sendSiteCodes', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectEditAccessCodes() {
    yield takeEvery(CONSTANTS.EDIT_ACCESS_CODES, requestEditAccessCodes);
}
export function* selectFetchAccessCodesList() {
    yield takeEvery(CONSTANTS.FETCH_ACCESS_CODES_LIST, requestAccessCodesList);
}
export function* selectFetchAccessCodesDetails() {
    yield takeEvery(CONSTANTS.FETCH_ACCESS_CODES_DETAILS, requestAccessCodesDetails);
}
export function* selectFetchDailyCodes() {
    yield takeEvery(CONSTANTS.FETCH_DAILY_CODES, requestFetchDailyCodes);
}
export function* selectSendSiteCodes() {
    yield takeEvery(CONSTANTS.SEND_SITE_CODES, requestSendSiteCodes);
}
export function* selectSendPendingSiteCodes() {
    yield takeEvery(CONSTANTS.SEND_PENDING_SITE_CODES, requestSendPendingSiteCodes);
}

export default function* rootSaga() {
    yield all([
        fork(selectEditAccessCodes),
        fork(selectFetchAccessCodesList),
        fork(selectFetchAccessCodesDetails),
        fork(selectFetchDailyCodes),
        fork(selectSendSiteCodes),
        fork(selectSendPendingSiteCodes)
    ]);
}
