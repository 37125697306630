import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import ToolTips from 'components/ToolTips';
import { generateTextID } from 'utilities';
class SelectInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requiredAlert: false,
            randomID: ''
        };
    }
    componentDidMount() {
        let randomID = generateTextID();
        this.setState({ randomID })
    }
    componentDidUpdate() {
        const { value, showRequired } = this.props;
        const { requiredAlert } = this.state;
        // if showRequired is true, the input will always be red if value is === ''
        showRequired && !requiredAlert && value === '' && this.setState({ requiredAlert: true });
        showRequired && requiredAlert && value !== '' && this.setState({ requiredAlert: false });
    }
    render() {
        const {
            intl,
            theme,
            title,
            disabledOption,
            options,
            value,
            onChange,
            fontSize,
            titleFontSize,
            width,
            name,
            tooltip,
            textTransform,
            titleTextTransform,
            required,
            margin,
            optionalText,
            hideDropdown,
            noBorder,
            noMargin,
            tooltipId,
            disabled
        } = this.props;
        const { requiredAlert, randomID } = this.state;
        this.styles = styles(theme, requiredAlert, fontSize, titleFontSize, noBorder);
        return (
            <div
                className={css(this.styles.wrapper)}
                style={{
                    width: width,
                    fontSize: fontSize,
                    margin: margin || (title !== '' ? '7px 0 0 0' : '0')
                }}>
                {title && title !== '' && randomID && (
                    <div 
                        className={css(this.styles.titleTop)} 
                        id={tooltipId ? tooltipId : randomID}
                        style={{
                            textTransform: titleTextTransform || 'none'
                        }}>
                        {title}
                        {required && ` (${intl.formatMessage({ id: 'form.required' })})`}
                        {optionalText && ` ${intl.formatMessage({ id: 'form.option' })}`}
                    </div>
                )}
                {title && title !== '' && tooltip && tooltip !== '' && randomID && <ToolTips target={tooltipId ? tooltipId : randomID} message={tooltip} />}
                <select
                    className={css(this.styles.simpleInput)}
                    onFocus={() => this.setState({ requiredAlert: false })}
                    onBlur={() => required && this.setState({ requiredAlert: value === '' })}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    style={{
                        textTransform: textTransform || 'none',
                        padding: title !== '' ? '10px 0 7px 0' : '7px 0',
                        margin: noMargin ? 0 : title !== '' ? '7px auto 7px auto' : '0 auto 7px auto',
                        color: !requiredAlert ? theme.color.fontColor : 'red',
                        transition: '.2s color ease-in-out, .2s width ease-in-out, .2s border-bottom ease-in-out',
                        fontSize: fontSize,
                        border: 'none',
                        borderBottom: noBorder ? 'none' : `1px solid ${!requiredAlert ? theme.color.borderColor : 'red'}`,
                        display: 'grid',
                        width: '100%',
                        outline: 'none',
                        background: 'none',
                    }}>
                    {disabledOption && (
                        <option className={css(this.styles.simpleInputOption)} hidden={true}>
                            {disabledOption}
                        </option>
                    )}
                    {options &&
                        options.length > 0 &&
                        options.map((option) => (
                            <option
                                className={css(this.styles.simpleInputOption)}
                                key={option.key || generateTextID()}
                                value={option.value}>
                                {option.text}
                            </option>
                        ))}
                </select>
                {!hideDropdown && (
                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: title !== '' ? 16 : 7,
                            pointerEvents: 'none',
                            color: !requiredAlert ? theme.color.fontColor : 'red',
                            transition: '.2s color ease-in-out'
                        }}>
                        <span className="material-icons">expand_more</span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme } = app;
    return { theme };
};

SelectInput.defaultProps = {
    fontSize: 15,
    width: '100%',
    title: '',
    disabled: false
};

export default injectIntl(connect(mapStateToProps)(SelectInput));

const styles = (theme, requiredAlert, fontSize, titleFontSize) =>
    StyleSheet.create({
        wrapper: {
            transition: '.2s color ease-in-out, .2s width ease-in-out',
            position: 'relative',
            display: 'grid'
        },
        simpleInput: {
            '::placeholder': {
                color: !requiredAlert ? theme.color.subFontColor : `rgba(255,0,0,0.55)`,
                transition: '.2s color ease-in-out',
                fontSize: fontSize
            },
            '::-webkit-scrollbar-track': {
                background: theme.color.headerColor
            }
        },
        simpleInputOption: {
            background: theme.color.headerColor,
            color: theme.color.fontColor
        },
        titleTop: {
            position: 'absolute',
            top: -4,
            color: !requiredAlert ? theme.color.themeColor : 'red',
            transition: '.2s color ease-in-out',
            fontSize: titleFontSize || 12,
            backgroundColor: theme.color.headerColor,
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }
    });
