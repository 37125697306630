import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import * as UTILS from 'utilities';

import CellText from 'components/Table/cell/components/text';
import CellRating from 'components/Table/cell/components/rating';
class FeedbackCell extends React.Component {
    render() {
        const { intl, data, width, authUser, userTimezone, site } = this.props;
        const { dateSubmitted, userFirstName, userLastName, rating } = data;
        return (
            <>
                <CellText
                    padding={'2px 10px 2px 0'}
                    maxCharLength={80}
                    Title={`${userFirstName} ${userLastName}`}
                    textAlign={'left'}
                    TitleColor={true}
                />
                {width > 800 && <CellRating Cellwidth={'98px'} rating={rating == "0" ? 0 : Number(rating) ? Number(rating) : 1} />}
                <CellText
                    padding={'2px 0 2px 10px'}
                    Cellwidth={'80px'}
                    Title={dateSubmitted && dateSubmitted !== "" ? UTILS.dateTimeFormatter(dateSubmitted, authUser, site, userTimezone) : intl.formatMessage({ id: `form.na` })}
                    textAlign={'right'}
                    TitleColor={true}
                />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, userTimezone } = app;
    const { authUser, site } = auth;
    return { width, authUser, site, userTimezone };
};

export default injectIntl(connect(mapStateToProps)(FeedbackCell));
