import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import { Wizard, Step, Controls } from 'react-decision-tree-flow';

import { fadeIn } from 'react-animations';

import { getWizardSteps, wizardContactSupport } from 'actions/HelpWizard';

import Skeleton from 'react-loading-skeleton';
import SkeletonContainer from 'components/Skeleton/skeletonWrapper';
class DecisionTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }
    componentDidMount() {
        const { getWizardSteps, activeWizard } = this.props;
        activeWizard && activeWizard.uuid && getWizardSteps(activeWizard.uuid);
    }
    componentDidCatch(error) {
        console.error(error);
        this.setState({ hasError: true });
    }
    findStepByKey = (data, key) => {
        return data.find((item) => `step${item.uuid}` === key);
    };
    clickChatWithUs = (uuid) => {
        this.props.wizardContactSupport(uuid);
        this.props.toggleHelperMinimized();
        window.HubSpotConversations && window.HubSpotConversations.widget.open();
    };
    clickCallUs = (uuid) => {
        this.props.wizardContactSupport(uuid);
        this.props.toggleHelperMinimized();
        this.props.history.push(`/app/help/contact/all`);
    };
    render() {
        const { intl, theme, wizardSteps } = this.props;
        this.styles = styles(theme);
        // formatted into a tree for the react-decision-tree-flow package
        let tree = {};
        wizardSteps &&
            wizardSteps.forEach((step) => {
                tree[`step${step.uuid}`] = [...step.destinations.map((destination) => `step${destination.uuid}`)];
            });
        // error handling - errors popup when destinations lead to a step that doesn't exist
        if (this.state.hasError) {
            return (
                <div className={css(this.styles.errorWrapper)}>
                    <div className={css(this.styles.errorTitle)}>
                        {intl.formatMessage({ id: `comp.helpWizard.error` })}
                    </div>
                    <div className={css(this.styles.errorMessage)}>
                        {intl.formatMessage({ id: `comp.helpWizard.errorDescription` })}
                    </div>
                </div>
            );
        }
        return wizardSteps && Object.keys(tree).length > 0 ? (
            <div className={css(this.styles.quizWrapper, this.styles.fadeIn)}>
                <Wizard tree={tree} first={String(Object.keys(tree)[0])}>
                    {Object.keys(tree).map((key) => (
                        <Step key={key + `-key`} name={String(key)}>
                            {this.findStepByKey(wizardSteps, key).mediaLink !== '' && (
                                <div className={css(this.styles.mediaLinkContainer)}>
                                    <img
                                        src={this.findStepByKey(wizardSteps, key).mediaLink}
                                        className={css(this.styles.mediaLinkElement)}
                                        alt={`image for ${key}`}
                                    />
                                </div>
                            )}
                            <div className={css(this.styles.stepBody)}>{this.findStepByKey(wizardSteps, key).body}</div>
                            <Controls>
                                {({ step, destinations }) =>
                                    Object.keys(destinations).length > 0 ? (
                                        Object.entries(destinations).map(([stepName, goToStep]) => (
                                            // Answer step buttons
                                            <div
                                                className={css(this.styles.stepAnswerBtn)}
                                                key={`step-control-${stepName}`}
                                                onClick={goToStep}>
                                                {
                                                    wizardSteps
                                                        .find((item) => `step${item.uuid}` === step)
                                                        .destinations.find((place) => `step${place.uuid}` === stepName)
                                                        .label
                                                }
                                            </div>
                                        ))
                                    ) : (
                                        // Contact support message
                                        <div className={css(this.styles.contactSupportMsg)}>
                                            <div className={css(this.styles.contactSupportTitle)}>
                                                {intl.formatMessage({
                                                    id: 'comp.helpWizard.contactSupport.title'
                                                })}
                                            </div>
                                            <div
                                                className={css(this.styles.contactSupportBtn)}
                                                onClick={() => this.clickChatWithUs(String(step).substring(4))}>
                                                {intl.formatMessage({
                                                    id: 'comp.helpWizard.contactSupport.chat'
                                                })}
                                            </div>
                                            <div
                                                className={css(this.styles.contactSupportBtn)}
                                                onClick={() => this.clickCallUs(String(step).substring(4))}>
                                                {intl.formatMessage({
                                                    id: 'comp.helpWizard.contactSupport.call'
                                                })}
                                            </div>
                                        </div>
                                    )
                                }
                            </Controls>
                        </Step>
                    ))}
                </Wizard>
            </div>
        ) : (
            <div style={{ padding: '30px' }}>
                <SkeletonContainer>
                    <Skeleton
                        style={{ margin: '0 0 3px 0', borderRadius: 5 }}
                        width={'100%'}
                        height={20}
                        count={2}
                        inline={false}
                    />
                    <Skeleton
                        style={{ margin: '0 0 3px 0', borderRadius: 5 }}
                        width={'66%'}
                        height={20}
                        count={1}
                        inline={false}
                    />
                    <div style={{ height: 20, width: '100%' }}></div>
                    <Skeleton
                        style={{ margin: '0 0 6px 0', borderRadius: 5 }}
                        width={'100%'}
                        height={45}
                        count={2}
                        inline={false}
                    />
                </SkeletonContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ app, wizard }) => {
    const { theme } = app;
    const { wizardSteps } = wizard;
    return { theme, wizardSteps };
};

export default injectIntl(withRouter(connect(mapStateToProps, { getWizardSteps, wizardContactSupport })(DecisionTree)));

const styles = (theme) =>
    StyleSheet.create({
        quizWrapper: {
            padding: '10px 30px',
            marginBottom: 15,
            color: theme.color.fontColor,
            overflowY: 'auto',
            maxHeight: '389px'
        },
        mediaLinkContainer: {
            height: '150px',
            margin: '15px 0',
            textAlign: 'center',
            display: 'flex',
            placeItems: 'center',
            justifyContent: 'center'
        },
        mediaLinkElement: {
            maxHeight: '100%',
            maxWidth: '100%',
            borderRadius: 5
        },
        stepBody: {
            lineHeight: 1.4,
            margin: '15px 0',
            fontSize: 16
        },
        stepAnswerBtn: {
            fontSize: 14,
            backgroundColor: theme.color.menuColor,
            marginBottom: 10,
            padding: 10,
            borderRadius: 5,
            cursor: 'pointer',
            transition: 'all 0.15s ease',
            ':hover': {
                backgroundColor: theme.color.themeColor,
                color: 'white'
            }
        },
        fadeIn: {
            animationName: fadeIn,
            animationDuration: '0.2s'
        },
        errorWrapper: {
            padding: 20,
            color: theme.color.fontColor
        },
        errorTitle: {
            fontSize: 18,
            fontFamily: 'Poppins',
            paddingBottom: 5
        },
        errorMessage: {
            fontSize: 14
        },
        contactSupportMsg: {
            fontSize: 16,
            lineHeight: 1.4,
            margin: '20px 0 5px 0'
        },
        contactSupportTitle: {
            paddingBottom: 10,
            fontStyle: 'italic'
        },
        contactSupportBtn: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            backgroundColor: theme.color.menuColor,
            marginBottom: 10,
            padding: 10,
            borderRadius: 5,
            cursor: 'pointer',
            transition: 'all 0.15s ease',
            ':hover': {
                backgroundColor: theme.color.themeColor,
                color: 'white'
            }
        }
    });
