import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';

import { fetchLowBatteryUnits } from 'actions/Units';
import { UnitIcon, LowLockIcon } from 'utilities/icons';

import Spinner from 'components/Spinner';
import Header from 'components/Header/dashboard';
class DashboardLowBattery extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { editWidgets, visible, fetchLowBatteryUnits } = this.props;
        (editWidgets || visible) && fetchLowBatteryUnits();
    }
    render() {
        const { intl, history, theme, width, lowBatteryUnits, authUser, site } = this.props;
        this.styles = styles(theme);
        const permissions = authUser.permissions;
        return (
            <>
                <Header
                    Icon={UnitIcon}
                    iconSize={26}
                    title={width > 1404 ? 'comp.table.header.lowBatteryUnits' : 'comp.table.header.lowBatteryUnit'}
                />
                <Row style={{ margin: '0 0 0 0', padding: '5px 20px 20px 20px', width: '100%' }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <div
                            className={
                                permissions.includes('manage_access_codes') && site && site.has3K
                                    ? css(this.styles.shortWrapper)
                                    : permissions.includes('remote_open')
                                    ? css(this.styles.wrapper)
                                    : css(this.styles.tallWrapper)
                            }
                            style={
                                lowBatteryUnits && lowBatteryUnits.length > 0
                                    ? {
                                          overflowY: 'auto',
                                          borderBottom: '1px solid' + theme.color.borderColor
                                      }
                                    : { overflowY: 'auto' }
                            }>
                            {lowBatteryUnits ? (
                                <table className={css(this.styles.table)}>
                                    {lowBatteryUnits && lowBatteryUnits.length > 0 ? (
                                        <tbody>
                                            {lowBatteryUnits.map((unit) => (
                                                <tr
                                                    key={'UNITLIST' + unit.unitId + unit.lockId}
                                                    className={css(this.styles.row)}
                                                    onClick={() => history.push(`/app/units/display/${unit.unitId}`)}>
                                                    <td style={{ paddingTop: 6, width: 28 }}>
                                                        <UnitIcon
                                                            height={22}
                                                            width={22}
                                                            color={theme.color.themeColor}
                                                        />
                                                    </td>
                                                    <td className={css(this.styles.bold)}>
                                                        {unit.lockName && unit.lockName}
                                                    </td>
                                                    <td className={css(this.styles.text)}>
                                                         {(unit.wiredVoltage && unit.wiredVoltage > 0) || (unit.lockHardware =="2A") ? (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center'
                                                                }}>
                                                                <i
                                                                    className="material-icons"
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: '#E02550',
                                                                        marginTop: 3
                                                                    }}>
                                                                    flash_on
                                                                </i>
                                                                {(unit.wiredVoltage / 1000).toFixed(1)}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                    <td className={css(this.styles.text)}>
                                                        {unit.batteryVoltage && unit.batteryVoltage > 0 ? (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center'
                                                                }}>
                                                                <LowLockIcon
                                                                    themeColor={theme.color.themeColor}
                                                                    color={'#E02550'}
                                                                    fill={'#E02550'}
                                                                />
                                                                <p
                                                                    style={{
                                                                        margin: 0,
                                                                        display: 'inline-block'
                                                                    }}>
                                                                    {unit.batteryVoltage &&
                                                                        (unit.batteryVoltage / 1000).toFixed(1)}
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody
                                            style={{
                                                minHeight: 102,
                                                fontSize: 12,
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                animationName: fadeIn,
                                                animationDuration: '1.2s'
                                            }}>
                                            <tr>
                                                <td>
                                                    {intl.formatMessage({
                                                        id: 'comp.table.cell.empty'
                                                    })}
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, units }) => {
    const { theme, width } = app;
    const { authUser, site } = auth;
    const { lowBatteryUnits } = units;
    return { theme, width, authUser, site, lowBatteryUnits };
};

export default withRouter(injectIntl(connect(mapStateToProps, { fetchLowBatteryUnits })(DashboardLowBattery)));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            maxHeight: 219,
            height: 219
        },
        tallWrapper: {
            maxHeight: 359,
            height: 359
        },
        shortWrapper: {
            maxHeight: 110,
            height: 110
        },
        header: {
            color: theme.color.themeColor,
            margin: '0 0 10px 0'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        },
        table: {
            width: '100%',
            fontSize: 11
        },
        row: {
            borderBottom: '1px solid' + theme.color.borderColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        bold: {
            fontWeight: 600,
            marginLeft: 5,
            paddingTop: 4
        },
        text: {
            textTransform: 'capitalize'
        }
    });
