import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const editAccessCode = ({ unitUUID = '', userUUID = '', accessCode = '' }) => {
    return {
        type: CONSTANTS.EDIT_ACCESS_CODES,
        payload: {
            url: ENDPOINTS.ENDPOINT_ACCESS_CODES_EDIT,
            data: {
                unitUUID,
                userUUID,
                accessCode
            }
        }
    };
};

export const fetchAccessCodeList = (filter = ['active', 'inactive', 'error', 'pending', 'setup']) => {
    return {
        type: CONSTANTS.FETCH_ACCESS_CODES_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_ACCESS_CODES_LIST,
            filter,
            data: {}
        }
    };
};

export const fetchAccessCodeDetails = (accessCodeUUID = '') => {
    return {
        type: CONSTANTS.FETCH_ACCESS_CODES_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_ACCESS_CODES_DETAILS,
            data: { accessCodeUUID },
            list: {
                url: ENDPOINTS.ENDPOINT_ACCESS_CODES_LIST,
                data: {
                    siteUUID: JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                }
            }
        }
    };
};

export const setAccessCodeList = (data = []) => {
    return {
        type: CONSTANTS.SET_ACCESS_CODES_LIST,
        payload: data
    };
};

export const setAccessCodeDetails = (data = null) => {
    return {
        type: CONSTANTS.SET_ACCESS_CODES_DETAILS,
        payload: data
    };
};

export const setAccessCodeHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ACCESS_CODES_HEADERS,
        payload: data
    };
};

export const setKeypadsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_KEYPADS_HEADERS,
        payload: data
    };
};

export const setAccessCodeFilters = (data) => {
    return {
        type: CONSTANTS.SET_ACCESS_CODES_FILTERS,
        payload: data
    };
};

export const fetchDailyCodes = () => {
    return {
        type: CONSTANTS.FETCH_DAILY_CODES,
        payload: {
            url: '/site/accesscodes/daily/',
            data: {}
        }
    };
};

export const setDailyCodes = (data) => {
    return {
        type: CONSTANTS.SET_DAILY_CODES,
        payload: data
    };
};

export const sendSiteCodes = () => {
    return {
        type: CONSTANTS.SEND_SITE_CODES,
        payload: {
            url: '/site/accesscodes/resend/',
            data: {}
        }
    };
};

export const sendPendingSiteCodes = () => {
    return {
        type: CONSTANTS.SEND_PENDING_SITE_CODES,
        payload: {
            url: '/site/accesscodes/resend/pending/',
            data: {}
        }
    };
};

export const setAccessCodesStats = (data) => {
    return {
        type: CONSTANTS.SET_ACCESS_CODES_STATS,
        payload: data
    };
};
