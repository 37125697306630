import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { fadeInDown } from 'react-animations';

import * as ICONS from 'utilities/icons';

import { fetchUserNotifications } from 'actions/Notifications';

import ToolTips from 'components/ToolTips';
import Weather from 'components/Weather/weatherHeader';
class SimpleHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fire: false
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.showLoader !== this.props.showLoader && !this.props.showLoader) {
            setTimeout(() => {
                this.setState({ fire: false });
            }, 4000);
        }
    }
    checkURL = () => {
        const { location } = this.props;
        if (location.pathname.includes('app/all') || location.pathname.includes('app/home')) {
            return false;
        }
        return true;
    };
    refresh = () => {
        window.location.reload();
        this.setState({ fire: true });
    };
    linkFeedback = () => {
        let { history } = this.props;
        history.push('/app/feedback/all');
    };
    linkContact = () => {
        let { history } = this.props;
        history.push('/app/tenant/contact/all');
    };
    linkSites = () => {
        let { authUser, history } = this.props;
        authUser && authUser.permissions.includes('support_view_all_companies')
            ? history.push('/app/facilities/all')
            : history.push('/app/user/facilities/all');
    };
    render() {
        const { width, theme, site, authUser } = this.props;
        const { fire } = this.state;
        this.styles = styles(theme);
        return (
            <div className={`app-main-Simple ${css(this.styles.wrapper)}`}>
                <div className={`d-flex app-toolbar align-items-center`}>
                    <div className={css(this.styles.imageWrapper, this.styles.fadeInDown)} onClick={this.switchURL}>
                        <img
                            alt={theme.title}
                            src={
                                theme && theme.menuLogo
                                    ? theme.menuLogo
                                    : 'https://storage.googleapis.com/janus-nokebucket/assets/images/Themes/Demo/logo.png'
                            }
                            className={css(this.styles.image)}
                        />
                    </div>
                    {/* <ul className="header-notifications list-inline ml-0">
                        <li style={{ marginLeft: 5 }} className="list-inline-item app-tour">
                            { this.checkURL() ? <NavLink to={`/app/home/all`} replace>
                                <div id="userIcon" className={css(this.styles.avatar)}>
                                    <ICONS.HomeIcon color={theme.color.buttonFontColor} width={18} height={18} />
                                </div>
                        </NavLink> : null }
                        </li>
                    </ul> */}
                    <ul className="header-notifications list-inline ml-auto">
                        {this.checkURL() ? (
                            <NavLink to={`/app/home/all`} replace>
                                <div id="homeIcon" className={css(this.styles.avatar)}>
                                    <ICONS.HomeIcon color={theme.color.buttonFontColor} width={18} height={18} />
                                </div>
                                <ToolTips target="homeIcon" message="pages.home.Type" />
                            </NavLink>
                        ) : null}
                        {width > 952 && authUser && authUser.sites && authUser.sites[1] ? (
                            <li
                                onClick={this.linkSites}
                                className={css(this.styles.hoverButton)}
                                style={{
                                    background: theme.color.themeColor,
                                    color: theme.color.buttonFontColor,
                                    borderRadius: 4,
                                    padding: '6px 5px',
                                    marginRight: 15,
                                    fontFamily: 'Poppins'
                                }}>
                                <span>{site && site.name}</span>
                            </li>
                        ) : null}
                        {width > 800 ? (
                            <li className="list-inline-item mail-tour">
                                <Weather />
                            </li>
                        ) : null}
                        <NavLink to={`/app/users/update/${authUser.uuid}/info/${authUser.uuid}`} replace>
                            <div id="userIcon" className={css(this.styles.avatar)}>
                                <ICONS.UserIcon color={theme.color.buttonFontColor} width={18} height={18} />
                            </div>
                        </NavLink>
                        <li className="list-inline-item mail-tour">
                            <i
                                onClick={this.refresh}
                                className={`material-icons ${css(this.styles.icon, fire && this.styles.zippyHeader)}`}
                                id="autorenew">
                                autorenew
                            </i>
                        </li>
                        {width > 400 && authUser && authUser.role && authUser.role.tier > 0 ? (
                            <li className="list-inline-item mail-tour">
                                <div onClick={this.linkFeedback} id="feedback_icon" className={css(this.styles.hover)}>
                                    <ICONS.FileIcon height={22} width={22} color={theme.color.themeColor} />
                                </div>
                            </li>
                        ) : width > 400 ?
                            <li className="list-inline-item mail-tour">
                                <div onClick={this.linkContact} id="help_icon" className={css(this.styles.hover)}>
                                    <ICONS.HelpIcon height={22} width={22} color={theme.color.themeColor} />
                                </div>
                            </li>
                        : null}
                        <li className="list-inline-item mail-tour">
                            <div
                                onClick={() => this.props.history.push('/app/logout')}
                                id="logout"
                                className={css(this.styles.hover)}>
                                <ICONS.LoginIcon height={22} width={22} color={theme.color.themeColor} />
                            </div>
                            <ToolTips target="logout" message="comp.button.logout" />
                        </li>
                    </ul>
                </div>
                <ToolTips target="autorenew" message="tooltip.refresh" />
                <ToolTips target="userIcon" message="pages.users.create.userInfo.title" />
                {width > 400 && authUser && authUser.role && authUser.role.tier > 0 && <ToolTips target="feedback_icon" message="comp.button.submit.tickets" />}
                {width > 400 && authUser && authUser.role && authUser.role.tier == 0 && <ToolTips target="help_icon" message="pages.contact.header" />}
            </div>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { site, authUser } = auth;
    const { width, theme, showLoader } = app;
    return { site, authUser, width, theme, showLoader };
};

export default injectIntl(withRouter(connect(mapStateToProps, { fetchUserNotifications })(SimpleHeader)));

const translateKeyframes = {
    '100%': {
        transform: 'rotate(360deg)'
    }
};

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.headerColor
        },
        icon: {
            fontSize: 24,
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        iconView: {
            fontSize: 27,
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        zippyHeader: {
            animationName: [translateKeyframes],
            animationDuration: '2s, 1200ms',
            animationIterationCount: 'infinite'
        },
        hover: {
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        imageWrapper: {
            backgroundColor: theme.color.headerColor,
            overflow: 'hidden'
        },
        image: {
            height: 52,
            padding: '0px 0px',
            borderRadius: 5,
            backgroundColor: theme.color.buttonFontColor
        },
        fadeInDown: {
            animationName: fadeInDown,
            animationDuration: '1.2s'
        },
        linkSites: {
            marginTop: -2
        },
        hoverButton: {
            boxShadow: theme.color.boxShadowIntense,
            ':hover': {
                boxShadow: theme.color.boxShadowIntense,
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        avatar: {
            display: 'inline-block',
            position: 'relative',
            top: '4.5px',
            backgroundColor: theme.color.themeColor,
            width: 30,
            height: 30,
            borderRadius: '50%',
            padding: 6,
            marginRight: 10
        }
    });
