import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
class HeaderBackButton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { intl, theme, margin, history, action, width } = this.props;
        this.styles = styles(theme, margin);
        const url = window.location.href;

        return width > 500 || url.includes('app/help') ? (
            <Button
                onClick={() => (action ? action() : history.goBack())}
                outline
                style={{ color: theme.color.themeColor }}
                className={css(this.styles.button)}>
                <i className={`material-icons ${css(this.styles.icon)}`}>arrow_back_ios</i>
                <span className={css(this.styles.backLink)}>{intl.formatMessage({ id: 'comp.button.back' })}</span>
            </Button>
        ) : (
            <div
                style={{
                    display: 'inline-block',
                    paddingLeft: url.includes('app/settings') ? 10 : 0
                }}>
                <div className={css(this.styles.iconBack)} onClick={() => (action ? action() : history.goBack())}>
                    <i className={`material-icons ${css(this.styles.iconSmall)}`}>arrow_back_ios</i>
                </div>
            </div>
        );
    }
}

HeaderBackButton.defaultProps = {
    margin: '0 0 0 0'
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default injectIntl(withRouter(connect(mapStateToProps)(HeaderBackButton)));

const styles = (theme, margin) =>
    StyleSheet.create({
        button: {
            margin,
            padding: 0,
            fontSize: 12,
            backgroundColor: theme && theme.color.headerColor,
            border: 'none',
            ':hover': {
                opacity: '.5'
            }
        },
        backLink: {
            fontWeight: 400,
            fontSize: 14,
            marginLeft: 0
        },
        icon: {
            fontSize: 18
        },
        iconBack: {
            background: theme && theme.color.themeColor,
            color: theme && theme.color.buttonFontColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            height: 25,
            width: 25
        },
        iconSmall: {
            fontSize: 14,
            paddingLeft: 5,
            paddingTop: 3
        }
    });
