import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as CONSTANTS from 'constants';
import * as ACTIONS_TUTORIALS from 'actions/Tutorials';
import * as ACTIONS_APP from 'actions/App';

const getSeenTutorials = (state) => state.tutorials.userSeenTutorials;
const getAuthUser = (state) => state.auth.authUser;
const getSite = (state) => state.auth.site;

function* requestTutorialList({ payload }) {
    try {
        yield put(ACTIONS_TUTORIALS.setTutorials(null));
        const tutorials = yield call(API.POST, payload.url, payload.data);
        if (!tutorials || !tutorials.data || !tutorials.data.featureData) {
            yield put(ACTIONS_TUTORIALS.setTutorials([]));
        } else {
            let authUser = yield select(getAuthUser);
            let site = yield select(getSite);
            let compUUID = localStorage.getItem('nse_login_data') && JSON.parse(localStorage.getItem('nse_login_data')).companyUUID;
            let filteredTutorials = authUser && authUser.role.tier == 1 ? tutorials.data.featureData : (site || compUUID) ? tutorials.data.featureData.filter(tutorial => tutorial.companyUUIDs.length > 0).filter(tutorial => tutorial.companyUUIDs.includes(site ? site.companyUUID : compUUID)) : [];
            filteredTutorials = UTILS.sortList(true, filteredTutorials, 'createdAt');
            yield put(ACTIONS_TUTORIALS.setTutorials(filteredTutorials));
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_TUTORIALS.setTutorials([]));
    }
}

function* requestCreateTutorial({ payload }) {
    try {
        const create = yield call(API.POST, payload.url, payload.data);
        if (!create) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
            yield history.push('/app/help/tutorials/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateTutorial({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.push('/app/help/tutorials/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteTutorial({ payload }) {
    try {
        const tutorial = yield call(API.POST, payload.url, payload.data);
        if (!tutorial) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield history.push('/app/help/tutorials/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserTutorialList({ payload }) {
    try {
        yield put(ACTIONS_TUTORIALS.setUserTutorials(null));
        yield put(ACTIONS_TUTORIALS.setShowTutorials(false));
        const tutorials = yield call(API.POST, payload.url, {});
        if (!tutorials || !tutorials.data || !tutorials.data.featureData) {
            yield put(ACTIONS_TUTORIALS.setUserTutorials([]));
            yield put(ACTIONS_TUTORIALS.setShowTutorials(false));
        } else {
            let authUser = yield select(getAuthUser);
            let site = yield select(getSite);
            let compUUID = localStorage.getItem('nse_login_data') && JSON.parse(localStorage.getItem('nse_login_data')).companyUUID;
            let unseenTutorials = authUser.tutorialUUIDs
                ? tutorials.data.featureData.filter(
                      (tutorial) => !authUser.tutorialUUIDs.includes(tutorial.tutorialUUID)
                  )
                : [];
            let filteredUnseenTutorials = authUser && authUser.role.tier == 1 ? unseenTutorials : (site || compUUID) ? unseenTutorials.filter(tutorial => tutorial.companyUUIDs.length > 0).filter(tutorial => tutorial.companyUUIDs.includes(site ? site.companyUUID : compUUID)) : [];
            if (filteredUnseenTutorials && filteredUnseenTutorials.length > 0) {
                yield put(ACTIONS_TUTORIALS.setShowTutorials(true));
                filteredUnseenTutorials = UTILS.sortList(true, filteredUnseenTutorials, 'createdAt');
                yield put(ACTIONS_TUTORIALS.setUserTutorials(filteredUnseenTutorials));
                yield put(
                    filteredUnseenTutorials[0] &&
                        ACTIONS_TUTORIALS.updateUserTutorial({
                            tutorialUUID: filteredUnseenTutorials[0].tutorialUUID,
                            userUUID: authUser.uuid
                        })
                );
            } else {
                yield put(ACTIONS_TUTORIALS.setUserTutorials(null));
                yield put(ACTIONS_TUTORIALS.setShowTutorials(false));
            }
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_TUTORIALS.setUserTutorials([]));
        yield put(ACTIONS_TUTORIALS.setShowTutorials(false));
    }
}

function* requestUpdateUserTutorial({ payload }) {
    let seenTutorials = yield select(getSeenTutorials);
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            if (!seenTutorials.includes(payload.data.tutorialUUID)) {
                seenTutorials.push(payload.data.tutorialUUID);
                yield put(ACTIONS_TUTORIALS.setSeenTutorials(seenTutorials));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectTutorialsList() {
    yield takeEvery(CONSTANTS.FETCH_TUTORIALS, requestTutorialList);
}
export function* selectCreateTutorial() {
    yield takeEvery(CONSTANTS.CREATE_TUTORIAL, requestCreateTutorial);
}
export function* selectUpdateTutorial() {
    yield takeEvery(CONSTANTS.UPDATE_TUTORIAL, requestUpdateTutorial);
}
export function* selectDeleteTutorial() {
    yield takeEvery(CONSTANTS.DELETE_TUTORIAL, requestDeleteTutorial);
}
export function* selectUserTutorialsList() {
    yield takeEvery(CONSTANTS.FETCH_USER_TUTORIALS, requestUserTutorialList);
}
export function* selectUpdateUserTutorial() {
    yield takeEvery(CONSTANTS.UPDATE_USER_TUTORIAL, requestUpdateUserTutorial);
}

export default function* rootSaga() {
    yield all([
        fork(selectTutorialsList),
        fork(selectCreateTutorial),
        fork(selectUpdateTutorial),
        fork(selectDeleteTutorial),
        fork(selectUserTutorialsList),
        fork(selectUpdateUserTutorial)
    ]);
}
