import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { fadeIn } from 'react-animations';

import { HelpIcon } from 'utilities/icons';
import { removeAllToolTips } from 'utilities';

import HeaderFilter from 'components/Header/table/filter';
import Search from 'components/Search';
import ToolTips from 'components/ToolTips';
import Filters from 'components/Filters/sites/export';
import FiltersDocumentation from 'components/Filters/sites/documentation';
import Saving from 'components/Information/saving';

class HeaderTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTimeframeSelector: false,
        }
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    // set showTimeframeSelector to false when element is clicked outside of popup
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ showTimeframeSelector: false });
        }
    }
    // set the wrapper ref (for clicking outside element)
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    toggleTimeframeSelector = () => {
        this.setState({showTimeframeSelector: !this.state.showTimeframeSelector});
    }
    clickTimeframe = (value) => {
        const { timeframeToggler } = this.props;
        value && timeframeToggler(value);
        this.toggleTimeframeSelector();
    }
    timeframeSelected = (value) => {
        const { timeframeSelection } = this.props;
        return value === timeframeSelection;
    }

    render() {
        const { showTimeframeSelector } = this.state;
        const {
            intl,
            theme,
            autoFocus,
            title,
            filter,
            children,
            Icon,
            total,
            subTitle,
            search,
            data,
            update,
            action,
            width,
            headers,
            manage,
            icon,
            titleOverride,
            showFilters,
            showFiltersDocumentation,
            filterList,
            link,
            headerInfo,
            border,
            titleMargin,
            iconPadding,
            marginBottom,
            marginTop,
            titleWeight,
            textTransform,
            showSavingStatus,
            timeframeSelection
        } = this.props;
        this.styles = styles(
            theme,
            border,
            titleMargin,
            iconPadding,
            marginBottom,
            marginTop,
            titleWeight,
            textTransform
        );
        let active = filter && filter.find((filt) => filt.active);

        return (
            <div className={css(this.styles.wrapper)}>
                {width > 800 && Icon && <div className={css(this.styles.iconWrapper)}>{Icon}</div>}
                {titleOverride ? (
                    <h2
                        className={link ? css(this.styles.titleLink) : css(this.styles.title)}
                        onClick={() => link && this.props.history.push(link)}>
                        {titleOverride}
                    </h2>
                ) : (
                    <h2
                        className={link ? css(this.styles.titleLink) : css(this.styles.title)}
                        onClick={() => link && this.props.history.push(link)}>
                        {intl.formatMessage({ id: title })} {children}
                    </h2>
                )}
                {headerInfo && (
                    <div className={css(this.styles.headerInfo)}>
                        <div id={String(headerInfo.replace(/[ ,.]/g, '') + '-' + title.replace(/[ ,.]/g, ''))}>
                            <HelpIcon width={16} height={16} color={theme.color.themeColor} />
                        </div>
                        <ToolTips
                            target={String(headerInfo.replace(/[ ,.]/g, '') + '-' + title.replace(/[ ,.]/g, ''))}
                            delay={{ show: 250, hide: 250 }}
                            message={headerInfo}
                        />
                    </div>
                )}
                {timeframeSelection && 
                    <div className={css(this.styles.timeframeSelector)} ref={this.setWrapperRef}>
                        <div className={css(this.styles.timeframeSelectorContainer)} onClick={this.toggleTimeframeSelector}>
                            <div className={css(this.styles.timeframeText)}>
                                {timeframeSelection === '24h' ? intl.formatMessage({id: `form.select.last.24.short`}) : 
                                timeframeSelection === '7d' ? intl.formatMessage({id: `form.select.last.7.short`}) : 
                                timeframeSelection === '1m' ? intl.formatMessage({id: `form.select.last.30.short`}) : 
                                timeframeSelection}
                            </div>
                            <div className={css(this.styles.timeframeArrow)}>
                                <span className={ css(this.styles.timeframeArrowSpan) + ' material-icons'}>expand_more</span>
                            </div>
                        </div>
                        {showTimeframeSelector && <div className={css(this.styles.timeframeDropdown)}>
                            <div onClick={() => this.clickTimeframe('24h')} className={css(this.styles.timeframeDropdownItem, this.timeframeSelected('24h') && this.styles.timeframeDropdownItemActive)}>
                                {intl.formatMessage({id: `form.select.last.24`})}
                            </div>
                            <div onClick={() => this.clickTimeframe('7d')} className={css(this.styles.timeframeDropdownItem, this.timeframeSelected('7d') && this.styles.timeframeDropdownItemActive)}>
                                {intl.formatMessage({id: `form.select.last.7`})}
                            </div>
                            <div onClick={() => this.clickTimeframe('1m')} className={css(this.styles.timeframeDropdownItem, this.timeframeSelected('1m') && this.styles.timeframeDropdownItemActive)}>
                                {intl.formatMessage({id: `form.select.last.30`})}
                            </div>
                        </div>}
                    </div>}
                {filter && <HeaderFilter data={filter} action={action} />}
                {subTitle && (
                    <div className={css(this.styles.subInfo)}>
                        <p className={css(this.styles.total)}>
                            {subTitle && intl.formatMessage({ id: subTitle })}: {total}
                        </p>
                    </div>
                )}
                {manage &&
                    manage.map((item, i) => (
                        <div
                            className={css(this.styles.linkWrapper)}
                            key={`${i} MANAGE ${item.link ? item.link : item.icon ? item.icon : i}`}>
                            {item.action ? (
                                <div
                                    onClick={() => {
                                        item.action();
                                        removeAllToolTips();
                                    }}
                                    className={css(this.styles.link)}>
                                    <i
                                        id={item.icon ? item.icon : icon}
                                        className="material-icons"
                                        style={{ fontSize: 24, marginTop: item.icon === 'get_app' ? 1 : 0 }}>
                                        {item.icon ? item.icon : icon}
                                    </i>
                                    {item.text && <ToolTips target={item.icon} message={item.text} />}
                                </div>
                            ) : (
                                <Link to={item.link} className={css(this.styles.link)}>
                                    <i
                                        id={item.icon ? item.icon : icon}
                                        className="material-icons"
                                        style={{ fontSize: 24, marginTop: item.icon === 'get_app' ? 1 : 0 }}>
                                        {item.icon ? item.icon : icon}
                                    </i>
                                    {item.text && <ToolTips target={item.icon} message={item.text} />}
                                </Link>
                            )}
                        </div>
                    ))}
                {showSavingStatus && <Saving />}
                {search && data && (
                    <div className={css(this.styles.search)}>
                        <Search
                            update={update}
                            filter={headers}
                            searchTitle={active.title}
                            searchType={headers}
                            data={data}
                            autoFocus={autoFocus}
                        />
                    </div>
                )}
                {showFilters && <Filters update={update} filterList={filterList} data={data} />}
                {showFiltersDocumentation && (
                    <FiltersDocumentation update={update} filterList={filterList} data={data} />
                )}
            </div>
        );
    }
}

HeaderTable.defaultProps = {
    title: 'blank',
    border: true,
    Icon: null,
    total: 0,
    subTitle: null,
    search: false,
    filter: false,
    manage: false,
    icon: 'add_circle_outline',
    headers: [],
    titleMargin: 3,
    iconPadding: 0,
    marginBottom: 0,
    marginTop: 0,
    titleOverride: false,
    autoFocus: true,
    titleWeight: 400,
    textTransform: 'uppercase',
    showSavingStatus: false
};

const mapStateToProps = ({ app }) => {
    const { theme, width } = app;
    return { theme, width };
};

export default withRouter(injectIntl(connect(mapStateToProps)(HeaderTable)));

const styles = (theme, border, titleMargin, iconPadding, marginBottom, marginTop, titleWeight, textTransform) =>
    StyleSheet.create({
        wrapper: {
            paddingBottom: border ? 5 : 0,
            marginBottom,
            marginTop,
            borderBottom: border ? `solid 1px ${theme.color.borderColor}` : 'none',
            minHeight: 40,
            textAlign: 'left',
            position: 'relative'
        },
        title: {
            marginTop: titleMargin,
            fontSize: 16,
            display: 'block',
            float: 'left',
            textTransform: textTransform,
            color: theme.color.fontColor,
            fontWeight: titleWeight
        },
        titleLink: {
            marginTop: titleMargin,
            fontSize: 16,
            display: 'block',
            float: 'left',
            textTransform: textTransform,
            color: theme.color.fontColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            fontWeight: titleWeight,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        subInfo: {
            display: 'block',
            float: 'right',
            textAlign: 'right'
        },
        total: {
            margin: 0
        },
        iconWrapper: {
            display: 'block',
            float: 'left',
            fontSize: 22,
            position: 'relative',
            marginRight: 10,
            paddingTop: iconPadding
        },
        search: {
            float: 'right'
        },
        linkWrapper: {
            float: 'right',
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        saving: {
            display: 'flex',
            flexDirection: 'row',
            float: 'right',
            animationName: fadeIn,
            animationDuration: '1.2s',
            marginRight: 5
        },
        link: {
            color: theme.color.themeColor,
            fontSize: 14,
            fontWeight: 400,
            margin: '0 3px',
            float: 'right',
            ':hover': {
                textDecoration: 'none'
            }
        },
        headerInfo: {
            float: 'left',
            margin: '6px 0 0 8px',
            ':hover': {
                opacity: 0.8
            }
        },
        timeframeSelector: {
            position: 'relative',
            float: 'right',
            borderRadius: 5,
            margin: '0 3px',
            background: theme && theme.color.themeColor,
            color: theme && theme.color.buttonFontColor,
            width: 48,
            display: 'grid',
            placeItems: 'center',
            padding: 3,
            paddingBottom: 0,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
            }
        },
        timeframeSelectorContainer: {
            display: 'flex',
            alignItems: 'center',
            ':hover': {
                opacity: '.8'
            }
        },
        timeframeText: {
            textTransform: 'uppercase',
            fontSize: 13,
            fontFamily: 'Poppins',
            marginBottom: 3,
        },
        timeframeArrow: {
            
        },
        timeframeArrowSpan: {
            fontSize: 16,
        },
        timeframeDropdown: {
            position: 'absolute',
            top: 30,
            right: 0,
            backgroundColor: theme && theme.color.headerColor,
            zIndex: 5,
            borderRadius: 5,
            border: `1px solid ${theme && theme.color.borderColor}`,
            width: 125,
            color: theme && theme.color.fontColor,
            boxShadow: '3px 3px 10px 1px rgba(0,0,0,0.07)',
        },
        timeframeDropdownItem: {
            padding: '5px 7px',
            borderBottom: `1px solid ${theme && theme.color.borderColor}`,
            transition: 'background 0.2s ease-in-out',
            ':hover': {
                background: theme && theme.color.pageColor,
            },
            ':last-child': {
                borderBottom: 'none',
            }
        },
        timeframeDropdownItemActive: {
            color: theme && theme.color.themeColor,
            fontWeight: 600,
        }
    });
