import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from 'rmc-drawer';
import { StyleSheet, css } from 'aphrodite';

import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants';
import { toggleCollapsedNav } from 'actions/App';

import MenuList from 'components/Menus/sidebar/list';
import MenuFooter from 'components/Menus/sidebar/footer';
import MenuHeader from 'components/Menus/sidebar/header';
class Menu extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    onToggleCollapsedNav = () => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };
    render() {
        const { navCollapsed, drawerType, width, isDirectionRTL, navigationStyle, theme, authUser } = this.props;
        this.styles = styles(theme);
        let drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? 'd-xl-flex'
            : drawerType.includes(COLLAPSED_DRAWER)
            ? ''
            : 'd-flex';
        let type = true;
        if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
            type = false;
        }
        if (navigationStyle === HORIZONTAL_NAVIGATION) {
            drawerStyle = '';
            type = false;
        }
        return (
            <Drawer
                docked={type}
                className={`app-sidebar ${drawerStyle}`}
                style={{ overflow: 'hidden', userSelect: 'none' }}
                touch={true}
                position={isDirectionRTL ? 'right' : 'left'}
                transitions={true}
                enableDragHandle={true}
                open={navCollapsed}
                onOpenChange={this.onToggleCollapsedNav}
                sidebar={
                    <div className={`side-nav ${css(this.styles.wrapper)}`}>
                        <MenuHeader alt={theme.title} src={theme.menuLogo} />
                        <div data-id="sidebar" style={{ overflowY: 'auto', height: 'calc(100% - 50px)' }}>
                            <div style={{ minHeight: 'calc(100% - 190px)' }}>
                                <MenuList authUser={authUser} />
                            </div>
                            <MenuFooter />
                        </div>
                    </div>
                }>
                <div />
            </Drawer>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { authUser } = auth;
    const { navCollapsed, drawerType, width, isDirectionRTL, navigationStyle, theme } = app;
    return { navCollapsed, drawerType, width, isDirectionRTL, navigationStyle, theme, authUser };
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav })(Menu));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            backgroundColor: theme.color.menuColor,
            borderRight: 'none'
        }
    });
