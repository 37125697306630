import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { UnitIcon } from 'utilities/icons';

import CellIcon from 'components/Table/cell/components/icon';
import CellText from 'components/Table/cell/components/text';
import CellStatus from 'components/Table/cell/components/status';
class AbnormalTempUnitsCell extends React.Component {
    render() {
        const { intl, data, width, theme, authUser } = this.props;
        const { name, rentalState, locks } = data;
        return (
            <>
                {width > 800 && (
                    <CellIcon
                        Cellwidth={'50px'}
                        Icon={UnitIcon}
                        backgroundColor={rentalState === 'available' ? '#3ac923' : theme.color.themeColor}
                        color={theme.color.buttonFontColor}
                        fill={rentalState === 'available' ? '#3ac923' : theme.color.themeColor}
                        stroke={2.5}
                    />
                )}
                <CellText
                    Cellwidth={'150px'}
                    Title={name && name !== "" ? name : intl.formatMessage({ id: `form.na` })}
                    Description={
                        locks &&
                        locks[0] &&
                        locks[0].temperature &&
                        authUser &&
                        authUser.temperatureScale === 'fahrenheit'
                            ? `${Math.round((locks[0].temperature * 9) / 5 + 32).toFixed(0)}°F`
                            : `${locks[0].temperature}°C`
                    }
                    textAlign={'left'}
                    TitleColor={true}
                />
                <CellStatus
                    input={rentalState}
                    checker={rentalState === 'available'}
                    badColor={theme.color.themeColor}
                />
            </>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { width, theme } = app;
    const { authUser } = auth;
    return { width, theme, authUser };
};

export default injectIntl(connect(mapStateToProps)(AbnormalTempUnitsCell));
