//||||||||||||||||||||||||||||||||||||||||
//|| CREATE TUTORIAL                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_CREATE_TUTORIAL = '/tutorial/create/';
export const PURPOSE_CREATE_TUTORIAL = 'Create a tutorial';
export const PERMISSIONS_CREATE_TUTORIAL = ['support_manage_tutorials'];
export const REQUIREMENTS_CREATE_TUTORIAL = ['Token Required'];
export const PARAMETER_CREATE_TUTORIAL = {
    featureTitle: '',
    featureDescription: '',
    gifLinkWeb: '',
    gifLinkApp: ''
};
export const RETURN_CREATE_TUTORIAL = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| TUTORIAL LIST                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_TUTORIAL_LIST = '/tutorial/read/';
export const PURPOSE_TUTORIAL_LIST = 'Fetch a list of all tutorials.';
export const PERMISSIONS_TUTORIAL_LIST = [];
export const REQUIREMENTS_TUTORIAL_LIST = ['Token Required'];
export const PARAMETER_TUTORIAL_LIST = {};
export const RETURN_TUTORIAL_LIST = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UPDATE TUTORIAL                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UPDATE_TUTORIAL = '/tutorial/update/';
export const PURPOSE_UPDATE_TUTORIAL = 'Update a release note';
export const PERMISSIONS_UPDATE_TUTORIAL = ['support_manage_tutorials'];
export const REQUIREMENTS_UPDATE_TUTORIAL = ['Token Required'];
export const PARAMETER_UPDATE_TUTORIAL = {
    versionID: '',
    featureTitle: '',
    featureDescription: '',
    externalLink: '',
    imageLink: '',
    releaseDate: '',
    tags: []
};
export const RETURN_UPDATE_TUTORIAL = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| DELETE TUTORIAL                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_DELETE_TUTORIAL = '/tutorial/delete/';
export const PURPOSE_DELETE_TUTORIAL = 'Delete a release note';
export const PERMISSIONS_DELETE_TUTORIAL = ['support_manage_tutorials'];
export const REQUIREMENTS_DELETE_TUTORIAL = ['Token Required'];
export const PARAMETER_DELETE_TUTORIAL = {
    id: 0
};
export const RETURN_DELETE_TUTORIAL = {
    result: 'success'
};

//||||||||||||||||||||||||||||||||||||||||
//|| UPDATE USER TUTORIAL               ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_UPDATE_USER_TUTORIAL = '/tutorial/update/user/';
export const PURPOSE_UPDATE_USER_TUTORIAL = 'Update a tutorail to show it is viewed';
export const PERMISSIONS_UPDATE_USER_TUTORIAL = [];
export const REQUIREMENTS_UPDATE_USER_TUTORIAL = ['Token Required'];
export const PARAMETER_UPDATE_USER_TUTORIAL = {
    userID: '',
    tutorialID: ''
};
export const RETURN_UPDATE_USER_TUTORIAL = {
    result: 'success'
};
