//||||||||||||||||||||||||||||||||||||||||
//|| SUPPORT LOGS                       ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SUPPORT_LOGS = '/support/logs/';
export const PURPOSE_SUPPORT_LOGS = 'Fetch support logs.';
export const PERMISSIONS_SUPPORT_LOGS = ['support_view_logs'];
export const REQUIREMENTS_SUPPORT_LOGS = ['Token Required'];
export const PARAMETER_SUPPORT_LOGS = {};
export const RETURN_SUPPORT_LOGS = {};

//||||||||||||||||||||||||||||||||||||||||
//|| SUPPORT TROUBLESHOOT LIST          ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_TROUBLESHOOT_LIST = '/error/read/all/';
export const PURPOSE_TROUBLESHOOT_LIST = 'Fetch error list.';
export const PERMISSIONS_TROUBLESHOOT_LIST = [];
export const REQUIREMENTS_TROUBLESHOOT_LIST = ['Token Required'];
export const PARAMETER_TROUBLESHOOT_LIST = {};
export const RETURN_TROUBLESHOOT_LIST = {};

//||||||||||||||||||||||||||||||||||||||||
//|| SITE SYNC STATUS                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SITE_SYNC_STATUS = '/support/site/sync/status/';
export const PURPOSE_SITE_SYNC_STATUS = 'Fetch support site sync status.';
export const PERMISSIONS_SITE_SYNC_STATUS = [];
export const REQUIREMENTS_SITE_SYNC_STATUS = ['Token Required'];
export const PARAMETER_SITE_SYNC_STATUS = {
    siteId: 0
};
export const RETURN_SITE_SYNC_STATUS = {};

//||||||||||||||||||||||||||||||||||||||||
//|| SITE SYNC UNITS                    ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SITE_SYNC_UNITS = '/site/sync/unitdetails/';
export const PURPOSE_SITE_SYNC_UNITS = 'Sync support site units.';
export const PERMISSIONS_SITE_SYNC_UNITS = [];
export const REQUIREMENTS_SITE_SYNC_UNITS = ['Token Required'];
export const PARAMETER_SITE_SYNC_UNITS = {
    siteId: 0
};
export const RETURN_SITE_SYNC_UNITS = {};

//||||||||||||||||||||||||||||||||||||||||
//|| SUPPORT ACTIVITY                   ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SUPPORT_ACTIVITY = '/support/logs/get/';
export const PURPOSE_SUPPORT_ACTIVITY = 'Fetch support activity logs.';
export const PERMISSIONS_SUPPORT_ACTIVITY = ['support_view_support_logs'];
export const REQUIREMENTS_SUPPORT_ACTIVITY = ['Token Required'];
export const PARAMETER_SUPPORT_ACTIVITY = {};
export const RETURN_SUPPORT_ACTIVITY = {};

//||||||||||||||||||||||||||||||||||||||||||||
//|| SUPPORT SEND TRAINING ACKNOWLEDGEMENT  ||
//||||||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SEND_TRAINING_ACKNOWLEDGEMENT = '/support/user/send/digital/ack/site/';
export const PURPOSE_SEND_TRAINING_ACKNOWLEDGEMENT = 'Send digital acknowledgement for training.';
export const PERMISSIONS_SEND_TRAINING_ACKNOWLEDGEMENT = ['support_send_digital_ack'];
export const REQUIREMENTS_SEND_TRAINING_ACKNOWLEDGEMENT = ['Token Required'];
export const PARAMETER_SEND_TRAINING_ACKNOWLEDGEMENT = {
    userId: 0,
    acknowledged: false
};
export const RETURN_SEND_TRAINING_ACKNOWLEDGEMENT = {};

//||||||||||||||||||||||||||||||||||||||||||||
//|| SUPPORT SUPPORT TICKET                 ||
//||||||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_SEND_SUPPORT_TICKET = '/hubspot/form/submit/';
export const PURPOSE_SEND_SUPPORT_TICKET = 'Submit a support ticket from the user.';
export const PERMISSIONS_SEND_SUPPORT_TICKET = [];
export const REQUIREMENTS_SEND_SUPPORT_TICKET = ['Token Required'];
export const PARAMETER_SEND_SUPPORT_TICKET = {
    tenantName: '',
    ticketDescription: '',
    productIssue: [],
    contactMethod: ''
};
export const RETURN_SEND_SUPPORT_TICKET = {};
