import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { StyleSheet, css } from 'aphrodite';
import DecisionTree from 'components/HelpWizard/DecisionTree/';
import ToolTips from 'components/ToolTips';
import Loader from 'react-loader-spinner';

import { fadeIn } from 'react-animations';

import { QuestionMarkLogo } from 'utilities/icons';
import { lightenDarkenColor } from 'utilities';

import { getWizardList } from 'actions/HelpWizard';
class HelpWizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeWizard: null
        };
    }
    componentDidMount() {
        const { getWizardList } = this.props;
        getWizardList();
    }
    clickBackButton = () => {
        this.setState({ activeWizard: null });
        this.props.toggleHelpWizardInProgress();
    };
    clickWizardListItem = (wizard) => {
        this.setState({ activeWizard: wizard });
        this.props.toggleHelpWizardInProgress();
    };
    render() {
        const { intl, theme, helperMinimized, toggleHelperMinimized, toggleShowHelper, wizardList } = this.props;
        const { activeWizard } = this.state;
        this.styles = styles(theme);
        return (
            <div
                className={css(this.styles.wrapper, this.styles.fadeIn)}
                style={{
                    display: helperMinimized ? 'none' : 'block',
                    height: !activeWizard ? 501 : null
                }}>
                <div className={css(this.styles.wizardHeader)}>
                    <div className={css(this.styles.wizardIconContainer)}>
                        <QuestionMarkLogo width={78} height={78} fill="white" />
                    </div>
                    {activeWizard && (
                        <div className={css(this.styles.controlsLeft)} onClick={this.clickBackButton}>
                            <i id="goBackIcon" className="material-icons" style={{ fontSize: 19, cursor: 'pointer' }}>
                                chevron_left
                            </i>
                            <div className={css(this.styles.backBtnText)}>
                                {intl.formatMessage({ id: 'comp.button.back' })}
                            </div>
                        </div>
                    )}
                    <div className={css(this.styles.controlsRight)}>
                        {activeWizard && (
                            <React.Fragment>
                                <i
                                    onClick={activeWizard ? toggleHelperMinimized : toggleShowHelper}
                                    id="minimizeHelper"
                                    className="material-icons"
                                    style={{ fontSize: 16, cursor: 'pointer' }}>
                                    minimize
                                </i>
                                <ToolTips
                                    placement="bottom-start"
                                    target="minimizeHelper"
                                    message="tooltip.helpWizard.minimize"
                                />
                            </React.Fragment>
                        )}
                        <i
                            onClick={toggleShowHelper}
                            id="closeHelper"
                            className="material-icons"
                            style={{ fontSize: 16, cursor: 'pointer', marginLeft: 3 }}>
                            close
                        </i>
                        <ToolTips placement="bottom-start" target="closeHelper" message="tooltip.helpWizard.close" />
                    </div>
                    <div className={css(this.styles.headerText)}>
                        {activeWizard ? (
                            <div
                                className={css(this.styles.headerTextActiveName)}
                                style={{ fontSize: activeWizard.name.length > 93 ? 13 : 18 }}>
                                {activeWizard.name}
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className={css(this.styles.headerTextTitle)}>
                                    {intl.formatMessage({ id: 'comp.helpWizard.title' })}
                                </div>
                                <div className={css(this.styles.headerTextSubtitle)}>
                                    {intl.formatMessage({ id: 'comp.helpWizard.subtitle' })}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                {activeWizard ? (
                    <DecisionTree activeWizard={activeWizard} toggleHelperMinimized={toggleHelperMinimized} />
                ) : wizardList && wizardList.length > 0 ? (
                    <div className={css(this.styles.wizardsListArea, this.styles.fadeIn)}>
                        <div className={css(this.styles.wizardsListAreaLeft)}>
                            {wizardList &&
                                wizardList.map(
                                    (wizard, i) =>
                                        i % 2 === 0 && (
                                            <div
                                                className={css(this.styles.wizardItem)}
                                                key={`wizard-item-${i}`}
                                                onClick={() => this.clickWizardListItem(wizard)}>
                                                {wizard.name}
                                            </div>
                                        )
                                )}
                        </div>
                        <div className={css(this.styles.wizardsListAreaRight)}>
                            {wizardList &&
                                wizardList.map(
                                    (wizard, i) =>
                                        i % 2 !== 0 && (
                                            <div
                                                className={css(this.styles.wizardItem)}
                                                key={`wizard-item-${i}`}
                                                onClick={() => this.clickWizardListItem(wizard)}>
                                                {wizard.name}
                                            </div>
                                        )
                                )}
                        </div>
                    </div>
                ) : (
                    <div className={css(this.styles.loaderWrapper)}>
                        <Loader type={'ThreeDots'} color={theme.color.themeColor} width={70} height={40} />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ app, wizard }) => {
    const { theme } = app;
    const { wizardList } = wizard;
    return { theme, wizardList };
};

export default injectIntl(withRouter(connect(mapStateToProps, { getWizardList })(HelpWizard)));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            position: 'absolute',
            backgroundColor: theme.color.headerColor,
            borderRadius: 5,
            top: 65,
            right: 20,
            width: 409,
            maxHeight: 501,
            boxShadow: theme.color.boxShadow,
            border: `1px solid ${theme.color.borderColor}`,
            '@media all and (max-width:470px), (max-height:575px)': {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                height: 'calc(100vh - 10px)',
                width: 'calc(100vw - 10px)',
                margin: 5,
                maxHeight: 'none'
            }
        },
        wizardHeader: {
            position: 'relative',
            display: 'grid',
            placeItems: 'center',
            background: `linear-gradient(336deg, #${lightenDarkenColor(
                theme.color.themeColor,
                11
            )} 0%, #${lightenDarkenColor(theme.color.themeColor, -11)} 100%)`,
            height: 110,
            color: 'white',
            borderRadius: '4px 4px 0 0',
            textAlign: 'center'
        },
        wizardIconContainer: {
            opacity: 0.2,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 15,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 78
        },
        controlsRight: {
            position: 'absolute',
            top: 5,
            right: 12,
            opacity: 0.9
        },
        controlsLeft: {
            cursor: 'pointer',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            opacity: 0.9,
            top: 5,
            left: 7
        },
        backBtnText: {
            position: 'relative',
            top: -1,
            paddingLeft: 2,
            fontFamily: 'Roboto',
            fontSize: 12,
            userSelect: 'none'
        },
        headerText: {
            fontFamily: 'Poppins',
            padding: '0 10px'
        },
        headerTextTitle: {
            fontSize: 22,
            fontWeight: 600
        },
        headerTextSubtitle: {
            fontSize: 14,
            fontWeight: 300
        },
        headerTextActiveName: {
            padding: '0 20px',
            lineHeight: 1.2,
            fontWeight: 300
        },
        wizardsListArea: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            padding: '20px 20px 0 20px',
            gridGap: 20,
            overflowY: 'auto',
            maxHeight: 'calc(100% - 110px)'
        },
        wizardItem: {
            fontFamily: 'Roboto',
            fontSize: 12,
            lineHeight: 1.5,
            background: theme.color.menuColor,
            color: theme.color.fontColor,
            padding: 10,
            borderRadius: 10,
            marginBottom: 12,
            cursor: 'pointer',
            transition: 'all 0.15s ease',
            ':hover': {
                background: theme.color.themeColor,
                color: 'white'
            }
        },
        loaderWrapper: {
            textAlign: 'center',
            height: 100,
            width: '100%',
            display: 'grid',
            placeItems: 'center'
        },
        fadeIn: {
            animationName: fadeIn,
            animationDuration: '0.3s'
        }
    });
