import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as ICONS from 'utilities/icons';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_ACTIVITY from 'actions/Activity';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const getActivityHeaders = (state) => state.activity.activityHeader;
const getSupportActivityFilters = (state) => state.activity.supportFilters;
const getActivityFilters = (state) => state.activity.activityFilters;
const getActivityList = (state) => state.activity.activityList;
const getUserTimezone = (state) => state.app.userTimezone;
const getAuthUser = (state) => state.auth.authUser;
const getSite = (state) => state.auth.site;

function* requestActivityList({ payload }) {
    try {
        let authUser = yield select(getAuthUser);
        let site = yield select(getSite);
        let userTimezone = yield select(getUserTimezone);
        let updatedPayload = JSON.parse(JSON.stringify(payload.data));
        let rangeStartDate =
            authUser && authUser.useSiteTime && site
                ? moment(updatedPayload.details.range.begin, 'YYYY-MM-DDTHH:mm:ss')
                      .tz(site.timeZone)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                : moment(updatedPayload.details.range.begin, 'YYYY-MM-DDTHH:mm:ss')
                      .tz(userTimezone)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        let rangeEndDate =
            authUser && authUser.useSiteTime && site
                ? moment(updatedPayload.details.range.end, 'YYYY-MM-DDTHH:mm:ss')
                      .tz(site.timeZone)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                : moment(updatedPayload.details.range.end, 'YYYY-MM-DDTHH:mm:ss')
                      .tz(userTimezone)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        updatedPayload.details.range.begin = rangeStartDate ? rangeStartDate : updatedPayload.details.range.begin;
        updatedPayload.details.range.end = rangeEndDate ? rangeEndDate : updatedPayload.details.range.end;
        if (payload.dash) {
            yield put(ACTIONS_ACTIVITY.setRecentUsers(null));
            yield put(ACTIONS_ACTIVITY.setRecentUnits(null));
            yield put(ACTIONS_ACTIVITY.setActivityDates(null));
        }
        let activityList = yield select(getActivityList);
        if (payload.page === 0 || payload.page === 1) {
            payload.type === 'unit'
                ? yield put(ACTIONS_ACTIVITY.setUnitActivityList(null))
                : payload.type === 'user'
                ? yield put(ACTIONS_ACTIVITY.setUserActivityList(null))
                : payload.type === 'entry'
                ? yield put(ACTIONS_ACTIVITY.setEntryActivityList(null))
                : yield put(ACTIONS_ACTIVITY.setActivityList(null));
            yield put(ACTIONS_ACTIVITY.setActivityPageCount(1));
        }

        // Defaults for everyone to see
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('unlock.accessed_offline_access_code')
            ? updatedPayload.subtype.push('unlock.accessed_offline_access_code')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('unlock.accessed_online_phone')
            ? updatedPayload.subtype.push('unlock.accessed_online_phone')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('unlock.accessed_online_phone_bt')
            ? updatedPayload.subtype.push('unlock.accessed_online_phone_bt')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('unlock.accessed_offline_phone')
            ? updatedPayload.subtype.push('unlock.accessed_offline_phone')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('unlock.accessed_offline_fob')
            ? updatedPayload.subtype.push('unlock.accessed_offline_fob')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('unlock.unlocked_mechanical_override')
            ? updatedPayload.subtype.push('unlock.unlocked_mechanical_override')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('unlock.lock.forced_entry')
            ? updatedPayload.subtype.push('lock.forced_entry')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.failed_unlock') &&
        !updatedPayload.subtype.includes('lock.failed_unlock.out_of_schedule')
            ? updatedPayload.subtype.push('lock.failed_unlock.out_of_schedule')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.failed_unlock') &&
        !updatedPayload.subtype.includes('lock.failed_unlock.overlocked')
            ? updatedPayload.subtype.push('lock.failed_unlock.overlocked')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.failed_unlock') &&
        !updatedPayload.subtype.includes('lock.failed_unlock.gatelocked')
            ? updatedPayload.subtype.push('lock.failed_unlock.gatelocked')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.failed_unlock') &&
        !updatedPayload.subtype.includes('lock.failed_unlock.blacklisted')
            ? updatedPayload.subtype.push('lock.failed_unlock.blacklisted')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.failed_unlock') &&
        !updatedPayload.subtype.includes('lock.failed_unlock.invalid_accesscode')
            ? updatedPayload.subtype.push('lock.failed_unlock.invalid_accesscode')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.failed_unlock') &&
        !updatedPayload.subtype.includes('lock.failed_unlock.invalid_accesscode.out_of_zone')
            ? updatedPayload.subtype.push('lock.failed_unlock.invalid_accesscode.out_of_zone')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('unlock.accessed_online_phone_mesh')
            ? updatedPayload.subtype.push('unlock.accessed_online_phone_mesh')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('lock.override.use')
            ? updatedPayload.subtype.push('lock.override.use')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('lock.override.request')
            ? updatedPayload.subtype.push('lock.override.request')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('note.all') &&
        !updatedPayload.subtype.includes('note.create')
            ? updatedPayload.subtype.push('note.create')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('note.all') &&
        !updatedPayload.subtype.includes('note.resolved')
            ? updatedPayload.subtype.push('note.resolved')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.hold.all') &&
        !updatedPayload.subtype.includes('lock.hold.enable')
            ? updatedPayload.subtype.push('lock.hold.enable')
            : '';
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.hold.all') &&
        !updatedPayload.subtype.includes('lock.hold.cancel')
            ? updatedPayload.subtype.push('lock.hold.cancel')
            : '';

        // only support will see these so it isn't too cluttered
        authUser.role.tier === 1 &&
        updatedPayload.subtype &&
        updatedPayload.subtype.includes('lock.unlock') &&
        !updatedPayload.subtype.includes('lock.limited')
            ? updatedPayload.subtype.push('lock.limited')
            : '';
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.temperature_out_of_bounds') &&
            updatedPayload.subtype.push('lock.temperature_out_of_bounds');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.failed_unlock.invalid_accesscode') &&
            updatedPayload.subtype.push('lock.failed_unlock.invalid_accesscode');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.failed_unlock.invalid_accesscode') &&
            updatedPayload.subtype.push('lock.failed_unlock.invalid_accesscode.out_of_zone');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('activity.sitedata.export') &&
            updatedPayload.subtype.push('activity.sitedata.export');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('activity.sitedata.export') &&
            updatedPayload.subtype.push('activity.search.export');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('auction.unassign') &&
            updatedPayload.subtype.push('auction.assign');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('auction.unassign') &&
            updatedPayload.subtype.push('auction.expired');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('transfer.unassign') &&
            updatedPayload.subtype.push('transfer.assign');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('transfer.unassign') &&
            updatedPayload.subtype.push('transfer.unassign');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('share.create') &&
            updatedPayload.subtype.push('share.update.manager');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('share.create') &&
            updatedPayload.subtype.push('share.create.manager');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('share.create') &&
            updatedPayload.subtype.push('share.delete.manager');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('user.confirm') &&
            updatedPayload.subtype.push('user.confirm');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('user.confirm') &&
            updatedPayload.subtype.push('user.delete');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('user.confirm') &&
            updatedPayload.subtype.push('user.update');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('user.confirm') &&
            updatedPayload.subtype.push('user.password.update');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('share.create') &&
            updatedPayload.subtype.push('share.delete');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('share.create') &&
            updatedPayload.subtype.push('site.manager.delete');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('unit.assign') &&
            updatedPayload.subtype.push('site.unit.setstate');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('unit.assign') &&
            updatedPayload.subtype.push('unit.assign');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('unit.assign') &&
            updatedPayload.subtype.push('unit.edit');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('unit.assign') &&
            updatedPayload.subtype.push('lock.battery.replace');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.alarm') &&
            updatedPayload.subtype.push('lock.alarm');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.motionstart') &&
            updatedPayload.subtype.push('lock.motionstart');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.motionstart') &&
            updatedPayload.subtype.push('lock.motionstop');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.failed_unlock.overlocked') &&
            updatedPayload.subtype.push('lock.failed_unlock.blacklisted');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.failed_unlock.overlocked') &&
            updatedPayload.subtype.push('lock.failed_unlock.gatelocked');
        authUser.role.tier === 1 &&
            updatedPayload.subtype &&
            updatedPayload.subtype.includes('lock.failed_unlock.overlocked') &&
            updatedPayload.subtype.push('lock.failed_unlock.out_of_schedule');

        let activity = payload.dash
            ? yield call(API.POST_ACTIVITY, payload.url, updatedPayload)
            : yield call(API.POST, payload.url, updatedPayload);
        if (!activity || !activity.data || (!activity.data.activity && payload.page <= 1)) {
            payload.type === 'unit'
                ? yield put(ACTIONS_ACTIVITY.setUnitActivityList([]))
                : payload.type === 'user'
                ? yield put(ACTIONS_ACTIVITY.setUserActivityList([]))
                : payload.type === 'entry'
                ? yield put(ACTIONS_ACTIVITY.setEntryActivityList([]))
                : yield put(ACTIONS_ACTIVITY.setActivityList([]));
            yield put(ACTIONS_ACTIVITY.setRecentUsers([]));
            yield put(ACTIONS_ACTIVITY.setRecentUnits([]));
            yield put(ACTIONS_ACTIVITY.setActivityDates([]));
        } else if (!activity || !activity.data || (!activity.data.activity && payload.page > 1)) {
            return;
        } else {
            activity.data.activity = payload.map
                ? activity.data.activity.filter((event) => event.event.lock && event.event.lock.hwType == 'unit')
                : activity.data.activity;
            activity.data.activity.map((item) => {
                item.Icon =
                    item.event.type == 'user'
                        ? item.event.subtype == 'user.delete'
                            ? ICONS.RemoveUserIcon
                            : item.event.subtype == 'note.create'
                            ? ICONS.CreateNoteIcon
                            : item.event.subtype == 'note.resolved'
                            ? ICONS.RemoveNoteIcon
                            : item.event.subtype == 'user.create'
                            ? ICONS.AddUserIcon
                            : ICONS.ActivityUserIcon
                        : item.event.type == 'share'
                        ? item.event.subtype == 'share.delete'
                            ? ICONS.RemoveUserIcon
                            : item.event.subtype == 'share.create'
                            ? ICONS.AddUserIcon
                            : ICONS.ActivityUserIcon
                        : item.event.type == 'unlock'
                        ? ICONS.UnlockIcon
                        : item.event.type == 'lock'
                        ? item.event.subtype == 'lock.sensor'
                            ? ICONS.MotionIcon
                            : item.event.subtype == 'lock.motionstart'
                            ? ICONS.MotionIcon
                            : item.event.subtype == 'lock.motionstop'
                            ? ICONS.MotionIcon
                            : item.event.subtype == 'lock.alarm'
                            ? ICONS.AlarmIcon
                            : ICONS.LockIcon
                        : item.event.type == 'unit'
                        ? item.event.subtype == 'unit.assign'
                            ? ICONS.AddUnitIcon
                            : item.event.subtype == 'unit.unassign'
                            ? ICONS.RemoveUnitIcon
                            : ICONS.UnitIcon
                        : item.event.type == 'fob'
                        ? ICONS.FobIcon
                        : ICONS.ActivityIcon;
                item.action = item.event.lockFailedUnlockType
                    ? `${item.event.subtype}-${item.event.lockFailedUnlockType}`
                    : item.event.subtype;
                item.type = item.event.type;
                item.user = `${item.event.by.firstName} ${item.event.by.lastName}`;
                item.userId = item.event.by.userId && JSON.parse(item.event.by.userId);
                item.userType = item.event.byUserType;
                item.userUnit = item.event.unit && item.event.unit.name ? item.event.unit.name : null;
                item.device = item.event.lock ? item.event.lock.name : '';
                item.deviceId = item.event.lock ? item.event.lock.id : 0;
                item.recordedDate = item.createdAt;
                item.byRole =
                    item.event &&
                    item.event.by &&
                    item.event.by.byRoles &&
                    item.event.by.byRoles.filter((role) => role.role_siteID == site.id).length > 0
                        ? item.event.by.byRoles.filter((role) => role.role_siteID == site.id)[0]
                        : '';
            });
            let activityHeaders = yield select(getActivityHeaders);
            let activeHeader = activityHeaders.find((item) => item.active);
            if (payload.page > 1) {
                let addedList = activityList.concat(activity.data.activity);
                addedList = payload.map
                    ? UTILS.sortList(activeHeader.order, addedList, activeHeader.sortTitle)
                    : UTILS.sortList(!activeHeader.order, addedList, activeHeader.sortTitle);
                let recentUsers = Object.entries(
                    addedList.reduce((a, e) => {
                        a[e.user] = ++a[e.user] || 0;
                        return a;
                    }, {})
                ).map((x) => ({
                    user: x[0],
                    count: x[1]
                }));
                recentUsers.map((user) =>
                    activity.data.activity.map((activity) =>
                        user.user == activity.userId ? (user.name = activity.user) : null
                    )
                );
                recentUsers = recentUsers.filter((user) => user.user !== '0');
                recentUsers = UTILS.sortList(true, recentUsers, 'count');
                let recentUnits = Object.entries(
                    addedList.reduce((a, e) => {
                        a[e.device] = ++a[e.device] || 0;
                        return a;
                    }, {})
                ).map((x) => ({
                    device: x[0],
                    count: x[1]
                }));
                recentUnits.map((user) =>
                    activity.data.activity.map((activity) => {
                        if (user.device == activity.deviceId) {
                            user.name = activity.device;
                            user.count = user.count + 1;
                            user.id = activity.event.unit && activity.event.unit.id ? activity.event.unit.id : null;
                            user.deviceId = activity.deviceId ? activity.deviceId : null;
                            user.type = activity.event.unit && activity.event.unit.accessType ? activity.event.unit.accessType : null;
                        }
                    })
                );
                if (payload.dash) {
                    if (activity.data.activity.length === 5000) {
                        let newPayloadData = JSON.parse(JSON.stringify(payload.data));
                        newPayloadData.details.range.end = activity.data.activity[4999].createdAt;
                        let newActivityList = yield call(API.POST, payload.url, newPayloadData);
                        let allActivity =
                            newActivityList && newActivityList.data && newActivityList.data.activity
                                ? newActivityList.data.activity.concat(activity.data.activity)
                                : activity.data.activity;
                        let userTimezone = yield select(getUserTimezone);
                        let authUser = yield select(getAuthUser);
                        let site = yield select(getSite);
                        let activityTimes = JSON.parse(JSON.stringify(allActivity));
                        activityTimes =
                            activityTimes &&
                            activityTimes.map((activity) => {
                                activity.eventDate =
                                    authUser && authUser.useSiteTime && site
                                        ? moment(activity.recordedDate)
                                              .tz(site.timeZone)
                                              .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                                        : moment(activity.recordedDate).tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss') +
                                          'Z';
                                return activity;
                            });
                        const range = moment.range(payload.data.details.range.begin, payload.data.details.range.end);
                        const rangeArr = range.snapTo('day');
                        let startTime =
                            authUser && authUser.useSiteTime && site
                                ? Moment().utc().tz(site.timeZone).format('YYYY-MM-DDTHH:mm:ss')
                                : Moment().utc().tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss');
                        let rangeArray = payload.day
                            ? [
                                  `${moment(startTime).subtract(23, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(22, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(21, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(20, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(19, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(18, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(17, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(16, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(15, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(14, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(13, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(12, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(11, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(10, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(9, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(8, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(7, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(6, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(5, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(4, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(3, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(2, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(1, 'hour').format('HH')}:00`,
                                  `${moment(startTime).format('HH')}:00`
                              ]
                            : Array.from(rangeArr.by('days')).map((m) => m.format('MM-DD'));
                        let activityDates =
                            activityTimes &&
                            activityTimes.map((activity) => {
                                return payload.day
                                    ? activity.eventDate.split('T')[1].split(':')[0]
                                    : activity.eventDate.split('T')[0].split('-').splice(1, 2).join('-');
                            });
                        activityDates = payload.day ? activityDates.map((time) => time + ':00') : activityDates;
                        let activityDateFrequency = {};
                        for (var i = 0, j = activityDates.length; i < j; i++) {
                            activityDateFrequency[activityDates[i]] =
                                (activityDateFrequency[activityDates[i]] || 0) + 1;
                        }
                        let activityDateArray = Object.keys(activityDateFrequency).map(function (activity) {
                            return { Name: activity, Events: activityDateFrequency[activity] };
                        });
                        let finalDates = [];
                        !payload.day && rangeArray.splice(0, 1);
                        rangeArray.map((day, i) => {
                            activityDateArray.map((activity) => {
                                day === activity.Name
                                    ? finalDates.push(activity)
                                    : finalDates.some((date) => date.Name === day)
                                    ? ''
                                    : activityDateArray.some((yo) => yo.Name === day)
                                    ? ''
                                    : finalDates.push({ Name: day, Events: 0 });
                            });
                        });
                        finalDates = UTILS.sortList(false, finalDates, 'Name');
                        yield put(ACTIONS_ACTIVITY.setActivityDates(finalDates));
                    } else {
                        const range = moment.range(payload.data.details.range.begin, payload.data.details.range.end);
                        const rangeArr = range.snapTo('day');
                        let userTimezone = yield select(getUserTimezone);
                        let authUser = yield select(getAuthUser);
                        let site = yield select(getSite);
                        let activityTimes = JSON.parse(JSON.stringify(activity.data.activity));
                        activityTimes =
                            activityTimes &&
                            activityTimes.map((activity) => {
                                activity.eventDate =
                                    authUser && authUser.useSiteTime && site
                                        ? moment(activity.recordedDate)
                                              .tz(site.timeZone)
                                              .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                                        : moment(activity.recordedDate).tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss') +
                                          'Z';
                                return activity;
                            });
                        let startTime =
                            authUser && authUser.useSiteTime && site
                                ? Moment().utc().tz(site.timeZone).format('YYYY-MM-DDTHH:mm:ss')
                                : Moment().utc().tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss');
                        let rangeArray = payload.day
                            ? [
                                  `${moment(startTime).subtract(23, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(22, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(21, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(20, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(19, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(18, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(17, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(16, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(15, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(14, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(13, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(12, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(11, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(10, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(9, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(8, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(7, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(6, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(5, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(4, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(3, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(2, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(1, 'hour').format('HH')}:00`,
                                  `${moment(startTime).format('HH')}:00`
                              ]
                            : Array.from(rangeArr.by('days')).map((m) => m.format('MM-DD'));
                        let activityDates =
                            activityTimes &&
                            activityTimes.map((activity) => {
                                return payload.day
                                    ? activity.eventDate.split('T')[1].split(':')[0]
                                    : activity.eventDate.split('T')[0].split('-').splice(1, 2).join('-');
                            });
                        activityDates = payload.day ? activityDates.map((time) => time + ':00') : activityDates;
                        let activityDateFrequency = {};
                        for (var i = 0, j = activityDates.length; i < j; i++) {
                            activityDateFrequency[activityDates[i]] =
                                (activityDateFrequency[activityDates[i]] || 0) + 1;
                        }
                        let activityDateArray = Object.keys(activityDateFrequency).map(function (activity) {
                            return { Name: activity, Events: activityDateFrequency[activity] };
                        });
                        let finalDates = [];
                        !payload.day && rangeArray.splice(0, 1);
                        rangeArray.map((day, i) => {
                            activityDateArray.map((activity) => {
                                day === activity.Name
                                    ? finalDates.push(activity)
                                    : finalDates.some((date) => date.Name === day)
                                    ? ''
                                    : activityDateArray.some((yo) => yo.Name === day)
                                    ? ''
                                    : finalDates.push({ Name: day, Events: 0 });
                            });
                        });
                        finalDates = UTILS.sortList(false, finalDates, 'Name');
                        yield put(ACTIONS_ACTIVITY.setActivityDates(finalDates));
                    }
                }
                recentUnits = UTILS.sortList(true, recentUnits, 'count');
                payload.type === 'unit'
                    ? yield put(ACTIONS_ACTIVITY.setUnitActivityList(addedList))
                    : payload.type === 'user'
                    ? yield put(ACTIONS_ACTIVITY.setUserActivityList(addedList))
                    : payload.type === 'entry'
                    ? yield put(ACTIONS_ACTIVITY.setEntryActivityList(addedList))
                    : yield put(ACTIONS_ACTIVITY.setActivityList(addedList));
                yield put(ACTIONS_ACTIVITY.setActivityPageCount(payload.page));
                yield put(ACTIONS_ACTIVITY.setRecentUsers(recentUsers ? recentUsers : []));
                yield put(ACTIONS_ACTIVITY.setRecentUnits(recentUnits ? recentUnits : []));
            } else {
                activity.data.activity = payload.map
                    ? UTILS.sortList(activeHeader.order, activity.data.activity, activeHeader.sortTitle)
                    : UTILS.sortList(!activeHeader.order, activity.data.activity, activeHeader.sortTitle);
                let recentUsers = Object.entries(
                    activity.data.activity.reduce((a, e) => {
                        a[e.userId] = ++a[e.userId] || 0;
                        return a;
                    }, {})
                ).map((x) => ({
                    user: x[0],
                    count: x[1]
                }));
                recentUsers.map((user) =>
                    activity.data.activity.map((activity) =>
                        user.user == activity.userId ? (user.name = activity.user) : null
                    )
                );
                recentUsers = recentUsers.filter((user) => user.user !== '0');
                recentUsers = UTILS.sortList(true, recentUsers, 'count');
                let recentUnits = Object.entries(
                    activity.data.activity.reduce((a, e) => {
                        a[e.deviceId] = ++a[e.deviceId] || 0;
                        return a;
                    }, {})
                ).map((x) => ({
                    device: x[0],
                    count: x[1]
                }));
                recentUnits.map((user) =>
                    activity.data.activity.map((activity) => {
                        if (user.device == activity.deviceId) {
                            user.name = activity.device;
                            user.count = user.count + 1;
                            user.id = activity.event.unit && activity.event.unit.id ? activity.event.unit.id : null;
                            user.deviceId = activity.deviceId ? activity.deviceId : null;
                            user.type = activity.event.unit && activity.event.unit.accessType ? activity.event.unit.accessType : null;
                        }
                    })
                );
                if (payload.dash) {
                    if (activity.data.activity.length === 5000) {
                        let newPayloadData = JSON.parse(JSON.stringify(payload.data));
                        newPayloadData.details.range.end = activity.data.activity[4999].createdAt;
                        let newActivityList = yield call(API.POST, payload.url, newPayloadData);
                        let allActivity =
                            newActivityList && newActivityList.data && newActivityList.data.activity
                                ? newActivityList.data.activity.concat(activity.data.activity)
                                : activity.data.activity;
                        let userTimezone = yield select(getUserTimezone);
                        let authUser = yield select(getAuthUser);
                        let site = yield select(getSite);
                        let activityTimes = JSON.parse(JSON.stringify(allActivity));
                        activityTimes =
                            activityTimes &&
                            activityTimes.map((activity) => {
                                activity.eventDate =
                                    authUser && authUser.useSiteTime && site
                                        ? moment(activity.recordedDate)
                                              .tz(site.timeZone)
                                              .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                                        : moment(activity.recordedDate).tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss') +
                                          'Z';
                                return activity;
                            });
                        const range = moment.range(payload.data.details.range.begin, payload.data.details.range.end);
                        const rangeArr = range.snapTo('day');
                        let startTime =
                            authUser && authUser.useSiteTime && site
                                ? Moment().utc().tz(site.timeZone).format('YYYY-MM-DDTHH:mm:ss')
                                : Moment().utc().tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss');
                        let rangeArray = payload.day
                            ? [
                                  `${moment(startTime).subtract(23, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(22, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(21, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(20, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(19, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(18, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(17, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(16, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(15, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(14, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(13, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(12, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(11, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(10, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(9, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(8, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(7, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(6, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(5, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(4, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(3, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(2, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(1, 'hour').format('HH')}:00`,
                                  `${moment(startTime).format('HH')}:00`
                              ]
                            : Array.from(rangeArr.by('days')).map((m) => m.format('MM-DD'));
                        let activityDates =
                            activityTimes &&
                            activityTimes.map((activity) => {
                                return payload.day
                                    ? activity.eventDate.split('T')[1].split(':')[0]
                                    : activity.eventDate.split('T')[0].split('-').splice(1, 2).join('-');
                            });
                        activityDates = payload.day ? activityDates.map((time) => time + ':00') : activityDates;
                        let activityDateFrequency = {};
                        for (var i = 0, j = activityDates.length; i < j; i++) {
                            activityDateFrequency[activityDates[i]] =
                                (activityDateFrequency[activityDates[i]] || 0) + 1;
                        }
                        let activityDateArray = Object.keys(activityDateFrequency).map(function (activity) {
                            return { Name: activity, Events: activityDateFrequency[activity] };
                        });
                        let finalDates = [];
                        !payload.day && rangeArray.splice(0, 1);
                        rangeArray.map((day, i) => {
                            activityDateArray.map((activity) => {
                                day === activity.Name
                                    ? finalDates.push(activity)
                                    : finalDates.some((date) => date.Name === day)
                                    ? ''
                                    : activityDateArray.some((yo) => yo.Name === day)
                                    ? ''
                                    : finalDates.push({ Name: day, Events: 0 });
                            });
                        });
                        finalDates = UTILS.sortList(false, finalDates, 'Name');
                        yield put(ACTIONS_ACTIVITY.setActivityDates(finalDates));
                    } else {
                        const range = moment.range(payload.data.details.range.begin, payload.data.details.range.end);
                        const rangeArr = range.snapTo('day');
                        let userTimezone = yield select(getUserTimezone);
                        let authUser = yield select(getAuthUser);
                        let site = yield select(getSite);
                        let activityTimes = JSON.parse(JSON.stringify(activity.data.activity));
                        activityTimes =
                            activityTimes &&
                            activityTimes.map((activity) => {
                                activity.eventDate =
                                    authUser && authUser.useSiteTime && site
                                        ? Moment(activity.recordedDate)
                                              .tz(site.timeZone)
                                              .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                                        : Moment(activity.recordedDate).tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss') +
                                          'Z';
                                return activity;
                            });
                        let startTime =
                            authUser && authUser.useSiteTime && site
                                ? Moment().utc().tz(site.timeZone).format('YYYY-MM-DDTHH:mm:ss')
                                : Moment().utc().tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss');
                        let rangeArray = payload.day
                            ? [
                                  `${moment(startTime).subtract(23, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(22, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(21, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(20, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(19, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(18, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(17, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(16, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(15, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(14, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(13, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(12, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(11, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(10, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(9, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(8, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(7, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(6, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(5, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(4, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(3, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(2, 'hour').format('HH')}:00`,
                                  `${moment(startTime).subtract(1, 'hour').format('HH')}:00`,
                                  `${moment(startTime).format('HH')}:00`
                              ]
                            : Array.from(rangeArr.by('days')).map((m) => m.format('MM-DD'));
                        let activityDates =
                            activityTimes &&
                            activityTimes.map((activity) => {
                                return payload.day
                                    ? activity.eventDate.split('T')[1].split(':')[0]
                                    : activity.eventDate.split('T')[0].split('-').splice(1, 2).join('-');
                            });
                        activityDates = payload.day ? activityDates.map((time) => time + ':00') : activityDates;
                        let activityDateFrequency = {};
                        for (var i = 0, j = activityDates.length; i < j; i++) {
                            activityDateFrequency[activityDates[i]] =
                                (activityDateFrequency[activityDates[i]] || 0) + 1;
                        }
                        let activityDateArray = Object.keys(activityDateFrequency).map(function (activity) {
                            return { Name: activity, Events: activityDateFrequency[activity] };
                        });
                        let finalDates = [];
                        !payload.day && rangeArray.splice(0, 1);
                        rangeArray.map((day, i) => {
                            activityDateArray.map((activity) => {
                                day === activity.Name
                                    ? finalDates.push(activity)
                                    : finalDates.some((date) => date.Name === day)
                                    ? ''
                                    : activityDateArray.some((yo) => yo.Name === day)
                                    ? ''
                                    : finalDates.push({ Name: day, Events: 0 });
                            });
                        });
                        finalDates = payload.day ? finalDates : UTILS.sortList(false, finalDates, 'Name');
                        yield put(ACTIONS_ACTIVITY.setActivityDates(finalDates));
                    }
                }
                recentUnits = UTILS.sortList(true, recentUnits, 'count');
                payload.type === 'unit'
                    ? yield put(ACTIONS_ACTIVITY.setUnitActivityList(activity.data.activity))
                    : payload.type === 'user'
                    ? yield put(ACTIONS_ACTIVITY.setUserActivityList(activity.data.activity))
                    : payload.type === 'entry'
                    ? yield put(ACTIONS_ACTIVITY.setEntryActivityList(activity.data.activity))
                    : yield put(ACTIONS_ACTIVITY.setActivityList(activity.data.activity));
                yield put(ACTIONS_ACTIVITY.setActivityPageCount(payload.page));
                yield put(ACTIONS_ACTIVITY.setRecentUsers(recentUsers ? recentUsers : []));
                yield put(ACTIONS_ACTIVITY.setRecentUnits(recentUnits ? recentUnits : []));
            }
        }
    } catch (error) {
        payload.type === 'unit'
            ? yield put(ACTIONS_ACTIVITY.setUnitActivityList([]))
            : payload.type === 'user'
            ? yield put(ACTIONS_ACTIVITY.setUserActivityList([]))
            : payload.type === 'entry'
            ? yield put(ACTIONS_ACTIVITY.setEntryActivityList([]))
            : yield put(ACTIONS_ACTIVITY.setActivityList([]));
        yield put(ACTIONS_ACTIVITY.setActivityPageCount(1));
        console.warn(error);
    }
}

function* requestActivityCSVList({ payload }) {
    let authUser = yield select(getAuthUser);
    try {
        if (payload.data.toEmail === '') {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noEmail`, 'warning'));
            return;
        } else {
            yield put(ACTIONS_ACTIVITY.setActivityCSVList(null));

            // Defaults for everyone to see
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('unlock.accessed_offline_access_code')
                ? payload.data.body.subtype.push('unlock.accessed_offline_access_code')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('unlock.accessed_online_phone')
                ? payload.data.body.subtype.push('unlock.accessed_online_phone')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('unlock.accessed_online_phone_bt')
                ? payload.data.body.subtype.push('unlock.accessed_online_phone_bt')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('unlock.accessed_offline_phone')
                ? payload.data.body.subtype.push('unlock.accessed_offline_phone')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('unlock.accessed_offline_fob')
                ? payload.data.body.subtype.push('unlock.accessed_offline_fob')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('unlock.unlocked_mechanical_override')
                ? payload.data.body.subtype.push('unlock.unlocked_mechanical_override')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('lock.forced_entry')
                ? payload.data.body.subtype.push('lock.forced_entry')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.failed_unlock') &&
            !payload.data.body.subtype.includes('lock.failed_unlock.out_of_schedule')
                ? payload.data.body.subtype.push('lock.failed_unlock.out_of_schedule')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.failed_unlock') &&
            !payload.data.body.subtype.includes('lock.failed_unlock.overlocked')
                ? payload.data.body.subtype.push('lock.failed_unlock.overlocked')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.failed_unlock') &&
            !payload.data.body.subtype.includes('lock.failed_unlock.gatelocked')
                ? payload.data.body.subtype.push('lock.failed_unlock.gatelocked')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.failed_unlock') &&
            !payload.data.body.subtype.includes('lock.failed_unlock.blacklisted')
                ? payload.data.body.subtype.push('lock.failed_unlock.blacklisted')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.failed_unlock') &&
            !payload.data.body.subtype.includes('lock.failed_unlock.invalid_accesscode')
                ? payload.data.body.subtype.push('lock.failed_unlock.invalid_accesscode')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.failed_unlock') &&
            !payload.data.body.subtype.includes('lock.failed_unlock.invalid_accesscode.out_of_zone')
                ? payload.data.body.subtype.push('lock.failed_unlock.invalid_accesscode.out_of_zone')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('unlock.accessed_online_phone_mesh')
                ? payload.data.body.subtype.push('unlock.accessed_online_phone_mesh')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('lock.override.use')
                ? payload.data.body.subtype.push('lock.override.use')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('lock.override.request')
                ? payload.data.body.subtype.push('lock.override.request')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('note.all') &&
            !payload.data.body.subtype.includes('note.create')
                ? payload.data.body.subtype.push('note.create')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('note.all') &&
            !payload.data.body.subtype.includes('note.resolved')
                ? payload.data.body.subtype.push('note.resolved')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.hold.all') &&
            !payload.data.body.subtype.includes('lock.hold.enable')
                ? payload.data.body.subtype.push('lock.hold.enable')
                : '';
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.hold.all') &&
            !payload.data.body.subtype.includes('lock.hold.cancel')
                ? payload.data.body.subtype.push('lock.hold.cancel')
                : '';

            // only support will see these so it isn't too cluttered
            authUser.role.tier === 1 &&
            payload.data.body.subtype &&
            payload.data.body.subtype.includes('lock.unlock') &&
            !payload.data.body.subtype.includes('lock.limited')
                ? payload.data.body.subtype.push('lock.limited')
                : '';
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.temperature_out_of_bounds') &&
                payload.data.body.subtype.push('lock.temperature_out_of_bounds');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.failed_unlock') &&
                payload.data.body.subtype.push('lock.failed_unlock.invalid_accesscode');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.failed_unlock') &&
                payload.data.body.subtype.push('lock.failed_unlock.invalid_accesscode.out_of_zone');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('activity.sitedata.export') &&
                payload.data.body.subtype.push('activity.sitedata.export');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('activity.sitedata.export') &&
                payload.data.body.subtype.push('activity.search.export');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('auction.unassign') &&
                payload.data.body.subtype.push('auction.assign');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('auction.unassign') &&
                payload.data.body.subtype.push('auction.expired');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('transfer.unassign') &&
                payload.data.body.subtype.push('tranfser.assign');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('transfer.unassign') &&
                payload.data.body.subtype.push('transfer.unassign');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('share.create') &&
                payload.data.body.subtype.push('share.update.manager');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('share.create') &&
                payload.data.body.subtype.push('share.create.manager');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('share.create') &&
                payload.data.body.subtype.push('share.delete.manager');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('user.confirm') &&
                payload.data.body.subtype.push('user.confirm');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('user.confirm') &&
                payload.data.body.subtype.push('user.delete');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('user.confirm') &&
                payload.data.body.subtype.push('user.update');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('user.confirm') &&
                payload.data.body.subtype.push('user.password.update');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('share.create') &&
                payload.data.body.subtype.push('share.delete');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('share.create') &&
                payload.data.body.subtype.push('site.manager.delete');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('unit.assign') &&
                payload.data.body.subtype.push('site.unit.setstate');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('unit.assign') &&
                payload.data.body.subtype.push('unit.assign');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('unit.assign') &&
                payload.data.body.subtype.push('unit.edit');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('unit.assign') &&
                payload.data.body.subtype.push('lock.battery.replace');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.alarm') &&
                payload.data.body.subtype.push('lock.alarm');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.motionstart') &&
                payload.data.body.subtype.push('lock.motionstart');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.motionstart') &&
                payload.data.body.subtype.push('lock.motionstop');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.failed_unlock.overlocked') &&
                payload.data.body.subtype.push('lock.failed_unlock.blacklisted');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.failed_unlock.overlocked') &&
                payload.data.body.subtype.push('lock.failed_unlock.gatelocked');
            authUser.role.tier === 1 &&
                payload.data.body.subtype &&
                payload.data.body.subtype.includes('lock.failed_unlock.overlocked') &&
                payload.data.body.subtype.push('lock.failed_unlock.out_of_schedule');

            let activity = yield call(API.POST, payload.url, payload.data);
            if (!activity) {
                yield put(ACTIONS_ACTIVITY.setActivityCSVList('failed'));
            } else {
                yield put(ACTIONS_ACTIVITY.setActivityCSVList(activity));
            }
        }
    } catch (error) {
        yield put(ACTIONS_ACTIVITY.setActivityCSVList('failed'));
        console.warn(error);
    }
}

function* requestFetchTenantActivity({ payload }) {
    try {
        yield put(ACTIONS_ACTIVITY.setTenantActivity(null));
        let activity = yield call(API.POST, payload.url, payload.data);
        if (!activity || !activity.data || !activity.data.countByDate) {
            yield put(ACTIONS_ACTIVITY.setTenantActivity([]));
        } else {
            let authUser = yield select(getAuthUser);
            let site = yield select(getSite);
            let userTimezone = yield select(getUserTimezone);
            let activityObject = Object.entries(activity.data.countByDate);
            let activities = activityObject.map(function (activity) {
                return {
                    Name: activity[0],
                    Tenants: activity[1]
                };
            });
            if (payload.data.interval === 'hour') {
                let dayActivities = activities.slice(Math.max(activities.length - 24, 1));
                dayActivities.map(
                    (activity) =>
                        (activity.Name =
                            authUser && authUser.useSiteTime && site
                                ? moment(activity.Name).tz(site.timeZone).format('HH:mm')
                                : moment(activity.Name).tz(userTimezone).format('HH:mm'))
                );
                yield put(ACTIONS_ACTIVITY.setTenantActivity(dayActivities));
            } else {
                activities.map((activity) => (activity.Name = moment(activity.Name).format('MM-DD')));
                yield put(ACTIONS_ACTIVITY.setTenantActivity(activities));
            }
        }
    } catch (error) {
        yield put(ACTIONS_ACTIVITY.setTenantActivity(null));
        console.warn(error);
    }
}

function* requestFetchActivityFilters({ payload }) {
    try {
        yield put(ACTIONS_ACTIVITY.setActivityList(null));
        yield put(ACTIONS_ACTIVITY.setUserActivityFilters(null));
        let authUser = yield select(getAuthUser);
        let activityFilters = yield select(getActivityFilters);
        let supportFilters = yield select(getSupportActivityFilters);
        let filters = yield call(API.POST, payload.url, payload.data);
        if (!filters || !filters.data || !filters.data.filters) {
            yield put(ACTIONS_ACTIVITY.setUserActivityFilters([]));
        } else {
            if (payload.uuid && payload.uuid !== '') {
                let filter = filters.data.filters.filter((filter) => filter.filterUUID == payload.uuid)[0];
                filter.json = JSON.parse(filter.json);
                yield put(ACTIONS_ACTIVITY.setUserActivityFilter(filter));
            }
            let filterDefault = filters.data.filters.filter((filter) => filter.default)[0]
                ? filters.data.filters.filter((filter) => filter.default)[0]
                : null;
            if (filterDefault) {
                yield put(ACTIONS_ACTIVITY.setActivityView(filterDefault));
            }
            if (payload.fetch && filterDefault) {
                let filterJSON = JSON.parse(filterDefault.json);
                yield put(ACTIONS_ACTIVITY.fetchActivityList({ page: 1, filter: filterJSON }));
                yield put(
                    authUser && authUser.role.tier === 1
                        ? ACTIONS_ACTIVITY.setSupportActivityFilters(filterJSON)
                        : ACTIONS_ACTIVITY.setActivityFilters(filterJSON)
                );
            } else if (payload.fetch) {
                let newActivityFilters =
                    authUser && authUser.role.tier === 1
                        ? JSON.parse(JSON.stringify(supportFilters))
                        : JSON.parse(JSON.stringify(activityFilters));
                yield put(ACTIONS_ACTIVITY.fetchActivityList({ page: 1, limit: 200, filter: newActivityFilters }));
            }
            yield put(ACTIONS_ACTIVITY.setActivityView(filterDefault));
            yield put(ACTIONS_ACTIVITY.setUserActivityFilters(filters.data.filters));
        }
    } catch (error) {
        yield put(ACTIONS_ACTIVITY.setUserActivityFilters(null));
        console.warn(error);
    }
}

function* requestCreateActivityFilter({ payload }) {
    try {
        let create = yield call(API.POST, payload.url, payload.data);
        if (!create || !create.data) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noAdd`, 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
            yield history.push('/app/activity/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateActivityFilter({ payload }) {
    try {
        let update = yield call(API.POST, payload.url, payload.data);
        if (!update || !update.data) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noEdit`, 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield history.push('/app/activity/all');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteActivityFilter({ payload }) {
    try {
        let deletion = yield call(API.POST, payload.url, payload.data);
        if (!deletion || !deletion.data) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noDelete`, 'warning'));
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield put(ACTIONS_ACTIVITY.setUserActivityFilters(deletion.data.filters));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetActivityDefaultView({ payload }) {
    try {
        let update = yield call(API.POST, payload.url, payload.data);
        if (!update || !update.data) {
        } else {
            yield put(ACTIONS_ACTIVITY.setUserActivityFilters(update.data.filters));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectFetchActivityList() {
    yield takeEvery(CONSTANTS.FETCH_ACTIVITY_LIST, requestActivityList);
}
export function* selectFetchActivityCSVList() {
    yield takeEvery(CONSTANTS.FETCH_ACTIVITY_CSV_LIST, requestActivityCSVList);
}
export function* selectFetchTenantActivity() {
    yield takeEvery(CONSTANTS.FETCH_TENANT_ACTIVITY, requestFetchTenantActivity);
}
export function* selectFetchActivityFilters() {
    yield takeEvery(CONSTANTS.FETCH_USER_ACTIVITY_FILTERS, requestFetchActivityFilters);
}
export function* selectCreateActivityFilter() {
    yield takeEvery(CONSTANTS.CREATE_USER_ACTIVITY_FILTER, requestCreateActivityFilter);
}
export function* selectUpdateActivityFilter() {
    yield takeEvery(CONSTANTS.UPDATE_USER_ACTIVITY_FILTER, requestUpdateActivityFilter);
}
export function* selectDeleteActivityFilter() {
    yield takeEvery(CONSTANTS.DELETE_USER_ACTIVITY_FILTER, requestDeleteActivityFilter);
}
export function* selectSetActivityDefaultView() {
    yield takeEvery(CONSTANTS.SET_ACTIVITY_DEFAULT_VIEW, requestSetActivityDefaultView);
}

export default function* rootSaga() {
    yield all([
        fork(selectFetchActivityList),
        fork(selectFetchActivityCSVList),
        fork(selectFetchTenantActivity),
        fork(selectFetchActivityFilters),
        fork(selectCreateActivityFilter),
        fork(selectUpdateActivityFilter),
        fork(selectDeleteActivityFilter),
        fork(selectSetActivityDefaultView)
    ]);
}
