import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { UnitIcon, UserIcon } from 'utilities/icons';

import CellIcon from 'components/Table/cell/components/iconLink';
import CellText from 'components/Table/cell/components/text';
class CellSearchResults extends React.Component {
    constructor(props) {
        super(props);
    }
    viewSite = (e, data) => {
        e.stopPropagation();
        this.props.history.push(`/app/facilities/display/${data.id}`);
    };
    render() {
        const { intl, data, width, theme } = this.props;
        const { unitName, userFirstName, userLastName, userPhone, userEmail } = data;
        return (
            <>
                {width > 800 && (
                    <CellIcon
                        Cellwidth={'45px'}
                        Icon={unitName && unitName !== '' ? UnitIcon : UserIcon}
                        backgroundColor={theme.color.themeColor}
                        fill={theme.color.themeColor}
                        color={theme.color.buttonFontColor}
                    />
                )}
                <CellText Title={unitName.toLowerCase().startsWith('srv') || unitName.toLowerCase().startsWith('employee')
                    ? intl.formatMessage({ id: `pages.service.unit.Type` })
                    : unitName && unitName !== "" ? unitName : intl.formatMessage({ id: 'form.na' })} textAlign={'left'} TitleColor={true} />
                <CellText Title={`${userFirstName} ${userLastName}`} textAlign={'left'} TitleColor={true} />
                {width > 800 && <CellText Title={userPhone ? userPhone : intl.formatMessage({ id: 'form.na' })} textAlign={'left'} TitleColor={true} />}
                {width > 800 && (
                    <CellText
                        padding={'2px 0 2px 10px'}
                        Title={userEmail ? userEmail : intl.formatMessage({ id: 'form.na' })}
                        textAlign={'left'}
                        TitleColor={true}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { width, theme } = app;
    return { width, theme };
};

export default injectIntl(withRouter(connect(mapStateToProps)(CellSearchResults)));
