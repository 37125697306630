import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as ICONS from 'utilities/icons';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_ZONE from 'actions/Zones';

const getZoneHeaders = (state) => state.zones.zoneHeader;
const getZoneUnitHeaders = (state) => state.zones.zoneUnitHeader;
const getZoneEntryHeaders = (state) => state.zones.zoneEntryHeader;

function* requestZoneList({ payload }) {
    try {
        yield put(ACTIONS_ZONE.setZoneList(null));
        let zones = yield call(API.POST, payload.url, payload.data);
        if (!zones) {
            yield put(ACTIONS_ZONE.setZoneList([]));
        } else {
            zones.data.zones.map((item) => {
                item.Icon = ICONS.SiteIcon;
                item.units = [];
                item.entry = [];
                item.Units.map((unit) => {
                    if (unit.accessType === 'rentable') {
                        unit.icon = ICONS.UnitIcon;
                        item.units.push(unit);
                    } else {
                        unit.icon = ICONS.EntryIcon;
                        item.entry.push(unit);
                    }
                });
                item.units = UTILS.cleanUpArray(item.units, 'id');
                item.entry = UTILS.cleanUpArray(item.entry, 'id');
                item.entry = item.entry.filter(entry => entry.accessType !== 'employee');
                item.keypad = item.entry.filter(
                    (entry) =>
                        entry.locks &&
                        entry.locks[0] &&
                        entry.locks[0].bleHwVersion &&
                        entry.locks[0].bleHwVersion == '3K'
                )[0]
                    ? true
                    : false;
                item.activeRelays = item.Relays.filter((item) => item.InZone == true);
                item.relays = item.Relays.map((relay) => (relay.icon = ICONS.EntryIcon));
                item.relays = Object.assign(item.Relays);
                item.relays.map((item) => {
                    item.id = item.Id;
                    item.uuid = item.uuid;
                    item.inZone = item.InZone;
                });
                item.relays = item.relays.reverse();
                item.unitLength = item.units.length;
                item.entryLength = item.entry.length;
                item.unitUUIDs = item.Units.map((unit) => unit.uuid);
                item.entryUUIDs = item.entry.map((unit) => unit.uuid);
                item.relayUUIDs = item.activeRelays.map((relay) => relay.uuid);
                item.relayLength = item.relayUUIDs.length;
            });
            let zoneHeaders = yield select(getZoneHeaders);
            let activeHeader = zoneHeaders.find((item) => item.active);
            zones.data.zones = UTILS.sortList(!activeHeader.order, zones.data.zones, activeHeader.sortTitle);
            let zoneKeypadList = zones.data.zones.filter((zone) => zone.keypad);
            yield put(ACTIONS_ZONE.setZoneList(zones.data.zones));
            yield put(ACTIONS_ZONE.setZoneKeypadList(zoneKeypadList));
        }
    } catch (error) {
        yield put(ACTIONS_ZONE.setZoneList([]));
        console.warn(error);
    }
}

function* requestZoneDetails({ payload }) {
    try {
        yield put(ACTIONS_ZONE.setZoneDetails(null));
        let zones = yield call(API.POST, payload.url, payload.data);
        if (!zones.data) {
            yield history.goBack();
        } else {
            let zoneUnitHeaders = yield select(getZoneUnitHeaders);
            let activeUnitHeader = zoneUnitHeaders.find((unitItem) => unitItem.active);
            let zoneEntryHeaders = yield select(getZoneEntryHeaders);
            let activeEntryHeader = zoneEntryHeaders.find((entryItem) => entryItem.active);
            zones.data.zones.map((item) => {
                item.Icon = ICONS.SiteIcon;
                item.name = item.Name;
                item.units = [];
                item.entry = [];
                item.hasKeypad = false;
                item.Units.map((unit) => {
                    unit.hwType = unit.locks && unit.locks[0] && unit.locks[0].hwType ? unit.locks[0].hwType : unit.accessType;
                    if (unit.accessType === 'rentable') {
                        unit.icon = ICONS.UnitIcon;
                        item.units.push(unit);
                    } else {
                        unit.name = unit.locks && unit.locks[0] && unit.locks[0].name ? unit.locks[0].name : unit.name
                        unit.icon = ICONS.EntryIcon;
                        unit.entryUUID = unit.uuid;
                        item.entry.push(unit);
                        unit.locks.map((lock) => {
                            if (lock.bleHwVersion.includes('K')) {
                                item.hasKeypad = true;
                            }
                        });
                    }
                });
                item.units = UTILS.cleanUpArray(item.units, 'uuid');
                item.entry = UTILS.cleanUpArray(item.entry, 'uuid');
                item.entry = item.entry.filter(entry => entry.accessType !== 'employee');
                item.activeRelays = item.Relays.filter((item) => item.InZone == true);
                item.relays = item.Relays.map((relay) => (relay.icon = ICONS.RelayIcon));
                item.relays = Object.assign(item.Relays);
                item.relays.map((item) => {
                    item.id = item.Id;
                    item.inZone = item.InZone;
                });
                item.relays = item.relays.reverse();
                item.unitLength = item.units.length;
                item.entryLength = item.entry.length;
                item.unitUUIDs = item.units.map((unit) => unit.uuid);
                item.entryUUIDs = item.entry.map((entry) => entry.uuid);
                item.relayUUIDs = item.activeRelays.map((relay) => relay.uuid);
                item.relayLength = item.relayUUIDs.length;
                item.units = UTILS.sortList(!activeUnitHeader.order, item.units, activeUnitHeader.sortTitle);
                item.entry = UTILS.sortList(!activeEntryHeader.order, item.entry, activeEntryHeader.sortTitle);
            });
            let zone = zones.data.zones.find((grab) => grab.uuid === payload.zoneUUID);
            if (!zone) {
                yield put(ACTIONS_APP.showMessage(`notifications.error.fail.notSiteZone`));
                yield history.goBack();
            } else {
                zone.Icon = ICONS.SiteIcon;
                yield put(ACTIONS_ZONE.setZoneDetails(zone));
            }
        }
    } catch (error) {
        yield put(ACTIONS_ZONE.setZoneDetails(null));
        console.warn(error);
    }
}

function* requestZoneSave({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            if (update.data.uuid && update.data.uuid !== '') {
                yield history.push(`/app/settings/zones/display/${update.data.uuid}`);
            } else {
                yield history.push(`/app/settings/zones/display/${update.data.id}`);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestZoneDeletion({ payload }) {
    try {
        const deletion = yield call(API.POST, payload.url, payload.data);
        if (!deletion) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield history.push('/app/settings/zones');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestResendZoneCode({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sentAccessCodes', 'success'));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectSaveZone() {
    yield takeEvery(CONSTANTS.SAVE_ZONE, requestZoneSave);
}
export function* selectDeleteZone() {
    yield takeEvery(CONSTANTS.DELETE_ZONE, requestZoneDeletion);
}
export function* selectFetchZoneList() {
    yield takeEvery(CONSTANTS.FETCH_ZONE_LIST, requestZoneList);
}
export function* selectFetchZoneDetails() {
    yield takeEvery(CONSTANTS.FETCH_ZONE_DETAILS, requestZoneDetails);
}

export function* selectResendZoneCode() {
    yield takeEvery(CONSTANTS.RESEND_ZONE_CODE, requestResendZoneCode);
}

export default function* rootSaga() {
    yield all([
        fork(selectSaveZone),
        fork(selectDeleteZone),
        fork(selectFetchZoneList),
        fork(selectFetchZoneDetails),
        fork(selectResendZoneCode)
    ]);
}
