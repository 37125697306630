import React from 'react';
import { connect } from 'react-redux';
import {StyleSheet, css} from 'aphrodite';
class ModalBody extends React.Component {
  constructor (props) {
    super (props);
  }
  render() {
    const { children, title, theme, width, height, style } = this.props
    this.styles = styles(theme, width,height)
    return (
      children && <div className={css(this.styles.wrapper)} style={style}>
        {children}
      </div>
    )
  }
}

const mapStateToProps = ({ app }) => {
  const { theme, width } = app;
  return { theme, width };
}

export default connect(mapStateToProps)(ModalBody);

const styles = () => StyleSheet.create({
  wrapper: {
    position: 'relative',
    display: 'block',
    padding: '1rem',
    minHeight: 100,
  }
});
