import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';

import {
    fetchUnitList,
} from 'actions/Units';

class AssignUnitContinuation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            filteredUnitsList: null,
        };
    }
    componentDidMount () {
        const { unitsList, fetchUnitList } = this.props;
        if (!unitsList) { fetchUnitList(); }
    }
    onInputChange = (e) => {
        const { unitsList } = this.props;
        const v = e.target.value.toLowerCase();
        const filteredResults = unitsList && unitsList.filter(unit => {
            return unit && unit.name.toLowerCase().includes(v);
        });
        this.setState({filteredUnitsList: filteredResults, searchValue: e.target.value});
    }

    render() {
        const { searchValue, filteredUnitsList } = this.state;
        const { intl, theme, unitsList, confirm } = this.props;
        this.styles = styles(theme);

        const availableUnits = unitsList && unitsList.filter(unit => unit.rentalState === 'available');
        const units = filteredUnitsList !== null ? filteredUnitsList : availableUnits;

        return (
            // maybe this top part should be broken up into a component? 
            <div className={css(this.styles.wrapper)}>
                <div className={css(this.styles.headerArea)}>
                    <div className={css(this.styles.headerTitle)}>{intl.formatMessage({id: 'search.assignUnit.chooseUnit'})}</div>
                    <div className={css(this.styles.headerSubtext)}>{intl.formatMessage({id: 'search.assignUnit.chooseUnitSubtext'})}</div>
                </div>
                <div className={css(this.styles.searchArea)}>
                    <div className={css(this.styles.searchChild)}>
                        <span style={{fontSize: 22, color: theme && theme.color.themeColor}} className="material-icons">search</span>
                        <div><input value={searchValue} placeholder={intl.formatMessage({id: 'comp.search.default'})} onChange={this.onInputChange} type="text" className={css(this.styles.searchInput)} /></div>
                    </div>
                    <div className={css(this.styles.searchChild)}>
                        {units && units !== null && <div className={css(this.styles.searchUnitsAmount)}>{units.length} {intl.formatMessage({id: 'pages.units.types'})}</div>}
                    </div>
                </div>
                <div className={css(this.styles.unitListArea)}>
                    {units && units.map((unit, i) => <div onClick={() => confirm(unit)} className={css(this.styles.unitItem)} key={'assignUnitSearch'+i}>
                        <div>{unit.name}</div>
                    </div>)}
                </div>
                {!unitsList && <div className={css(this.styles.loadingText)}>{intl.formatMessage({id: 'search.assignUnit.loading'})}</div>}
                {units !== null && units.length === 0 && <div className={css(this.styles.loadingText)}>{intl.formatMessage({id: 'comp.table.cell.empty'})}</div>}
            </div>
        );
    }
}

const mapStateToProps = ({ app, units }) => {
    const { width, theme  } = app;
    const { unitsList } = units;
    return { width, theme, unitsList };
};

export default withRouter(injectIntl(connect(mapStateToProps, {
    fetchUnitList,
})(AssignUnitContinuation)));

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            position: 'relative',
            color: theme && theme.color.fontColor,
            
        },
        headerArea: {
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontSize: 18,
            padding: '15px',
            paddingTop: 10,
            fontWeight: 'bold',
        },
        headerTitle: {
            fontSize: 18,
            color: theme && theme.color.themeColor,
            whiteSpace: 'nowrap',
        },
        headerSubtext: {
            fontSize: 15,
            fontWeight: 'normal',
            whiteSpace: 'nowrap',
        },

        searchArea: {
            borderBottom: `1px solid ${theme && theme.color.borderColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 20px',
            marginBottom: 10, 
        },
        searchChild: {
            display: 'flex',
            gap: 3,
            alignItems: 'center',
        },
        searchUnitsAmount: {
            fontStyle: 'italic',
            paddingRight: 10,
        },
        searchInput: {
            border: 'none',
            color: theme && theme.color.fontColor,
            outline: 'none',
            // fontSize: 14,
            marginBottom: 4,
            background: 'none',
        },

        unitListArea: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 8,
            padding: '0 20px',
            paddingBottom: 10,
            maxHeight: '257px', // make this more dynamic? 
            overflowY: 'auto',
        },
        unitItem: {
            color: theme && theme.color.fontColor,
            textAlign: 'center',
            padding: 4,
            borderRadius: 5,
            border: `1px solid ${theme && theme.color.borderColor}`,
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
            transition: '0.2s all ease-in-out',
            cursor: 'pointer',
            ':hover': {
                boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
                backgroundColor: theme && theme.color.themeColor,
                color: theme && theme.color.buttonFontColor,
            }
        },
        loadingText: {
            textAlign: 'center',
        }
    })