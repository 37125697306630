import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';

import Box from 'components/Box';
import MyStores from 'components/ManagerDashboard/MyStores';
import Notifications from 'components/ManagerDashboard/Notifications';
import Employees from 'components/ManagerDashboard/EmployeesOnline';
import CrossSiteSearch from 'components/ManagerDashboard/CrossSiteSearch';
class DashboardDisplay extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        document.title = 'Dashboard | Smart Entry';
    }
    render() {
        const { width } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={12} lg={12}>
                                <Box customHeight={width > 991 && 840}>
                                    <MyStores />
                                </Box>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={12} lg={12}>
                                <Box customHeight={100}>
                                    <CrossSiteSearch />
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <Box customHeight={358}>
                                    <Notifications />
                                </Box>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12}>
                                <Box customHeight={313}>
                                    <Employees />
                                </Box>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, width, height } = app;
    return { theme, width, height };
};

export default withRouter(injectIntl(connect(mapStateToProps, {})(DashboardDisplay)));
