import * as CONSTANTS from 'constants';

const INIT_STATE = {
    accessCodeList: [],
    accessCodeDetails: null,
    dailyCodes: null,
    accessCodeStats: null,
    accessCodeHeader: [
        {
            id: 0,
            title: 'comp.table.header.code',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'accessCode',
            mobile: true,
            width: 200,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'state',
            width: 100,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.user',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'userName',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.unit',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            tablet: true,
            sortTitle: 'unitName',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.date.create',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'createdDate',
            style: { textAlign: 'left' }
        },
        {
            id: 5,
            title: 'comp.table.header.date.update',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'updatedDate',
            style: { textAlign: 'left' }
        },
        {
            id: 6,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 120
        }
    ],
    keypadsHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'entryName',
            mobile: true,
            width: 208,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'state',
            style: { textAlign: 'left' }
        }
    ],
    accessCodeFilters: [
        {
            title: 'filters.all',
            value: ['active', 'inactive', 'error', 'pending', 'setup'],
            active: true
        },
        {
            title: 'comp.button.active',
            value: ['active'],
            active: false
        },
        {
            title: 'db.inactive',
            value: ['inactive'],
            active: false
        },
        {
            title: 'db.error',
            value: ['error'],
            active: false
        },
        {
            title: 'db.pending',
            value: ['pending'],
            active: false
        },
        {
            title: 'db.setup',
            value: ['setup'],
            active: false
        }
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_ACCESS_CODES_LIST: {
            return {
                ...state,
                accessCodeList: action.payload
            };
        }
        case CONSTANTS.SET_ACCESS_CODES_DETAILS: {
            return {
                ...state,
                accessCodeDetails: action.payload
            };
        }
        case CONSTANTS.SET_ACCESS_CODES_HEADERS: {
            return {
                ...state,
                accessCodeHeader: action.payload
            };
        }
        case CONSTANTS.SET_KEYPADS_HEADERS: {
            return {
                ...state,
                keypadsHeader: action.payload
            };
        }
        case CONSTANTS.SET_ACCESS_CODES_FILTERS: {
            return {
                ...state,
                accessCodeFilters: action.payload
            };
        }
        case CONSTANTS.SET_DAILY_CODES: {
            return {
                ...state,
                dailyCodes: action.payload
            };
        }
        case CONSTANTS.SET_ACCESS_CODES_STATS: {
            return {
                ...state,
                accessCodeStats: action.payload
            };
        }
        default:
            return state;
    }
};
