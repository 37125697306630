import * as CONSTANTS from 'constants';

const INIT_STATE = {
    tutorialList: null,
    searchedReleaseList: null,
    showTutorials: false,
    userTutorials: null,
    userSeenTutorials: [],
    releaseFilters: [],
    tutorialTooltips: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_TUTORIALS: {
            return {
                ...state,
                tutorialList: action.payload
            };
        }
        case CONSTANTS.SET_SEARCHED_RELEASES: {
            return {
                ...state,
                searchedReleaseList: action.payload
            };
        }
        case CONSTANTS.SET_RELEASE_FILTERS: {
            return {
                ...state,
                releaseFilters: action.payload
            };
        }
        case CONSTANTS.SET_USER_TUTORIALS: {
            return {
                ...state,
                userTutorials: action.payload
            };
        }
        case CONSTANTS.SET_SHOW_TUTORIALS: {
            return {
                ...state,
                showTutorials: action.payload
            };
        }
        case CONSTANTS.SET_SEEN_TUTORIALS: {
            return {
                ...state,
                userSeenTutorials: action.payload
            };
        }
        case CONSTANTS.SET_TUTORIAL_TOOLTIPS: {
            return {
                ...state,
                tutorialTooltips: action.payload
            };
        }
        case CONSTANTS.ADD_TUTORIAL_TOOLTIP: {
            return {
                ...state,
                tutorialTooltips: state.tutorialTooltips.push(action.payload)
            };
        }
        case CONSTANTS.REMOVE_TUTORIAL_TOOLTIP: {
            return {
                ...state,
                tutorialTooltips: state.tutorialTooltips.filter(item => {
                    return item.name !== action.payload.name
                })
            };
        }
        default:
            return state;
    }
};
